<template>
	<el-card class="box-card tss-main">
		<div class="tss-tit">
			<p class="text-left tss-title">在线选座<span class="tss-min">(仅支持C、D、G开头的动车)</span></p>
			<p class="text-right tss-tit-right">{{selectSeat}}/{{optionSeatNumber}}</p>
		</div>
		<div class="tss-tsy">若所选票席无票，将为您购买其他坐席</div>
		<div class="tss-content">
			<div class="text-left tss-con-main relative flex" >
				<div class="setText">窗</div>
				<div v-for="(item , index) in seatData[0]" :key="item.id+('-'+index)" v-show="index<aisle">
					<div class="iconfont yj-icon-zuowei relative marLeft40 zwfont" :class="{'color-theme':(item.checked==true)}"  @click="chooseSeat(item, index, 0)">
						<span class="absolute ts-center tss-text">{{item.name}}</span>
					</div>
				</div>
				<div class="marLeft40 setText">过道</div>
				<div v-for="(item , index) in seatData[0]" :key="item.id+('+'+index)" v-show="index>=aisle">
					<div class="iconfont yj-icon-zuowei relative marLeft40 zwfont" :class="{'color-theme':(item.checked==true)}"  @click="chooseSeat(item, index, 0)">
						<span class="absolute ts-center tss-text">{{item.name}}</span>
					</div>
				</div>
				<div class="marLeft40 setText">窗</div>
			</div>
			<div class="text-left tss-con-main relative flex" style="margin-top: 20px;" v-if="optionSeatNumber>1?true:false">
				<div class="setText">窗</div>
				<div v-for="(item , index) in seatData[1]" :key="item.id+('-'+index)" v-show="index<aisle">
					<div class="iconfont yj-icon-zuowei relative marLeft40 zwfont" :class="{'color-theme':(item.checked==true)}"  @click="chooseSeat(item, index, 1)">
						<span class="absolute ts-center tss-text">{{item.name}}</span>
					</div>
				</div>
				<div class="marLeft40 setText">过道</div>
				<div v-for="(item , index) in seatData[1]" :key="item.id+('+'+index)" v-show="index>=aisle">
					<div class="iconfont yj-icon-zuowei relative marLeft40 zwfont" :class="{'color-theme':(item.checked==true)}"  @click="chooseSeat(item, index, 1)">
						<span class="absolute ts-center tss-text">{{item.name}}</span>
					</div>
				</div>
				<div class="marLeft40 setText">窗</div>
			</div>
		</div>
	</el-card>
</template>

<script>
	import {mapState} from 'vuex';
	export default{
		name: 'TrainSelectSeat',
		props:{
			//可选座位数量
			optionSeatNumber:{
				type:Number,
				default: 1,
			},
			// 在线选座
			seatInfo:{
				type:Object,
				default(){
					return {};
				},
			},
			//出行人数
			cxrLength:{
				type:Number,
				default: 1,
			}
		},
		
		data() {
			return {
				selectSeat: 0, // 已选座位
				chooseSeatList: [], // 选中座位列表
				currentSeatList:[], // 记忆选中当前座位
				seatTwoList:[ //二等座位
					[
						{
							name:'A',
							id: '1A',
							checked:false,
						},
						{
							name:'B',
							id: '1B',
							checked:false,
						},
						{
							name:'C',
							id: '1C',
							checked:false,
						},
						{
							name:'D',
							id: '1D',
							checked:false,
						},
						{
							name:'F',
							id: '1F',
							checked:false,
						},
					],
					[
						{
							name:'A',
							id: '2A',
							checked:false,
						},
						{
							name:'B',
							id: '2B',
							checked:false,
						},
						{
							name:'C',
							id: '2C',
							checked:false,
						},
						{
							name:'D',
							id: '2D',
							checked:false,
						},
						{
							name:'F',
							id: '2F',
							checked:false,
						},
					]
				],
				seatOneList:[ //一等座位
					[
						{
							name:'A',
							id: '1A',
							checked:false,
						},
						{
							name:'C',
							id: '1C',
							checked:false,
						},
						{
							name:'D',
							id: '1D',
							checked:false,
						},
						{
							name:'F',
							id: '1F',
							checked:false,
						},
					],
					[
						{
							name:'A',
							id: '2A',
							checked:false,
						},
						{
							name:'C',
							id: '2C',
							checked:false,
						},
						{
							name:'D',
							id: '2D',
							checked:false,
						},
						{
							name:'F',
							id: '2F',
							checked:false,
						},
					]
					
				],
				seatSWList:[ //商务座
					[
						{
							name:'A',
							id: '1A',
							checked:false,
						},
						{
							name:'C',
							id: '1C',
							checked:false,
						},
						
						{
							name:'F',
							id: '1F',
							checked:false,
						},
					],
					[
						{
							name:'A',
							id: '2A',
							checked:false,
						},
						{
							name:'C',
							id: '2C',
							checked:false,
						},
						{
							name:'F',
							id: '2F',
							checked:false,
						},
					]
					
				],
				seatData:[],// 选座列表
				aisle:0, //过道
			}
		},
		computed:{
			...mapState({
				trainYdInfo: state => state.train.trainYdInfo,
			}),
			// 火车票座位级别
			seatType(){
       let seatType
       if(this.$route.query.sqdType && this.$route.query.sqdType == 'xak'){
         seatType = this.trainYdInfo.cabinCode;
       }else{
         seatType = this.trainYdInfo.selectSeat.seatType;
       }
       return seatType;
			},
		},
		watch:{
			seatInfo:{
				handler(val){
					this.chooseSeatList= val.chooseSeatList; // 选中座位列表
					this.currentSeatList = val.seatIdList; // 记忆选中当前座位
				},
				immediate:true,
			},
		},
		mounted(){
			this.setSeatType()
		},
		methods: {
			setSeatType(){
				if(this.seatType=='O'){
					this.seatData = this.seatTwoList;
					this.aisle = 3;
				}else if(this.seatType=='M'){
					this.seatData = this.seatOneList;
					this.aisle = 2;
				}else if(this.seatType == 9){
					this.seatData = this.seatSWList;
					this.aisle = 2;
				}
				
				if(this.currentSeatList.length>0 && this.cxrLength>0){
					let num = this.seatData.length;
					for(let i=0; i<num ; i++){
						this.seatData[i].map((item)=>{
							if(this.currentSeatList.indexOf(item.id)!=-1){
								item.checked = true;
							}
							return item
						})
					}
					
				}
			},
			// 选中座位
			chooseSeat(params,number, i) {
				// 只有1个乘车人时
				if(this.optionSeatNumber==1){
					this.chooseSeatList = [];
					this.seatData[i].forEach((item)=>{
						if(params.id == item.id){
							if(this.currentSeatList.length>0 && this.currentSeatList.indexOf(params.id)!=-1){
								this.chooseSeatList = [];
								this.currentSeatList = [];
								item.checked = false;
							}else{
								this.currentSeatList = [];
								item.checked = true;
								this.chooseSeatList.push(item);
								this.currentSeatList.push(item.id)
							}
							
						
						}else{
							item.checked =false;
						}
						
					})
					this.selectSeat = this.chooseSeatList.length;
				}
				
				// 多选时
				if(this.optionSeatNumber>1 && this.optionSeatNumber){
					this.seatData[i].forEach((item,index)=>{
						if(item.id == params.id){
							if(this.currentSeatList.length>0 && this.currentSeatList.indexOf(params.id)!=-1){
								let inx = this.currentSeatList.indexOf(params.id);
								this.chooseSeatList.splice(inx,1);	
								this.currentSeatList.splice(inx,1);
								item.checked = false;
								this.selectSeat = this.currentSeatList.length;
							}else{
								if(this.chooseSeatList.length<this.optionSeatNumber){
									item.checked = true;
									this.chooseSeatList.push(item);	
									this.currentSeatList.push(params.id);
									this.selectSeat = this.currentSeatList.length;
								}else{
									let shift = this.chooseSeatList.shift();
										shift.checked =false
										this.chooseSeatList.push(item);
										item.checked = true;
										this.currentSeatList.shift();
										this.currentSeatList.push(params.id)
										this.selectSeat = this.currentSeatList.length;
								}
							}
						}
					})	
				}
				
				this.$emit('chooseSeatList',this.currentSeatList,this.chooseSeatList)		
			},
		}
	}
</script>

<style  scoped lang="scss">
	.el-card.is-always-shadow{
		border: 1px solid #E6E6E6;
		box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 0;
	}
	.tss-main{
		font-family: '微软雅黑';
		.tss-min{
			font-size: 14px;
			color: #ccc;
			font-weight: 400;
			padding-left: 10px;
		}
		
		/deep/ .el-card__body{
			padding:30px;
		}
		.tss-tit{
			display: flex;
			justify-content: space-between;
			.tss-title{
				font-size: 24px;
				font-weight: bold;
				color: #223344;
				line-height: 24px;
				
			}
			.tss-tit-right{
				font-size: 15px;
				font-weight: 400;
				color: #223344;
				line-height: 15px;
			}
		}
		.tss-tsy{
			text-align: left;
			font-size: 14px;
			background: #fff2de;
			line-height: 20px;
			padding-left: 10px;
			// width: 300px;
			color: #ffab1c;
			margin:10px 0 30px;
		}
		.tss-content{
			margin-bottom: 20px;
			.tss-con-main{
				.setText{
					font-size: 12px;
					font-weight: bold;
					color: #8F98A9;
					line-height: 38px;
				}
			}
		}
		.tss-text{
			font-size: 14px;
			color:#fff;
			font-weight: bold;
			z-index:1;
		}
		.font16{
			font-size: 16px;
		}
		.font48{
			font-size: 48px;
		}
		.zwfont{
			font-size: 38px;
			cursor: pointer;
			color: #CBDCF0;
		}
		.text-left{
			text-align: left;
		}
		.text-right{
			text-align: right;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.flex{
			display: flex;
		}
		.mar-l-r{
			margin: 0 15px;
		}
		.marLeft40{
			margin-left: 40px; 
		}
		.ts-center{
			top: 35%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		.bold{
			font-weight: bold;
		}
		.line-h48{
			line-height: 48px;
		}
		.color-theme{
			color: #006BB9;
		}
		.pointer{
			cursor: pointer;
		}
	}
	
</style>
