<template>
  <div class="tye-main">
    
    <!-- 12306账号切换 -->
    <train-login @account12306Login="account12306Login" @needSuccess="needSuccess" @loginSuccess="loginSuccess"
      :loginPerson="loginPerson" @cancelLogin="cancelLogin" :loginAgain="loginAgain" :needCheck="needCheck">
    </train-login>
    
    <!-- 出行人数据 -->
    <train-cxr></train-cxr>
    
    <!-- 审批规则 差旅信息 -->
    <yj-travel-info v-if="tripType==1 " :cxrList="cxrList" ref="trTravelInfo" :orderType="11009" :proType="10904"
      :defaultPoject="defaultPoject" :clbzMap="clbzMap" :ticketPrice="price"
      @selectReason="getWbReason" @chooseProject="currentProjectData" @getCxrList="getCxrList" @chooseSpgz="getSpgz"
      @changeWuXuShenPi="changeWuXuShenPi"></yj-travel-info>

    <!-- 联系人 -->
    <yj-contacts @contactInfo="contactInfo"></yj-contacts>
    
    <!-- 在线选座 -->
    <train-select-seat :optionSeatNumber="optionSeat" :cxrLength="cxrList.length" :seatInfo="seatInfo"
      @chooseSeatList="chooseSeatList"
      v-if="(seatType=='O' || seatType=='M' || seatType==9) && (cxrList.length<6 && cxrList.length>0) ">
    </train-select-seat>
    
    <!-- 保险 -->
    <yj-in-surance v-if="isBxShow" :cplx="10904" :defaultBxList="bxOrderList" @chooseBxcp="chooseBxcp"
      @isShowBX="isShowBX" :bxTitle="'您的航程已经获保障'" :tripType="searchData.tripType"></yj-in-surance>
      
    <!-- 无座怎么办 -->
    <el-card class="box-card">
      <div v-for="item in remainTicket" :key="item.id" class="tye-seat" @click="acceptSeat(item.checked)">
        <span class="bold">{{ item.name }}</span>
        <el-checkbox v-model="item.checked"></el-checkbox>
      </div>
    </el-card>
    
    <!-- 南航一键入会 及 提示 -->
    <train-notice></train-notice>
    
    <el-button class="tye-btn" @click="next">
      下一步
    </el-button>
  </div>
</template>

<script>
  import mixin from '@/mixin';
import {
Loading
} from 'element-ui';
import {
mapGetters, mapMutations, mapState
} from 'vuex';
import TrainNotice from './trainEditDialog/TrainNotice';
import TrainCxr from "./trainYdEdit/TrainCxr";
import TrainLogin from './trainYdEdit/TrainLogin';
import TrainSelectSeat from "./trainYdEdit/TrainSelectSeat";
  export default {
    name: 'TrainYdEdit',
    data() {
      return {
        remainTicket: [{
          name: '余票不足时，接受无座。',
          checked: true,
          id: 'ypbzjswz'
        }],
        contantObj: {}, // 联系人信息
        passengerList: [], // 乘客列表

        isAcceptSeat: true,
        trainAccount: '', // 火车票账号信息
        trainPassWord: '', //火车票密码
        endingNumber: '', //火车票后四位


        orderId: '', //订单Id

        loadingInstance: "",

        trParams: {}, //火车票参数集合

        needCheck: false, // 下单失败时是否需要核验
        loginAgain: false, // 重新登录
        loginPerson: {
          username: '',
          password: '',
        },

        appInfo: {
          gzId: '', // 审批规则id
          gzmc: '', // 审批规则名称
          gzdm: '', // 审批规则代码
        },
        isWuXuShenPi: true,
        bookBxBean: {},
        isBxShow: true,
        isXak: this.$route.query.sqdType=='xak'?true:false, // 是否 希安康快速预订
      }
    },

    mixins: [mixin.commonMixin],
    components: {
      'train-notice': TrainNotice,
      'train-cxr': TrainCxr,
      'train-select-seat': TrainSelectSeat,
      'train-login': TrainLogin,
    },
    computed: {
      ...mapState({
        searchData: state => state.train.trainQuery.searchData,
        cxrList: state => state.train.cxrList, //(出行人)
        passengerLength: state => state.train.passengerLength,
        trainEdit: state => state.train.trainEdit, // 火车下单页信息（出差项目，审批）
        trainYdInfo: state => state.train.trainYdInfo, // 火车票预订信息（车次）
      }),
      ...mapGetters({
        getVipParamterByParNo: 'common/getVipParamterByParNo',
      }),
      // 
      bxOrderList() {
        return this.trainEdit.bxOrderList || [];
      },
      seatInfo() {
        return this.trainEdit.seatInfo || {};
      },
      // 违背原因
      clbzMap() {
        var obj = {
          // 当前使用谁的差旅标准
          useNameclbz: this.searchData.useNameclbz,
          // 去程违背原因
          violateCode: this.searchData.violateCode,
          violateItem: this.searchData.violateItem,
          reasonCode: this.searchData.reasonCode,
          reasonDesc: this.searchData.reasonDesc,

        }
        return obj
      },
      tripType() {
        return this.searchData.tripType;
      },
      userInfo() {
        let userInfo = this.$common.sessionget('user_info') || {};
        return userInfo
      },
      // 企业信息
      vipCorp() {
        return this.userInfo.vipCorp || {};
      },
      // 1订单模式 2出差申请单模式
      bookStyle() {
        return this.vipCorp.bookStyle;
      },

      // 默认项目
      defaultPoject() {
        return this.trainEdit.project
      },
      // 火车票座位级别
      seatType() {
        let seatType
        if(this.isXak){
          seatType = this.trainYdInfo.cabinCode;
        }else{
          seatType = this.trainYdInfo.selectSeat.seatType;
        }
        return seatType;
      },
      // 服务费
      fwfPrice() {
        return this.tripType == 1 ? ((this.trainYdInfo.fee && this.trainYdInfo.fee.feeAutoB) || 0) : ((this.trainYdInfo
          .fee && this.trainYdInfo.fee.feeAutoP) || 0);
      },
      // 使用人（差旅标准）
      travelName() {
        return this.searchData.useNameclbz;
      },
      
      //可选座位数量
      optionSeat() {
        return this.cxrList.length;
      },
      
      // 火车票单价金额
      price(){
        let price = 0;
        if(this.isXak){
          price = this.trainYdInfo.dj;
        }else{
          price = this.trainYdInfo.selectSeat.price;
        }
        return price
      }
    },

    methods: {
      ...mapMutations({
        SET_SEARCH_DATA: 'train/setSearchData',
        SET_TrainEdit: 'train/setTrainEdit',
        SET_CxrList: 'train/setCxrList',
      }),
      // 是否无需审批
      changeWuXuShenPi(val) {
        this.isWuXuShenPi = val;
      },
      // 是否接受无座
      acceptSeat(bool) {
        this.isAcceptSeat = bool;
      },
      chooseSeatList(idList, list) {

        let obj = {
          seatInfo: {
            seatIdList: idList,
            chooseSeatList: list,
          }
        }
        //预订保险
        this.SET_TrainEdit(obj);
      },
      // 是否显示保险， 当保险列表为空时不展示
      isShowBX(bool) {
        this.isBxShow = bool;
      },
      // 选中保险产品
      chooseBxcp(list) {
        let obj = {
          bxOrderList: list,
        }
        //预订保险
        this.SET_TrainEdit(obj);

      },
      //获取违背原因
      getWbReason(obj) {
        this.SET_SEARCH_DATA(obj)
      },

      // 12306 账号信息
      account12306Login(data={}) {
        this.trainAccount = data.username;
        this.trainPassWord = data.password;
        this.endingNumber = data.endingNumber;
      },
      // 核验成功
      needSuccess(bool, hyjg) {
        this.needCheck = false;
        // if (hyjg) {
        //   this.next();
        // }
      },
      // 登录成功
      loginSuccess() {
        this.loginAgain = false;
      },
      // 取消登录 取消核验
      cancelLogin() {
        this.loginAgain = false;
        this.needCheck = false;
      },
      // 联系人信息
      contactInfo(contantObj) {
        this.contantObj = contantObj;
      },
      // 出差项目
      currentProjectData(obj) {
        let info = {
          project: obj,
        }
        this.SET_TrainEdit(info);
      },
      // 获取审批规则
      getSpgz(info) {
        this.appInfo = {
          gzId: info.gzId, // 审批规则id
          gzmc: info.gzmc, // 审批规则名称
          gzdm: info.gzdm, // 审批规则代码
        }

      },
      // 获取乘车人信息列表
      getEmpInfo(list) {
        //证件类型枚举值
        //1：1'中国居民身份证' 2：B'护照',3:'港澳通行证',4:台胞证,10:外国人永久居留证
        let idTypeInfo = {
          '1': '1',
          '2': 'B',
          '3': 'C',
          '4': 'G',
          '10': 'H',
        }
        // 判断是否是希安康 快速预订
       
        this.passengerList = [];
        list.map(item => {
          let obj = {};
          for (let i = 0; i < item.idcardList.length; i++) {
            if (item.useCardType == item.idcardList[i].idType) {
              obj = {
                "born": item.born || '',
                "costCenterCode": item.costCenterCode || '',
                "costCenterId": item.costCenterId || '',
                "costCenterName": item.costCenterName || '',
                "email": item.empEmail || item.email || '',
                "empId": item.empId,
                "idCode": item.idcardList[i].idNo,
                "idType": idTypeInfo[item.idcardList[i].idType],
                "insureAmount": item.insureAmount || 0,
                "nation": item.idcardList[i].placeOfIssue,
                "passengerName": item.empName,
                "passengerType": item.psgType || '1', //成人，儿童
                "passportPeriod": item.idcardList[i].validDate || '',
                "phoneNumber": item.phoneNumber || '',
                "sex": item.sex || '',
                "ticketPrice": this.isXak?this.trainYdInfo.dj || 0:this.trainYdInfo.selectSeat.price || 0,
                "ticketType": 1 || item.psgType,
              }
              // 如果类型为外国人永久居留证，有英文名称就展示英文名称。也可以手动输入英文名
              if (obj.idType == "H") {
                if (item.idcardList[i].firstName && item.idcardList[i].lastName) {
                  obj.firstName = item.idcardList[i].firstName;  
                  obj.lastName = item.idcardList[i].lastName;  
                  obj.passengerName = item.idcardList[i].firstName + "" + item.idcardList[i].lastName;
                }
              }
            }
          }
          this.passengerList.push(obj)
        })
      },
      // 处理乘车人数据 成本中心
      getCxrList(cxrList) {
        // 需要处理的就是单订默认
        this.SET_CxrList(cxrList)
        cxrList.forEach((item, index) => {
    
          this.passengerList[index] = this.passengerList[index] || {};
          this.passengerList[index].costCenterCode = cxrList[index].costCenterCode;
          this.passengerList[index].costCenterId = cxrList[index].costCenterId;
          this.passengerList[index].costCenterName = cxrList[index].costCenterName;
        })
      },
      
      //根据年月日出发时分和运行时间，返回出发时间
      getDateByTimes(startDate, startTime, runMinute) {
      	var runTime = (runMinute - 0) * 60 * 1000;
      	var d = startDate.split('-');
      	var t = startTime.split(':');
      	var year = d[0] - 0;
      	var month = d[1] - 1;
      	var dat = d[2] - 0;
      	var hours = t[0] - 0;
      	var minutes = t[1] - 0;
      	startDate = new Date(year, month, dat, hours, minutes, 0);
      	return startDate;
      },
      
      //获取车次到达时间
      getToDate(startDate, arriveDays) {
      	// var runTime = (runMinute - 0) * 60 * 1000;
      	// var startDate = this.getDateByTimes(startDate, startTime, runMinute);
      	// var dat1 = new Date(startDate.getTime() + runTime);
      	// var nowYear = dat1.getFullYear();
      	// var nowMonth = dat1.getMonth() + 1;
      	// nowMonth = nowMonth > 9 ? nowMonth : '0' + nowMonth;
      	// var nowDay = dat1.getDate();
      	// nowDay = nowDay > 9 ? nowDay : '0' + nowDay;
      	// return nowYear + '-' + nowMonth + '-' + nowDay; 
				return this.$common.getDateMing(startDate, arriveDays);
      },
      // 验证火车票信息
      vailTrainParams() {
        this.loginPerson = {
          username: this.trainAccount,
          password: this.trainPassWord,
          endingNumber: this.endingNumber,
        }
        let ticketPrice
        
        if(this.isXak){
          ticketPrice = parseFloat(this.trainYdInfo.dj) * this.optionSeat;
        }else{
          ticketPrice = parseFloat(this.trainYdInfo.selectSeat.price) * this.optionSeat; //+ this.fwfPrice;
        }
       

        let errMsg = "";
        // 出差申请单模式
        // 因公因私
        if (!this.trainAccount) {
          errMsg = "请登录12306账号";
          return errMsg
        } else if (!this.trainPassWord) {
          errMsg = "12306账号密码为空";
          return errMsg
        } else if (this.endingNumber && this.endingNumber.length != 4) {
          errMsg = "登录账号关联证件号后四位格式不正确";
          return errMsg
        }

        if (this.passengerList.length == 0) {
          errMsg = "乘客信息为空，请添加乘客";
          return errMsg
        } else if (!this.contantObj.linkName) {
          errMsg = "联系人为空";
          return errMsg
        } else if (!this.contantObj.linkPhone) {
          errMsg = "联系人手机号为空";
          return errMsg
        } else if (!this.isXak && this.trainYdInfo.selectSeat.seatCount < this.passengerList.length) {
          errMsg = "车票余数不足，请减少出行人或选择其他车次";
          return errMsg
        }
  
        // 2.验证差旅信息、项目、成本中心、审批规则
        if (this.tripType == 1) {
          var clzcRule = this.$refs['trTravelInfo'] && this.$refs['trTravelInfo'].validTravelInfo();
          if (!clzcRule.bool) {
            errMsg = clzcRule.msg
            return errMsg
          }
        }

        // 二代身份证
        let idNo = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        // 护照
        let passport = /^[a-zA-Z0-9]{1,10}$/;
        // 港澳通行证 （新老版）
        let gatxzCheck = /^[CW]\d{8}$/;
        // 台胞证 （—）
        // 新版台胞证  /^830000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/
        // 老版台胞证  /^\d{10}[DAB]$/
        // 支持新老版 /^(?:(830000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX])|\d{10}[DAB])$/
        let tbzCheck = /^830000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/

        this.passengerList.forEach((item, i) => {

          if (!item.idCode) {
            errMsg = item.passengerName + "证件信息为空";
            return errMsg
          } else if (!item.idType) {
            errMsg = item.passengerName + "请选择证件类型";
            return errMsg
          } else if (!item.passengerName) {
            errMsg = '第' + (i + 1) + '位乘客姓名为空' + "请填写乘客姓名";
            return errMsg
          } else if (!item.passengerType) {
            errMsg = '第' + (i + 1) + '位乘客' + "请选择乘车人类型";
            return errMsg
          } else if (!item.sex) {
            errMsg = '第' + (i + 1) + '位乘客' + "请选择性别";
            return errMsg
          }

          if (item.idType != 1) {
            if (!item.nation) {
              errMsg = item.passengerName + "国籍信息为空";
              return errMsg
            } else if (!item.passportPeriod) {
              errMsg = item.passengerName + "证件有效期为空";
              return errMsg
            } else if (!item.born) {
              errMsg = item.passengerName + "出生日期为空";
              return errMsg
            }
          }
          
          // 外国人永久居住证校验规则
          var otherCountryReg = /^[a-zA-Z]{3}\d{12}$/;
            
          if (item.idCode && item.idType) {
            if (!idNo.test(item.idCode) && item.idType == 1) {
              errMsg = item.passengerName + "身份证件 号码错误";
              return errMsg
            } else if (!passport.test(item.idCode) && item.idType == "B") {
              errMsg = item.passengerName + "护照证件 号码错误";
              return errMsg
            } else if (!gatxzCheck.test(item.idCode) && item.idType == "C") {
              errMsg = item.passengerName + "港澳通行证件 号码错误";
              return errMsg
            } else if (!tbzCheck.test(item.idCode) && item.idType == "G") {
              errMsg = item.passengerName + "台胞证 号码错误";
              return errMsg
            } else if (!otherCountryReg.test(item.idCode) && item.idType == 'H') {
              errMsg = item.passengerName + "外国人永久居留证件号码不符合15位检验准则!";
              return errMsg
            } 
          }
        })
        // 获取座位
        let seatIdList = this.seatInfo.seatIdList || [];

        // 因公 出差审请单 与单订模式
        if (this.$route.query.sqdType == 'xak') {
          this.trParams = {
            acceptNoSeat: this.isAcceptSeat, //  //是否接受无座 默认为true ,
            appId: this.appInfo.gzId || '', //审批规则ID | C端无需传 ,
            "bookBxBean": this.bookBxBean,
            chooseSeats: seatIdList.length > 0 ? seatIdList.join(",") :
            '', //选座 范围:1A-1F 2A-2F 几个人就几个(一个人时,不允许出现2A-2F) ,
            linkEmail: this.contantObj.linkEmail || '', //联系人电子邮箱 ,
            linkMobile: this.contantObj.linkPhone, //联系人手机 ,
            linkTel: this.contantObj.linkPhone || '', //联系人电话 ,
            linkman: this.contantObj.linkName || '', //联系人 ,
            ontTicketPrice: this.trainYdInfo.dj || 0, //车票单价；必传 ,
            orderFrom: 10503, ///订单来源；必传；C端固定传10503 ,
            passengerList: this.passengerList, //乘客集合 | 必传 ,
            password: this.trainPassWord || '', //12306密码 ,
            projectCode :this.trainEdit.project.projectCode, //项目代码；C端无需传 ,
            projectId : this.trainEdit.project.id, //项目id；C端无需传 ,
            projectName : this.trainEdit.project.projectName,//项目名称 ,
            remark: '', //备注 ,
            remarksCu: '', //客户备注 ,
            routeId: this.$route.query.routeId, //行程id ,行程id注意一下需要单独传入
            sellServiceCharge: this.fwfPrice, //销售服务费 ,
            ticketPrice: ticketPrice, //总票价；必传 ,
            trainAccount: this.trainAccount, //12306账号
          }
        } else {
          this.trParams = {
            "acceptNoSeat": this.isAcceptSeat,
            "appId": this.appInfo.gzId,
            "bookBxBean": this.bookBxBean,
            "chooseSeats": seatIdList.length > 0 ? seatIdList.join(",") : '',  
            "fromDate": this.searchData.depDate,
            "fromStationCode": this.trainYdInfo.fromStationCode,
            "fromStationName": this.trainYdInfo.fromStationName,
            "fromTime": this.trainYdInfo.startTime,
            "ifViolate": 0,
            "linkEmail": this.contantObj.linkEmail || '',
            "linkMobile": this.contantObj.linkPhone || '',
            "linkTel": this.contantObj.linkPhone || '',
            "linkman": this.contantObj.linkName || '',
            "ontTicketPrice": this.trainYdInfo.selectSeat.price || 0,
            "orderFrom": 10503,
            "passengerList": this.passengerList,
            "password": this.trainPassWord,
            "endingNumber": this.endingNumber,
            "reasonCode": this.searchData.reasonCode || "",
            "reasonDesc": this.searchData.reasonDesc || "",
            // "remark": "",
            // "remarksCu": "",
            "seatType": this.trainYdInfo.selectSeat.seatType,
            "sellServiceCharge": this.fwfPrice,
            // "supplierCode": "",
            // "supplierId": "",
            // "supplierName": "",
            "ticketPrice": ticketPrice || 0,
            "toDate": this.getToDate(this.searchData.depDate, this.trainYdInfo.arriveDays) || '',
            "toStationCode": this.trainYdInfo.toStationCode,
            "toStationName": this.trainYdInfo.toStationName,
            "toTime": this.trainYdInfo.arriveTime,
            "trainAccount": this.trainAccount,
            "trainCode": this.trainYdInfo.trainCode,
            "trainId": this.trainYdInfo.trainId || '',
            "trainNo": this.trainYdInfo.trainNo,
            "travelDistance": this.trainYdInfo.distance,
            // "travelStandard": '',
            "tripType": this.tripType,
            "violateitem": this.searchData.violateItem,
            "violateitemCode": this.searchData.violateCode,
          }
          
          if (this.tripType == 1 && this.bookStyle == 2) {
            this.trParams.ccsqdId = this.searchData.ccsqdId;
            this.trParams.ccsqdNo = this.searchData.ccsqdNo;
            this.trParams.routeId = this.searchData.routeId;
          } else if (this.tripType == 1 && this.bookStyle == 2 || this.bookStyle == 1) {
            this.trParams.projectCode = this.trainEdit.project.projectCode;
            this.trParams.projectId = this.trainEdit.project.id;
            this.trParams.projectName = this.trainEdit.project.projectName;
          }
        }
        this.trParams.bookBxBean = this.getBookBxBean(this.bxOrderList, this.passengerList)
        return errMsg
      },
      // 下一步 火车票数据收集，及箱单
      next(trParams={}) {
        const loading = this.$loading({
          lock: true,
          text: '下单中，请勿关闭页面',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)',
        });
        loading.$el.style.fontSize = "30px";

        this.loadingInstance = Loading.service(loading);
        this.getEmpInfo(this.cxrList);
        let bool = this.vailTrainParams();

        if (bool) {
          this.$alert(bool, '温馨提示', {
            type: 'error',
          })
          this.loadingInstance.close();
          return false
        }
		
		trParams = !trParams.continueBook? this.trParams: trParams;
        this.trSubmit(trParams);
      },
      // 调用下单接口火车票 提交订单
      trSubmit(trParams) {
		trParams.continueBook = trParams.continueBook || 0;   
        let urlStr = this.$route.query.sqdType == 'xak' ? 'xakCcsqd.trBookOrder' : 'trBook.trBookOrder'
        this.$conn.getConn(urlStr)(trParams, (res) => {

          this.loadingInstance.setText('订座中，请稍等');
          let data = res.data || {};
          this.getTrainDetail(data.orderId)
          let obj = {
            seatInfo: {
              seatIdList: [],
              chooseSeatList: [],
            },
            bxOrderList: [],
          }
          //清除保险及座位
          this.SET_TrainEdit(obj);
        }, (err) => {
		  this.loadingInstance.close();
		  if (err.errCode == '7514' || err.errCode == '7515') {
			this.$confirm(err.tips, '提示', {
				confirmButtonText: '继续',
				cancelButtonText: '取消',
				showCancelButton: false,
				closeOnClickModal: false,
				type: 'warning'
			}).then((v) => {
				trParams.continueBook = 1 // 继续预订
				this.next(trParams);
			}).catch(() => {
						 
			})
		  } else {
			  if (err.errCode == '7527') {
				// 重新登录
				this.loginAgain = true;
			  } else if (err.errCode == '7524' || err.errCode == '7530') {
				// 需要核验
				this.needCheck = true;
			  } 
			  this.$message({
				message: err.tips,
				type: 'error'
			  })
		  }	
        })
      },
      // 订单完成查看订单状态信息
      getTrainDetail(orderId) {
        let obj = {
          id: orderId
        };
        this.$conn.getConn('trBook.trOrderDetail')(obj).then((res) => {
          let data = res.data || {};
          //orderStatus:订单状态:
          //1.待审批2.审批中3.订座中4.待支付5.出票中6.已出票
          //7.已取消8.审批拒绝9.取消中15.抢票中16.抢票失败 ,
          this.$common.sessionset('orderStatus', data.orderStatus)
          //需要送审、去详情页
          if (data.orderStatus == 1) {
            this.loadingInstance.close();
            this.goToTrainDetail(orderId)
          }
          //占座中,重新调用订单详
          if (data.orderStatus == 3) {
            this.loadingInstance.close();
            this.goToTrainDetail(orderId);
          }
          //待支付状态、去支付页
          if (data.orderStatus == 4) {
            this.loadingInstance.close();
            // 自动审批 因公 有公司支付权限
            var autoPay = this.getVipParamterByParNo(20004, 3)
            //自动支付权限   因公   公司支付权限  
            if (autoPay && this.tripType == 1 && this.userInfo.empInfo.useAccount) {
              this.goToTrainDetail(orderId)
            } else {
              this.goToTrainDetail(orderId)

            }
          }
          if (data.orderStatus >= 5) {
            this.loadingInstance.close();
            this.goToTrainDetail(orderId)
          }
        }).catch((err) => {

        })
      },
      // 需要送审、去完成页
      goToComplete(orderId) {
        this.$router.push({
          path: "trainComplete",
          query: {
            'orderId': orderId
          }
        })
      },
      // 去详情页
      goToTrainDetail(orderId) {
        this.$router.push({
          path: "/yjDetail/trainDetail",
          query: {
            'orderId': orderId
          }
        })
      },
      // 去支付页面
      goToTrainPay(orderId) {
        this.$router.push({
          path: "/yjDetail/payment",
          query: {
            'orderId': orderId,
            orderType: 11009
          }
        })
      },
      // 待支付页面
      goToPaidTrainPay(orderId) {
        this.$router.push({
          path: "/yjTravel/myTravel/userCenter/order",
          query: {
            toBePaid: 1
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  /deep/ .el-loading-text {
    font-size: 30px;
  }

  .tye-main {
    font-family: '微软雅黑';

    /deep/ .el-loading-text {
      font-size: 0;
    }


    .el-card.is-always-shadow {
      border: 1px solid #E6E6E6;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0;
    }

    .box-card {
      margin-top: 20px;
    }

    .tye-btn {
      width: 100%;
      background: #FF9524;
      font-size: 24px;
      font-weight: 600;
      color: #FFFFFF;
      height: 80px;
      box-shadow: 4px 7px 8px 0px rgba(255, 149, 36, 0.16);
      margin-top: 20px;
      margin-bottom: 50px;
      border: none;
    }

    .tye-btn:hover {
      opacity: 0.8;
    }

    .tye-seat {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 0;
    }

  }
</style>
