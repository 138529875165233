<template>
	<div class="cl-main">
		<el-card>
			<div class="flex between">
				<div class="font15 bold line-h32">
					常用地址
				</div>
				<el-button class="el-icon-plus" type="primary" @click="address">
					新增地址
				</el-button>
			</div>
			<div style="height: 20px;"></div>
			<el-table
				:data="addressList"
				style="width: 100%"
				:header-cell-style="header"
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .8)"
				element-loading-spinner = "el-icon-loading"
				element-loading-text = "加载中，请稍后..."
				class="bghover"		
			>
				<el-table-column type="index" width="50"></el-table-column>
				<el-table-column label="收件人" prop="recipient" width="120"></el-table-column>
				<el-table-column label="所在地区" prop="cityName">
					<template slot-scope="scope">
						<span>{{scope.row.cityName}}</span>
						<span>{{scope.row.cName}}</span>				
					</template>  
				</el-table-column>
				<el-table-column label="详细地址" prop="addr" width="150"></el-table-column>     
				<el-table-column label="邮编"  prop="post"  width="80"></el-table-column>
				<el-table-column label="联系手机" prop="phoneNumber" width="100"></el-table-column>    
				<el-table-column label="默认地址" prop="phoneNumber" width="75">
					<template slot-scope="scope">
						<span>{{scope.row.isDefault==1?'是':'否'}}</span>		
					</template>  
				</el-table-column>    
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="editCommonAddress(scope.row)">编辑</el-button>
						<el-button type="text" @click="removeAddress(scope.row.empAddrId)">删除</el-button>	
					</template>  
				</el-table-column>  	   
			</el-table>	
		</el-card>
		<el-dialog
			:title="title==1?'新增常用地址':'编辑常用地址'"
			:close-on-click-modal="false"
			:visible.sync="visible"
			:before-close="handleClose"
			:destroy-on-close="true"
			style="text-align: left;"
			>
			<el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="所在地区" prop="province">
					<el-select v-model="ruleForm.province" class="w85 widthSet" filterable :filter-method="sreachProvince" @change="chooseProvince" placeholder="请选择">
						<el-option
							v-for="item in provinceList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>
					<span class="padLR5">省</span>
					<el-select v-model="ruleForm.city " class="w130 widthSet2" filterable :filter-method="sreachCity" placeholder="请选择城市">
						<el-option
							v-for="item in cityList"
							:key="item.id"
							:label="item.cityName"
							:value="item.id">
						</el-option>
					</el-select>
					<span class="padLR5">市</span>
				</el-form-item>
				<el-form-item label="详细地址" prop="addr" >
					<el-input v-model="ruleForm.addr" class="addrWidth" placeholder="请输入详细地址"></el-input>
				</el-form-item>
				<el-form-item label="邮政编号" prop="post" style="width:100%;">
					<el-input v-model="ruleForm.post" placeholder="请输入邮政编号"></el-input>
				</el-form-item>
				<el-form-item label="收件人姓名" prop="recipient" style="width:100%;">
					<el-input v-model="ruleForm.recipient" placeholder="请输入收件人姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phoneNumber">
					<el-input v-model="ruleForm.phoneNumber"  placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item label="" prop="isDefault">
					<el-checkbox label="设为默认地址" v-model="checked"></el-checkbox>
				</el-form-item>
				
			</el-form>	
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取消</el-button>
				<el-button type="primary" @click="saveAddress('ruleForm')">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'Address',
		
		data() {
			// 验证省份城市
			let valiProvince = (rule, value, callback)=>{
				if(value){
					let city = this.ruleForm.city;
					if(city){
						callback()
					}else{
						callback(new Error('请选择城市'))
					}
				}
			};
			// 验证手机号
			let valiPhoneNumber = (rule, value, callback)=> {
				const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
				setTimeout(() => {
				    if (phoneReg.test(value)) {
				        callback()
				    } else {
				        callback(new Error('手机号码格式不正确'))
				    }
				}, 100)
			};
			// 验证邮编
			let valiPost = (rule, value, callback)=>{
				const postReg = /^[0-9]\d{5}(?!\d)$/
				if(value){
					if (postReg.test(value)) {
					    callback()
					} else {
					    callback(new Error('邮编格式不正确'))
					}
				}else{
						 callback()
				}
				
			};
			// 验证邮箱
			let valiEmail = (rule, value, callback)=> {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
					if(value){
						if (mailReg.test(value)) {
						    callback()
						} else {
						    callback(new Error('请输入正确的邮箱格式'))
						}
					}else{
						 callback()
					}
					
			};
			return {
				header:{background:'#F2F2F2'},
				addressList: [], // 常用地址列表
				isEmpty: false, // 当列表状态为空时，显示
				visible: false, 
				ruleForm: {  // 基本信息参数
				    addr: "",
				    area: "",
				    city: "",
				    email: "",
				    empAddrId: "",
				    isDefault: 0,
				    phoneNumber: "",
				    post: "",
				    province: "",
				    recipient: ""
				},
				
				rules: {
					province:[
						{ required: true, message: '请选择城市', trigger: 'change' },
						{ validator: valiProvince, trigger: 'blur'},
					],
					addr:[
						{ required: true, message: '请输入详细地址', trigger: 'blur' },
					],
					recipient: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
					],
					post: [
						{ validator: valiPost, trigger: 'blur'},
					],
				    phoneNumber: [
				        { required: true, message: '请输入手机号', trigger: 'blur' },
				        { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' },
						{ validator: valiPhoneNumber, trigger: 'blur'}
				    ],
					email:[
						{ validator: valiEmail, trigger: 'blur' }
					],
				},
				provinceList: [] , // 省份列表
				cityList: [], // 城市列表
				newProvinceList: [], // 搜索用备份省份列表
				newCityList: [], // 搜索用备份城市列表
				title: 1, //1新增 2编辑
				checked: false,
				loading: false,
			}
		},
		mounted() {
			this.getAddress();
			this.getProvinceInfo();
		},
		methods: {
			// 获取常用地址列表
			getAddress(){
				this.loading = true;
				this.$conn.getConn('user.addrGet')().then(res=>{
					let data = res.data || [];
					this.addressList = data;
					if(this.addressList.length>0){
						this.isEmpty = false;
					}else{
						this.isEmpty = true;
					}
					this.loading = false;
				}).catch(err=>{
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 新增常用地址
			address(){
				this.ruleForm= {  // 基本信息参数
				    addr: "",
				    area: "",
				    city: "",
				    email: "",
				    empAddrId: "",
				    isDefault: 0,
				    phoneNumber: "",
				    post: "",
				    province: "",
				    recipient: ""
				},
				this.title = 1;
				this.visible = true;
			},
			// 保存常用地址
			saveAddress(formName){
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						let params = {};
						if(this.checked ==false){
							this.ruleForm.isDefault = 0
						}else{
							this.ruleForm.isDefault = 1
						}
						params = {
							addr: this.ruleForm.addr || "",
							area: this.ruleForm.area || "",
							city: this.ruleForm.city || "",
							email: this.ruleForm.email || "",
							empAddrId: this.ruleForm.empAddrId || "",
							isDefault: this.ruleForm.isDefault,
							phoneNumber: this.ruleForm.phoneNumber || "",
							post: this.ruleForm.post || "",
							province: this.ruleForm.province || "",
							recipient: this.ruleForm.recipient || ""
						}	
						this.$conn.getConn('user.addrEdit',{jsfile:false})(params).then(res=>{
							this.$message({
								type: 'success',
								message: '保存成功'
							})
							this.visible = false;
							this.getAddress();
							
						}).catch(err=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})
				    } else {
				      return false;
				    }      
				});
			},
			
			// 获取省份城市信息列表
			getProvinceInfo(){
				this.provinceList = this.$common.localget('provinceList');
				let city = this.$common.localget('allCityListInfo');//获取城市
				let sfList = this.provinceList;
			
				if(sfList && sfList.length>0){
					var provinceList = [];
					for(let i in sfList) {
						var provinceInfo = {};
						provinceInfo.id = sfList[i].id;
						provinceInfo.cName = sfList[i].cName;
						provinceInfo.by1 = sfList[i].by1; 
						let cityArray = [];
						for(let j in city[provinceInfo.id]){
							let cityInfo = {};
							cityInfo.id = city[provinceInfo.id][j].id;
							cityInfo.cityName = city[provinceInfo.id][j].cityName;
							cityInfo.pyjsm = city[provinceInfo.id][j].pyjsm;
							cityInfo.eName = city[provinceInfo.id][j].eName;
							cityArray.push(cityInfo);
						}
						this.cityList = cityArray;
						this.ruleForm.city = cityArray[0].cityName;
						
						provinceList.push(provinceInfo);
						this.newProvinceList = provinceList;
					}
				}else{
					this.$conn.getConn('commu.baseData',{jsfile:true})({}).then((res)=>{
						let data = res || {};
						let province = data.province['00002']; //获取省份
						let city = data.city; //获取城市
						// 国家
						var provinceList = [];
						// 国内城市对象
						let cityObj = {};
						// 国内城市列表
						let cityArr = [];
						// 每一条城市数据对象
						let obj = {};
						for(let i in province) {
							for(const j in city){
								if(j == province[i].id){
									obj = {
										[j]:city[j],
									}
									cityArr.push(obj)
								}
							}
						}
						// 遍历城市数据 ， 改变数据结构
						// 两种写法1
						// for (let c in cityArr) {
						// 	let key = Object.keys(cityArr[c])[0];					
						// 	cityObj[key] = cityArr[c][key];					
						// }
						      
						cityObj = Object.assign(...cityArr)
						this.$common.localset('allCityListInfo',cityObj)
						this.$common.localset('provinceList',province)
						this.provinceList = province;
						this.newProvinceList = province;
					})
				}
				
					
						
			},
			// 搜索省份地区 
			sreachProvince(e){
				if(e){
					this.provinceList = [];
					this.newProvinceList.forEach((item,index)=>{
						if((item.by1.indexOf(e.toUpperCase())!=-1) || (item.cName.indexOf(e)!=-1)){
							this.provinceList.push(item)
						}
					})
				}else{
					this.provinceList = this.newProvinceList;
				}
			},
			// 选中省份
			chooseProvince(id) {
				let city = this.$common.localget('allCityListInfo');//获取城市
				let cityArray = [];
				for(let j in city[id]){
					let cityInfo = {};
					cityInfo.id = city[id][j].id;
					cityInfo.cityName = city[id][j].cityName;
					cityInfo.pyjsm = city[id][j].pyjsm;
					cityInfo.eName = city[id][j].eName;
					cityArray.push(cityInfo);
				}
				this.cityList = cityArray;
				this.newCityList = cityArray; 	
				this.ruleForm.city = cityArray[0].id
				if(this.provinceList.length==0){
					this.getProvinceInfo();
				}
			},
			// 搜索城市
			sreachCity(e){
				if(e){
					this.cityList = [];
					this.newCityList.forEach((item,index)=>{
						if( (item.eName.indexOf(e.toUpperCase())!=-1) ||
							(item.cityName.indexOf(e)!=-1) || 
							(item.pyjsm.indexOf(e.toUpperCase())!=-1) ){
							this.cityList.push(item)
						}
					})
				}else{
					this.cityList = this.newCityList;
				}
			},
			// 编辑常用地址
			editCommonAddress(item){
				this.title = 2;
				this.visible = true;
				this.checked = item.isDefault==1?true:false;
				this.ruleForm = {
				    addr: item.addr || "",
				    area: item.area || "",
				    city: item.city || "",
				    email: item.email || "",
				    empAddrId: item.empAddrId || "",
				    isDefault: item.isDefault,
				    phoneNumber: item.phoneNumber || "",
				    post: item.post || "",
				    province: item.province || "",
				    recipient: item.recipient || "",
				}
				
			},
			// 删除常用地址
			removeAddress(id){
				let obj = {
					empAddrId: id
				}
				this.$confirm('此操作将永久删除该选项, 是否继续?', '删除', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn('user.addrDel',{jsfile:false})(obj).then((res)=>{
							this.$message({
								type: 'success',
								message: '删除成功'
							})
							this.visible = false;
							this.getAddress()
						}).catch((err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						}) 	  							 
					}).catch(() => {
						 this.$message({
							type: 'info',
							message: '已取消删除'							
						 });     
					});       	   	   
			},
			// 关闭页面
			handleClose(){
				this.visible = false;
			},
			// 关闭页面
			cancel(){
				this.visible = false;
			},
		}
		
	}
</script>

<style scoped lang="scss">
	.cl-main{
		font-family: '微软雅黑';
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
		.bghover :hover{
			background-color: #F5F7FA;
		}
		/deep/ .el-dialog{
			width: 660px;
		}
		/deep/ .el-dialog__header{
			padding: 20px;
			border-bottom: 1px solid #DCDCDC;
		}
		/deep/ .el-dialog__body{
			padding: 20px;
		}
		/deep/ .el-dialog__footer{
			padding: 15px 30px 20px ;
			border-top: 1px solid #DCDCDC;
		}
		/deep/ .el-input__inner{
			border-radius: 0;
		}
		.w85{
			width: 85px;
		}
		.widthSet{
			/deep/ .el-select .el-input__inner{
				width: 85px;
			}
		}
		.w130{
			width: 130px;
		}
		.widthSet2{
			/deep/ .el-select .el-input__inner{
				width: 130px;
			}
		}
		.addrWidth{
			width: 320px;
			/deep/ .el-input__inner{
				width: 320px;
			}
		}
		.padLR5{
			padding:0 5px;
		}
		.name{
			.first {
				width: 78px;
				
			}
			.last {
				width: 108px;
				// /deep/ .el-input__inner{
				//  	width: 100px;
				// }
			}
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.font15{
			font-size: 15px;
		}
		.bold{
			font-weight: bold;
		}
		.padTB14{
			padding: 14px 0;
		}
		.padTB18{
			padding: 18px 0;
		}
		.bfb-w10{
			width: 10%;
		}
		.bfb-w15{
			width: 15%;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w25{
			width: 25%;
		}
		.bfb-w30{
			width: 30%;
		}
		.bgF2{
			background: #F2F2F2;
		}
		.add-title{
			font-size: 12px;
			line-height: 12px;
			font-weight: 400;
			color: #808080;
		}
		.add-text{
			font-size: 12px;
			font-weight: 500;
			color: #282828;
		}
		.marT20{
			margin-top: 20px;
		}
		.line-h32{
			line-height: 32px;
		}
		.lineH38{
			line-height: 38px;
		}
		.borderB-bc{
			border-bottom: 1px solid #DCDCDC;
		}
		.btn{
			/deep/ .el-button--small{
				padding: 0 ;
			}
		}
		.color28{
			color: #282828;
		}
		.minh831{
			min-height: 831px;
		}
		
	}
</style>

