<template>
  <div class="orderGz">
    <el-dialog title="退改签规则" class="tggzDialog" :visible.sync="visible" :lock-scroll="false" :before-close="close">
    	<div class="tgqDialog">
    		<div class="gzleft">退票规则</div>
    		<div class="gzright">
    			<p>{{orderInfo.refundRule}}</p>
    		</div>
    	</div>
    	<div class="tgqDialog marTop20"  style="margin-top: -1px;">
    		<div class="gzleft">改签规则</div>
    		<div class="gzright">
    			<p>{{orderInfo.changeRule}}</p>
    		</div>
    	</div>
    	<div class="tgqDialog" style="margin-top: -1px;">
    		<div class="gzleft">升舱规则</div>
    		<div class="gzright">
    			<p>{{orderInfo.upgradeRule}}</p>
    		</div>
    	</div>
      <!-- <div style="display: flex;">
        <table v-if="refundRuleList.length>0" class="table">
          <tbody>
            <tr>
              <th colspan="2">退票费</th>
            </tr>
            <tr v-for="(item, index) in refundRuleList" :key="index">
              <td>{{item.date}}</td>
              <td v-if="item.charge>=0 || item.charge<0"><span style="color:orange;">￥{{item.charge}}</span> /人</td>
              <td v-else style="color:orange;">{{item.charge}}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="changeRuleList.length>0" class="table">
          <tbody>
            <tr>
              <th colspan="2">改签费</th>
            </tr>
            <tr v-for="(item, index) in changeRuleList" :key="index">
              <td>{{item.date}}</td>
              <td v-if="item.charge>=0 || item.charge<0"><span style="color:orange;">￥{{item.charge}}</span> /人</td>
              <td v-else style="color:orange;">{{item.charge}}</td>
            </tr>
          </tbody>
        </table>
        
      </div> -->
    	<span slot="footer" class="dialog-footer">
    		<el-button type="primary" @click="close">关 闭</el-button>
    	</span>
    </el-dialog>
  </div>
</template>

<script>
  export default{
    name:"OrderGz",
    data(){
      return{
       orderInfo: {},
       changeRuleList:[],
       refundRuleList:[],
      }
    },
    props:{
      // 退改签信息
      orderObj:{
        type: Object,
        default(){
          return {};
        }
      },
      //显示退改签
      visible:{
        type: Boolean,
        default(){
          return false;
        }
      }
    },
    watch:{
      orderObj:{
        handler(val){
          this.getOrderObj(val)
        },
        immediate: true
      }
    },
    methods:{
      /*获取详情信息*/
      getOrderObj(val){
        this.orderInfo = val || {};
        this.changeRuleList = val.changeRuleList || [];
        this.refundRuleList = val.refundRuleList || [];
      },
      
      close(){
        this.$emit('handClose',false)
      }
    }
  }
</script>

<style scoped lang="scss">
  .tggzDialog{
  	/deep/ .el-dialog {
  		width: 600px;
  	}
  }
  .tgqDialog {
  	display: flex;
  	justify-content: space-between;
  	text-align: left;
  	border: 1px solid #e6e6e6;
  
  	.gzleft {
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		background-color: #FAFAFA;
  		width: 92px;
  		min-height: 40px;
  		border-right: 1px solid #e6e6e6;
  		font-size: 15px;
  	}
  
  	.gzright {
  		width: 508px;
  		line-height: 24px;
  		padding: 15px 15px 15px 20px;
  	}   
  }
  .table{
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-spacing: 1px;
    width: 50%;
    tr{
      border-top: 1px solid #ddd;
      height: 40px;
    }
  }
</style>
