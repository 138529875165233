<template>
	<div class="book-tab" :style="{ backgroundColor }">
		<div class="book-tab-content">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				:background-color="backgroundColor" text-color="#fff" active-text-color="#ffd04b">
				<el-menu-item style="height: 50px; line-height: 50px" :disabled="item.params==0 && gkfsparams==1"
					v-for="item in tabList" 
					:key="item.name" 
					:index="item.index">
							{{ item.name }}
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	import { mapGetters} from 'vuex';
	export default {
		name: "BookTab",
		props: {},
		data() {
			return {
				backgroundColor: localStorage.getItem("color") ?
					localStorage.getItem("color") :
					"#006bb9",
				activeIndex: "",
			};
		},
		computed:{
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 1 置灰 2隐藏 模块控制
			gkfsparams(){	
				return this.$common.getVipParamterByParNo(this.$store,20029, 3);
			},
			tabList(){
				let list = [{
						index: "TkQuery",
						name: "国内机票",
						params: this.getVipParamterByParNo(20007,3),
					},
					{
						index: "TkZcQuery",
						name: "政采机票",
						params: this.getVipParamterByParNo(20030,3),
					},
					{
						index: "HtCnQuery",
						name: "国内酒店",
						params: this.getVipParamterByParNo(20009,3),
					},
					{
						index: "TrQuery",
						name: "火车票",
						params: this.getVipParamterByParNo(20011,3),
					},
					{
						index: "InTkEdit",
						name: "国际机票",
						params: this.getVipParamterByParNo(20008,3),
					},
					{
						index: "InHtEdit",
						name: "国际酒店",
						params: this.getVipParamterByParNo(20010,3),
					},
					// {
					//   index: "HtQuery",
					//   name: "休息室",
					// },
					// {
					//   index: "HtQuery",
					//   name: "快速通道",
					// },
					// {
					//   index: "HtQuery",
					//   name: "用车",
					// },
					// {
					//   index: "TkQuery",
					//   name: "代客泊车",
					// },
					// {
					//   index: "TkQuery",
					//   name: "代办值机",
					// },
				];
				// 计算属性中处理隐藏数据
				let newList = [];
				for(var i=0; i<list.length; i++){
					if(list[i].params ==0 && this.gkfsparams==2){
						
					}else{
						newList.push(list[i])
					}
				}
				return newList
			}
		},
		mounted() {
			
			this.initPathRoute(this.$route.path)
			
			
			
		},
		watch: {
			"$store.state.color"(val, old) {
				this.backgroundColor = val;
			},
			"$route.path"(val, old) {
				this.initPathRoute(val)
			},
		},
		methods: {
			// 初始化路径选择
			initPathRoute(val){
				if (val.indexOf("hkRouter") > -1) {
					this.activeIndex = "HkQuery";
				} else if (val.indexOf("htRouter")  > -1) {
					this.activeIndex = "HtQuery";
				} else if (val.indexOf("trRouter")  > -1) {
					this.activeIndex = "TrQuery";
				} else if (val.indexOf("htCnRouter")  > -1) {
					this.activeIndex = "HtCnQuery";
				} else if (val.indexOf("inTkEdit")  > -1) {
					this.activeIndex = "InTkEdit";
				} else if (val.indexOf("inHtEdit")  > -1) {
					this.activeIndex = "InHtEdit";
				} else if(val.indexOf("tkZcQuery") > -1){
					this.activeIndex = "TkZcQuery";
				} else if (val.indexOf("TkQuery")  > -1) {
					this.activeIndex = "TkQuery";
				}else{
					let bool = false;
					for(let k=0;k<this.tabList.length;k++){
						let tabItem = this.tabList[k] || {};
						if(tabItem['params'] == 1 || tabItem['params'] == 2 || tabItem['params'] == 3 ){
							bool = true;
							this.handleSelect(tabItem.index);
							break;
						}
					}
					
					
				}
			},

			handleSelect(key) {
				this.activeIndex = key;
				var queryObj = {
					name: key,
					query: {},
				}

				if (key == 'TkZcQuery') {
					queryObj.name = null;
					queryObj.path = '/yjTravel/book/tkRouter/tkZcQuery';
					queryObj.query.tkType = 1;
				} else if (key == 'TkQuery') {
					queryObj.name = null;
					queryObj.path = '/yjTravel/book/tkRouter/tkQuery';
					queryObj.query.tkType = 0;
				}
				this.$router.push(queryObj);

			},
		},
	};
</script>

<style scoped lang="scss">
	.book-tab {
		// position: absolute;
		// top: 60px;
		// left: 0;
		width: 100%;
		height: 50px;
		z-index: 1999;

		.book-tab-content {
			margin: 0 auto;
			width: 1200px;

		}

		.el-menu-demo {
			height: 100%;
		}

		.el-menu-item.is-disabled{
			opacity: 0.5;
			// background-color: #808080!important;
		}
		
	}
</style>
