!<template>
    <div class="tkod-main">
        <div class="box">
            <div class="box-left">
                <span  class="span1">会议分类:{{info.miceTypeName}}</span>
                <span class="span2">{{info.name}}</span>
                <span>城市:{{info.cityName}}</span>
            </div>
            <el-button type="primary" style="    float: right;" @click="join(info)">立即报名</el-button>
        </div>
        <div style="margin-top:20px" v-html="info.des">
           
        </div>
    </div>
</template>

<script>

export default {
  name: '',

  components: {},

  data() {
    return {
        info:{},
        phone:''
    }
  },

  created() {
        this.info= this.$common.sessionget('miceDetailInfo');
        console.log(this.info)
        this.getPhone()
  },

  methods: {
    join(){
 		this.$confirm('策划师联系方式'+this.phone , '提示', {
					  confirmButtonText: '我知道了',
					  cancelButtonText: '取消',
					  type: 'warning'
		        }).then(() => {
		        }).catch(() => {
		            this.$message({
		            type: 'info',
		            message: '已关闭'
		        });          
		});
    },
    getPhone(){
        // debugger
        let getBParam = this.$conn.getConn('commu.getBParam')
            getBParam({
                    paramNo: 3001
            }, (resp) => {
                let data = resp.data || {};
                this.phone = data.value1 || '';
            }, (err) => {
                    this.$message({
		            type: 'info',
		            message:err.tips
		        });   
            })
    },
  },

}

</script>

<style lang='scss' scoped>
.tkod-main {
	margin: 0 auto;
	width: 1200px;
	padding-top: 20px;
	padding-bottom: 50px;
    .box{
        display: flex;
        align-items: center;
        .box-left{

            display: flex;
            flex-direction: column;
            text-align: left;
            margin-right: 50px;


        }
    }



	.flex {
		display: flex;
	}
}
.span1{
        font-size: 16px;
    font-weight: bold;
}
.span2{
    font-size: 14px;
    color:#409EFF;

    margin: 10px 0px ;
}
</style>