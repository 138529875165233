<template>
	<div class="a-main">
		<div class="content">
			<span class="text-name"><span class="red">* </span>审批规则</span>
			<el-input placeholder="请选择审批规则" v-model="gzInfo.gzmc" readonly @click.native="showApproval"></el-input>
		</div>	
		<!-- 审批规则 -->
		<el-dialog title="审批规则" :visible.sync="approvalVisible" class="approvalDialog" >
			<div>
				所选出行人涉及多条审批规则, 请选择审批规则后,再进行下一步操作
			</div>
			<el-table :data="approvalList" :header-cell-style="{ backgroundColor: 'rgba(64,160,255,0.1)'}" class="ta-table">
				<el-table-column  width="100">
					<template slot-scope="scope" >
						<el-radio v-model="gzInfo.gzid" :label="scope.row.gzid" @click.native="choosApproval(scope.row)"><span></span></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="gzmc" label="审批规则" width="200"></el-table-column>
				<el-table-column prop="spr" label="审批人" width="200"></el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button  @click="cancel">取 消</el-button>
			    <el-button type="primary" @click="determine">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'Approval',
		props:{
			// 出差人信息
			ccrInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			// 出差单信息
			formInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			approvalId:{
				type: String,
				default: '',
			},
			
		},
		data() {
			return {
				approvalVisible: false, //审批规则
				approvalList: [], // 审批规则列表
				currentInfo:{}, //单前审批规则
				appId:'',
				gzInfo:{
					gzmc: '', //规则名称
					gzid: '', // 审批人信息
				}
				
			}
		},
		
		computed:{
			userInfo(){
				let userInfo = this.$common.sessionget('user_info') || {};
				return userInfo;
			},
			empInfo(){
				return this.userInfo.empInfo || {};
			},
			vipCorp(){
				return this.userInfo.vipCorp || {};
			},
		},
		watch:{
			approvalId(id){
				if(id){
					this.appId = id;
					this.queryApproval()
				}
			},
			
			ccrInfo:{
				handler(val){
					if(val.ccrId){
						this.gzInfo = {
							gzmc: '', //规则名称
							gzid: '', // 审批人信息
						}
						this.queryApproval();
					}
				},
				immedite: true,
				
			}
		},
		methods:{
			showApproval(){
				this.approvalVisible = true;
				this.queryApproval();
			},
			// 查询审批规则
			queryApproval(){
				
				if(!this.ccrInfo.ccrId){
					this.$message({
						type: 'error',
						message: '请选择出差人'
					})
					return	
				}
				let queryParams = {};
					queryParams = {
						bookEmpId: this.empInfo.empId,
						corpId:  this.empInfo.corpId,
						ifViolate: '',
						orderType: "11099",
						projectId: this.formInfo.projectId,
						travelEmpIds: this.ccrInfo.ccrId,
					}
				this.$conn.getConn('commu.singleModelMatchSpgz')(queryParams).then((res)=>{
					let data = res.data || [];
					this.approvalList = data;
					
					if(this.appId){
						this.approvalList.forEach((item,index)=>{
							// 处理详情
							if(this.appId==item.gzid){
								this.gzInfo.gzid = item.gzid;
								this.gzInfo.gzmc = item.gzmc;
								this.$emit('approvalInfo',item,true);
							}
						})
					}else{
						if(this.approvalList.length==1){
							this.gzInfo.gzid = this.approvalList[0].gzid;
							this.gzInfo.gzmc = this.approvalList[0].gzmc;
							this.$emit('approvalInfo',this.approvalList[0],true);
						}else{
							this.$emit('approvalList',this.approvalList,false);
						}
					}
					
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})					
			},
			// 选择审批规则
			choosApproval(row){
				this.currentInfo = row;
			},
			// 取消
			cancel(){
				this.approvalVisible = false;
			},
			// 确定
			determine(){
				if(!this.currentInfo.gzid){
					this.$message({
						type: 'warning',
						message: '您未选择审批规则'
					})
				}
				this.gzInfo = this.currentInfo;
				this.$emit('approvalInfo',this.gzInfo,true);
				this.approvalVisible = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.a-main{
		.content{
			margin-top: 19px;
			margin-bottom: 39px;
			display: flex;
			justify-content: flex-start;
			.el-input{
				width: auto;
				/deep/ .el-input__inner{
					width: 497px;
					height: 49px;
				}
			} 
		}
		.text-name{
			width: 80px;
			line-height: 49px;
			text-align: left;
		}
		.text-name1{
			width: 100px;
			line-height: 49px;
			text-align: left;
		}
		.text-detail{
			width: 200px;
		}
		.red{
			color: red;
		}
		.approvalDialog{
			/deep/ .el-dialog{
				width: 540px;
				text-align: left;
			}
			/deep/ .el-dialog__body{
				padding:10px 20px;
			}
		}
	}
</style>
