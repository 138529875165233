<template>
	<div class="te-main" @scroll.passive="getScroll($event)">
		<div class="main">
			<!-- 进度条 -->
			<yj-nav-detail :bgClass="bgClass">
				<div slot="contentSlot">
					<yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
				</div>
			</yj-nav-detail>

			<div class="te-con" >
				<div class="te-con-left">
					<train-yd-edit></train-yd-edit>
				</div>
				<div class="te-con-right" >
					<train-yd-right :class="{fixed:fixed}"></train-yd-right>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import TrainYdRight from './trComponents/trEdit/TrainYdRight';
	import TrainYdEdit from './trComponents/trEdit/TrainYdEdit';
	export default{
		name: 'TrEdit',
		data() {
			return {
				currentStatus:1,
				statusList:[
					{
						status:'1',
						name:'填写',
					},
					{
						status:'2',
						name:'支付',
					},
					{
						status:'3',
						name:'完成',
					},
				],
				fixed: false,
				bgClass:{
					'background':'transparent',
					'border-bottom': 'none',
				}
			}
		},
		components: {
			'train-yd-right':TrainYdRight,
			'train-yd-edit':TrainYdEdit
		},
		mounted() {
			// dom加载完后,进行监听配置
			this.$nextTick(()=>{
				// 1. 航班列表滚动监听
				this.addScrollListen();	
			})
		},
		destroyed(){
			// 移除滚动监听
			this.removeScrollListen();
		
		},
		methods:{
			addScrollListen(){
				window.addEventListener("scroll",this.handleScroll);
			},
			removeScrollListen(){
				window.removeEventListener('scroll', this.handleScroll)
			},
			// 滚动监听处理操作
			handleScroll(){
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 80) {
				  //大于80的时候要做的操作
				   this.fixed = true;
				}else{
					this.fixed = false;
				}	
				        
			},
		}
		
	}
</script>

<style scoped lang="scss">
	.te-main{
		background-color: #eef1f6;
		.main{
			width: 1200px;
			margin: 0 auto;
		}
		.te-top{
			padding:30px 0;
		}
		.te-con{
			display: flex;
			justify-content: space-between;
			.te-con-left{
				width:780px;
			}
			.te-con-right{
				width:390px;
				
			}
		}
		.fixed{
			width:390px;
			position: fixed;
			top: 0;
			
		}
	}
</style>
