<template>
	<div class="itd-main">
		<el-dialog
			title="修改证件信息"
			:visible.sync="idtypeVisible"
			:close-on-click-modal="false"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="身份证号" prop="idNumber">
					<el-input v-model="form.idNumber"></el-input>
				</el-form-item>
				<el-form-item label="护照号">
					<el-input v-model="form.passport"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm('form')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: "EditIdtypeDialog",
		props:{
			idtypeVisible:{
				type: Boolean,
				default: false,
			},
		},
		data(){
			// 验证证件号
			let vailIdNumber = (rule, value, callback) => {
					let reg = /^[1-9][0-9]{5}(19[0-9]{2}|200[0-9]|2010)(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{3}[0-9xX]$/
					if(!reg.test(this.form.idNumber)){
						callback(new Error('证件号码错误'));
					}else {
						callback();
			        }
			    };
			// 验证护照
			// let vailPort = (rule, value, callback) => {
			// 		let reg = /^1[45][0-9]{7}$|(^[P|p|S|s]\d{7}$)|(^[S|s|G|g|E|e]\d{8}$)|(^[Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|(^[H|h|M|m]\d{8,10}$)/;
			// 		if(!reg.test(this.form.passport)){
			// 			callback(new Error('证件号码错误'));
			// 		}else {
			// 			callback();
			//         }
			//     };
				
			return {
				form:{
					passport:"",
					idNumber:"",
				},
				rules:{
					idNumber: [{ required: true, message: '请输入身份证件号',trigger: 'blur'},
								{max:18,min:18,message:'证件号长度错误',trigger: 'blur'},
								{ validator:vailIdNumber,trigger: "blur"}],
					passport:[{ required: true, message: '请输入护照号',trigger: 'blur'},]		
				}
			}
		},
		watch:{
			idtypeVisible(val){
				if(val){
					this.getEmpInfo()		
				}
				
			}
		},
		methods:{
			getEmpInfo(){
				let user = this.$common.sessionget('user_info') || {};
				this.empInfo = user.empInfo || {};
				let empInfo = this.$common.deepCopy(this.empInfo);
				empInfo.idcardList.forEach((item)=>{
					if(item.idType==1){
						this.form.idNumber = item.idNo;
					}else if(item.idType==2){
						this.form.passport = item.idNo;
					}
				})
			},
			// 确定
			confirm(formName){
			
				this.$refs[formName].validate((valid)=>{
					if (valid) {
						let params = {
							passport: this.form.passport,
							idNumber: this.form.idNumber,
						}
					    this.$conn.getConn('user.editInfo')(params,(res)=>{
							this.$message.success('修改完成')
							this.$emit('callParams','ok',false)
						},(err)=>{
							this.$emit('callParams','no',false)
							this.$message.error(err.tips)
						})
					}
				})
				
				
			},
			// 取消
			cancel(){
				this.$emit('callParams',false)
			},
			handleClose(){
				this.$emit('callParams',false)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.itd-main{
		/deep/ .el-dialog{
			width: 500px;
		}
	}
</style>
