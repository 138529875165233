<template>
	<div class="tkod-main">
		<el-card class='textLeft marTop20'>
			<div class="marBtm10 flex between">
				<p>订单号：{{orderDetail.orderNo?orderDetail.orderNo:''}}</p>
				<p :style="getTextColor()" class="order-status">
					<span v-if="query.overdueFlag==1" style="color:red">行程过期</span>
					<span v-else>{{orderDetail.orderStatusRemark}}
						{{orderDetail.orderStatus==17?': '+orderDetail.errMsg:''}}</span>
				</p>
			</div>
			<div class="flex border sp-main" v-if="orderDetail.appId && orderDetail.vipStatus==0">
				<yj-approval-rule-detail :detail="detail" :appId="orderDetail.appId"></yj-approval-rule-detail>
			</div>
			<div class="flex border sp-main" v-if="orderDetail.appId && orderDetail.vipStatus > 0">
				<yj-approval-progress :orderId="orderId" :orderType="orderType" :resetspjd="resetspjd"
					style="width: 100%;" @showProgress="showProgress"></yj-approval-progress>
			</div>
			<div class="tk-main">
				<div class="left-main-width">
					基本信息
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width line-h78">
					<div class="bfb-w24">
						<span class="label-span">预订人：</span>
						<span>{{orderDetail.bookerName}}</span>
					</div>
					<div class="bfb-w24">
						<span class="label-span">服务商：</span>
						<span>{{orderDetail.supplierName }}</span>
					</div>
					<div class="bfb-w24">
						<span class="label-span">预订时间：</span>
						<span>{{orderDetail.bookDate}}</span>
					</div>
					<div class="bfb-w24" v-show="orderDetail.payStatus == 1">
						<span class="label-span">支付方式：</span>
						<span>{{orderDetail.paySubjectName}}</span>
					</div>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">
					航程信息
				</div>
				<div class="tk-hc">
					<div class="tk-hcmain" v-for="(item, index) in orderDetail.orderRangeBeanList" :key="item.id">
						<div class="flex" style="width: 100%;">
							<div style="width:80%; padding:40px 0;" class="flex">
								<div style="width: 33%;text-align: center;">
									<p class="bold">{{item.departTime}}</p>
									<p class="h50 line-h50">{{item.departCityName+' ('+ item.departAirportName +')'}}{{item.departTerminal}}
									</p>
									<p>{{item.departDate+' '+item.week}}</p>
								</div>
								<div style="width: 33%;">
									<div class="relative">
										<p class="iconfont yj-icon-jiantou1 absolute pCenter"></p>
										<p class="absolute ls-btm" style="text-align: center;width:100%;">
											{{item.flightTime}}
										</p>
									</div>
								</div>
								<div style="width: 33%;text-align: center;">
									<p class="bold">{{item.arriveTime}}</p>
									<p class="h50 line-h50">{{item.arriveCityName+' ('+ item.arriveAirportName +')'}}{{item.arriveTerminal}}
									</p>
									<p>{{item.arriveDate}}</p>
								</div>
							</div>
							<div style="width:20%;" class="tgqgz">
								<span class="pointer" @click="showTGQGZ(item)">退改签规则</span>
							</div>
						</div>

						<div style="width:100%;" class="borderTop">
							<div class="padL40 line-h40">
								<span>
									<span class="label-span">航司：</span>
									{{item.airlineName}}
								</span>
								<span class="padL40">
									<span class="label-span">航班号：</span>
									{{item.flightNo}}
								</span>
								<span class="padL40">
									<span class="label-span">机型：</span>
									{{item.model}}
								</span>
								<span class="padL40">
									<span class="label-span">舱位名称：</span>
									{{item.cabinName}}
								</span>
								<span class="padL40">
									<span class="label-span">折扣：</span>
									{{ filterSaleDiscount(item.saleDiscount) }}
								</span>
								<span class="padL40" >
									<span class="label-span">行李额：</span>
									{{item.baggageWeight | filterBaggage}}
								</span>
								<span class="padL40">
									<span class="label-span">餐食：</span>
									<span>{{item.hasfood==1?'有':'无'}}</span>
								</span>
							</div>
						</div>
						<div style="width:100%;" v-if="(orderDetail.orderRangeBeanList.length-1)>index"
							class="borderTop"></div>
					</div>

				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">
					乘机人
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width" style="padding:20px 0;">
					<el-table :data="orderDetail.orderPsgBeanList" :header-cell-style="header" border
						style="width: 100%">
						<el-table-column prop="psgName" label="乘机人" width="100"></el-table-column>
						<el-table-column prop="idNo" label="证件号" width="140"></el-table-column>
						<el-table-column prop="phoneNumber" label="手机号" width="100"></el-table-column>
						<el-table-column prop="tkno" label="票号" width="140"></el-table-column>
						<el-table-column prop="salePrice" label="票价(元)" width="80"></el-table-column>
						<el-table-column prop="tax" label="燃油(元)" width="80"></el-table-column>
						<el-table-column prop="serviceFee" label="服务费(元)" width="80"></el-table-column>
						<el-table-column prop="airportFee" label="基建(元)" width="80"></el-table-column>
						<el-table-column prop="costCenterName" v-show="orderDetail.tripType == 1" label="成本中心"
							width="120"></el-table-column>
					</el-table>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">
					金额
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width padTB30">
					<div class="bfb-w20">
						<span class="label-span">总金额(元)：</span>
						<span class="orange">{{orderDetail.totalPrice}}</span>
					</div>
			
					<div class="bfb-w20">
						<span class="label-span">保险金额(元)：</span>
						<span class="orange">{{orderDetail.bxPrice || 0}}</span>
					</div>
				</div>
			</div>
			<!-- 	<div class="tk-main" v-show="orderDetail.payStatus == 1">
				<div class="left-main-width">
					支付方式
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width line-h78">
					<div>
						<span>{{orderDetail.paySubjectName}}</span>
					</div>
				</div>
			</div> -->
			<div class="tk-main">
				<div class="left-main-width">
					联系信息
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width line-h78">
					<div class="bfb-w25">
						<span class="label-span">联系人：</span>
						<span>{{orderDetail.linkman}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">联系电话：</span>
						<span>{{orderDetail.linkTel}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">联系邮箱：</span>
						<span>{{orderDetail.linkEmail}}</span>
					</div>
				</div>
			</div>
			<div class="tk-main" v-show="orderDetail.tripType == 1">
				<div class="left-main-width">
					差旅信息
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width line-h78">
					<div class="bfb-w25">
						<span class="label-span">项目：</span>
						<span>{{orderDetail.projectName}}</span>
					</div>
					<div class="bfb-w25" v-if="orderDetail.violateitem">
						<span class="label-span">违背事项：</span>
						<span>{{orderDetail.violateitem }}</span>
					</div>
					<div class="bfb-w25" v-if="orderDetail.reasonDesc">
						<span class="label-span">违背原因：</span>
						<span>{{orderDetail.reasonDesc }}</span>
					</div>
				</div>
			</div>
			<div class="tk-main" v-show="orderDetail.tripType == 1 && orderDetail.tkType == 1">
				<div class="left-main-width">
					政采信息
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width line-h78">
					<div class="bfb-w25" v-show="orderDetail.gpValidType == 1">
						<span class="label-span">预算单位：</span>
						<span>{{orderDetail.budgetUnitName}}</span>
					</div>
					<div class="bfb-w75" v-show="orderDetail.gpValidType == 2">
						<span class="label-span">公务卡：</span>
						<span>{{orderDetail.bankName}}</span>
					</div>
				</div>
			</div>
			<!-- 增值服务 -->
			<div class="tk-main" v-if="orderDetail.tkProductBeans && orderDetail.tkProductBeans.length>0">
				<div class="left-main-width">增值产品</div>
				<div style="width: 996px;">
					<div v-for="(tkItem,tkIndex) in orderDetail.tkProductBeans" :key="tkIndex">
						<div class="tk-bx-item">
							<span class="xu-hao">{{tkIndex + 1}}</span>
							<span> {{tkItem.name}}</span>&#x3000;
							<span class="gray" style="color: gray;">{{tkItem.instructions}}</span>
							
						</div>
					</div>
				</div>
			</div>
			
			<div class="tk-main" v-if="bxInfo.bxcpList && bxInfo.bxcpList.length>0">
				<div class="left-main-width">保险信息</div>
				<div style="width: 996px;">
					<div v-for="(bxItem,bxIndex) in bxInfo.bxcpList" :key="bxIndex">
						<div class="tk-bx-item">
							<span class="xu-hao">{{bxIndex + 1}}</span>
							<span> {{bxItem.xzmc}}-{{bxItem.bxcpmc}}</span>
							<span class="orange" style="margin-left: 20px;">{{bxItem.salePrice}}元/人</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 腿票信息 -->
			<div class="tk-main" v-if="tkRefundList.length>0">
				<div class="left-main-width">退票信息</div>
				<div style="width:996px;">
					<div class="tk-refund" :class="{borderTop:index>0}" v-for="(item, index) in tkRefundList"
						:key="index">
						<div class="tk-top" v-for="reItem in item.refundRangeList" :key="reItem.rangeId">
							<div style="width: 33%;text-align: center;">
								<p>{{reItem.departTime}}</p>
								<p class="h50 line-h50">{{reItem.departCityName+'('+reItem.departAirportName+')'}}{{reItem.departTerminal }}</p>
								<p>{{reItem.departDate+' '+reItem.week}}</p>
							</div>
							<div style="width: 33%;">
								<div class="relative">
									<p class="absolute iconfont yj-icon-jiantou1 pCenter"></p>
									<p class="absolute ls-btm" style="text-align: center;width:100%;">
										{{reItem.flightTime}}
									</p>
								</div>

							</div>
							<div style="width: 33%;text-align: center;">
								<p>{{reItem.arriveTime}}</p>
								<p class="h50 line-h50">{{reItem.arriveCityName+' ('+reItem.arriveAirportName+')'}}{{reItem.arriveTerminal }}</p>
								<p>{{reItem.arriveDate+' '+reItem.week}}</p>
							</div>
						</div>
						<div class="tk-footer ">
							<div class="bfb-w75">
								<div style="width: 100%; " class="flex">
									<div class="bfb-w50">
										<span class="td-bt">{{item.refundStatusRemark}}</span>
									</div>
									<div class="bfb-w50">
										<span class="label-span">退票合计：</span>
										<span style="color: #FFA558">{{'￥'+item.cuRefundAmount}}</span>
									</div>
								</div>
								<div style="width: 100%;" class="flex">
									<div class="bfb-w50" v-for="(refundPsgItem ,refundPsgIndex) in item.refundPsgList" :key="refundPsgIndex">
										<span>{{'乘机人：'+refundPsgItem.psgName+'(身份证'+refundPsgItem.idNo+')'}}</span>
									</div>
									<div class="bfb-w50">
										<span class="label-span">退票号：</span>
										<span>{{item.tkno}}</span>
									</div>
								</div>
							</div>
							<div class="bfb-w25 textRight">
								<span class=" pointer padR24" style="color: #006BB9;"
									@click="goToRefundDetail(item.refundId)">查看退票详情</span>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="tk-main" v-if="tkChangeList.length>0">
				<div class="left-main-width">
					改签信息
				</div>
				<div style="width:996px;">
					<div style="padding: 15px;">共有{{tkChangeList.length}}条改签记录</div>
					<div style="width:996px;" v-for="item in tkChangeList" :key="item.changeId">
						<div class="flex line-h24 borderTop padTB10" style="padding-left: 40px;">
							<div class="bfb-w25">
								<span class="label-span">改签单号：</span>
								<span >{{item.changeNo}}</span>
							</div>
							<div class="bfb-w25">
								<span class="label-span">申请时间：</span>
								<span >{{item.applyDate}}</span>
							</div>
						
							<div class="bfb-w25">
								<span class="label-span">改签状态：</span>
								<span >{{item.orderStatusRemark}}</span>
							</div>
							<div class="bfb-w25 textRight">
								<span class=" pointer padR24" style="color: #006BB9;" @click="goToChangeDetail(item.changeId)">查看改签详情</span>
							</div>
						</div>
						<div style="padding-left: 40px;">
							<div class="bfb-w25">
								<span class="label-span">改签合计：</span>
								<span style="color: #FFA558">{{'￥'+item.changeAmount}}</span>
							</div>
						</div>
						<!-- 改签航段 -->
						<div class="" style="padding: 15px;">
								
								<el-table :data="item.rangeList" :header-cell-style="header" border
									style="width: 100%">
									
									<el-table-column label="原航班">
										<el-table-column prop="oFlightNo" label="航班号" ></el-table-column>
										<el-table-column prop="oDepartAirportName" label="出发机场" ></el-table-column>
										<el-table-column prop="oArriveAirportName" label="到达机场"></el-table-column>
										<el-table-column prop="oDepartDate" label="出发日期" ></el-table-column>
										<el-table-column prop="oDepartTime" label="出发时间" ></el-table-column>
										<el-table-column prop="oCabinName" label="舱位" ></el-table-column>
										
									</el-table-column>
									<el-table-column label="新航班">
										<el-table-column prop="nFlightNo" label="航班号" ></el-table-column>
										<el-table-column prop="nDepartAirportName" label="出发机场" ></el-table-column>
										<el-table-column prop="nArriveAirportName" label="到达机场"></el-table-column>
										<el-table-column prop="nDepartDate" label="出发日期" ></el-table-column>
										<el-table-column prop="nDepartTime" label="出发时间" ></el-table-column>
										<el-table-column prop="nCabinName" label="舱位" ></el-table-column>
										
										
									</el-table-column>
									
					
							
								</el-table>
						
						</div>
						<!-- 乘机人 -->
						<div class="" style="padding: 15px;">
							
								<el-table :data="item.psgList" :header-cell-style="header" border
									style="width: 100%">
									<el-table-column prop="psg" label="乘机人" ></el-table-column>
									<el-table-column prop="idNo" label="证件号" ></el-table-column>
									<el-table-column prop="oTkno" label="原票号" ></el-table-column>
									<el-table-column prop="nTkno" label="新票号" ></el-table-column>
									<el-table-column prop="oFcny" label="原票价"  width="80"></el-table-column>
									<el-table-column prop="nFcny" label="新票价" width="80"></el-table-column>
									<el-table-column prop="priceDifference" label="差价"  width="80" ></el-table-column>
									<el-table-column prop="cuChangeAmount" label="手续费"  width="80"></el-table-column>
									<el-table-column prop="serviceFee" label="服务费"  width="80"></el-table-column>
							
								</el-table>
						
						</div>
						

					</div>
				</div>
			</div>
		


			<!-- 订单操作和审批操作  v-if="isSpStatus"-->
			<yj-approval-cao-zuo 
				:appType="appType" 
				:workItemId="workItemId" 
				:orderType="orderType" 
				:orderId="orderId"
				@closePage="spResetDetail" >
				<template slot="orderFooterSlot">
					<div class=" pad20" style="text-align: center;">
						<!-- getCjrInfo -->
						<!-- <el-button class="padButton" v-show="orderDetail.isShowCancel==1" @click="closePage()">关 闭
						</el-button> -->
						<el-button class="padButton" v-show="orderDetail.orderStatus == 6" type="primary"
							@click="getCjrInfo()">打印行程单</el-button>
						<el-button class="padButton" v-show="orderDetail.isShowCancel==1"
							@click="orderCaoZuo(1,orderDetail)">取 消</el-button>
						<el-button class="padButton el-button el-button--primary" v-show="orderDetail.isShowPay==1" @click="orderCaoZuo(2,orderDetail)">支
							付</el-button>
						<el-button class="padButton el-button el-button--primary" v-show="orderDetail.isShowApproval==1"
							@click="orderCaoZuo(3,orderDetail)">送 审</el-button>
						<el-button class="padButton" v-show="orderDetail.isShowRefund==1"
							@click="orderCaoZuo(4,orderDetail)">退 票</el-button>
						<el-button class="padButton" v-show="orderDetail.isShowEndorse==1"
							@click="orderCaoZuo(5,orderDetail)">改 签</el-button>
						<el-button class="padButton" v-show="orderDetail.isShowCancelApprove==1"
							@click="orderCaoZuo(16,orderDetail)">撤 回</el-button>
					</div>
				</template>
			</yj-approval-cao-zuo>
		</el-card>


		<!-- 退改签规则 -->
		<order-gz :visible="visible" :orderObj="tgOrderObj" @handClose="handClose"></order-gz>




		<el-dialog title="打印行程单" class="xcdDialog" :visible.sync="xcdVisible" :lock-scroll="false"
			:before-close="xcdCancel">
			<el-table :data="orderDetail.orderPsgBeanList" :header-cell-style="header">
				<el-table-column width="180" align="center">
					<template slot-scope="scope">
						<el-radio v-model="xcdRadio" :label="scope.row.id" @click.native="chooseXCR(scope.row)">
							<span></span></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="idNo" label="姓名(证件号)" width="240" align="center">
					<template slot-scope="scope">
						<div>{{scope.row.psgName}}{{'('+scope.row.idNo+')'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="tkno" label="票号" width="240" align="center"></el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="xcdCancel">取 消</el-button>
				<el-button type="primary" @click="xcdDetermine">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import caozuoMixin from '@/mixin/models/caozuoMixin.js';
import { mapGetters } from 'vuex';
import OrderGz from './tkComponent/tkOrderDetail/OrderGz';
	export default {
		name: 'TkOrderDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				orderDetail: {}, // 订单详情			

				tkRefundList: [], //退票信息集合
				tkChangeList: [], //改签信息集合
				approvalInfo: {}, // 审批规则数据
				appProgress: {}, // 审批进度
				spStatus: 0, // 审批状态

				visible: false, // 退改签规则
				xcdVisible: false, // 打印行程单
				xcdRadio: '',

				costCenterName: '', //成本中心名称集合

				changeRule: '', //改签规则
				refundRule: '', //退票规则
				upgradeRule: '', //升舱规则
				tgOrderObj: {}, //退票改签规则


				bxInfo: {}, /// 保险信息


				header: {
					background: '#eeeeee',
					height: 40 + 'px',
				},
				detail: {
					// flowState: 3,
					// currentLevel: 0,
					appId: '',
				},
				isSpStatus: false,
				resetspjd: false, //重置审批进度
			}
		},
		components: {
			"order-gz": OrderGz,
		},
		computed: {
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),	
			userInfo() {
				return this.$common.sessionget('user_info') || {};
			},
			empId() {
				return this.userInfo.empInfo.empId;
			},
			query() {
				return this.$route.query || {}
			},
			appType() {
				return this.query.appType
			},

			// 获取订单id
			orderId() {
				return this.query.orderId
			},
			// 获取订单类型
			orderType() {
				return this.query.orderType || '11001'
			},
			workItemId() {
				return this.query.workItemId
			},
			leng() {
				return this.approvalInfo.spjdInfoList ? this.approvalInfo.spjdInfoList.length : 0;
			},
			// 机票是否提交BPM机票更改申请流程
			params20032(){
				return this.getVipParamterByParNo(20032,1)
			},
		},
		mounted() {
			// 获取订单详情
			this.getTkOrderDetail();
			// 获取退票订单信息
			this.getFindTkRefundOrder();
			// 获取改签票订单信息
			this.getFindTkChangeOrderByOrderId();
			// 获取保险信息
			this.getBxOrder();
		},
		methods: {
			// 获取订单详情
			getTkOrderDetail() {
				let obj = {
					id: this.orderId || orderId,
				};
				this.$conn.getConn('tkQuery2023.orderDetail3', {
					jsfile: false
				})(obj, (res) => {
					let data = res.data || {};
					this.orderDetail = data;
					this.detail.appId = data.appId;
				}, (err) => {
					this.$message({
						message: err.tips,
						type: 'error'
					})
				})
			},
			// 获取退票订单信息
			getFindTkRefundOrder() {
				let obj = {
					orderId: this.orderId
				};
				this.$conn.getConn('tkQuery2023.moreRefundInfo', {
					jsfile: false
				})(obj).then((res) => {
					let data = res.data || [];
					this.tkRefundList = data;
				}).catch((err) => {
					this.$message({
						message: err.tips,
						type: 'error'
					})
				})
			},
			// 退票详情
			goToRefundDetail(refundId) {
				let path = 'tkRefundDetail';
				let query = {
					orderId: refundId,
					orderType: 11002,
				}
				let routeUrl = this.$router.resolve({
					path: path,
					query: query
				});
				window.open(routeUrl.href, "_blank")
			},
			// 改签详情
			goToChangeDetail(changeId) {
				let path = 'tkChangeDetail';
				let query = {
					orderId: changeId,
					orderType: 11003,
				}
				let routeUrl = this.$router.resolve({
					path: path,
					query: query
				});
				window.open(routeUrl.href, "_blank")
			},
			// 获取改签票订单信息
			getFindTkChangeOrderByOrderId() {
				let obj = {
					orderId: this.orderId,
				};
				this.$conn.getConn('tkQuery2023.findChangeOrderByOrderId', {
					jsfile: false
				})(obj, (res) => {
					let data = res.data || [];
					this.tkChangeList = data;
				}, (err) => {
					this.$message({
						message: err.tips,
						type: 'error'
					})
				})
			},
			// 获取保险信息
			getBxOrder() {
				let obj = {
					glddId: this.orderId
				};
				this.$conn.getConn('insurance.getBxOrder')(obj, (res) => {
					let data = res.data || {};
					this.bxInfo = data;
				}, (err) => {

				})
			},
			// 获取单号字体颜色
			getTextColor() {
				if (JSON.stringify(this.orderDetail) != "{}") {
					let styleObj = {}
					if (this.orderDetail.orderStatus == 6 || this.orderDetail.orderStatus == 7) {
						styleObj = {
							'color': '#FFA558',
						}
					} else if (this.orderDetail.orderStatus == 4 || this.orderDetail.orderStatus == 9 || this.orderDetail
						.orderStatus == 15) {
						styleObj = {
							'color': '#99ccff',
						}
					} else if (this.orderDetail.orderStatus == 5) {
						styleObj = {
							'color': '#0066ff',
						}
					} else if (this.orderDetail.orderStatus == 8 || this.orderDetail.orderStatus == 16 || this.orderDetail
						.orderStatus == 17) {
						styleObj = {
							'color': 'red',
						}
					}

					return styleObj
				}
			},
			// 是否显示退改签规则
			showTGQGZ(item) {
				this.visible = true;

				this.tgOrderObj = this.$common.deepCopy(item)
			},

			/*关闭退改签规则*/
			handClose() {
				this.visible = false;
			},

			// 订单操作枚举：1.取消  2. 支付 3.送审 4.退票 5.改签
			orderCaoZuo(type, orderDetail) {

				var orderId = orderDetail.id;

				if (type == 1) {
					this.cancleOrder(orderId)
				} else if (type == 2) {
					this.$router.push({
						path: "/yjDetail/payment",
						query: {
							orderId: orderId,
							orderType: "11001"
						}
					})
				} else if (type == 3) {
					this.songShen(orderDetail)
				} else if (type == 4) {
					if(this.params20032 == 0 ){
						let refundMsg = '该订单无法进行退票，如需退票，请提交BPM机票更改申请流程！'
						 this.$alert(refundMsg, '温馨提示', {
						        confirmButtonText: '确定',
						        callback: action => {}
						});
						
						return
					}
					let path = this.$dict.orderRefundConfig(this.orderType);
					let query = {
						orderId: this.orderId,
						orderType: this.orderType,
						refundFlag: 1,
					}
					let routeUrl = this.$router.resolve({
						path: path,
						query: query
					});
					window.open(routeUrl.href, "_blank")
				} else if (type == 5) {
					if(this.params20032 == 0 ){
						let refundMsg = '该订单无法进行改签，如需改签，请提交BPM机票更改申请流程！'
						 this.$alert(refundMsg, '温馨提示', {
						        confirmButtonText: '确定',
						        callback: action => {}
						});
						
						return
					}
					let path = this.$dict.orderChangeConfig(this.orderType);
					let query = {
						orderId: this.orderId,
						orderType: this.orderType,
						changeFlag: 1,
					}
					let routeUrl = this.$router.resolve({
						path: path,
						query: query
					});
					window.open(routeUrl.href, "_blank")

				}else if(type == 16){
					this.goToCancelApprove({
							orderId: this.orderId,
							orderType:this.orderType,
						},(res)=>{
					
							this.getTkOrderDetail(this.orderId);
						},(err)=>{
		
					})
				}
			},
			// 取消订单  /1.0.0/cancelTkOrder
			cancleOrder(orderId) {
				this.$confirm('您确定要取消订单吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var queryString = {
						orderId: orderId
					};
					// var url = "tkOrder.cancelTkOrder";
					var url = "tkQuery2023.tkOrderCancel2";
					
					this.$conn.getConn(url)(queryString, (res) => {
						this.$alert('', '取消成功！', {
							type: 'success',
							confirmButtonText: '确定',
							callback: action => {
								this.getTkOrderDetail(orderId);

							}
						})

					}, (err) => {
						this.$alert(err.tips, '取消失败！', {
							type: 'error',
						})
					})
				}).catch(() => {

				});



			},
			// 送审
			songShen(orderDetail) {
				var user = this.$common.getUserInfo();
				this.$confirm('你确定要送审吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					//二次审批
					if (user.vipCorp.bookStyle == 2) {
						var queryString = {
							orderId: this.orderId,
							orderType: orderDetail.orderType,
							spgzId: orderDetail.appId,
							againApp: 1
						};
					}
					if (user.vipCorp.bookStyle == 1) {
						var queryString = {
							orderId: this.orderId,
							orderType: orderDetail.orderType,
							spgzId: orderDetail.appId,
							againApp: 0
						};
					}
					this.$conn.getConn("commu.sendApp")(queryString, (resp) => {
						this.$alert('您可在订单详情查看审批进度', '送审成功!', {
							type: 'success',
							callback: () => {
								this.getTkOrderDetail()
							}
						});
					}, (err) => {
						this.$alert(err.tips, '送审失败!', {
							type: 'error',
							callback: () => {
								this.getTkOrderDetail()
							}
						});

					})

				}).catch(() => {

				});
			},

			// 审批完成时刷新详情
			spResetDetail() {

				this.$router.replace({
					path: this.$dict.detailConfig(11001),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				 
				this.getTkOrderDetail();
				this.resetspjd = true;

			},
			// 审批进度
			showProgress(info, currentSpjd, status) {
				// this.spjd = info.spjd; //当前审批进度（1/2）
				this.resetspjd = false;
				// this.detail.currentLevel = currentSpjd; //当前到谁审批
				// this.detail.flowState = status; // 当前审人状态

				// this.getCurrentSprLevel()
			},
			// // 获取当前审批人层级列表
			// getCurrentSprLevel() {
			// 	let params = {
			// 		spgzId: this.detail.appId
			// 	}
			// 	this.$conn.getConn('commu.appRulesDetail')(params, (res) => {
			// 		let data = res.data || {};
			// 		let applyList = data.gznr || [];
			// 		this.getGznrList(applyList)
			// 	}, (err) => {

			// 	})
			// },
			// // 获取审批人列表

			// getGznrList(list) {
			// 	if (list.length > 0) {
			// 		// 遍历审批人
			// 		for (var i = 0; i < list.length; i++) {
			// 			// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
			// 			if (list[i].level == this.detail.currentLevel) {
			// 				// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
			// 				for (var j = 0; j < list[i].empList.length; j++) {
			// 					if (list[i].empList[j].empId == this.empId && this.detail.flowState == 3) {
			// 						this.isSpStatus = true;
			// 					} else {
			// 						this.isSpStatus = false;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}

			// },
			// 关闭本页面
			closePage() {
				window.close();
			},
			// 关闭本页面
			close() {
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					window.close();
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已关闭'
					});
				});
			},

			// 获取乘机人信息
			getCjrInfo() {
				this.xcdVisible = true;
			},
			//选中乘机人 
			chooseXCR(row) {
				this.xcdRadio = row.id
			},
			// 取消选中乘机人
			xcdCancel() {
				this.xcdRadio = '';
				this.xcdVisible = false;
			},
			// 确定乘机人
			xcdDetermine() {
				if (!this.xcdRadio) {
					this.$message({
						type: 'warning',
						message: '请选择乘机人'
					})
					return
				}
				let routeUrl = this.$router.resolve({
					path: "/tkRouteItinerary",
					query: {
						orderId: this.xcdRadio
					}
				});
				window.open(routeUrl.href, '_blank')
				this.xcdVisible = false;
			},
			// 处理折扣问题
			filterSaleDiscount(val){
				if(val){
					let num = val;
					if(num == 10){
						return '全价'
					}else if(num > 10){
						let bei = (num/10).toFixed(2);
						return `${bei}倍`
					}else{
						let zhe = num.toFixed(2);
						return `${zhe}折`
					}
				}
				return val
			}
			
		},
		
		filters:{
			
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.tkod-main {
		margin: 0 auto;
		width: 1200px;
		padding-top: 20px;
		padding-bottom: 50px;

		.sp-main {
			margin-bottom: 10px;
			padding: 35px 30px;

			.setqd {
				width: 80px;
				height: 80px;
				padding: 8px;

				.set-item {
					width: 59px;
					height: 59px;
					font-size: 12px;
					padding: 0;
					text-align: center;
					line-height: 59px;
				}
			}

			.bordersp1 {
				border: 2px solid #424242;
				color: #424242;
				border-radius: 50%;
			}

			.bordersp2 {
				border: 2px solid #006BB9;
				color: #006BB9;
				border-radius: 50%;
			}

			.bordersp3 {
				border: 2px solid #FF0000;
				color: #FF0000;
				border-radius: 50%;
			}

			.bordersp4 {
				border: 2px solid #f5a337;
				color: #f5a337;
				border-radius: 50%;
			}
		}

		.tk-main {
			display: flex;
			margin-bottom: 10px;
			min-height: 80px;
			border: 1px solid #E6E6E6;
			box-sizing: border-box;

			.left-main-width {
				width: 160px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #FAFAFA;
				border-right: 1px solid #E6E6E6;
			}

			.tk-hc {
				width: 996px;

				.tk-hcmain {
					width: 100%;
					display: flex;
					flex-wrap: wrap;

					.bold {
						font-weight: bold;
					}
				}
			}

			.tk-refund {
				display: flex;
				width: 100%;
				flex-wrap: wrap;

				.tk-top {
					width: 80%;
					display: flex;
					padding: 40px 0;
				}

				.tk-footer {
					display: flex;
					line-height: 24px;
					border-top: 1px solid #E6E6E6;
					padding: 10px 0 10px 40px;
					width: 100%;
				}
			}

			.right-main-width {
				width: 960px;
				display: flex;
				flex-wrap: wrap;
			}

			.padTB30 {
				padding: 30px 0px;
			}
		}

		.flex {
			display: flex;
		}

		.xcdDialog {
			text-align: left;

			/deep/ .el-dialog {
				width: 700px;

				/deep/ .el-dialog__body {
					padding: 10px 20px 20px;
				}
			}
		}

		.bfb-w20 {
			width: 20%;
		}

		.bfb-w24 {
			width: 24%;
		}

		.bfb-w25 {
			width: 25%;
		}

		.bfb-w50 {
			width: 50%;
		}

		.bfb-w75 {
			width: 75%;
		}

		.between {
			justify-content: space-between;
		}

		.relative {
			position: relative;
		}

		.absolute {
			position: absolute;
		}


		.ls-top {
			top: 35px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.ls-btm {
			top: 45px;
		}

		.pCenter {
			left: 50%;
			top: 25px;
			transform: translate(-50%, 0);
		}

		.marBtm10 {
			margin-bottom: 10px;
		}

		.marTop20 {
			margin-top: 20px;
		}

		.marR24 {
			margin-right: 24px;
		}

		.padTB10 {
			padding: 10px 0;
		}

		.padTop20 {
			padding-top: 20px;
		}

		.padL40 {
			margin-left: 40px;
		}

		.textLeft {
			text-align: left;
		}

		.textRight {
			text-align: right;
		}

		.padButton {
			padding: 10px 30px;
			font-size: 15px;
		}



		.border {
			border: 1px solid #E6E6E6;
			box-sizing: border-box;
		}

		.borderRight {
			border-right: 1px solid #E6E6E6;
		}

		.borderTop {
			border-top: 1px solid #E6E6E6;
		}

		.borderTopNone {
			border-top: none;
		}

		.h80 {
			min-height: 80px;
		}

		.h50 {
			height: 50px;
		}

		.line-h50 {
			line-height: 50px;
		}


		.tgqgz {
			color: #DEAC56;
			text-align: right;
			padding: 24px 24px 0 0;
		}



		.line-h78 {
			line-height: 78px;
		}

		.line-h40 {
			line-height: 40px;
		}

		.line-h24 {
			line-height: 24px;
		}

		.padTB30 {
			padding: 30px 0px;
		}

		.padR24 {
			padding-right: 24px;
		}

		.bg {
			background: #FAFAFA;
		}



		.w100 {
			width: 100px;
		}

		.bgC {
			background-color: #CCCCCC;
		}

		.h2 {
			height: 2px;
		}



		.pointer {
			cursor: pointer;
		}

		.td-bt {
			height: 24px;
			background: #006BB9;
			color: #fff;
			padding: 0 8px;
			display: inline-block;
			border-radius: 4px;
		}
	}

	.label-span {
		color: #909399;
	}

	.order-status {
		font-size: 14px;
		// font-weight: bolder;
	}

	// 保险
	.tk-bx-item {
		padding-left: 40px;
		line-height: 40px;
	}

	.xu-hao {
		width: 28px;
		height: 28px;
		display: inline-block;
		border-radius: 50%;
		text-align: center;
		line-height: 28px;
		background-color: #e6e6e6;
		margin-right: 20px;
	}

	.orange {
		color: orange;
	}
</style>
