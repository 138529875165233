<template>
	<div class="login">
		<Header :compInfo="compInfo"></Header>
		<pure :compInfo="compInfo"></pure>
		<yj-copy-right  :compInfo="compInfo" :copyRightClass="copyRightClass"></yj-copy-right>
		<yj-mask v-if="!isShowLoginPage"></yj-mask>
	</div>
</template>
<script>
	import Header from "./login/Header";
	import Pure from "./login/Pure";
	
	import loginMixin from "./../../mixin/models/loginMixin.js"
	export default {
		name: "Login",
		mixins:[loginMixin],
		data() {
			return {
				isShowLoginPage:false, // 是否显示登录页
				compInfo: {},
				copyRightClass:{
					'width': 100+'%',
					'min-height': 56+'px',
					'cursor':'initial',
					'background': '#323232',
				}
			};
		},
		components: {
			'Header':Header,
			'pure':Pure,
		},
		
		computed:{
			
		},
		mounted() {
			// 方法来源于 loginMixins
			
			
			// 获取页面风格
			this.initPageSet();
			this.getEtPageSetList();
			// 单点登录信息
			this.getSsoLogin();
		},
		
		
		methods: {
	
			
		
		},
	};
</script>
<style scoped lang="scss">
	
	
</style>
