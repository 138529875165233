<template>
	<div class="epd-main">
		<el-dialog
			title="修改手机号"
			:visible.sync="phoneVisible"
			:before-close="handleClose"
			:close-on-click-modal="false"
			style="text-align: left;"
			>
			<div class="marB20">
				<span class="inlineB bfb-w16">手机号：</span><el-input v-model="phoneNumber" :disabled="true" style="width:50%;"></el-input>		
			</div>
			<div>
				<span class="inlineB bfb-w16">密码：</span><el-input v-model="password" type="password" show-password placeholder="请输入密码" style="width:50%;"></el-input>	  
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'EditPhoneDialog',
		props: {
			phoneVisible:{
				type: Boolean,
				default: function(){
					return false
				}
			},
			loginInfo:{
				type: Object,
				default: function(){
					return {}
				}
			}
		},
		data() {
			return {
				phoneNumber: '', //手机号
				password: '', //密码
			}
		},
		watch: {
			loginInfo(val){
				this.getPhone(val)
			}
		},
		methods: {
			handleClose(){
				this.$emit('callParams',false)
			},
			// 确定
			confirm(){
				if(this.password){
					let obj = {
						  "pwd": this.password
						}
					this.$conn.getConn('user.validatePwd',{jsfile:false})(obj).then((res)=>{
						if(res.data == '1'){
							let info = {
								phoneNumber: this.phoneNumber,
								password: this.password, 
							}
							this.$emit('callParams',false,info)
							this.password = '';
						}
						if(res.data == '0'){
							this.$message({
								type: "error",
								message: '密码验证失败'
							})
						}
					})
					
				}else{
					this.$message({
						type: "error",
						message: '请输入密码'
					})
				}
			},
			// 取消
			cancel(){
				this.$emit('callParams',false)
			},
			getPhone(val){
				this.phoneNumber = val.phoneNumber;
			}
			
		}
	}
</script>

<style scoped lang="scss">
	.epd-main{
		/deep/ .el-dialog{
			width: 500px;
		}
		.inlineB{
			display: inline-block;
		}
		.bfb-w16{
			width: 16%;
		}
		.marB20{
			margin-bottom: 20px;
		}
	}
</style>
