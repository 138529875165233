<template>
	<el-card class="pc-main">
		<div class="flex between padB30 borderBF2">
			<div @click="getAvatar">
				<el-avatar :class="{avatarNo:!headImg,avatar:headImg}" shape="square" :src="headImg" ></el-avatar>
			</div>
			<div class="w594 textLeft marL20 relative">
				<div class="absolute top0">
					<p class='setTxMc'>
						<span class="padR10">{{vipCorp.shortname}}</span>
						<span>-</span>
						<span class="padL10">{{empInfo.empName}}</span>
					</p>
					<p class="line-h14 padT20">
						<span class="padR10 borderRC">{{empInfo.deptName}}</span>
						<span class="padL10">{{empInfo.empRankName}}</span>
					</p>
				</div>
				
				<div class="absolute bottom0 w594">
					<div class="flex w594 hhwarp" style="padding-bottom: 10px;">
						<p class="sethide">英文名：{{empInfo.engName}}</p>
						<p class="sethide">性别：{{(empInfo.sex?empInfo.sex:'不详')}}</p>
						<p class="sethide" >国籍：{{placeOfIssueName}}</p>
						<p class="sethide" >出生日期：{{empInfo.born}}</p>
						<p class="sethide" >电话：{{empInfo.phoneNumber | filterPhone}}</p>
						<p class="sethide">个人偏好：{{empInfo.preference}}</p>
					</div>
					
				</div>
			</div>
			<div>
				<el-button class="textRight" @click="editPersonInfo">
					基本资料修改
				</el-button>
			</div>
		</div>
		<div class="padT30">
			<div class="bgFA marB20 padTB30 flex pointer"  @click="editPhoneNumber">
				<div class="w120" style="line-height: 54px;">
					<div class="iconfont yj-icon-phone_icon icontit"></div>
				</div>
				<div class="w690 textLeft">
					<div class="list-title">
						<span >手机号</span>
						<span class="padL20">{{empInfo.phoneNumber | filterPhone}}</span>
					</div>
					<div class="list-text">
						手机号会默认为填入订票联系人，用于接受订票进度信息，也可用于本账号的找回密码。
					</div>
				</div>
				<div class="w60">
					<div class="iconfont yj-icon-zlbj_icon font17 line-h40 colorZTS"></div>
				</div>
			</div>
			<div class="bgFA marB20 padTB30 flex pointer"  @click="editEmail">
				<div class="w120" style="line-height: 54px;">
					<div class="iconfont yj-icon-mail_icon icontit" style="font-size:34px;"></div>
				</div>
				<div class="w690 textLeft">
					<div class="list-title">
						<span >邮箱</span>
						<span class="padL20 ">{{empInfo.email}}</span>
					</div>
					<div class="list-text">
						邮箱会默认为填入订票联系人，用于接受订票进度信息。
					</div>
				</div>
				<div class="w60">
					<div class="iconfont yj-icon-zlbj_icon font17 line-h40 colorZTS "></div>
				</div>
			</div>
			<div class="bgFA marB20 padTB30 flex pointer" @click="editIdtype">
				<div class="w120" style="line-height: 54px;">
					<div class="iconfont yj-icon-juxing20 icontit"></div>
				</div>
				<div class="w690 textLeft">
					<span class="list-title">证件信息 </span>
					<span class="list-title" v-for="(item, index) in empInfo.idcardList" :key="item.idType">
						<span v-if="item.idType ==1">
							<span class="padL20">身份证
								<span v-if="item.idType ==1">{{item.idNo | filterIdNo}}</span>
								<span v-else  class="colorAF">未添加</span>
							</span>
						</span>
						<span v-if="item.idType==2">
							<span class="padL10">护照
								<span v-if="item.idType==2">{{item.idNo | filterPassPort}}</span>
								<span v-else class="colorAF">未添加</span>
							</span>	
						</span>
						<span class="padL10" v-if="index==0 && (item.idType==2 || item.idType ==1)">/</span>
					</span>
					<div class="list-text">
						身份证及护照信息请务必填写一项，以方便您的购票。
					</div>
				</div>
				<div class="w60" >
					<div class="iconfont yj-icon-zlbj_icon font17 line-h40 colorZTS "></div>
				</div>
			</div>
			<div class="bgFA marB20 padTB30 flex pointer" @click="editPassword">
				<div class="w120" style="line-height: 54px;">
					<div class="iconfont yj-icon-juxing21 icontit"></div>
				</div>
				<div class="w690 textLeft">
					<div class="font15 bold line-h15">
						<span class="list-title">安全设置</span>
					</div>
					<div class="list-text">
						可通过现密码或绑定手机号进行密码更改。
					</div>
				</div>
				<div class="w60">
					<div class="iconfont yj-icon-zlbj_icon font17 line-h40 colorZTS " ></div>
				</div>
			</div>
		</div>
		<el-dialog
			title="修改头像"
			:close-on-click-modal="false"
			:visible.sync="avatarVisible"
			class="dialog"
			>
			<div style="margin-bottom: 20px;">
				<span class="title">8款嘉嘉精美头像供您选择</span>
				<div class="imgList">
					<div v-for="(item, index) in serviceImgList" :key="index" @click="chooseImg(item)" class="vImg">
						<el-image class="img" :src="item"></el-image>
					</div>
				</div>
			</div>
			<div v-if="isfile">
				<yj-up-load @successfile="getFileImg"></yj-up-load>
				请点击上传图片，（图片最大不超过30MB）
			</div>
			<span slot="footer">
				<el-button @click="openUpload">自定义头像</el-button>
			</span>
		</el-dialog>
		<edit-phone-dialog :phoneVisible="phoneVisible" :loginInfo="loginInfo" @callParams="currentParams"></edit-phone-dialog>
		<new-edit-phone-dialog :newPhoneVisible="newPhoneVisible" :vailRepeatInfo="vailRepeatInfo"  @callParams="newCurrentParams"></new-edit-phone-dialog>
		<edit-password-dialog :passVisible="passVisible"   @callParams="passWordParams"></edit-password-dialog>
		<edit-person-info-dialog :personVisible="personVisible" @callParams="personParams"></edit-person-info-dialog>
		<edit-email-dialog :emailVisible="emailVisible" :email="empInfo.email"  @callParams="getEmailInfo"></edit-email-dialog>
		<edit-idtype-dialog :idtypeVisible="idtypeVisible" :empInfo="empInfo"  @callParams="getIdTypeInfo"></edit-idtype-dialog>
	</el-card>
</template>

<script>
	import EditPhoneDialog from './dialog/EditPhoneDialog';
	import NewEditPhoneDialog from './dialog/NewEditPhoneDialog';
	import EditPasswordDialog from './dialog/EditPasswordDialog';
	import EditPersonInfoDialog from './dialog/EditPersonInfoDialog';
	import EditEmailDialog from './dialog/EditEmailDialog';
	import EditIdtypeDialog from './dialog/EditIdtypeDialog';	
	import {mapState,mapMutations} from 'vuex';
	export default{
		name: 'TravelInfo',
		data() {
			return {
				phoneVisible: false, // 修改手机号页面是否展示
				loginInfo: {}, //登录人信息
				newPhoneVisible: false, // 密码输入成功
				vailRepeatInfo: {}, //验证参数
				passVisible: false, // 修改登录密码
				personVisible: false, // 修改个人信息
				emailVisible: false, // 修改邮箱
				idtypeVisible: false, // 修改身份证件，护照号码
				
				avatarVisible:false, // 修改头像
				serviceImgList:[ // 头像
				  "https://stf.yjtravel.com/group1/20210410/80/1-161802090085617313.png",
				  "https://stf.yjtravel.com/group1/20210410/57/2-161802095306992437.png",
				  "https://stf.yjtravel.com/group1/20210410/44/3-161802098189255511.png",
				  "https://stf.yjtravel.com/group1/20210410/77/4-16180210108786978.png",
				  "https://stf.yjtravel.com/group1/20210410/55/5-161802104011430886.png",
				  "https://stf.yjtravel.com/group1/20210410/77/6-161802106687496381.png",
				  "https://stf.yjtravel.com/group1/20210410/11/7-1618021092026731.png",
				  "https://stf.yjtravel.com/group1/20210410/91/8-161802112675379928.png",
				],
				
				isfile: false,
			
				
				empInfo:{},
				vipCorp:{},
			}
		},
		created(){
			this.getUserInfo();
		},
		computed:{
			...mapState({
				headImg: state => state.common.headImg,
			}),
			userInfo(){
				return this.$common.sessionget('user_info') || {};
			},
			
			placeOfIssueName(){
				let name = ''
				let empInfo = this.userInfo.empInfo || {};
				empInfo.idcardList.forEach(item=>{
					if(item.idType){
						name = item.placeOfIssueName
					}
				})
				return name
			}
		},
		components: {
			'edit-phone-dialog': EditPhoneDialog,
			'new-edit-phone-dialog': NewEditPhoneDialog,
			'edit-password-dialog': EditPasswordDialog,
			'edit-person-info-dialog': EditPersonInfoDialog,
			'edit-email-dialog':EditEmailDialog,
			'edit-idtype-dialog':EditIdtypeDialog,
		},
		methods:{
			...mapMutations({
				setHeadImg: "common/setHeadImg",
			}),
			// 修改手机号
			editPhoneNumber(){
				this.loginInfo = this.empInfo;
				this.phoneVisible = true;
				
			},
			// 当前手机密码
			currentParams(bool,info){
				this.phoneVisible = bool;
				if(info){
					this.showNewEditPhone(info)
				}
			},
			// 验证手机号
			showNewEditPhone(info){
				this.newPhoneVisible = true;
				this.vailRepeatInfo = this.userInfo;
			},
			newCurrentParams(bool,info){
				this.newPhoneVisible = bool;
				if(info){
					this.getUserInfo()
				}
			},
			// 修改邮箱
			editEmail(){
				this.emailVisible = true;
			},
			// // 邮箱
			getEmailInfo(params,bool){
				this.emailVisible = bool;
				if(params=='ok'){
					this.getUserInfo()
				}		
			},
			// 打开修改证件信息列表
			editIdtype(){
				this.idtypeVisible = true;
			},
			// 获取返回参数
			getIdTypeInfo(params,bool){
				this.idtypeVisible = bool;
				if(params=='ok'){
					this.getUserInfo()
				}	
			},
			// 获取登录人及企业信息 
			getUserInfo(){
				this.$conn.getConn('user.userInfo')({refresh:1},(res)=>{
					let data = res.data || {};
					this.empInfo = data.empInfo || {};
					this.vipCorp = data.vipCorp || {};
					this.setHeadImg(data.empInfo.profilePhotoUrl);
					this.$common.sessionremove('user_info');
					this.$common.sessionset('user_info',data);
				},(err)=>{
					this.$message.error(err.tips)
				})
			},
			// 修改密码
			editPassword(){
				this.passVisible = true;
			},
			// 修改密码后关闭页面
			passWordParams(bool){
				this.passVisible = bool;
			},
			// 修改基本信息
			editPersonInfo(){
				this.personVisible = true;
			},
			personParams(bool,info){
				this.personVisible = bool;
				if(info){
					this.getUserInfo()
				}
			},
			// 获取头像
			getAvatar(){
				this.avatarVisible = true;
			},
			// 选中更换头像
			chooseImg(item){
				let queryString = {
					profilePhotoUrl: item
				}
				this.$conn.getConn('user.editPersonInfo')(queryString,(res)=>{
					this.getUserInfo();
					this.avatarVisible = false;
				},(err)=>{
					this.avatarVisible = false;
					this.$message.error(err.tips)
				})
			},
			// 打开上传图片
			openUpload(){
				this.isfile = true
			},
			// 获取文件图片
			getFileImg(img){
				this.setHeadImg(img);
				this.avatarVisible = false;
			}
		}
	}
</script>

<style scoped lang="scss" >
	.el-card.is-always-shadow{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 5px;	
	}
	.pc-main{
		font-family: '微软雅黑';
		.avatar{
			width: 120px;
			height: 120px;
			background-color: transparent;
			cursor: pointer;
		}
		.avatarNo{
			width: 120px;
			height: 120px;
			cursor: pointer;
		}
		.flex{
			display: flex;
		}
		.hhwarp{
			flex-wrap: wrap;
		}
		.setTxMc{
			font-size: 22px;
			line-height: 22px;
			font-weight: 400;
			font-style: italic;
			color: #262633;
		}
		.sethide{
			font-size: 12px;
			font-weight: 400;
			color: #7D7D80;
			padding-right: 20px;
		}
		.between{
			justify-content: space-between;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.dialog{
			/deep/ .el-dialog{
				width: 600px;
				text-align: left;
				.el-dialog__body{
					padding:  20px
				}
				.title{
					line-height: 24px;
					padding-bottom: 10px;
					display: inline-block;
				}
				.imgList{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.vImg{
						width: 60px;
						height: 60px;
						cursor:pointer;
						transition: all 0.3s ease;
						
						.img{
							width: 60px;
							height: 60px;
						}
					}
					.vImg:hover{
						border-radius: 5px;
						background-color: #dddddd;
					}
				}
				.allImg{
					margin-left: 20px;
				}
			}
		}
		
		.top0{
			top: 0;
		}
		.bottom0{
			bottom: 0;
		}
		.font17{
			font-size: 17px;
		}
		.icontit{
			font-size: 40px;
			color:#CCCCCC;
		}
		.font12{
			font-size: 12px;
		}
		.font15{
			font-size: 15px;
		}
		.bold{
			font-weight: bold;
		}
		.line-h40{
			line-height: 40px;
		}
		.line-h12{
			line-height: 12px;
		}
		.line-h14{
			line-height: 14px;
		}
		.line-h15{
			line-height: 15px;
		}
		.w60{
			width: 60px;
		}
		.w73{
			width: 73px;
		}
		.w120{
			width: 120px;
		}
		.w127{
			width: 127px;
		}
		.w150{
			width: 150px;
		}
		.w690{
			width: 690px;
		}
		.h120{
			height: 120px;
		}
		.w594{
			width: 594px;
		}
		.textLeft{
			text-align: left;
		}
		.textRight{
			text-align: left;
		}
		.marL20{
			margin-left: 20px;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.padT10{
			padding-top: 10px;
		}
		.padT20{
			padding-top: 20px;
		}
		.padTB30{
				padding: 30px 0;
		}
		.padT30{
			padding-top: 30px;
		}
		.padL10{
			padding-left: 10px;
		}
		.padL20{
			padding-left: 20px;
		}
		.padR10{
			padding-right: 10px;
		}
		.padB30{
			padding-bottom: 30px;
		}
		
		.borderRC{
			border-right:2px solid #CCCCCC;
		}
		.borderBF2{
			border-bottom:1px solid #F2F2F2;	
		}
		.bfb-w10{
			width: 10%;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w25{
			width: 25%;
		}
		.bfb-w30{
			width: 30%;
		}
		.inlineB{
			display: inline-block;
		}
		.bgFA{
			background: #FAFAFA;
		}
		.colorAF{
			color: #AFAFB3;
		}
		.colorZTS{
			color: #006BB9;
		}
		.list-title{
			font-size: 16px;
			font-weight: bold;
			color: #262633;
			line-height: 27px;
		}
		.list-text{
			font-size: 12px;
			font-weight: 400;
			color: #AFAFB3;
			line-height: 27px;
		}
		.pointer{
			cursor: pointer;
		}
	}
</style>
