<template>
	<div class="cr-main" >
		<div class="main">
			<div class="title">
				{{this.$t("login.corpRegister")}}
			</div>
			<div class="setform">
				<el-form ref="form" :inline="true"  class="demo-ruleForm" :model="form" :rules="rules" label-width="80px">
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item prop="fullname" label="企业全称" >
								<el-input :placeholder="$t('register.enterpriseFullname')" v-model="form.fullname"
									 clearable>
									<i slot="prefix" class="el-input__icon el-icon-s-home" :class="{iconColor:form.fullname}"></i>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="shortname"  label="企业简称" >
								<el-input  :placeholder="$t('register.enterpriseShortname')"
									
									v-model="form.shortname" clearable>
									<i slot="prefix" class="el-input__icon el-icon-s-home" :class="{iconColor:form.shortname}"></i>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="24">
						<el-col :span="24">
							<el-form-item prop="address"  label="公司地址" class="setitembfb">
								<el-input :placeholder="$t('register.enterpriseAddress')" v-model="form.address">
									<i slot="prefix" class="el-input__icon el-icon-map-location" :class="{iconColor:form.address}"></i>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="24">
						<el-col :span="6">
							<el-form-item prop="industry"  label="行业"  class="itemcity" >
								<el-select v-model="form.industry" :placeholder="$t('register.industry')">
								    <el-option
								      v-for="item in industryList"
								      :key="item.id"
								      :label="item.cName"
								      :value="item.id">
								    </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="corpScale"  label="企业规模"  class="itemcity" >
								<el-select v-model="form.corpScale" :placeholder="$t('register.corpScale')">
								    <el-option
								      v-for="item in corpScaleList"
								      :key="item.id"
								      :label="item.text"
								      :value="item.id">
								    </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="provinceName"  label="省份"  class="itemcity" >
								<el-select v-model="form.provinceName" @change="getProvince" :placeholder="$t('register.provinceName')">
								    <el-option
								      v-for="item in provinceList"
								      :key="item.id"
								      :label="item.cName"
								      :value="item.id">
								    </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="cityName"  label="城市" class="itemcity" >
								<el-select v-model="form.cityName" :placeholder="$t('register.cityName')">
								    <el-option
								      v-for="item in cityList"
								      :key="item.id"
								      :label="item.cName"
								      :value="item.id">
								    </el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					
					
					
					
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item prop="postCode"  label="邮编">
								<el-input :placeholder="$t('register.postCode')" v-model="form.postCode">
									<i slot="prefix" class="el-input__icon el-icon-bank-card" :class="{iconColor:form.postCode}"></i>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="linkman"  label="联系人">
								<el-input :placeholder="$t('register.linkman')" v-model="form.linkman">
									<i slot="prefix" class="el-input__icon el-icon-user" :class="{iconColor:form.linkman}"></i>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item prop="linkPhone"  label="手机号">
								<el-input :placeholder="$t('register.linkPhone')" v-model="form.linkPhone">
									<i slot="prefix" class="el-input__icon el-icon-mobile-phone" :class="{iconColor:form.linkPhone}"></i>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="email"  label="邮箱">
								<el-input :placeholder="$t('register.email')" v-model="form.email">
									<i slot="prefix" class="el-input__icon el-icon-folder-opened" :class="{iconColor:form.email}"></i>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item prop="smsCode"  label="验证码" class="item-bfb100 textLeft" >
								<el-input :placeholder="$t('register.smsCode')" maxlength="6" v-model="form.smsCode">
									<i slot="prefix" class="el-input__icon el-icon-key" :class="{iconColor:form.smsCode}"></i>
									<i slot="suffix" class="suffix-item" @click.prevent="getYzmCode">
										{{showCode?$t('login.resend')+'('+ timer +')':$t('login.getYzmCode')}}
									</i>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-button type="primary" class="button" @click="toRegister('form')">
						<span class="el-icon-loading" v-if="loading"></span>
							{{$t('register.submitRegister')}}</el-button>
				</el-form>

			</div>
			<div class="zclx" :class="{'shake-slow':isShake}">
				<span class="el-icon-success xy_icon" :class="{'icon_xy':isAgreement,}" @click="getAgreement"></span>
				<span>我已阅读并同意</span>
				<span class="xyColor" @click="getService">《壹嘉商旅服务条款》</span>
			</div>
			<div class="footer">
				{{this.$t("register.haveAnAccount")}} <span class="toLogin" @click="goToLogin">{{this.$t("register.login")}}</span>
			</div>
		</div>
		<yj-term-agreement :visible="isService" :params="params"  @cancelService="cancelService"></yj-term-agreement>
	</div>
</template>

<script>
	export default{
		name: 'CorpRegister',
		data() {
			let valiPhone = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.form.linkPhone)){
						callback(new Error(this.$t("register.invalidPhone")));
			        }else {
						callback();
			        }	
			    };
			// 验证邮箱
			var valiEmail = (rule, value, callback)=> {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
					if(value){
						if (mailReg.test(value)) {
						    callback()
						} else {
						    callback(new Error('请输入正确的邮箱格式'))
						}
					}else{
						callback()
					}
			};	
			
			return{
				isService: false,
				params:2,
				isAgreement: false, // 选中服务协议隐私条款
				isShake: false, // 抖动效果
				form:{
					  "address": "", //企业地址
					  "cityId": "", //城市Id
					  "cityName": "",  //城市名称
					  "compId": "", //总公司id
					  "corpScale": 0, //企业规模
					  "email": "", 
					  "fullname": "", // 企业全称
					  "industry": "", // 企业行业
					  "linkPhone": "", // 联系电话
					  "linkman": "", // 联系人
					  "postCode": "", //邮编 ,
					  "province": "", // 省份ID
					  "provinceName": "", // 省份名称
					  "shortname": "",// 企业简称
					  "smsCode": "", // 手机验证码
				},
				corpScaleList: [// 企业规模列表
					{
						id:0,
						text:'1-49',
					},
					{
						id:1,
						text:'50-199',
					},
					{
						id:2,
						text:'200-500',
					},
					{
						id:3,
						text:'500以上',
					},
				],
				industryList:[], // 行业列表
				provinceList:[], // 省份列表
				countryInfo:{}, // 所有国家信息
				cityList: [], // 城市列表
				allCityInfo:{}, // 所有城市列表 对象数组
				loading: false, // 注册加载
				showCode: false, // 验证码显示
				timer: 60, //验证码有效时间
				compId:'',
				rules:{
					fullname: [{required: true,message: this.$t("register.enterpriseFullname"),trigger: "blur"}],
					linkPhone: [{required: true,message: this.$t("register.linkPhone"),trigger: "blur"},
									{validator:valiPhone,trigger: "blur"}],
					linkman:[{required: true,message: this.$t("register.linkman"),trigger: "blur"}],
					smsCode: [{required: true,message: this.$t("register.verifyCode_tip"),trigger: "blur"}],
					email:[{required: true,message: this.$t("register.linkPhone"),trigger: "blur"},
									//{validator:valiEmail,trigger: "blur"},
									]
				},
				clearTime:'',
			}
		},
		mounted() {
			let configData = this.$common.getConfigData() || {};
			this.compId =  configData.compId;
			this.getIndustry();
			this.provinceList = this.$common.localget('provinceList');
			this.allCityInfo = this.$common.localget('allCityListInfo');
			if(!this.provinceList || this.provinceList.length==0 || JSON.stringify(this.allCityInfo)=="{}"){
				this.getBaseData();
			}
		},
		beforeDestroy(){
			clearTimeout(this.clearTime)
		},
		methods: {
			// 获取服务条款
			getService(){
				this.isService = true;
			},
			// 获取行业行息
			getIndustry(){
				let queryParams = {
					parNo: 103
				}
				this.$conn.getConn("commu.getBClass")(queryParams,(res)=>{
					let data = res.data || {};
					this.industryList = data.list || [];
					this.form.industry = this.industryList[0].id;
				},(err)=>{
					
				})
			},
			// 获取省份城市数据
			getBaseData(){
				this.$conn.getConn('commu.baseData',{jsfile:true})({}).then((res)=>{
					let data = res || {};	
					let city = data.city; //获取城市
					this.provinceList = data.province['00002']; //获取省份
					this.countryInfo = data.country;
					// 国内城市列表
					let cityArr = [];
					// 每一条城市数据对象
					let obj = {};
					for(let i in this.provinceList) {
						for(const j in city){
							if(j == this.provinceList[i].id){
								obj = {
									[j]:city[j],
								}
								cityArr.push(obj)
							}
						}
					}
					this.allCityInfo = Object.assign(...cityArr)
					this.$common.localset('countryInfo',this.countryInfo)
					this.$common.localset('provinceList',this.provinceList);
					this.$common.localset('allCityListInfo',this.allCityInfo)
				})
			},
			// 获取省份信息
			getProvince(e){
				let cityArray = [];
				for(let i in this.allCityInfo[e]){
					let cityInfo = {};
					cityInfo.id = this.allCityInfo[e][i].id;
					cityInfo.cName = this.allCityInfo[e][i].cityName;
					cityInfo.pyjsm = this.allCityInfo[e][i].pyjsm;
					cityInfo.eName = this.allCityInfo[e][i].eName;
					cityArray.push(cityInfo);
				}
				this.cityList = cityArray;
				this.form.cityName = cityArray[0].cName;
			},
			// 关闭服务协议
			cancelService(bool){
				this.isService = bool;
			},
			// 选中服务协议
			getAgreement(){
				this.isAgreement =(this.isAgreement?false:true);
			},
			// 去登录
			goToLogin(){
				this.$router.push({path:'/login'})
			},
			// 获取验证码
			getYzmCode(){
				if(!this.form.linkPhone){
					this.$message.warning(this.$t('register.phoneNumber_tip'))
					return 
				}else if(this.form.linkPhone){
					var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
					if(!myreg.test(this.form.linkPhone)){
						this.$message.error(this.$t("register.invalidPhone"))	
						return 
					}	
					
				}
				if(this.showCode){
					return
				}
				this.showCode = true;
				this.getTimer();
				let queryParams = {
						phoneNumber: this.form.linkPhone,
						compId:this.compId,
				}
				this.$conn.getConn('user.getRegisterSmsCode',{jsfile:false})(queryParams,(res)=>{
					this.$message.success('获取企业验证码成功')
				},(err)=>{
					this.$message.error(err.tips)
				})
			},
			// 计时器时间
			validationTime(){
				this.clearTime = setTimeout(()=>{
					if(this.timer == 0){
						this.showCode = false;
						this.timer = 5;
					}else{
						this.getTimer()
					}
				},1000)
			},
			// 倒计时
			getTimer(){
				this.timer--;
				this.validationTime();
			},
			//企业注册
			toRegister(formName){
				if(!this.isAgreement){
					this.isShake = true;
					this.$message.warning(this.$t("login.termsOfService"))
					setTimeout(()=>{
						this.isShake = false;
					},1000)
					return 
				}
				this.$refs[formName].validate(valid => {
					if(valid){
						this.loading = true;
						let queryParams = {
								address: this.form.address,
								cityId: this.form.cityId,
								cityName: this.form.cityName,
								compId: this.compId,
								corpScale: this.form.corpScale,
								email: this.form.email,
								fullname: this.form.fullname,
								industry: this.form.industry,
								linkPhone: this.form.linkPhone,
								linkman: this.form.linkman,
								postCode: this.form.postCode,
								province: this.form.province,
								provinceName: this.form.provinceName,
								shortname: this.form.shortname,
								smsCode: this.form.smsCode,
							}
						this.$conn.getConn("user.saveVipCorpApply",{jsfile:false})(queryParams,(res)=>{
							this.$message.success(this.$t('register.registerSuccess'))
							this.$router.push({path:'/login'});
							this.loading = false;
							clearTimeout(this.clearTime)
						},(err)=>{
							clearTimeout(this.clearTime)
							this.$message.error(err.tips)
							this.loading = false;
						})	
					}
				})
			}
		}
		
	}
</script>

<style lang="scss" scoped>
	.cr-main{
		background: rgba(0,0,0,0.2);
		width: 100%;
		height: 100%;
		font-family: '微软雅黑';
		.main{
			position: relative;
			top: 50%;
			transform: translate(0,-50%);
			width: 1200px;
			height: 650px;
			background-color: #FFFFFF;
			margin: 0 auto;
			padding:20px 30px;
			border-radius: 4px;
			.el-col {
				padding:0!important;
			}
			.title{
				font-size: 24px;
				font-weight: 500;
				color: #666666;
				line-height: 48px;
			}
			
			.setform{
				margin-top: 20px;
				
				.el-input{
					width: 100%;
				}
				/deep/ .el-form-item__label{
					height: 46px;
					line-height: 46px;
				}
				
				.item-width{
					width: 50%;
					margin-right: 0;
					
				}
				.textLeft{
					text-align: left;
				}
				.textRight{
					text-align: right;
				}
				/deep/ .el-input__inner {
					height: 46px;
					width: 490px;
					background: #FFFFFF;
					border: 1px solid #DBDBDB;
					border-radius: 2px;
					line-height: 46px;
					padding-left: 60px;
					cursor: pointer;
				}
				.setitembfb{
					width: 100%;
					text-align: left;
					
					/deep/ .el-input__inner {
						       width: 1072px;
							}
					
				}
				.item-bfb100{
					width: 100%;
					/deep/ .el-input__inner {
						    width: 490px;
							
					}
				}
				.itemhy{
					width: 30%;
					margin-right: 0;
					/deep/ .el-input__inner {
						    width: 250px;
							padding-left: 10px;
					}
				}
				.itemqygm{
					width: 20%;
					margin-right: 0;
					/deep/ .el-input__inner {
						    width: 138px;
							padding-left: 10px;
							
					}
				}
				.itemsf{
					width: 25%;
					margin-right: 0;
					/deep/ .el-input__inner {
						    width: 185px;
							padding-left: 10px;
							
					}
				}
				.itemcity{
					
					/deep/ .el-input__inner {
						     width: 200px;
							padding-left: 10px;
							
					}
				}
				
				.marginR50{
					margin-right: 50px;
				}
				.marginR10{
					margin-right: 10px;
				}
				.suffix-item{
					width: 200px;
					display: inline-block;
					text-align: center;
					cursor: pointer;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					border-left: 1px solid #DBDBDB;
				}
				/deep/ .el-input__prefix {
					top: 1px;
					left: 1px;
					width: 46px;
					height: 44px;
					background: #F5F5F5;
					font-size: 18px;
					line-height: 44px;
					border-radius: 1px 0px 0px 1px;
					cursor: pointer;
				}
				/deep/ .el-input__suffix{
				    line-height: 44px;
					top: 1px;
					right: 1px;
				}
				/deep/ .iconColor{
					color: #007FE9;
				}
				.button{
					margin-top: 10px;
					width: 50%;
					height: 50px;
					font-size: 17px;
					font-weight: 500;
					color: #FFFFFF;
					border-radius: 0;
					text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
				}
				
				
			}
		
			.zclx{
				margin-top: 20px;
				font-size: 14px;
				.xy_icon{
					font-size: 20px;
					color: #999999;
					padding-right: 5px;
					cursor: pointer;
					vertical-align: top;
				}
				.icon_xy{
					color: #007AFF;
				}
				.xyColor{
					color: #007AFF;
					cursor: pointer;
				}
				.xyColor:hover{
					opacity: 0.7;
				}
			}
			
			.footer{
				font-size: 16px;
				line-height: 24px;
				margin-top: 20px;
				.toLogin{
					color: #007AFF;
					cursor: pointer;
				}
				.toLogin:hover{
					opacity: 0.7;
				}
			}
			@keyframes shake-slow {
				0% {  transform: translate(0px, 0px);}  					
				10% {  transform: translate(8px, 0px);}
				20% {  transform: translate(0px, 0px);}
				30% {  transform: translate(7px, 0px);}
				40% {  transform: translate(0px, 0px);}   
				50% {  transform: translate(6px, 0px);}   
				60% {  transform: translate(0px, 0px);} 
				70% {  transform: translate(5px, 0px);}  
				80% {  transform: translate(0px, 0px);} 
				90% {  transform: translate(4px, 0px);} 
				100% {  transform: translate(0px, 0);} 
						 
			 }  
			.shake-slow{  animation:shake-slow 1s infinite ease-in-out; }
		}
	}
</style>

