<template>
	<div class="ti-main">
		<el-card class="textLeft" v-if="vipCorp.zcGnjp==1">
			<el-collapse v-model="active1">
				<el-collapse-item title="国内机票差旅政策" name="1">
					<div>
						<div class="flex setTitle" >
							<div class="bfb-w32">里程</div>
							<div class="bfb-w32">差旅标准</div>
							<div class="bfb-w32">管控方式</div>
						</div>
						<div v-for="item in ticketInfoList" :key="item.clzcId" class="borderT-F0 flex setText">
							<div class="bfb-w32">{{item.distanceS +' -- '+ item.distanceE+' 公里'}}</div>
							<div class="bfb-w32">{{item.list[0].gkValue}}</div>
							<div class="bfb-w32">{{item.gkfs==1?'只做提醒':'不允许预订'}}</div>
						</div>
					</div>
					<el-empty description="暂无国内机票差旅政策" v-if="ticketInfoList.length==0"></el-empty>	
				</el-collapse-item>
			</el-collapse>	 
		</el-card>
		<el-card class="textLeft marT20" v-if="vipCorp.zcGjjp==1">
			<el-collapse v-model="active2">
				<el-collapse-item title="国际机票差旅政策" name="2">
					<div>
						<div class="flex setTitle" >
							<div class="bfb-w32">里程</div>
							<div class="bfb-w32">差旅标准</div>
							<div class="bfb-w32">管控方式</div>
						</div>
						<div v-for="item in gjTicketInfoList" :key="item.clzcId" class="borderT-F0 flex setText">
							<div class="bfb-w32">{{item.distanceS +' -- '+ item.distanceE+' 公里'}}</div>
							<div class="bfb-w32">{{item.list[0].gkValue}}</div>
							<div class="bfb-w32">{{item.gkfs==1?'只做提醒':'不允许预订'}}</div>
						</div>
					</div>
					<el-empty description="暂无国际机票差旅政策" v-if="gjTicketInfoList.length==0"></el-empty>	
				</el-collapse-item>
			</el-collapse>	 
		</el-card>
		<el-card class="textLeft marT20" v-if="vipCorp.zcJd==1">
			<el-collapse v-model="active3" >
				<el-collapse-item title="国内酒店差旅政策" name="3">
					<div>
						<div class="flex setTitle" >
							<div class="bfb-w32">城市</div>
							<div class="bfb-w32">差旅标准</div>
							<div class="bfb-w32">管控方式</div>
						</div>
						<div v-for="item in hotelInfoList" :key="item.clzcId" class="borderT-F0 flex  setText">
							<div class="bfb-w32">
								<span>{{item.cityLevelName}}</span>
							</div>
							<div class="bfb-w32">{{item.gkValue?item.gkValue:'未设置酒店价格标准'}}</div>
							<div class="bfb-w32">{{item.gkfs==1?'只做提醒':'不允许预订'}}</div>
						</div>
					</div>
					<el-empty description="暂无国内酒店差旅政策" v-if="hotelInfoList.length==0"></el-empty>
				</el-collapse-item>
			</el-collapse>	 
		</el-card>
		<el-card class="textLeft marT20" v-if="vipCorp.zcGjjd==1">
			<el-collapse v-model="active4" >
				<el-collapse-item title="国际酒店差旅政策" name="4">
					<div>
						<div class="flex setTitle" >
							<div class="bfb-w32">城市</div>
							<div class="bfb-w32">差旅标准</div>
							<div class="bfb-w32">管控方式</div>
						</div>
						<div v-for="item in gjHotelInfoList" :key="item.clzcId" class="borderT-F0 flex  setText">
							<div class="bfb-w32">
								<span>{{item.cityLevelName}}</span>
							</div>
							<div class="bfb-w32">{{item.gkValue}}</div>
							<div class="bfb-w32">{{item.gkfs==1?'只做提醒':'不允许预订'}}</div>
						</div>
					</div>
					<el-empty description="暂无国际酒店差旅政策" v-if="gjHotelInfoList.length==0"></el-empty>
				</el-collapse-item>
			</el-collapse>	 
		</el-card>
		<el-card class="textLeft marT20" v-if="vipCorp.zcHcp==1">
			<el-collapse v-model="active5">
				<el-collapse-item title="火车票差旅政策" name="5">
					<div>
						<div class="flex bold setTitle" >
							<div class="bfb-w32">车型</div>
							<div class="bfb-w32">差旅政策</div>
							<div class="bfb-w32">管控方式</div>
						</div>
						<div v-for="item in trainInfoList" :key="item.clzcId" class="borderT-F0 flex setText">
							<div class="bfb-w32">{{item.cityLevelName}}</div>
							<div class="bfb-w32">{{item.gkValue}}</div>
							<div class="bfb-w32">{{item.gkfs==1?'只做提醒':'不允许预订'}}</div>
						</div>
					</div>
					<el-empty description="暂无火车票差旅政策" v-if="trainInfoList.length==0"></el-empty>
				</el-collapse-item>
			</el-collapse>	 	
		</el-card>
		<el-card class="textLeft marT20 marB20" v-if="isCar">
			<el-collapse v-model="active6" >
				<el-collapse-item title="用车差旅政策" name="6">
					<div>
						<div class="flex bold setTitle textLeft padL20" >
							差旅政策
						</div>
						<div v-for="item in carInfoList" :key="item.id" class="borderT-F0  padL20 textLeft" style="line-height: 36px;">
							<div>
								<span class="carBlock">因公/用车：</span>
								<span>{{item.utilityVehicle==1?'管控':'不管控'}}</span></div>
							<div>
								<span class="carBlock">距离限制：</span>
								<span>{{item.distanceLimitXz==1?'限 '+item.distanceLimit+' 公里':'不限制'}}</span></div>
							<div >
								<span class="carBlock">用车时间段：</span>
								<span v-if="item.carDayXz==0">不限制</span>
								<span v-if="item.carDayXz==1">
									<span class="padL20">
										限(
										<span>
											<span v-if="(item.carDay.split(',')).indexOf('1')!=-1">周一,</span>
											<span v-if="(item.carDay.split(',')).indexOf('2')!=-1">周二,</span>
											<span v-if="(item.carDay.split(',')).indexOf('3')!=-1">周三,</span>
											<span v-if="(item.carDay.split(',')).indexOf('4')!=-1">周四,</span>
											<span v-if="(item.carDay.split(',')).indexOf('5')!=-1">周五,</span>
											<span v-if="(item.carDay.split(',')).indexOf('6')!=-1">周六,</span>
											<span v-if="(item.carDay.split(',')).indexOf('7')!=-1">周日</span>
										</span>	
										)
										<span>{{item.carTime+'期间 '}}</span>
										可因公用车
									</span>
								</span>
							</div>
							<div>
								<span class="carBlock">同一城市:</span>
								<span style="text-align: left;display: inline-block;">
									{{item.sameCity==1?'开启': '未开启'}}
								</span>
							</div>
							<div >
								<span class="carBlock">单笔限额: </span>
								<span>
									<span v-if="item.singleLimitXz==0">不限制</span>
									<span v-if="item.singleLimitXz==1">
										{{item.singleLimit}}￥
									</span>
								</span> 
							</div>
							<div>
								<span class="carBlock">用车类型：</span>
								<span style="text-align: left;display: inline-block;">
									<span class="padR5" v-if="item.carType.includes('10,11')">
										专车
									</span>
									<span class="padR5" v-if="item.carType.includes('8,9')">
										快车
									</span>
									<span class="padR5" v-if="item.carType.includes('4')">
										接机
									</span>
									<span class="padR5" v-if="item.carType.includes('5')">
										送机
									</span>
									<span class="padR5" v-if="item.carType.includes('6')">
										接站
									</span>
									<span class="padR5" v-if="item.carType.includes('7')">
										送站
									</span>
									<span class="padR5" v-if="item.carType.includes('1,2')">
										包车
									</span>
								</span>
							</div>
							<div>
								<span class="carBlock">用车车型：</span>
								<span>
									<span class="padR5" v-if="(item.carModel.split(',')).indexOf('15201')!=-1">经济型</span>
									<span class="padR5" v-if="(item.carModel.split(',')).indexOf('15202')!=-1">舒适型</span>
									<span class="padR5" v-if="(item.carModel.split(',')).indexOf('15203')!=-1">商务型</span>			
									<span class="padR5" v-if="(item.carModel.split(',')).indexOf('15204')!=-1">豪华型</span>    
								</span>
							</div>
							<div>
								<span class="carBlock">超标管控：</span>
								<span v-if="item.excessiveControl==0">超标不允许预订</span>
								<span v-if="item.excessiveControl==1">超标个人支付</span>
								<span v-if="item.excessiveControl==2">超标需填写原因</span>
							</div>
						</div>
					</div>
					<el-empty description="暂无用车差旅政策" v-if="carInfoList.length==0"></el-empty>
				</el-collapse-item>
			</el-collapse>	
		</el-card>
		<el-empty description="暂未开启差旅政策" v-if="vipCorp.zcGnjp==0 && vipCorp.zcJd==0 && vipCorp.zcHcp==0 && (isCar==false)"></el-empty>	
	</div>
</template>

<script>
	export default{
		name: 'TravelInfo',
		data() {
			return {
				active1:'1', // 展示页面
				active2:'2',
				active3:'3',
				active4:'4',
				active5:'5', 
				active6:'6',
				cplx:'',
				trainInfoList:[], // 火车票信息
				hotelInfoList:[], // 酒店信息
				ticketInfoList:[], //机票行息
				gjTicketInfoList:[], // 国际机票行息
				gjHotelInfoList:[], // 国际酒店
				carInfoList:[], //用车差旅政策
				isCar:false, // 是否显示用车差旅政策
				carInfo:{
					carModel:'',
					carDay:'',
				},
				empInfo:{},
				vipCorp:{},
			}
		},
		created(){
			let userInfo = this.$common.getUserInfo() || {};
			this.empInfo = userInfo.empInfo || {};
			this.vipCorp = userInfo.vipCorp || {};
		},
		mounted(){
			this.getVipClzcHtTrGetInfo(10903);
			this.getVipClzcHtTrGetInfo(10904);
			this.getVipClzcTkGetInfo(10901);
			this.getGjVipClzcTkGetInfo(10902);
			this.getTravelGjHtList();
			// this.getInterHotelTravelPolicy(10910);
		},
		methods:{
			// 获取差旅管理政策
			// 暂时不可显示用车差旅政策 ， 因为此vipControlDetail 是为管理端使用， h5与obt没有此权限
			// getVipControlDetail() {
			// 	this.$conn.getConn('commu.vipControlDetail',{jsfile:false})({}).then((res)=>{
			// 		let data = res.data || {};
			// 		if(data.zcYc==1){
			// 			this.isCar = true;
			// 			this.getCarClzcList();
			// 		}else{
			// 			this.isCar = false;
			// 		}
			// 	}).catch((err)=>{
			// 		this.$message({
			// 			type: 'error',
			// 			message: err.tips
			// 		})
			// 	})
			// },
			// 获取用车差旅标准
			getCarClzcList() {
				let queryParams = {};
					queryParams = {
						count: 10,
						pageNum: 1,
					}
				this.$conn.getConn('carBook.vipCarClzcList',{jsfile:false})(queryParams).then(res=>{
					let data = res.data || {};
					let empRank = this.empInfo.empRank;
					this.carInfoList = [];
					data.list.forEach(item=>{
						if(item.empRankS<=empRank && item.empRankE>=empRank){
							this.carInfoList.push(item)
						}
					})
					this.carInfoList.forEach(item=>{
						if(item.carModel){
							let arr = item.carModel.split(',');
							arr.indexOf('15201')
						}
					})
				
				}).catch(err=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 获取酒店 火车票 差旅政策
			getVipClzcHtTrGetInfo(num){
				let queryParams = {};
				queryParams = {
					cplx : num,
				}
				this.$conn.getConn('travelZc.hcJdZcGet',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					if(num==10903){
						this.hotelInfoList = [];
					}else{
						this.trainInfoList = [];
					}

					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							if(num==10903){
								this.hotelInfoList = item.list;
							}else{
								this.trainInfoList = item.list;
							}
							
						}
					})	
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips,
					})
				})
				
			},
			// 获取国内酒店
			getTravelGjHtList(){
				let queryParams = {};
				queryParams = {
					cplx : 10910,
				}
				this.$conn.getConn('travelZc.hcJdZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					
									
					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.gjHotelInfoList = item.list;	
						}
					})	
				},(err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 获取机票差旅政策
			getVipClzcTkGetInfo(num){
				let queryParams = {};
				queryParams = {
					cplx : num,
				}
				this.$conn.getConn('travelZc.tkZcGet',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.ticketInfoList = [];
					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.ticketInfoList = item.list
						}
					})
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			//获取国际机票差旅政策
			getGjVipClzcTkGetInfo(num){
				let queryParams = {};
				queryParams = {
					cplx : num,
				}
				this.$conn.getConn('travelZc.tkZcGet',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.gjTicketInfoList = [];
					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.gjTicketInfoList = item.list
						}
					})
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// handleChangeOne(){
				
			// }
		}
	}
</script>

<style scoped lang="scss" >
	.ti-main{
		font-size: 12px;
		font-family: '微软雅黑';
		font-weight: 400;
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		/deep/ .el-collapse-item__content{
			font-size: 12px;
			font-weight: 400;
			color: #000000;
		}
		/deep/ .el-collapse-item__header{
			font-size: 18px;
			font-weight: 600;
			color: #000000;
		}
		.setTitle{
			height: 30px;
			background: #F0F0F0;
			color: #838793;
			line-height: 30px;
			text-align: center;
		}
		.setText{
			height: 36px;
			color: #000000;
			line-height: 36px;
			text-align: center;
		}
		.font17{
			font-size: 17px;
		}
		.font15{
			font-size: 15px;
		}
		.font20{
			font-size: 20px;
		}
		.bold{
			font-weight: bold;
		}
		.line-h50{
			line-height: 50px;
		}
		.bfb-w10{
			width: 10%;
		}
		.bfb-w15{
			width: 15%;
		}
		.bfb-w32{
			width: 32%;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w30{
			width: 30%;
		}
		.w100{
			width: 100px;
		}
		.carBlock{
			display: inline-block;
			width: 80px;
		}
		.textLeft{
			text-align: left;
		}
		.textRight{
			text-align: left;
		}
		.marT20{
			margin-top: 20px;
		}
		.marL20{
			margin-left: 20px;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.padT10{
			padding-top: 10px;
		}
		.padT20{
			padding-top: 20px;
		}
		.padTB30{
				padding: 30px 0;
		}
		.padT30{
			padding-top: 30px;
		}
		.padL5{
			padding-left: 5px;
		}
		.padR5{
			padding-right: 5px;
		}
		.padL10{
			padding-left: 10px;
		}
		.padL20{
			padding-left: 20px;
		}
		.padR10{
			padding-right: 10px;
		}
		.padB30{
			padding-bottom: 30px;
		}
		.borderT-F0{
			border-top:1px solid #F0F0F0;	
		}

		.bgf2{
			background: #F2F2F2;
		}
		.colorAF{
			color: #AFAFB3;
		}
		.colorZTS{
			color: #006BB9;
		}
		.color80{
			color: #808080;
		}
		.pointer{
			cursor: pointer;
		}
	}
</style>
