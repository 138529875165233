<template>
	<div class="nol-main">
		<el-table
			v-if="needIndex==1"
		    :data="orderList"
		   >
			<el-table-column label="行程" width="60">
				<template slot-scope="scope">
					<el-button type="text" class="bttext"  v-if="scope.row.voyageType==1">单程</el-button>
					<el-button type="text" class="bttext"  v-if="scope.row.voyageType==2">往返</el-button>
					<el-button type="text" class="bttext"  v-if="scope.row.voyageType==3">多程</el-button>
				</template>
			</el-table-column>
		    <el-table-column label="出发/到达城市" width="220">
				<template slot-scope="scope">
					<div v-for="(item, index) in scope.row.interNeedOrders" :key="index"  style="font-size: 12px;">
						{{item.departCityName}}-{{item.arriveCityName}}
					</div>
				</template>
		    </el-table-column>
		    <el-table-column label="出行日期" width="180">
				<template slot-scope="scope">
					<div v-for="(item, index) in scope.row.interNeedOrders" :key="index"  style="font-size: 12px;">
						{{item.departTime}} {{item.week}}
					</div>
				</template>
		    </el-table-column>
		    <el-table-column label="出行人数" width="100">
				<template slot-scope="scope">
					<div v-if="scope.row.adultNum>0" style="font-size: 12px;">成人：{{scope.row.adultNum}}</div>
					<div v-if="scope.row.babyNum>0" style="font-size: 12px;">儿童：{{scope.row.babyNum}}</div>
					<div v-if="scope.row.childNum>0" style="font-size: 12px;">婴儿：{{scope.row.childNum}}</div>
				</template>
		    </el-table-column>
			<el-table-column prop="linkman" label="联系人" width="120">
			</el-table-column>
			<el-table-column prop="linkTel" label="手机号" width="120"></el-table-column>
			<el-table-column prop="address" label="操作">
				<template slot-scope="scope">
					<div v-if="scope.row.orderStatus==1">待处理</div>
					<div v-if="scope.row.orderStatus==2">已转单</div>
					<div v-if="scope.row.orderStatus==3">已忽略</div>
					<el-button type="text" @click="goToDetail(scope.row.id)">订单详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-table
			v-if="needIndex==2"
		    :data="orderList"
		   >
			<el-table-column label="行程" width="210">
				<template slot-scope="scope">
					<div class="flex">
						<div>
							<el-button type="text" class="iconfont yj-icon-jiudian1 setIcon"></el-button>
						</div>
						<div class="nol-title">
							<div class="nol-con-title">{{scope.row.jdmc}}</div>
							<div class="padT12">{{scope.row.cityName}}</div>
							<div class="padT12 colorB3">{{scope.row.createdate}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
		    <el-table-column label="入住时间" prop="rzrq" width="100"></el-table-column>
		    <el-table-column label="出行人数/房间数" width="120">
				<template slot-scope="scope">
					<span>{{scope.row.rooms}}间 入住{{scope.row.nights}}晚</span>
				</template>
		    </el-table-column>
		   
			<el-table-column prop="linkman" label="联系人" width="120"></el-table-column>
			<el-table-column prop="linkMobile" label="手机号" width="120"></el-table-column>
			<el-table-column label="订单号" width="130">
				<template slot-scope="scope">
					<el-button type="text" @click="goToHtDetail(scope.row.id)">{{scope.row.orderNo}}</el-button>
				</template>
			</el-table-column>	
			<el-table-column label="状态">
				<template slot-scope="scope">
					<div class="w140 setlineH20" >
						<div class="color42" v-if="scope.row.ddzt==1">待处理</div>
						<div class="color42" v-if="scope.row.ddzt==2">已完成</div>
						<div class="color42" v-if="scope.row.ddzt==3">已忽略</div>
						<div class="color42" v-if="scope.row.ddzt==4">处理中</div>
						<el-button type="text" @click="goToHtDetail(scope.row.id)">订单详情</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default{
		name: 'NeedOrderList',
		props:{
			orderList:{
				type: Array,
				default(){
					return [];
				}
			},
			titleType:{
				type: [Number,String],
				default: 4 || '4',
			},
			tabIndex:{
				type: [Number,String],
				default: 1 || '1',
			}
		},
		data() {
			return {
				needIndex: 1,
			}
		},
		watch:{
			tabIndex:{
				handler(num){
					this.needIndex = num
				},
				immediate:true,
				
			}
		},
		methods:{
			cancel(orderItem){
				
			},
			// 去详情
			goToDetail(id){
				let query = {
					orderId: id
				}
				let routeUrl = this.$router.resolve({path: '/yjDetail/inTkDetail',query:query});
				window.open(routeUrl.href,"_blank")
			},
			// 酒店需求单详情
			goToHtDetail(id){
				let query = {
					orderId: id
				}
				let routeUrl = this.$router.resolve({path: '/yjDetail/inHtDetail',query:query});
				window.open(routeUrl.href,"_blank")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.nol-main{
		font-family: '微软雅黑';
		font-weight: 400;
		color: #424242;
		.text{
			color: #1C83ED;
			cursor: pointer;
		}
		.bttext{
			font-size: 14px; 
			font-weight: bold;
		}
		.nol-main-title{
			padding: 30px 0;
			font-size: 12px;
			font-weight: bold;
			color: #808080;
			line-height: 12px;
			border-bottom:  1px solid #F2F2F2;
		}
		.nol-title{
			font-size: 12px;
			line-height: 12px;
		}
		.setIcon{
			font-size: 16px;
			margin: 0 15px 0 10px;
			padding: 0;
			vertical-align: initial;
		}
		.nol-con-title{
			font-size: 14px;
			line-height: 16px;
			font-weight: 400;
			color: #424242;
		}
		.flex{
			display: flex;
		}
		.between{
			
		}
		.textLeft{
			text-align: left;
		}
		.setContent{
			text-align: left;
			border-bottom: 1px solid #F2F2F2;
			padding: 29px 0;
		}
		.setContent:hover{
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
		}
		.setPrice{
			font-size: 16px;
			font-weight: bold;
			color: #FF9524;
			line-height: 70px;
		}
		.cxrItem{
			line-height: 14px;
			padding: 28px 0;
		}
		.colOrderNo{
			line-height: 14px;
			padding: 28px 0;
			color: #1C83ED;
		}
		.padT12{
			padding-top: 12px;
		}
		.line-h70{
			line-height: 70px;
		}
		.colorB3{
			color: #B3B3B3;
		}
		.padTB19{
			padding: 19px 0;
		}
		.setlineH20{
			padding: 15px 0;
			line-height: 20px;
		}
		.setlineH16{
			padding: 11px 0;
			line-height: 16px;
		}
		.setHiden{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.w65{
			width: 65px;
		}
		.w80{
			width: 80px;
		}
		.w100{
			width: 100px;
		}
		.w120{
			width: 120px;
		}
		.w140{
			width: 140px;
		}
		.w150{
			width: 150px;
		}
		.w190{
			width: 190px;
		}
		.w210{
			width: 210px;
		}
		.w260{
			width: 260px;
		}
		.w300{
			width: 300px;
		}
	}
</style>


