<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick"  >
            <el-tab-pane :label="item.name" :name="item.value" class="box" v-for="item in list" :key="item.name"    >
                <el-card :body-style="{ padding: '0px' }" class="card" v-for="info in dataList" :key="info.id">
                    <img :src="info.fileUrl" class="image">
                    <div style="padding: 7px;">
                        <span style="color: #1C83ED;">{{info.name}}</span>
                        <div class="bottom clearfix" >
                        <time class="time" >{{info.cityName}}</time>

                        </div>
                     <el-button type="primary" style="    float: right;" @click="jumpDetail(info)">查看</el-button>
                    </div>
                </el-card>
            </el-tab-pane>
  </el-tabs>
    <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :total="total">
  </el-pagination>
  </div>
</template>

<script>

export default {
  name: '',

  components: {},

  data() {
    return {

        activeName: '',
        pageNum:1,
        currentDate: new Date(),
        total:0,
        pages:1,
        list:[
            {value:'',name:'全部'},
            {value:'19002',name:'企业活动'},
            {value:'19003',name:'企业会展'},
            {value:'19004',name:'商务接待'},
        ],
        dataList:[]
    }
  },

  created() {
    this.getCaseshowPage()
  },

  methods: {
     handleClick(tab, event) {
        console.log(this.activeName,"activeName")
        console.log(tab, event);
        this.pageNum=1
        this.getCaseshowPage()
      },

	getCaseshowPage(){
		let queryParams = {
			caseStatus :1,
            count:10,
            miceType :this.activeName==0?'':this.activeName,
            pageNum:this.pageNum
		}
		this.$conn.getConn("mice.caseshowPage")(queryParams,(res)=>{
			let data = res.data || {};
            this.dataList=data.list
            this.total=data.total
            // console.log(data)
		},(err)=>{
			
		})
	},
    handleCurrentChange(val){
        console.log(val,"bal;;;")
        this.pageNum=val
        this.getCaseshowPage()
    },
    jumpDetail(info){
        this.$common.sessionset('miceDetailInfo', info)
		let routeUrl = this.$router.resolve({
			path: '/yjDetail/miceDetail',
			query: {
			}
		});
		window.open(routeUrl.href, "_blank")
    }
  },

}

</script>

<style lang='scss' scoped>
.box{
    display: flex;
    flex-wrap: wrap;
    .card{
        width: 18%;
        height: 200px;
        margin-bottom: 20px;
        margin-right: 15px;
        img{
            width: 100%;
            height: 100px;
        }
        .bottom{
            margin: 5px 0px;
        }
    }


}
.el-pagination{
    text-align: center;
}
</style>