<template>
	<div class="clk-main">
		<el-card>
			<div class="flex between">
				<div class="cylsx">
					常用联系人
				</div>
				<el-button class="el-icon-plus" type="primary" @click="addLxr">
					新增联系人
				</el-button>
			</div>
			<div style="height: 20px;"></div>
			<el-table
				:data="linkmanList"
				style="width: 100%"
				:header-cell-style="header"
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .8)"
				element-loading-spinner = "el-icon-loading"
				element-loading-text = "加载中，请稍后..."
				class="bghover"		
			>
				<el-table-column type="index" width="50"></el-table-column>
				<el-table-column label="常用联系人" prop="contactName"></el-table-column>
				<el-table-column label="手机号" prop="contactPhone"></el-table-column>
				<el-table-column label="邮箱" prop="contactEmail"></el-table-column>     
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="editLinkman(scope.row)">编辑</el-button>
						<el-button type="text" @click="removeLinkman(scope.row.id)">删除</el-button>
					</template>  
				</el-table-column>  	   
			</el-table>	
		</el-card>
		<el-dialog
			:title="title"
			:close-on-click-modal="false"
			:visible.sync="visible"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="联系人姓名" prop="contactName">
					<el-input v-model="ruleForm.contactName"  placeholder="请输入联系人姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="contactPhone">
					<el-input v-model="ruleForm.contactPhone"  placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="contactEmail">
					<el-input v-model="ruleForm.contactEmail" placeholder="请输入邮箱"></el-input>
				</el-form-item>
			</el-form>	
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="submit('ruleForm')">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'Linkman',
		data(){
			var valiEmail = (rule, value, callback)=> {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
					if(value){
						if (mailReg.test(value)) {
						    callback()
						} else {
						    callback(new Error('请输入正确的邮箱格式'))
						}
					}else{
						callback()
					}
			};
			// 验证手机号
			let valiPhoneNumber = (rule, value, callback)=> {
				const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
				setTimeout(() => {
				    if (phoneReg.test(value)) {
				        callback()
				    } else {
				        callback(new Error('手机号码格式不正确'))
				    }
				}, 100)
			};
			
			return {
				header:{background:'#F2F2F2'},
				title: '',
				loading: false,
				visible: false,
				isEmpty: false, //常用联系人列表为空时展示
				linkmanList:[], //联系人列表
				ruleForm:{
					contactEmail: "",
					contactName: "",
					contactPhone: "",
					id:"",
				},
				rules: {
					contactEmail:[
						{ validator: valiEmail, trigger: 'blur' }
					],
				    contactPhone: [
				        { required: true, message: '请输入手机号', trigger: 'blur' },
				        { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' },
						{ validator: valiPhoneNumber, trigger: 'blur'}
				    ],
					contactName: [
						{ required: true, message: '请输入常用联系人姓名', trigger: 'blur'},		
					],
					
				},
			}
		},
		mounted(){
			this.getLinkmanList();
		},
		methods:{
			// 获取常用联系人列表
			getLinkmanList(){
				this.$conn.getConn('user.findContactsByEmpId')({},(res)=>{
					let data = res.data || {};
					this.linkmanList = data.contactList || [];		
				},(err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
				
			},
			// 新增常用联系人
			addLxr(){
				this.title = '新增联系人';
				this.visible = true;
				this.ruleForm= {  // 基本信息参数
					contactEmail: "",
					contactName: "",
					contactPhone: "",
				}
			},
			// 编辑联系人
			editLinkman(item){
				this.title = '编辑联系人';
				this.visible = true;
				this.ruleForm= {  // 基本信息参数
					contactEmail: item.contactEmail,
					contactName: item.contactName,
					contactPhone: item.contactPhone,
					id: item.id
				}
			},
			//删除联系人
			removeLinkman(id){
				this.$confirm('此操作将永久删除该选项, 是否继续?', '删除', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn('user.delContacts')({id:id},(res)=>{
							this.$message({
								type: 'success',
								message: '删除成功'
							})
							this.getLinkmanList()
						},(err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})  							 
					}).catch(() => {
						 this.$message({
							type: 'info',
							message: '已取消删除'							
						 });     
					}); 
				
			},
			// 保存联系人
			submit(formName){
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						let params = {}
						params = {
							contactEmail:this.ruleForm.contactEmail,
							contactName: this.ruleForm.contactName,
							contactPhone: this.ruleForm.contactPhone,
							id: this.ruleForm.id,
						}
						this.$conn.getConn('user.saveContacts')(params,(res)=>{
							this.$message({
								type: 'success',
								message: '保存成功'
							})
							this.visible = false;
							this.getLinkmanList()
						},(err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})
				    } else {
				      return false;
				    }      
				});
			},
			// 关闭页面
			handleClose(){
				this.visible = false;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.clk-main{
		/deep/ .el-dialog{
			width: 660px;
		}
		/deep/ .el-dialog__header{
			padding: 20px;
			border-bottom: 1px solid #DCDCDC;
		}
		/deep/ .el-dialog__body{
			padding: 20px;
		}
		/deep/ .el-dialog__footer{
			padding: 15px 30px 20px ;
			border-top: 1px solid #DCDCDC;
		}
		/deep/ .el-select .el-input__inner{
			width: 200px;
		}
		/deep/ .el-input__inner{
			border-radius: 0;
		}
		/deep/ .el-date-editor--date{
			width: 200px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.cylsx{
			font-size: 15px;
			line-height: 32px;
			font-weight: bold;
		}
		.add-title{
			font-size: 12px;
			line-height: 12px;
			font-weight: 400;
			color: #808080;
			background: #F2F2F2;
			margin-top: 20px;
			padding: 14px 0;
		}
		.add-text{
			font-size: 12px;
			font-weight: 500;
			line-height: 32px;
			color: #282828;
			padding: 15px 0;
			border-bottom: 1px solid #DCDCDC;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w30{
			width: 30%;
		}
	
	}
		
	
</style>
