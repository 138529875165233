<template>
	<div class="car">
		<el-card>
			<div class="header">
				<p>订单号：{{detail.ddbh}}</p>
				<p>订单状态：
					<span class="colorOrange">{{status[detail.ddzt]}}</span>
				</p>
			</div>
			<div class="carContent">
				<div class="carLeft">基本信息</div>
				<div class="carRight lineH78">
					<span class="text">支付方式：{{detail.payMothedName}}</span>
					<span class="text">预订时间：{{detail.bookDate}}</span>
					<span class="text">订单金额：<span class="colorOrange">{{detail.zfy}}¥</span></span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">预订信息</div>
				<div class="carRight" style="padding:10px 0; line-height: 30px;">
					<div>
						<span class="text">代泊车机场：{{detail.cityName}}-{{detail.jsjcmc}}</span>
					</div>
					<div>
						<span class="text">去程交车点：{{detail.jchzl}}-{{detail.jcaddr}}</span>
						<span class="text">交车时间：{{detail.jcsj}}</span>
						<span class="text">交车司机：{{detail.jcdriverName}}{{detail.jcdriverTel}}</span>
					</div>
					<div>
						<span class="text">去程交车点：{{detail.schzl}}-{{detail.scaddr}}</span>
						<span class="text">交车时间：{{detail.scsj}}</span>
						<span class="text">交车司机：{{detail.scdriverName}}{{detail.scdriverTel}}</span>
					</div>
					<div>
						<span class="text">
							已选服务：
							<span v-if="detail.sfxyfp==1" style="padding-left:10px;">需要发票</span>
							<span v-if="detail.sfxyjy==1" style="padding-left:10px;">需要加油</span>
							<span v-if="detail.sfxyxc==1" style="padding-left:10px;">需要洗车</span>
						</span>
					</div>
					<div>
						<span class="text">
							备注：{{detail.bzbz || '无'}}
						</span>
					</div>
					<div>
						<span class="text" style="color: blue;cursor: pointer;">
							如需延期取车可致电客服
						</span>
					</div>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">泊车信息</div>
				<div class="carRight lineH78">	
					<span class="text">停车天数：{{detail.tcts}}天</span>
					<span class="text">停车价格：<span class="colorOrange">{{detail.price}}¥</span></span>
					<span class="text">泊车费用：<span class="colorOrange">{{detail.pcfy}}¥</span></span>
					<span class="text">洗车费用：<span class="colorOrange">{{detail.xcfy}}¥</span></span>
					<span class="text">总费用：<span class="colorOrange">{{detail.zfy}}¥</span></span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">差旅信息</div>
				<div class="carRight lineH78">
					<span class="text">成本中心：{{detail.costCenterName}}</span>
					<span class="text">出差项目：{{detail.projectName}}</span>
					<span class="text">出差单号：{{detail.ccsqdNo}}</span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">联系信息</div>
				<div class="carRight lineH78">
					<span class="text">联系人：{{detail.linkman}}</span>
					<span class="text">手机号：{{detail.linkTel}}</span>
					<span class="text">邮箱：{{detail.linkEmail}}</span>
				</div>
			</div>
		</el-card>
		<el-button type="primary" plain v-if="detail.showCancel==1" @click="cancel(detail)">取 消</el-button>
	</div>
</template>

<script>
	export default{
		name: 'ParkDetail',
		data() {
			return {
				detail:{},
				status:{
					1:'已预订',
					2:'代交车',
					3:'已交车',
					4:'待取车',
					5:'已完成',
					6:'已取消',
				},
			}
		},
		mounted(){
			let orderId = this.$route.query.orderId || '';
			this.getOrderDetail(orderId)
		},
		methods:{
			// 获取详情信息
			getOrderDetail(orderId){
				var params = {
					id: orderId
				}
				let parkOrderDetail = this.$conn.getConn('park.parkOrderDetail')
				parkOrderDetail(params,(res)=>{
					this.detail = res.data || {};
					
				},(err)=>{
					
				})
			},
			// 取消信息
			cancel(detail){
				let orderId = detail.id;
				let url = "carBook.getYcCancelFee";
				this.$conn.getConn(url,{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					let khFine = data.khFine ? data.khFine: 0;
					var text = '';
					if(khFine==0){
						text = '您确定要取消吗？';
					}else{
						text = '违约金'+khFine+'元，您确定要取消吗？';
					}
					this.$confirm(text, '', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'
					    }).then(() => {
							let path = 'park.cancelParkOrder'
					    	this.toParkCancelOrder(data,orderId);
					    }).catch(() => {
					      this.$message({
					        type: 'info',
					        message: '关闭取消'
					    });              
					});
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},	
			toParkCancelOrder(data,orderId){
				let queryParams = {};
					queryParams = {
						confirmId: data.confirmId,
						orderId: orderId,
					}
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type:'success',
						message: '取消成功'
					})
					this.getOrderDetail(orderId)
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.car{
		margin: 0 auto;
		width: 1200px;
		padding: 40px 0;
		.header{
			display: flex;
			justify-content: space-between;
			line-height: 40px;
		}
		.carContent{
			display: flex;
			margin-bottom: 10px;
			min-height: 80px;
			border: 1px solid #E6E6E6;
			box-sizing: border-box;
			.carLeft{
				width: 160px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #FAFAFA;
				border-right: 1px solid #E6E6E6;    
			}
			.carRight{
				width: 960px;
				text-align: left;
				.text{
					padding-left: 40px;
				}
			}
			.lineH78{
				line-height: 78px;
			}
		}
		.colorRed{
			color: red;
		}
		.colorOrange{
			color: #f5a337;
		}
	}
</style>
