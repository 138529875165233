<template>
	<div class="pr-main" >
		<div class="main">
			<div class="title">
				{{this.$t("login.personRegister")}}
			</div>
			<div class="setform">
				<el-form ref="form" key="form" :model="form" :rules="rules" label-width="0px">
					<el-form-item prop="userName" style="margin-bottom: 20px;">
						<el-input :placeholder="$t('register.username')" v-model="form.userName"
							 clearable>
							<i slot="prefix" class="el-input__icon el-icon-user" :class="{iconColor:form.userName}"></i>
						</el-input>
					</el-form-item>
					<el-form-item prop="phoneNumber" style="margin-bottom: 20px;">
						<el-input  :placeholder="$t('register.phoneNumber')"
							v-model="form.phoneNumber" clearable>
							<i slot="prefix" class="el-input__icon el-icon-mobile-phone" :class="{iconColor:form.phoneNumber}"></i>
						</el-input>
					</el-form-item>
					<el-form-item prop="smsCode" style="margin-bottom: 20px;">
						<el-input :placeholder="$t('register.verifyCode')" maxlength="6" v-model="form.smsCode">
							<i slot="prefix" class="el-input__icon el-icon-key" :class="{iconColor:form.smsCode}"></i>
							<i slot="suffix" class="suffix-item" @click.prevent="getYzmCode">
								{{showCode?$t('login.resend')+'('+ timer +')':$t('login.getYzmCode')}}
							</i>
						</el-input>
					</el-form-item>
					
					
					<el-button type="primary" class="button" @click="toRegister('form')">
						<span class="el-icon-loading" v-if="loading"></span>
					{{$t('register.submitRegister')}}</el-button>
				</el-form>
			</div>
			<div class="zclx" :class="{'shake-slow':isShake}">
				<span class="el-icon-success xy_icon" :class="{'icon_xy':isAgreement,}" @click="getAgreement"></span>
				<span>我已阅读并同意</span>
				<span class="xyColor" @click="getService">《{{configData.corpName}}服务条款》</span>
			</div>
			<div class="footer">
				{{this.$t("register.haveAnAccount")}} <span class="toLogin" @click="goToLogin">{{this.$t("register.login")}}</span>
			</div>
		</div>
		<yj-term-agreement :visible="isService" :params="params"  @cancelService="cancelService"></yj-term-agreement>
	</div>
</template>

<script>
	export default{
		name: 'PersonRegister',
		data() {
			let valiPhone = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.form.phoneNumber)){
						callback(new Error(this.$t("register.invalidPhone")));
			        }else {
						callback();
			        }	
			    };
			return{
				form:{
					phoneNumber: "",
					smsCode: "",
					userName: "",
				},
				loading: false, // 注册加载
				showCode: false, // 验证码显示
				timer: 60, //验证码有效时间
				params: 2,
				isService: false,
				isAgreement: false,
				isShake: false,
				rules:{
					phoneNumber: [{
						required: true,
						message: this.$t("register.phoneNumber_tip"),
						trigger: "blur"
					},
					{validator:valiPhone,trigger: "blur"}],
					smsCode: [{
						required: true,
						message: this.$t("register.verifyCode_tip"),
						trigger: "blur"
					}],
					userName: [{
						required: true,
						message: this.$t("register.username_tip"),
						trigger: "blur"
					}]
				},
				clearTime: '',
			}
		},
		computed:{
			// 总公司信息
			configData(){
				let configData = this.$common.getConfigData() || {};
				return configData
			},
			// 总公司id
			compId(){
				let id = this.configData.compId || '';
				return id
			},
		},
		beforeDestroy(){
			clearTimeout(this.clearTime)
		},
		methods: {
			cancelService(bool){
				this.isService = bool;
			},
			getService(){
				this.isService = true;
			},
			getAgreement(){
				this.isAgreement =(this.isAgreement?false:true);
			},
			// 去登录
			goToLogin(){
				this.$router.push({path:'/login'})
			},
			// 获取验证码
			getYzmCode(){
				if(!this.form.phoneNumber){
					this.$message.warning(this.$t('register.phoneNumber_tip'))
					return 
				}
				if(this.showCode){
					return
				}
				this.showCode = true;
				this.getTimer();
				let queryParams = {
						phoneNumber: this.form.phoneNumber,
						compId:this.compId,
				}
				this.$conn.getConn('user.getRegisterSmsCode',{jsfile:false})(queryParams,(res)=>{
					
				},(err)=>{
					this.$message.error(err.tips)
					this.showCode = false;
				})
			},
			// 计时器时间
			validationTime(){
				this.clearTime = setTimeout(()=>{
					if(this.timer == 0){
						this.showCode = false;
						this.timer = 5;
					}else{
						this.getTimer()
					}
				},1000)
			},
			// 倒计时
			getTimer(){
				this.timer--;
				this.validationTime()
			},
			// 个人注册
			toRegister(formName){
				if(!this.isAgreement){
					this.isShake = true;
					this.$message.warning(this.$t("login.termsOfService"))
					setTimeout(()=>{
						this.isShake = false;
					},1000)
					return 
				}
				this.$refs[formName].validate(valid => {
					if(valid){
						this.loading = true;
						let queryParams = {
								compId: this.compId,
								phoneNumber: this.form.phoneNumber,
								smsCode: this.form.smsCode,
								userName: this.form.userName,
							}
						this.$conn.getConn("user.userRegister",{jsfile:false})(queryParams,(res)=>{
							this.$message.success(this.$t('register.registerSuccess'))
							this.$router.push({path:'/login'});
							this.loading = false;
							clearTimeout(this.clearTime)
						},(err)=>{
							this.$message.error(err.tips)
							this.showCode = false;
							this.loading = false;
							clearTimeout(this.clearTime)
						})	
					}
				})
			}
		}
		
	}
</script>

<style lang="scss" scoped>
	.pr-main{
		background: rgba(0,0,0,0.2);
		width: 100%;
		height: 100%;
		font-family: '微软雅黑';
		.main{
			position: relative;
			top: 50%;
			transform: translate(0,-50%);
			width: 500px;
			height: 500px;
			background-color: #FFFFFF;
			margin: 0 auto;
			padding:20px 30px;
			border-radius: 4px;
			.title{
				font-size: 24px;
				font-weight: 500;
				color: #666666;
				line-height: 48px;
			}
			.setform{
				margin-top: 20px;
				/deep/ .el-input__inner {
					height: 46px;
					background: #FFFFFF;
					border: 1px solid #DBDBDB;
					border-radius: 2px;
					line-height: 46px;
					padding-left: 60px;
					cursor: pointer;
				}
				.suffix-item{
					width: 200px;
					display: inline-block;
					text-align: center;
					cursor: pointer;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					border-left: 1px solid #DBDBDB;
				}
				/deep/ .el-input__prefix {
					top: 1px;
					left: 1px;
					width: 46px;
					height: 44px;
					background: #F5F5F5;
					font-size: 18px;
					line-height: 44px;
					border-radius: 1px 0px 0px 1px;
					cursor: pointer;
				}
				/deep/ .el-input__suffix{
				    line-height: 44px;
					top: 1px;
					right: 1px;
				}
				/deep/ .iconColor{
					color: #409EFF;
				}
				.button{
					margin-top: 30px;
					width: 100%;
					height: 50px;
					font-size: 17px;
					font-weight: 500;
					color: #FFFFFF;
					border-radius: 0;
					text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
				}
				
			}
			.footer{
				font-size: 16px;
				line-height: 24px;
				margin-top: 10px;
				.toLogin{
					color: #007AFF;
					cursor: pointer;
				}
				.toLogin:hover{
					opacity: 0.7;
				}
			}
			@keyframes shake-slow {
				0% {  transform: translate(0px, 0px);}  					
				10% {  transform: translate(8px, 0px);}
				20% {  transform: translate(0px, 0px);}
				30% {  transform: translate(7px, 0px);}
				40% {  transform: translate(0px, 0px);}   
				50% {  transform: translate(6px, 0px);}   
				60% {  transform: translate(0px, 0px);} 
				70% {  transform: translate(5px, 0px);}  
				80% {  transform: translate(0px, 0px);} 
				90% {  transform: translate(4px, 0px);} 
				100% {  transform: translate(0px, 0);} 
						 
			 }  
			.shake-slow{  animation:shake-slow 1s infinite ease-in-out; }
			.zclx{
				margin-top: 20px;
				font-size: 14px;
				.xy_icon{
					font-size: 20px;
					color: #999999;
					padding-right: 5px;
					cursor: pointer;
					vertical-align: top;
				}
				.icon_xy{
					color: #007AFF;
				}
				.xyColor{
					color: #007AFF;
					cursor: pointer;
				}
				.xyColor:hover{
					opacity: 0.7;
				}
			}
		}
	}
</style>
