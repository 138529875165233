<template>
	<div class="tr-screen-loading">
		<el-skeleton :loading="loading" style="width: 100%"  animated  :throttle="500" :count="1">
			<template slot="template">
				<div class="skelet" style="padding:20px;">
					<el-skeleton-item variant="h3" style="width: 100%;height:30px;"/>
				</div>
				<div class="skelet" style="margin-top:20px;">
					<div class="flex between" style="padding:20px;margin-top: 20px ;">
						<el-skeleton-item variant="h3" style="width: 20%;height:30px;" />
						<el-skeleton-item variant="h3" style="width: 60%;height:30px;" />
					</div>	
				</div>
				<div class="skelet" style="margin-top:20px;">
					<div class="flex between" style="padding:20px;margin-top: 20px ;">
						<el-skeleton-item variant="h3" style="width: 20%;height:30px;" />
						<el-skeleton-item variant="h3" style="width: 60%;height:30px;" />
					</div>	
				</div>
				<div class="skelet" style="margin-top:20px;">
					<div class="flex between" style="padding:20px;margin-top: 20px ;">
						<el-skeleton-item variant="h3" style="width: 20%;height:30px;" />
						<el-skeleton-item variant="h3" style="width: 60%;height:30px;" />
					</div>	
				</div>
				<div class="skelet" style="margin-top:20px;">
					<div class="flex between" style="padding:20px;margin-top: 20px ;">
						<el-skeleton-item variant="h3" style="width: 20%;height:30px;" />
						<el-skeleton-item variant="h3" style="width: 60%;height:30px;" />
					</div>	
				</div>
			</template>
		</el-skeleton>	
	</div>
</template>

<script>
	export default{
		name: 'TrainScreenLoading',
		props:{
			loading:{
				type:[Boolean,Function],
				default (){
					return false
				}
			}
		},
		data(){
			return {
				rows:11, // 每一项有几个元素过渡
				count:6, // 共有多少项
				cabinLength:2,
				
			}
		},
		
	}
</script>

<style scoped="scoped" lang="scss">
	.tr-screen-loading{
		background-color: white;
		.skelet{
			width: 100%;
			height:auto;
			background-color: #FFFFFF;
			border-radius: 4px;
			.flex{
				display: flex;
			}
			.between{
				justify-content: space-between;
			}
		}
		
	}
</style>
