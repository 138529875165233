<template>
	<div class="car">
		<el-card>
			<div class="header">
				<p>订单号：{{detail.orderNo}}</p>
				<p>订单状态：
					<span :class="{colorRed:isRed,colorOrange:isOk}">{{status[detail.orderStatus]}}</span>
				</p>
			</div>
			<div class="carContent">
				<div class="carLeft">基本信息({{detail.tripType==1?'因公':'因私'}})</div>
				<div class="carRight lineH78">
					<span class="text">支付方式：{{detail.paySubjectName}}</span>
					<span class="text">预订时间：{{detail.bookDate}}</span>
					<span class="text">订单金额：<span class="colorOrange">{{detail.useVehicleFee}}¥</span></span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">预订信息</div>
				<div class="carRight lineH78">
					<span class="text">用车车型：{{detail.vehicleTypeName}}</span>
					<span class="text">用车时间：{{detail.startTime}}</span>
					<span class="text">用车说明：{{detail.scdd}}{{detail.orderTypeName}}服务</span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">费用信息</div>
				<div class="carRight lineH78">
					<span class="text">每日单价：<span class="colorOrange">{{detail.dayPrice}}¥</span></span>
					<span class="text">天数：{{detail.useDays}}</span>
					<span class="text">夜间服务费：<span class="colorOrange">{{detail.nightServiceFee}}¥</span></span>
					<span class="text">超时费：<span class="colorOrange">{{detail.outTimeFee}}¥</span></span>
					<span class="text">超公里费：<span class="colorOrange">{{detail.outDistanceFee}}¥</span></span>
					<span class="text">其他费用：<span class="colorOrange">{{detail.otherFee}}¥</span></span>
					<span class="text">应付总计：<span class="colorOrange">{{detail.useVehicleFee}}¥</span></span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">乘客信息</div>
				<div class="carRight lineH78">
					<span class="text" v-if="detail.sfbrdc == 1">姓名：{{detail.empName}}</span>
					<span class="text" v-if="detail.sfbrdc == 1">联系电话：{{detail.phoneNumber}}</span>
					<span class="text" v-if="detail.sfbrdc != 1">姓名：{{detail.linkman}}</span>
					<span class="text" v-if="detail.sfbrdc != 1">联系电话：{{detail.linkTel}}</span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">差旅信息</div>
				<div class="carRight lineH78">
					<span class="text">成本中心：{{detail.costCenterName}}</span>
					<span class="text">出差项目：{{detail.projectName}}</span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">联系信息</div>
				<div class="carRight lineH78">
					<span class="text">联系人：{{detail.linkman}}</span>
					<span class="text">手机号：{{detail.linkTel}}</span>
				</div>
			</div>
			<div class="carContent">
				<div class="carLeft">车辆信息</div>
				<div class="carRight lineH78">
					<span class="text">司机：{{detail.driver}}</span>
					<span class="text">司机手机号：{{detail.driverTel}}</span>
					<span class="text">车牌号：{{detail.licenseNumber}}</span>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default{
		name: 'CarDetail',
		data() {
			return {
				detail:{},
				status:{
					1:'已预订',
					2:'已派单',
					3:'司机已到达',
					4:'正在前往目的地',
					5:'到达目的地',
					6:'已取消',
					7:'司机已拒单',
					8:'待退款',
					9:'退款完成',
					10:'待送审',
					11:'审批中',
					12:'审核拒绝',
					13:'已叫车',
					51:'已完成',
					61:'取消失败'
				},
				isRed: false,
				isOk: false,
			}
		},
		mounted(){
			let orderId = this.$route.query.orderId || '';
			this.getOrderDetail(orderId)
		},
		methods:{
			getOrderDetail(orderId){
				var params = {
					orderId: orderId
				}
				let queryCarOrderDetail = this.$conn.getConn('carBook.queryCarOrderDetail')
				queryCarOrderDetail(params,(res)=>{
					this.detail = res.data || {};
					if(this.detail.orderStatus==61 || this.detail.orderStatus==7 || this.detail.orderStatus==12){
						this.isRed = true;
					}else if(this.detail.orderStatus==2 || this.detail.orderStatus==9 || this.detail.orderStatus==51){
						this.isOk = true;
					}else{
						this.isRed = false;
						this.isOk = false;
					}
				},(err)=>{
					
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.car{
		margin: 0 auto;
		width: 1200px;
		padding: 40px 0;
		.header{
			display: flex;
			justify-content: space-between;
			line-height: 40px;
		}
		.carContent{
			display: flex;
			margin-bottom: 10px;
			min-height: 80px;
			border: 1px solid #E6E6E6;
			box-sizing: border-box;
			.carLeft{
				width: 160px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #FAFAFA;
				border-right: 1px solid #E6E6E6;    
			}
			.carRight{
				width: 960px;
				text-align: left;
				.text{
					padding-left: 40px;
				}
			}
			.lineH78{
				line-height: 78px;
			}
		}
		.colorRed{
			color: red;
		}
		.colorOrange{
			color: #f5a337;
		}
	}
</style>
