<template>
	<div class="atf-main">
		<div class="main">
			<h1 class="seth1">新建出差申请单</h1>
			<el-card class="marT20">
				<ccsqd-header ref="ccsqdHeader" type="1" :detail="detailHeader" :routeLength="routeLength" @getCcsqdDateInfo="getCcsqdDateInfo" @ccProjectInfo="ccProjectInfo" @selectCxrList="selectCxrList" @allFormData="allFormData"></ccsqd-header>
				<div class="borderB-F0"></div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">交通计划</span>
							<span class="setText">详细填写您的差旅行程，更方便您审批通过</span>
						</div>
						<el-button class="setBtn" @click="addVipRouteTkTr">添加行程</el-button>
					</div>
					<div class="table-main">
						<div class="tabTitle" v-show="vipRouteList.length>0">
							<div class="w34">序号</div>
							<div style="width: 65px">行程类型</div>
							<div style="width: 122px">出发日期</div>
							<div style="width: 122px">结束日期</div>
							<div class="w34">天数</div>
							<div style="width: 113px">出发/到达城市</div>
							<div class="w50">出行人</div>
							<div class="w73">航班号/车次</div>
							<div class="w73">舱位/席别</div>
							<div class="w73">费用预算(元)</div>
							<div class="w73">打车费(元)</div>
							<div class="w73">出差类型</div>
							<div style="width: 60px">出差事由</div>
							<div class="w73">住宿说明</div>
							<div class="w100">操作</div>
						</div>
						<div v-for="(item, index) in vipRouteList" :key="item.proType+''+index" class="tabContent" @click="item.proType==10904?trIndex=index:tkIndex=index">
							<div class="w34 model-k">{{index+1}}</div>
							<div class="model-k" style="width: 65px">
								<el-select v-model="item.proType" placeholder="请选择" @change="chooseRouteTypeTop(item.proType,index)">
								    <el-option
								      v-for="proTypeItem in proTypeList"
								      :key="proTypeItem.value"
								      :label="proTypeItem.name"
								      :value="proTypeItem.value"
								      :disabled="proTypeItem.disabled">
								    </el-option>
								  </el-select>
								<!-- <div class="relative">
									<div class="setdiv">
										{{item.proType=='10901'?'机票':'火车票'}}
									</div>
									<i class="tql-icon-t absolute toptableR" @click="chooseRouteTypeTop(item.proType,index)"></i>
									<i class="tql-icon-b absolute bottomtableR" @click="chooseRouteTypeTop(item.proType,index)"></i>
								</div> -->	
							</div>
							<div class="model-k flex-v" style="width: 122px">
								<el-date-picker
									value-format="yyyy-MM-dd"
								  style="width: 100%; border-right: none;"
									prefix-icon=" "
									:clearable="false"
								  v-model="item.departDate"
								  type="date"
									@change="getChangeDate(item)"
									:picker-options="pickerOptions(ccsqdRange )"
								  placeholder="出发/起飞日期"
								>
								</el-date-picker>
								<el-time-picker
									v-model="item.departDateTime"
									style="margin-left: 3px; width: 55px; border-right: none;"
									placeholder="00:00"
									format="HH:mm"
									value-forma="HH:mm"
									prefix-icon=" "
							    :clearable="false"
									:editable="false"
								>
								</el-time-picker>
							</div>
							<div class="model-k flex-v" style="width: 122px">
								<el-date-picker
									value-format="yyyy-MM-dd"
									style="width: 100%; border-right: none;"
									prefix-icon=" "
									:clearable="false"
								    v-model="item.arriveDate"
								    type="date"
									@change="getChangeDate(item)"
									:picker-options="pickerOptions(ccsqdRange )"
								    placeholder="出发/起飞日期"
								    >
								</el-date-picker>
								<el-time-picker
									style="margin-left: 3px; width: 55px; border-right: none;"
									v-model="item.arriveDateTime"
									placeholder="00:00"
									format="HH:mm"
									value-forma="HH:mm"
									prefix-icon=" "
									:clearable="false"
									:editable="false"
								>
								</el-time-picker>
							</div>
							<div class="w34 model-k">{{routeDiffTime(item)}}</div>
							<div class="w100 model-k cityInput" style="width: 113px" v-if="item.proType=='10901'">
								<yj-tk-city :keywords="item.depJHName"  @chooseCity="chooseDepCity" style="display: inline-block;">
									<div slot="referenceContent" style="width:36px" >
										<el-input placeholder="出发" style="width:100%" v-model="item.departCityName" @input="queryCityName(item.proType,item.departCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>
								</yj-tk-city>
								<yj-tk-city :keywords="item.arrJHName" @chooseCity="chooseArrCity" style="display: inline-block;">
									<div slot="referenceContent" style="width:36px" >
										<el-input placeholder="到达" style="width:100%" v-model="item.arriveCityName" @input="queryArrCityName(item.proType,item.arriveCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>
								</yj-tk-city>
								<el-button class="el-icon-search search-btn"  @click="getTkList(item,index)"></el-button>
							</div>
							<div class="w100 model-k cityInput" style="width: 113px" v-if="item.proType=='10904'">
								<yj-tr-city  :keywords="item.depJHName" :isModalStation="true"   @checkTrCity="chooseDepCity" style="display: inline-block ;width:36px">	
									<div slot="content">
										<el-input placeholder="出发" style="width:100%" v-model="item.departCityName" @input="queryCityName(item.proType,item.departCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>	
								</yj-tr-city>
								<yj-tr-city  :keywords="item.arrJHName" :isModalStation="true" @checkTrCity="chooseArrCity" style="display: inline-block; width:36px">
									<div slot="content">
										<el-input placeholder="到达" style="width:100%" v-model="item.arriveCityName" @input="queryArrCityName(item.proType,item.arriveCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>	
								</yj-tr-city>
								<el-button class="el-icon-search search-btn" @click="getTrList(item,index)"></el-button>
							</div>
                             
                            <div class="w100 model-k otherInput" style="width: 113px" v-if="item.proType=='109991' || item.proType=='109992' || item.proType=='109993' || item.proType=='10999'"> 
                                <el-cascader style="display: inline-block ;width:45px"  ref="cascaderArr1" :show-all-levels="false" :options="addressOptions"  :props="adressProps" v-model="item.departDistrict" @change="addressChange(item,1)"></el-cascader>
                                <el-cascader style="display: inline-block ;width:45px"  ref="cascaderArr2" :show-all-levels="false" :options="addressOptions"  :props="adressProps" v-model="item.arriveDistrict" @change="addressChange(item,2)"></el-cascader>
								<!-- <div style="display: inline-block ;width:40px"><el-input placeholder="出发" style="width:100%" v-model="item.departCityName" @input="queryCityName(item.proType,item.departCityName,index)" @click.native="queryTkTrIndex(index)"></el-input></div>
						        <div style="display: inline-block ;width:40px"><el-input placeholder="到达" style="width:100%" v-model="item.arriveCityName" @input="queryArrCityName(item.proType,item.arriveCityName,index)" @click.native="queryTkTrIndex(index)"></el-input></div> -->
								<!-- <el-button class="el-icon-search search-btn" @click="getTrList(item,index)"></el-button> -->
							</div>



							<div class="w50 model-k">
								<el-input v-model="item.cxr" style="width:100%"  @click.native="screenCXR(item.proType=='10904'?'tr':'tk',item,index)" readonly></el-input>
							</div>
							<div class="w73 model-k">
								<el-input v-model="item.flightNo" style="width:100%" @blur="getFlightNo(item,index)"></el-input>
							</div>
							<div class="w73 model-k">
								<el-input v-model="item.cabin" style="width:100%"  @blur="getCabin(item,index)"></el-input>
							</div>
							<!-- <div class="w73 model-k">
								<el-input v-model="item.dj" style="width:100%" @input="getDj(item,index)"></el-input>
							</div> -->
							<div class="w73 model-k">
								<el-input v-model="item.fyys" style="width:100%"  @blur="getDj()"></el-input>
							</div>
                            <div class="w73 model-k">
								<el-input v-model="item.dcf" style="width:100%" @blur="getDj()" ></el-input>
							</div>
                            <div class="w73 model-k">
								<!-- <el-input v-model="item.cclx" style="width:100%"  ></el-input> -->
                                  <el-select v-model="item.cclx" @change="xxlcChange($event, item)" placeholder="请选择">
                                        <el-option
										    v-for="item in goTypeList"
										    :key="item.projectId"
										    :label="item.projectName"
										    :value="item.projectId">
                                        </el-option>
                                    </el-select>
							</div>
                            <div class="model-k" style="width: 60px">
								<el-input v-model="item.ccsy" style="width:100%"  ></el-input>
							</div>
                             <div class="w73 model-k">            
                                    <el-select v-model="item.zssm" placeholder="请选择">
                                        <el-option
                                        v-for="item in moduleList.roomsTypeList"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
							</div>
							<!-- <div class="w73 model-k">
								<el-input   style="width:100%" ></el-input>
							</div> -->
							<div class="w100 model-k setRemove">
								<i class="el-icon-remove-outline" style="font-size:20px" @click="removeCurrent(index,1,item)"></i>
                                <i class="el-icon-circle-plus-outline" style="font-size:20px;margin:0 5px;" v-show="item.zssm==1" @click="addVipRouteHotel(item,index)"></i>
                                    <el-tooltip style="width:20px;height:20px"  effect="dark" :content="item.wbyy" placement="bottom">
                                        <span>
                                            <i class="iconfont yj-icon-weizhang" v-if="item.against==1" style="font-size:20px;color:red" ></i>
                                        </span>
                                    </el-tooltip>
                                
							</div>			
						</div>
					</div>
				</div>
				<div class="setPad">
					<div class="flex between setButton">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">住宿安排</span>
						</div>
						<!-- <el-button class="btnw39h39" @click="addVipRouteHotel">添加行程</el-button> -->
					</div>
					<div class="table-main">
						<div class="tabTitle" v-show="viphtRouteList.length>0">
							<div class="w50">序号</div>
							<div class="w94">入住城市</div>
							<div class="w220">入住/离店日期</div>
              <div class="w50">晚数</div>
							<div class="w114">入住人</div>
							<div class="w97">酒店名称</div>
							<div class="w97">房型</div>
							<div class="w77">房间数</div>
							<div class="w97">餐费(元)</div>
							<div class="w97">住宿费(元)</div>
							<div class="w73">出差事由</div>
							<div class="w100">操作</div>
						</div>
						<div v-for="(item, index) in viphtRouteList" :key="index" class="tabContent" @click="htIndex=index">
							<div class="w50 model-k">{{index+1}}</div>
							<div class="w94 model-k htInput"> 
								<yj-car-city  :modelType="2" :keywords="item.depHtName"  :inputWidth="76"  @checkCarCity="checkHtCity">
									<div slot="content">
										<el-input placeholder="入住离店城市" v-model="item.departCityName" @input="queryCityName(10903,item.departCityName,index)"></el-input>
									</div>	
								</yj-car-city>
							</div>
							<div class="model-k cityInput w220">
								<el-date-picker
									value-format="yyyy-MM-dd"
									prefix-icon=" "
								  v-model="item.departDate"
								  type="date"
									:clearable="false"
									:picker-options="pickerOptions(ccsqdRange)"
									@change="getHotelDate(item,index)"
									placeholder="入住日期"
								>
								</el-date-picker>
								<!-- <el-time-picker
									style="width: 36px; border-right: none;"
									v-model="item.departDateTime"
									placeholder="00:00"
									format="HH:mm"
									value-forma="HH:mm"
									prefix-icon=" "
									:clearable="false"
									:editable="false"
								>
								</el-time-picker> -->
								<el-date-picker
									value-format="yyyy-MM-dd"
									style="margin-left: 3px"
									prefix-icon=" "
								  v-model="item.arriveDate"
								  type="date"
									:clearable="false"
									@change="getHotelDate(item,index)"
									:picker-options="pickerOptions(ccsqdRange,{min:item.departDate})"
								  placeholder="离店日期"
								>
								</el-date-picker>
								<!-- <el-time-picker
									style="width: 36px; border-right: none;"
									v-model="item.arriveDateTime"
									placeholder="00:00"
									format="HH:mm"
									value-forma="HH:mm"
									prefix-icon=" "
									:clearable="false"
									:editable="false"
								>
								</el-time-picker> -->
								<el-button class="el-icon-search btn39" style="width: 25px" @click="getHtList(item,index)"></el-button>
							</div>
              <div class="w50 model-k">{{routeDiffTime(item)}}</div>
							<div class="w114 model-k">
								<el-input v-model="item.cxr" class="inputw76" @click.native="screenCXR('ht',item,index)" readonly></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.flightNo" class="inputw76" @blur="getFlightNo(item,index)"></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.cabin" class="inputw76" @blur="getCabin(item,index)"></el-input>
							</div>
							<div class="w77 model-k input56">
								<el-input v-model="item.fjs" class="inputw56" ></el-input>
							</div>
              <div class="w97 model-k">
								<el-input v-model="item.cf" class="inputw76" @blur="getDj()" @click.native="getDiffDate(item.departDate,item.arriveDate,index)"></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.fyys" class="inputw76" @blur="getDj()" ></el-input>
							</div>
							<div class="w73 model-k">
								<el-input  v-model="item.ccsy"  class=" " style="width:100%"></el-input>
							</div>
							<div class="w100 model-k setRemove">
								<i class="el-icon-remove-outline" style="font-size:20px" @click="removeCurrent(index,2,item)"></i>
								<el-tooltip style="width:20px;height:20px"  effect="dark" :content="item.wbyy" placement="bottom">
										<span>
												<i class="iconfont yj-icon-weizhang"  style="font-size:20px;color:red ;margin:0 5px" v-show="item.against==1"></i>
										</span>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
				
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">其他备注</span>
						</div>
					</div>
					<el-input  type="textarea" class="marT17" v-model="by1" placeholder="请填写您的其他备注"></el-input>
				</div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">费用合计</span>
						</div>
					</div>
					<div class="ys-main">
						<div class="ys-header">
							<div>交通预算(元)</div>
							<div>住宿预算(元)</div>
                            <div>餐饮预算(元)</div>
							<div>其他预算(元)</div>
						</div>
						<div class="ys-content">
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="allfyys.jtys" disabled></el-input>
							</div>
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="allfyys.zsys" disabled></el-input>
							</div>
                            <div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="allfyys.cyys"  @blur="getDj(true)"></el-input>
							</div>
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="allfyys.qtys" @blur="getDj()"></el-input>
							</div>
						</div>
						<div class="ys-footer">
							<span class="footer2">{{allfyys.zys}}元</span>
							<span class="footer1">预计本次出差费用</span>
						</div>
					</div>
				</div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">审批事项</span>
						</div>
					</div>
					<!-- <approval  :ccrInfo="ccrInfo" :empId='empId'  :approvalId="detailAppId" :formInfo="formInfo" @approvalInfo="chooseApproval"></approval> -->
					<approval :approvalPropObj="approvalPropObj" :initQuery="ccsqdId? false: true" :ccrInfo="ccrInfo" :formInfo="formInfo" @approvalInfo="chooseApproval"></approval>
				</div>
				<div class="borderB-F0"></div>
				<div class=" setPadTop">
                    <el-button  plain class="setBtn2" @click="saveAndApproval(3)">预览</el-button>
					<el-button  class="setBtn1" plain @click="saveAndApproval(1)">保存草稿</el-button>
					<el-button type="primary" class="setBtn2" @click="saveAndApproval(2) " :loading="btnLoading">保存并送审</el-button>
                    
				</div>
			</el-card>
		</div>
		<!-- 选择出行人 -->
		<el-dialog title="选择出行人" :visible.sync="cxrVisible" class="cxrDialog" >
			<div class="cxr-con">
			<el-checkbox-group v-model="checkCxrList">
				    <el-checkbox v-for="item in currentCxrList" :key="item.empId" :label="item.empId" class="cxr-content">
						<span>姓名：</span>
						<span style="width: 200px;">{{item.empName || item.cxrName}}</span>
						<span>电话：</span>
						<span style="width: 200px;">{{item.phoneNumber}}</span>
						<span>部门：</span>
						<span style="width: 200px;">{{item.deptName}}</span>		
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="determineCxr(mkType)">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 火车票查询	 -->
		<div class="tr-search" v-if="searchTrVisible">
			<div class="dialog-main">
				<div class="dialogTitle">
					<span class="font18">火车票查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<div class="searchTop">
					<div class="searchCon">
						<span class="label">出发城市:</span>
						<el-input v-model="trInfo.depCity" :disabled="true"></el-input>
					</div>
					<div class="iconhc iconfont yj-icon-train2huoche">
					</div>
					<div class="searchCon">
						<span class="label">到达城市:</span>
						<el-input v-model="trInfo.arrCity" :disabled="true"></el-input>	
					</div>
					<el-date-picker
						type="date"
						v-model="trInfo.depDate"
						value-format="yyyy-MM-dd"
                        :disabled='true'
						:picker-options="pickerOptions(ccsqdRange)"
						class="searchDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="button"  @click="searchTr(2)">
						<span v-show="trQueryStatus != 1">搜索您的车次</span>
						<span v-show="trQueryStatus == 1">搜索车次中...</span>
					</el-button>
				</div>
				<div class="search-content">
					<train-list-loading :loading="trQueryStatus==1 && allLoading"></train-list-loading>
					<!-- 新增出差申请单搜索 不显示违背 -->

					<train-query-list v-if="trQueryStatus>1 || !allLoading" :showDate='false' :trCityQuery="trCityQuery" @chooseDate="chooseDate" :ccsqdSearch="true" :isOrderChange="true" :closeOpen="true" @close="closeTrainInfo"></train-query-list>
				</div>			
			</div>	
		</div>
	
		<!-- 机票航班搜索弹窗 -->
		<div class="tch-search" v-if="searchTkVisible">
			<div class="search-main">
				<div class="searchTitle">
					<span class="font18">航班查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<div class="searchTop">
					<div class="searchCon">
						<span class="label">出发城市:</span>
						<el-input v-model="tkInfo.depCity" :disabled="true"></el-input>
					</div>
					<div class="iconhc iconfont yj-icon-jh_aircraft">
					</div>
					<div class="searchCon">
						<span class="label">到达城市:</span>
						<el-input v-model="tkInfo.arrCity" :disabled="true"></el-input>	
					</div>
					<el-date-picker
						type="date"
						v-model="tkInfo.depDate"
                        :disabled='true'
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions(ccsqdRange)"
						class="searchDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="button" :loading="loadingFlightList==1" @click="searchTk">
						<span v-show="loadingFlightList != 1">搜索</span>
						<span v-show="loadingFlightList == 1">搜索航班中...</span>
					</el-button>
				</div>
				<div class="list-main">
					<flight-list
					    v-if="flightList.length>0 && loadingFlightList > 1"
						:flightList="flightList"
						:flightMap="flightMap" 
						:priceMap="flightMap.priceMap"
						:planeMap="flightMap.planeMap" 
						@chooseFlight="toChooseFlight">
					</flight-list>
					
					<el-empty :image-size="200" v-show="loadingFlightList == 2 && flightList.length==0"></el-empty>

					<flight-list-loading :loading="loadingFlightList == 1"></flight-list-loading>
				</div>
			</div>
		</div>
	
		<!-- 入住酒店查询 -->
		<div class="ht-query" v-if="searchHtVisible">
			<div class="ht-main">
				<div class="searchTitle">
					<span class="font18">酒店查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<ht-cn-search @searchHotel="searchHotel" :newCcsqdSearch="true" style="border-bottom: 3px solid #eef1f6;"></ht-cn-search>
				<hotel-list :search="isSearchHtList" @clearSearch="clearHtSearch" :cxrList="currentCxrList" @htInfo="htYDInfo"></hotel-list>
			</div>
		</div>	
        		<!-- 违背原因选择 -->
		<yj-violation 
			:vltShow="isShowWeiBei" 
			:vltReason="violateItem" 
			byNumber="10901" 
			@chooseReason="chooseReason"
			@cancelReason="cancelReason"
			></yj-violation>
	</div>
</template>

<script>
	import {mapState,mapMutations} from 'vuex';
	import CcsqdHeader from './ccsqdModule/CcsqdHeader';
	import Approval from './ccsqdModule/Approval';
	import TrainSearch from '@/views/tr/trComponents/trQuery/TrainSearch';
	import TrainQueryList from '@/views/tr/trComponents/trQuery/TrainQueryList';
	import FlightList from '@/views/tk/tkComponent/tkQuery/FlightList';
	import FlightListLoading from '@/views/tk/tkComponent/tkQuery/FlightListLoading';
	import TrainListLoading from '@/views/tr/trComponents/trQuery/TrainListLoading';
	import htCnSearch from '@/views/htCn/HtCnComponent/htCnSearch';
	import HotelList from './ccsqdModule/HotelList';
    import moduleList from './ccsqdModule/moduleList.js'
	
	// 机票模块公共js文件
	import tkCommonJs from '@/views/tk/tkCommon/tkCommon.js';
	export default{ 
		name: 'NewCcsqd',
		data() {
			return {
				header:{ // 行程计划 ， 住宿安排 表格头部底色
					color:'#808080',
					background: '#F7F7F7',
					'text-align': 'center',
				},
                isShowWeiBei:false,
                violateItem:'',
                ccsyRestriction:'',
                localRestriction:'',
                timeAutoRestriction:'',
                timeRestriction:'',				
				proTypeList:[
					{
						name:'飞机',
						value:'10901',
					},
					{
						name:'火车',
						value:'10904',
					},
                    {
						name:'大巴',
						value:'109991',
					},
                    {
						name:'轮船',
						value:'109992',
					},
                    {
                       	name:'自驾',
						value:'109993', 
                    },
                    {
                       	name:'其他',
						value:'10999', 
                    }
				],
                btnLoading:false,
                selectCxrIndex:'',
                selectRouteChecklist:[],
                empId:'',
                moduleList,
                gzmc:'',
                wbType:'', // 看看是哪个类型在违背
				proTypeCode: '10901',//行程类型 ， 默认机票
				detailHeader:{}, // 详情头部信息
				cxrVisible:false, //出行人是否显示
				ccrInfo: {}, //出差人信息
				formInfo:{}, //出差项目 表单信息
				vipCostCenterList:[], // 乘车人成本中心集合列表
				currentIndex: 0,//当前类型索引
				
				jhIndex: 0, //获取选中 火车票 机票 行程索引
				trIndex: 0, //火车票索引
				htIndex: 0, //酒店索引
				tkIndex: 0, //机票索引
				carIndex: 0, //用车索引
                addressOptions:[],
                city:'',
                province:'',          
                adressProps: {
                    lazy: true,   //可以理解为开关，什么时候结束
                    lazyLoad: this.cascaderLazyLoad, 
                    emitPath:false,
                }, 
				trInfo:{
					depCity:'',
					depCitySzm:'',
					arrCity:'',
					arrCitySzm:'',
					depDate:'',
				}, //火车票查询信息
				tkInfo:{
					depszm:'',
					arrszm:'',
					depCity:'',
					arrCity:'',
					depDate:'',
				}, //机票查询信息
				htInfo:{
					depCity:'',
					depCitySzm:'',
					arrCity:'',
					arrCitySzm:'',
					depDate:'',
					cityId:'',
				}, //酒店查询信息
				htSearchList:[],
				jdType:1,
				cfSearch: false, //重复搜索
				searchTrVisible: false, //火车票搜索
				searchTkVisible: false, //机票搜索
				searchHtVisible: false, //酒店搜索
				vipRouteList: [], //机票 火车票列表
				viphtRouteList: [], //酒店列表
				vipCarRouteList: [], //用车列表
				loadingFlightList: 0, // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				trListLoading: 0, //   加载火车票列表  0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				// 火车票列表
				trainList: [],
				// 航班列表
				flightList:[],
				flightMap:{},
				
				isSearchHtList: false, // 是否搜索酒店列表
				
				currentCxrList: [], // 当前出行人列表(所有出行人)	
				checkCxrList:[], // 选中出行人列表 (选中的出行人)	
				
				by1:'', //其他备注
				
				vipPersonList:[], // 当前出行人 
				xcxh:0, //行程序号
                    allfyys:{
                    jtys:0, //交通预算
                    zsys:0, // 住宿预算
                    zys:0, // 总预算
                    qtys:0, // 其他预算
                    cyys:0, //餐饮预算
                },
          
				vipRouteInfo: {
					against: 0, // 是否违背
                    airProductType:'', //机票产品类型 0特价；1普通；2官网；3协议 4GP票 ,
					arriveAirport: "", //: 到达机场/车站三字码 
					arriveAirportName: "", //到达机场/车站 ,
					arriveCity: "", //到达城市id 
					arriveCityName: "", //到达城市
                    arriveDistrict:'',// 到达区id ,
                    arriveDistrictName :'',// 到达区名称 ,
					arriveDate: "", //到达日期/离店日期 
					arriveTime: "", //到达时间
                    averagePrice:'',// (number, optional): 差标金额(每间夜) ,
					cabin: "", //舱位/席别/房型
                    cabinCode:'',//舱位代码/席别代码/房型id ,
                    dcf:'', //打车费
					cxr: '', //出行人使用逗号分割
					cxrId: '', //出行人id使用逗号分割
					departAirport: "", //出发机场/车站三字码
					departAirportName: "", //出发机场/车站 
					departCity: "", //出发城市/入住城市id
					departCityName: "", //出发城市/入住城市
                    departDistrict:'',//出发/入住区id ,
                    departDistrictName :'', //出发/入住区名称 ,
					departDate: "", // 出发日期/入住日期
					departTime: "", //出发时间 
                    id :'',// 行程id,修改时传 ,
					dj: 0, //单价
					fjs: 1, //房间数
                    priceId:'',//价格计划id ,
                    extendInfo:'', //(string, optional): 扩展字段 
					flightNo: "", //航班号/车次/酒店名称
					fyys: 0, //费用预算
					// isChange: "", // 是否变更
					proType: '', //产品代码来自b_class
					routeId: "", //行程id修改变更单时 
					// routeStatus: "", //行程状态
					travelStandard: "", //出差标准
					violateitem: "", //违背事项说明
					violateitemCode: "", //违背事项代码
					wbyy: "", //违背原因备注说明
					xcxh: this.xcxh, //行程序号
					yroId: "", //原行程id
                    protocolNo:'', //机票三方协议号 ,
                    queryFlag:0,//1已查询 0未查询 ,
					depHtName:'',
					depJHName:'',
					arrJHName:'',
					depCarName:'',
				}, // 一条出行人行程信息

				
				hcpys: 0, // 火车票预算
				ycys: 0, // 用车预算
				jpys: 0, // 机票预算
				// zys: 0, //总预算时
				ysInfo:{ // 预算
					qtys: 0,
					jtys: 0,
					zsys: 0,
                    ccys:0,
				},

        
                
				appId:'', // 审批项目id
				detailAppId:'', // 详情审批项目id
				ccsqdDateArr:[], // 出差申请单日期
				
				hotelDateStartRange:'', // 当酒店选择相同日期是控制日期范围
				hotelDateEndRange:'', // 当酒店选择相同日期是控制日期范围
				
				hotelDay:1, // 酒店入住天数，默认为1
				mkType:'', // 模块类型
				
				repeatFlag:0, //保存出差申请单前验证是否重复订单
				
				delRouteIdsList:[], // 删除行程id列表
				
				routeLength:0, //行程长度
				trCityQuery: {}, // 火车票查询城市
				goTypeList: [],
				approvalPropObj: {}, // 审批流子组件所传的值
			}
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				tkQuery: state => state.tk.tkQuery, // 机票查询数据
				htCnParams: state => state.htCn.htCnParams,
                trCxrList: state => state.train.cxrList, // 出行人信息
			}),
			userInfo(){
				let userInfo = this.$common.getUserInfo();
				return userInfo;
			},
			empInfo(){
				return this.userInfo.empInfo;
			},
			vipCorp(){
				return this.userInfo.vipCorp;
			},
			// 显示项目
			showProject(){
				let param = this.$common.getVipParamterByParNo(this.$store,20003,3);
				return param==1 && this.tripType==1;
			},
			// 显示成本中心
			showCostCenter(){
				let param = this.$common.getVipParamterByParNo(this.$store,20002,3);
				return param==1 && this.tripType==1;
			},			
			// 出差项目 是否开启必填 0不开启  1开启
			projectRequired(){
				let param = this.$common.getVipParamterByParNo(this.$store,10996,3);
				return param==1  && this.tripType==1 && this.showProject
			},
			 // 成本中心 是否开启必填 0不开启  1开启
			costCenterRequired(){
				let param = this.$common.getVipParamterByParNo(this.$store,10995,3);
				return param==1 && this.tripType==1 && this.showCostCenter
			},
			// 出差申请单 费用预算是否必填 0否 1填  必填控制时费用预算必须大于0
			ccsqdYs(){
				let param = this.$common.getVipParamterByParNo(this.$store,10998,3);
				return param
			},
			query(){
				return this.$route.query;
			},
			ccsqdId(){
				return this.query.ccsqdId;
			},
            		// 火车票查询状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			},
            		// 全部加载
			allLoading(){
				return this.searchData.allLoading;
			},	
			ccsqdRange(){
				let arr = this.$refs['ccsqdHeader']['ccsqdDateRange'] || [];
				return arr
			},

			routeDiffTime(){
				return function(item){
						const m1 = this.$moment(this.$moment(item.departDate).format('yyyy-MM-DD'));
						const m2 = this.$moment(this.$moment(item.arriveDate).format('yyyy-MM-DD'));
						const day = m2.diff(m1, 'd');
						return day
				}
			},
		},
		components:{
			'ccsqd-header':CcsqdHeader,
			'approval':Approval,
			'train-list-loading':TrainListLoading,
			'train-query-list':TrainQueryList,
			'flight-list':FlightList,
			'flight-list-loading':FlightListLoading,
			'ht-cn-search':htCnSearch,
			'hotel-list':HotelList,
		},
		mounted() {
			this.getProjectList();
			// 如果有出差申请单id 则为修改
			if(this.ccsqdId){
				this.queryCcsqd(this.ccsqdId)
			}
            this.getFindCcrCcsqdSetting(this.empInfo.empId)
            this.getProvinceInfo()
		},
		methods: {
			...mapMutations({
				setTrainData: 'train/setTrainData',
                setSearchData: 'train/setSearchData',
				setTkQuery: 'tk/setTkQuery',
				SET_HTCN_PARAMS:'htCn/SET_HTCN_PARAMS',
                setCxrList:'train/setCxrList'
			}),
			chooseReason(res){
				if(this.wbType=='10901'){
					let myRoute= this.vipRouteList[this.tkIndex]
					myRoute.wbyy = res?.reasonDesc??'';
					myRoute.reasonCode = res?.reasonCode??'';
					this.searchTkVisible = false;
				}
				this.isShowWeiBei = false;
			},
			// 获取出差类型
			getProjectList() {
				this.$conn.getConn('commu.projectFindList')( {count: 20}, res => {
					let data = res.data || "";
					let arr = data.list || [];
					let list = [];
                    if(arr){
                        arr.forEach(item => { 
                            list.push({
                                ...item,
                                projectId: item.id
                            })
                        });
                    }
					this.goTypeList = list;
				});
			},
            getFindCcrCcsqdSetting(empId){
                if(!empId){
                    return
                }
                let findCcrCcsqdSetting = this.$conn.getConn('xakCcsqd.findCcrCcsqdSetting');
                    findCcrCcsqdSetting({
                        empId
                    }, res => {
                        let data=res.data || {}
                        this.ccsyRestriction=data.ccsyRestriction
                        this.localRestriction=data.localRestriction
                        this.timeAutoRestriction=data.timeAutoRestriction
                        this.timeRestriction=data.timeRestriction
                    });
            },
			xxlcChange(id, routeItem) {
                if(this.goTypeList){
                        this.goTypeList.forEach(sItem => {
                        if (sItem.projectId == id) {
                            routeItem.cclxMc = sItem.projectName;
                        }
                    });
                }else{
                    	this.$message({
						type: 'error',
						message: '当前企业未配置出差类型，请前往后台配置'
					})
                }
			},
  
    	// 取消违背原因弹窗
			cancelReason(bool){
				this.isShowWeiBei = false;
			},
            cascaderLazyLoad(node, resolve){
                if (!node) {  
                    return false
                }
                const { level } = node
                if (level == 1) {
                   this.province = node.value   //选择省后对数据进行存储，因为要用省去查询市
                    this.$conn.getConn('xakCcsqd.getCityInfo',{})({province:node.value},res=>{
                        let list = res.data.list || []
                        resolve(list.map(item=>{
                            return {
                                    value: item.cityId,
                                    label: item.cityName,
                                }
                        }) )
                });

                } else if (level == 2) {
                    this.city = node.value
                    this.$conn.getConn('xakCcsqd.getLocationInfo',{})({cityId:node.value},res=>{
                        let list = res.data.list || []
                        resolve(list.map(item=>{
                            return {
                                        value: item.locationId,
                                        label: item.locationName,
                                        leaf: level >= 2,  //因为省市区三项，所以第三次点击就不用在加载了，所以 >=2
                                    }
                            })  

                                
                        )
                });
                }
            },
            addressChange(item,num){

                let checkedNode='';
                let pathLabels='';
                let path='';
                if(num==1){
                    checkedNode=this.$refs.cascaderArr1[0].getCheckedNodes()[0];
                    pathLabels=checkedNode.pathLabels
                    path=checkedNode.path
                    item.departProvince=path[0]
                    item.departProvinceName=pathLabels[0]
                    item.departCityName =pathLabels[1]
                    item.departCity=path[1]
                    item.departDistrictName=pathLabels[2]
                    // item.departDistrict=path[2]
                }else{
                    checkedNode=this.$refs.cascaderArr2[0].getCheckedNodes()[0];
                    pathLabels=checkedNode.pathLabels
                    path=checkedNode.path
                    item.arriveProvince=path[0]
                    item.arriveProvinceName=pathLabels[0]
                    item.arriveCityName =pathLabels[1]
                    item.arriveCity=path[1]
                    item.arriveDistrictName =pathLabels[2]
                    // item.arriveDistrict =path[2]
       
                }
                console.log(item,"item")
            },
            getProvinceInfo(){ // 获取省份城市
                this.$conn.getConn('xakCcsqd.getProvinceInfo')({},res=>{
                   let list = res.data.list || []
                   list.forEach(item=>{
                       item.value=item.province
                       item.label=item.provinceName
                   })
                    this.addressOptions=list || []
                })
            },
			// 出差申请单日期范围控制
			pickerOptions(ccsqdRange,canMap = {}){
				let min = canMap.min;
				let max = canMap.max;
				let minDay = ccsqdRange[0];
				let maxDay = ccsqdRange[1];
				minDay = min?(min>minDay?min:minDay):minDay;
				maxDay = max?(max>maxDay?maxDay:max):maxDay;
				return { // 默认当天之前 日期不能选择
						disabledDate: (time) => {
							let minTime = new Date(new Date(minDay).toLocaleDateString()).getTime();
							let maxTime = new Date(new Date(maxDay).toLocaleDateString()).getTime();
							return time.getTime() < minTime  || time.getTime() > maxTime;
						}
				}
				
			},
            sortEmpRank(){
                let cxrList=this.vipPersonList || []
                        cxrList.sort((a,b)=>{    
                        return a.empRank > b.empRank ? 1 : -1
                        })
                return cxrList[0]
            },
			// 出差申请单 详情 当有id时，表示编辑
			queryCcsqd(ccsqdId){
				this.vipRouteList = [];
				this.viphtRouteList = [];
				this.vipCarRouteList = [];
				let queryString = {
						id:ccsqdId
						}
				this.$conn.getConn('xakCcsqd.xakVipCcsqdGetInfo')(queryString,(res)=>{
					let data = res.data || {};
					let list = data.fastDetailsRouteList;
					this.xcxh = list.length;
                    list.forEach(item=>{
                        item.cxrList=[]
                        let cxrIdList=[]
                        cxrIdList=item.cxrId.split(",")
                        data.fastDetailsPersonList.forEach(i=>{
                            cxrIdList.forEach(cxrItem=>{
                                if(cxrItem==i.empId){
                                    item.cxrList.push(i)
                                }
                            })               
                        })

                        if(item.proType!=10903){
							item.departDateTime = this.$moment(item.departDateLong).toDate();
							item.arriveDateTime = this.$moment(item.arriveDateLong).toDate();
							this.vipRouteList.push(item);
							console.log(this.vipRouteList)
                        }else{
							this.viphtRouteList.push(item)
							console.log(this.viphtRouteList)
                        }
                    })
					this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;					
					this.formInfo = {
						ccsqdId:this.$route.query.ccsqdId,
						ccsqdNo:data.ccsqdNo,
						ccr: data.ccr,
						ccsy: data.ccsy,
						costCenterCode: data.costCenterCode,
						costCenterId: data.costCenterId,
						costCenterName: data.costCenterName,
						dateBegin: data.dateBegin,
						dateEnd: data.dateEnd,
						dateBeginLong: data.dateBeginLong,
						dateEndLong: data.dateEndLong,
						mdd: data.mdd,
						project: data.project,
						projectCode: data.projectCode,
						projectId: data.projectId,
						projectName: data.projectName,
						sxr: data.sxr,
                        area:data.area||'0'

                       
					}

					this.vipPersonList = data.fastDetailsPersonList;  // 出行人信息列表
                    this.currentCxrList = data.fastDetailsPersonList;
					this.vipCostCenterList = data.costCenter; // 选择的成本中心列表
					this.detailHeader = {
						appId: data.appId, //审批规则id ,
						ccsy: data.ccsy, //出差事由
						ccr: data.ccr, //出差人 ,
						ccsqdNo: data.ccsqdNo,// 单号
						ccsqdId:  data.id,// 出差申请单id
						costCenterCode: data.costCenterCode, // 成本中心编码
						costCenterId: data.costCenterId, // 成本中心id
						costCenterName: data.costCenterName, //默认成本中心
						dateBeginLong: data.dateBeginLong, //出差日始带时分
						dateEndLong: data.dateEndLong, //出差日止带时分
						dateBegin: data.dateBegin, //出差日始
						dateEnd: data.dateEnd, //出差日止
						mdd: data.mdd, //目的地
						project: data.project, //是否是出差项目
						projectCode: data.projectCode, //出差项目编码
						projectId: data.projectId, // 出差项目id
						projectName: data.projectName, // 出差项目名称
						sxr: data.sxr, //随行人 
						reqTime:data.createdate,
                        area:data.area||'0'
                         
					}
                    this.id=data.id
					this.by1 = data.by1;
					this.detailAppId = data.appId;
                    this.allfyys.jtys=data.jtys
                    this.allfyys.zsys=data.zsys
                    this.allfyys.zys=data.zys
                    this.allfyys.cyys=data.cyys
                    this.allfyys.qtys=data.qtys
					
					this.setApprovalQueryPropObj({
						empId: this.empId,
						approvalId: this.detailAppId,
						area: this.formInfo.area,
					})
				},(err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 审批流子组件所传的值
			setApprovalQueryPropObj(obj) {
				this.queryPropObj = obj;
			},
			// 筛选出行人
			screenCXR(type,item,index){
				if(type=='ht'){
					this.mkType = 'ht'
				}else if(type=='tk'){
					this.mkType = 'tk'
				}else if(type=='tr'){
					this.mkType = 'tr'
				}
                this.checkCxrList=item.cxrList.map(item=>item.empId)
                this.selectCxrIndex=index;
				this.cxrVisible = true;
			},
		
			
			// 出行人数据
			selectCxrList(list){
				this.vipCostCenterList = [];
				this.vipPersonList = [];
                this.currentCxrList = [];
				list.forEach((item)=>{
					let obj = {};
						obj.ccr = item.cxrName || item.empName;
						obj.ccsqdNo = item.ccsqdNo;
						obj.corpId = item.corpId;
						obj.costCenterCode = item.costCenterCode;
						obj.costCenterName = item.costCenterName;
						obj.costId = item.costCenterId;
						// obj.costId = item.empId;
						this.vipCostCenterList.push(obj) 
					let info = {};               
					info.cxrName = item.cxrName || item.empName;
					info.deptName = item.deptName;
					info.empId = item.contactsId || item.empId || '', // 出行人员工id ;;
					info.empName = item.empName;
					info.empNo = item.empNo;
					info.empRank = item.empRank;
					info.empRankName = item.empRankName;
					info.exPerson = ((item.sxr ==0 || item.cxrType==1)?0:1);
					info.personId =item.contactsId || item.empId || '';
					info.sxr = (item.sxr==0?0:1);
                    info.costId = item.costCenterId;
                    info.empDeptid=item.deptId || '',
					this.vipPersonList.push(info);
                    this.currentCxrList.push(info)
				})
                this.getFindCcrCcsqdSetting(this.vipPersonList?.[0]?.empId??'')
				this.vipRouteList = [];
				this.viphtRouteList = [];
				this.vipCarRouteList = [];
				this.xcxh = 1;
			},

			// 出行人重置处理
			determineCxr(type){
                if(this.checkCxrList.length==0){
					this.$confirm('出行人不能为空！', '提示', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'     
					})
					return false
				}
                let arr=[]

                let empNameList = [];
				let empIdList = [];
                this.currentCxrList.forEach((item)=>{
                    console.log(item.empId,"item.empId",item.empName,"item.empName",item.costCenterId,"item.costCenterId")
					let obj = {};
					obj.cxrName = item.cxrName || item.empName;
					obj.deptName = item.deptName;
					obj.empId = item.contactsId || item.empId || '', // 出行人员工id 
					obj.empName = item.empName;
					obj.empNo = item.empNo;
					obj.empRank = item.empRank;
					obj.empRankName = item.empRankName;
					obj.exPerson = item.exPerson;
					obj.personId = item.personId;
					obj.sxr = item.sxr;
					if(this.checkCxrList.indexOf(item.empId)>-1){
						empNameList.push(item.empName || item.cxrName);
						empIdList.push(item.empId);
                        arr.push(obj)
					}
				})
                if(type!='ht'){
                        this.vipRouteList[this.selectCxrIndex].cxrList=this.$common.deepCopy(arr)
                        this.vipRouteList[this.selectCxrIndex].cxr=empNameList.length>0?empNameList.join(","):'';
                        this.vipRouteList[this.selectCxrIndex].cxrId = empIdList.length>0?empIdList.join(","):'';   
                         this.getDj()              
                    }else{
                        this.viphtRouteList[this.selectCxrIndex].cxrList=this.$common.deepCopy(arr)
                        this.viphtRouteList[this.selectCxrIndex].cxr=empNameList.length>0?empNameList.join(","):'';
                        this.viphtRouteList[this.selectCxrIndex].cxrId = empIdList.length>0?empIdList.join(","):'';
                        this.viphtRouteList[this.selectCxrIndex].fjs = this.checkCxrList.length;
                        this.getDj()
                        
                    }

				this.cxrVisible = false;
			},
			// 表单头部所有数据
			allFormData(form){
				this.formInfo = form;
			},
			// 出差申请单日期 控制酒店日期取值
			getCcsqdDateInfo(dateArr){
				this.ccsqdDateArr = dateArr;
				if(dateArr.length == 2){
					let format = 'yyyy-MM-DD';
					this.hotelDateStartRange = this.$moment(dateArr[0]).format(format);
					this.formInfo.dateBegin = this.$moment(dateArr[0]).format(format);;
					this.formInfo.dateEnd = this.$moment(dateArr[1]).format(format);;
					this.formInfo.dateBeginLong = dateArr[0];
					this.formInfo.dateEndLong = dateArr[1];
					this.hotelDateEndRange = this.$common.getAppointDay(dateArr[0],1);
				}
			},
			// 获取酒店日期，操作主动选择相同日期 时 重置入住离店日期 
			getHotelDate(item, index){
				var arriveDate = new Date(item.arriveDate);
				var departDate = new Date(item.departDate);
				var startTime = departDate.getTime();
				var endTime = arriveDate.getTime();
				if(startTime == endTime){
					this.$alert('酒店入住离店日期相同','',{
						confirmButtonText: '确定',
						type:'warning',
						callback: action => {
						    this.viphtRouteList[index].arriveDate = this.hotelDateEndRange;
							this.viphtRouteList[index].departDate = this.hotelDateStartRange; 
						}
					})
				}
				this.hotelDay = this.$common.getDiffDay(item.departDate,item.arriveDate)
				// if(item.dj){
				// 	// this.getDj(item, index)
				// }

			},
			// 出差项目头部信息 form 是表单信息  info 为补充信息
			ccProjectInfo(form,info){
				this.formInfo = form;
				this.ccrInfo = info;
			},
			// 选择出发城市
			chooseDepCity(info){
				this.vipRouteList.map((item,i)=>{
					if(i==this.jhIndex){
						item.departAirport = info.threeCode;
                        if(info.cityInput && item.proType=='10904'){
                             item.departCityName=(info.airportName || info.cityName) + (info.cityInput+'站')
                        }else{
                            item.departCityName = info.airportName || info.cityName;
                        }
						
						item.departCity = info.csbh?info.csbh:info.id || info.cityId;
					}
					return item
				})
                this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
			},
			// 选择到达城市
			chooseArrCity(info){
				this.vipRouteList.map((item,i)=>{
					if(i==this.jhIndex){
						item.arriveAirport = info.threeCode;
						item.arriveCityName = info.airportName || info.cityName;
						item.arriveCity = info.csbh?info.csbh:info.id || info.cityId;
					}
					return item
				}) 
				this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
			},
			
			// 用车城市
			checkCarCity(val){
				this.vipCarRouteList.map((item,i)=>{
					if(i==this.carIndex){
						item.departCityName = val.cityName || val.airportName;
						item.departCity = val.csbh?val.csbh:val.id || val.cityId;
					}
					return item
				})
			        this.vipCarRouteList=this.$common.deepCopy(this.vipCarRouteList)
			},
			// 查询当前是机票 火车票
			queryTkTrIndex(index){
				this.jhIndex = index;	
			},
			// 机票，火车票增加行程行息
			addVipRouteTkTr(){
                let xcxh=this.vipRouteList.length+1
				let empNameList = [];
				let empIdList = []; 
                let arr=[]                              
                this.currentCxrList.forEach((item)=>{
					let obj = {};
					obj.cxrName = item.cxrName || item.empName;
					obj.deptName = item.deptName;
					obj.empId = item.contactsId || item.empId || '', // 出行人员工id ;
					obj.empName = item.empName;
					obj.empNo = item.empNo;
					obj.empRank = item.empRank;
					obj.empRankName = item.empRankName;
					obj.exPerson = item.exPerson;
					obj.personId = item.personId;
					obj.sxr = item.sxr;
				    empNameList.push(item.empName || item.cxrName);
					empIdList.push(item.empId);
                    arr.push(obj)
				})
				this.checkCxrList = empIdList;
				this.proTypeCode = '10901';
				let toDay =  this.ccsqdRange.length > 0 ? this.$moment(this.ccsqdRange[0]).format('yyyy-MM-DD') : this.$dateTime.getToDay(); //今天
				let arrDay = this.$dateTime.getAppointDay(toDay); //指定天数
				// this.xcxh++ ;
				this.vipRouteInfo = {
					cxr: empNameList.length>0?empNameList.join(','):'', //出行人使用逗号分割
					cxrId: empIdList.length>0?empIdList.join(','):'', //出行人id使用逗号分割
					proType: '10901', //产品代码来自b_class
					xcxh: xcxh, //行程序号
					departDate: toDay,
					arriveDate:arrDay,
					against: 0,
                    fyys:0,
                    dcf:0,
                    zssm:'1',
                    cclx:'',
                    ccsy:'',
					violateitem: "",
					violateitemCode: "",
                    cxrList:this.$common.deepCopy(arr) ,
					wbyy: "",
					// isChange: "",
					routeId: "",
					// routeStatus: "",
					departDateTime: this.$moment(this.formInfo.dateBeginLong).toDate(),
					arriveDateTime: this.$moment('2022-01-01 23:59').toDate()
				}
				this.trInfo.depDate = this.vipRouteInfo.departDate;
				this.tkInfo.depDate = this.vipRouteInfo.departDate;
				
				this.vipRouteList.push(this.vipRouteInfo);	
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
                this.getDj()
			},
			//获取火车票列表行息
			getTrList(item,index){
                
				this.trIndex = index;
				this.searchTrVisible = true;
				var errMsg = "";
				if(!item.departAirport){
					errMsg = "请选择出发城市"
				}else if(!item.arriveAirport){
					errMsg = "请选择到达城市"
				}else if(!item.departDate){
					errMsg = "请选择出发时间"		
				}
				if(errMsg){
					this.$message.error(errMsg)
					return
				}
				this.trCityQuery = {
					arrCity: item.arriveCityName,
					depCity: item.departCityName,
				}
				this.trInfo.depCitySzm = item.departAirport;
				this.trInfo.arrCitySzm = item.arriveAirport;
				this.trInfo.depCity = item.departCityName;
				this.trInfo.arrCity = item.arriveCityName;
				this.trInfo.depDate = item.departDate;
				this.searchTr(1)
				
			},
			// 单选中的火车票日期
			chooseDate(date){
				this.trInfo.depDate = date;
				this.searchTr();
			},
			// 获取火车票列表
			searchTr(num){
				if(this.cfSearch){
					return
				}
				// num 传1 全局加载 2 局部加载 
				this.setSearchData({
					trQueryStatus: 1,
					allLoading: num==1?true:false,
					partLoading: num==1?false:true,
					searchNum: num==1?0:1,
				})
				
				let queryString = {
					byTrainDate: this.trInfo.depDate,
					ccsqdId: '',
					ccsqdRouteId: '' ,
					fromStationCode: this.trInfo.depCitySzm,
					minRank: 1,
					//allTrains: true, 
					toStationCode: this.trInfo.arrCitySzm,
					//includePrice: true, //是否包含票价 
					tripType: 2,
				}
				this.$conn.getConn('trBook.trSearch')(queryString,(res)=>{
					let data = res.data || {};
					if(data.trainList.length>0){ // 查询有结果
						this.setSearchData({
							trQueryStatus: 2,
							partLoading:true,
							searchNum:1,
						})
					}else{ // 查询无结果
						this.setSearchData({
							trQueryStatus: 3,
							partLoading:true,
							searchNum:1,
						})
					}
					this.setTrainData({
						trainData: data
					})
					this.cfSearch = false;			
				},(err)=>{
					this.$message.error(err.tips)
					this.setSearchData({
						trQueryStatus: 4,
						partLoading:false,
						searchNum:0,
					})
				})
			},
			// 关闭火车票页面 , 选中火车票信息
			closeTrainInfo(bool,info){
                this.setCxrList(this.vipPersonList)		
                let myRoute=this.vipRouteList[this.trIndex]
                myRoute.queryFlag = 1;
                myRoute.against = info.selectSeat.wb  || 0;
                if(myRoute.against ==1){
                    myRoute.violateitem =info.clzcStr || "";
                    myRoute.violateitemCode = info.clzcCode || "";
                    myRoute.wbyy = info.chooseReasonObj?.reasonDesc??'';
                    myRoute.reasonCode = info.chooseReasonObj?.reasonCode??'';
                }
                myRoute.extendInfo = {};
                // myRoute.extendInfo.trainType=info.trainType || '',
                myRoute.extendInfo=info
                myRoute.flightNo = info.trainCode || "";
                myRoute.cabin = info.selectSeat?.seatName??'';
                myRoute.fyys = info.selectSeat?.price?? '';
                myRoute.dj =info.selectSeat?.price?? '';
                myRoute.cabinCode = info.selectSeat?.seatType?? "";
                myRoute.arriveTime =info.arriveTime || "";
                myRoute.departDateTime = 
									info.startTime ? 
									this.$moment('2022-01-01 ' + info.startTime).toDate() : 
									this.$moment('2022-01-01 00:00').toDate();
                myRoute.arriveDateTime = 
									info.arriveTime ? 
									this.$moment('2022-01-01 ' + info.arriveTime).toDate() : 
									this.$moment('2022-01-01 23:59').toDate();
                myRoute.departTime = info.startTime || "";
                myRoute.arriveAirport=info.toStationCode 
                myRoute.arriveAirportName=info.toStationName  ;
                myRoute.departAirport=info.fromStationCode;
                myRoute.departAirportName=info.fromStationName ;
                myRoute.extendInfo=JSON.stringify(myRoute.extendInfo )
				this.searchTrVisible = bool;
                this.getDj()
			},
			// 酒店增加行程
			addVipRouteHotel(item,index){
                console.log(index,index,"sasaa")
                if(!(item.departCityName&&item.arriveCityName)){
                    this.$alert('请先输入城市','',{
						type:'error'
					})
                    return
                }
				let empNameList = [];
				let empIdList = [];
				this.checkCxrList = [];
				item.cxrList.forEach(item=>{
					empNameList.push(item.empName|| item.cxrName);
					empIdList.push(item.empId);
				})
				this.checkCxrList = empIdList;
                let arriveDate;
                if( this.$dateTime.getAppointDay(item.arriveDate,1)>= this.formInfo.dateEnd){
                    arriveDate=this.formInfo.dateEnd
                }else{
                    arriveDate=this.$dateTime.getAppointDay(item.arriveDate,1)
                }

                //  this.$dateTime.getAppointDay(toDay,1);
				this.vipRouteInfo = {
					cxr: empNameList.length>0?empNameList.join(','):'', //出行人使用逗号分割
					cxrId: empIdList.length>0?empIdList.join(','):'', //出行人id使用逗号分割
					proType: '10903', //产品代码来自b_class
					xcxh: item.xcxh, //行程序号
					departDate: item.departDate,
					arriveDate: arriveDate, 
					against: 0,
					violateitem: "",
					violateitemCode: "",
					wbyy: "",
                    fjs:this.$common.deepCopy(item.cxrList).length || 1,
                    fyys:0,
                    cf:0,
                    cxrList:this.$common.deepCopy(item.cxrList) ,
					// isChange: "",
					routeId: "",
					// routeStatus: "",
                    departCityName : item.arriveCityName || '',
					departCity :item.arriveCity || '',
					// departDateTime: new Date(1995, 11, 17, 18, 0, 0),
					// arriveDateTime: new Date(1995, 11, 17, 14, 0, 0)
                    
				}
				let obj = this.$common.deepCopy(this.vipRouteInfo);
				this.viphtRouteList.push(obj);
                this.viphtRouteList=this.$common.deepCopy(this.viphtRouteList);
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
			},
			// 搜索酒店信息
			getHtList(item,index){
				this.htIndex = index;
				this.getDiffDate(item.departDate,item.arriveDate,index)
				let errMsg = '';
				if(!this.viphtRouteList[index].departCity){
					errMsg = '请选择入住城市';
				}else if(!item.departDate){
					errMsg = '请选择入住时间';
				}else if(!item.arriveDate){
					errMsg = '请选择离店时间';
				}
				if(errMsg){
					this.$alert(errMsg,'',{
						type:'error'
					})
					return false
				}
				let params = {
					checkInDate: item.departDate, //日期开始时间
					checkOutDate: item.arriveDate, // 日期结束时间
                    cityId : item.departCity||'',
                    ccsqdCityName:item.departCityName||'',
                    empRank:this.sortEmpRank().empRank
				}
                
				this.SET_HTCN_PARAMS(params);
                this.searchHtVisible = true;
				this.searchHotel()
                this.getDj()
			},
			// 酒店城市
			checkHtCity(obj,){
				this.viphtRouteList.map((item,i)=>{
					if(i==this.htIndex){
						item.departAirport = obj.threeCode;
						item.departCityName = obj.airportName || obj.cityName;
						item.departCity = obj.csbh?obj.csbh:obj.id || obj.cityId;
					}
					return item
				})
				let params = {
					ccsqdCityName:obj.cityName, // 城市名字
					cityId: obj.id, //城市或者商圈id
				}
				this.htInfo.cityId = obj.id;
				this.SET_HTCN_PARAMS(params)
                this.viphtRouteList=this.$common.deepCopy(this.viphtRouteList)
			},
			// 搜索酒店
			searchHotel(){
				this.isSearchHtList = true;
			},
			// 清除搜索
			clearHtSearch(){
				this.isSearchHtList = false;
			},
			// 酒店预定信息
			htYDInfo(info){
                let myRoute=this.viphtRouteList[this.htIndex]
                myRoute.against=info.against
                myRoute.cabin= info.cabin
                myRoute.cabinCode= info.cabinCode
                myRoute.dj= info.dj
                myRoute.flightNo=info.flightNo
                myRoute.fyid=info.fyid
                myRoute.fyys=info.fyys
                myRoute.jdid=info.jdid
                myRoute.priceId= info.priceId
                myRoute.queryFlag=info.queryFlag
                myRoute.reasonCode=info.reasonCode
                myRoute.violateitem=info.violateitem
                myRoute.violateitemCode= info.violateitemCode
                myRoute.wbyy= info.wbyy
                myRoute.extendInfo=JSON.stringify(info.extendInfo)
                this.getDj()
				this.searchHtVisible = false;	
			},

			// 关闭机票 酒店 火车票 查询列表
			closeSearch(){
				this.searchTkVisible = false;
				this.searchTrVisible = false;
				this.searchHtVisible = false;
			},
			//获取机票信息
			getTkList(item,index){
				this.tkIndex = index;
				let errMsg = ''
				if(!item.departAirport){
					errMsg = '请选择出发城市'
				}else if(!item.arriveAirport){
					errMsg = '请选择到达城市'
				}else if(!item.departDate){
					errMsg = '请选择出发日期'
				}
				this.tkInfo.depszm = item.departAirport;
				this.tkInfo.arrszm = item.arriveAirport;
				this.tkInfo.depCity = item.departCityName;
				this.tkInfo.arrCity = item.arriveCityName;
				this.tkInfo.depDate = item.departDate;
				if(errMsg){
					this.$message.error(errMsg)
					return
				}
				this.searchTkVisible = true;
				this.searchTk()
				
			},
			// 机票查询
			searchTk(){
				this.loadingFlightList = 1;
                
				let queryString = {
						arriveAirport: this.tkInfo.arrszm,
						departAirport: this.tkInfo.depszm,
						departDate: this.tkInfo.depDate,
						prdType: 'C', //G为政采票，C为普通票,
						tripType: 1,
                        minEmpRankEmpId: this.sortEmpRank().empId || this.empInfo.empId ,
                        empRank: this.sortEmpRank().empRank,
                        // useNameclbz: this.sortEmpRank().empRank.empName
                        // empRank:
				};
				this.$conn.getConn('tkBook.queryFlight')(queryString,(res)=>{
					let data = res.data || {};
                    this.matchClbz(data)
				},(err)=>{
					this.$message.error(err.tips)
					this.loadingFlightList = 4;
				})
                
			},

            //匹配差标
			matchClbz(data) {
			  var priceMap = data.priceMap || {};
			  var newPriceMap = {};
			  var queryString = {
			    "cacheId": data.cacheId,
			    "minZj": this.sortEmpRank().empRank || this.empInfo.empRank,
			  };
			//   if (this.tkQuery.gkfsRole && this.tkQuery.gkfsRole == 1) {
			//     queryString.budget = this.tkQuery.fyys;
			//     queryString.cabinCode = this.tkQuery.cabin;
			//   }
			  this.$conn.getConn('tkBook.matchClbz2')(queryString,(res)=>{
				  var wbdata = res.data || {};
				  for (var pKey in priceMap) {
				    var priceObj = this.$common.deepCopy(priceMap[pKey]);
				    var wbPriceObj = wbdata[pKey] || {};
				    newPriceMap[pKey] = Object.assign(wbPriceObj, priceObj);
				  		
				  }
				  data.priceMap = newPriceMap;
				  this.handleFlightListData(data);
                //1. 正常预订 不违背  2. 违背 预订提醒  3.抢票  4.不可预订
				
			  },err=>{
				   this.handleFlightListData(data);
			  })
			 
			
			},
			// 存储、并处理航班数据
			handleFlightListData(data){
				
				let flightList = this.$common.objToArr(data.flightMap || {});
				// 处理航班列表，重新排序格式
				let newFlightList = tkCommonJs.chuLiFlightCabinPriceArr(flightList, data.priceMap,data.planeMap,data.flightMap,{});
				
				this.flightList = newFlightList;
				this.flightMap = data.flightMap || {};
				
				// this.cacheId = data.cacheId;
				if(this.flightList.length>0){ // 查询有结果
					this.loadingFlightList = 2;
				}else{ // 查询无结果
					this.loadingFlightList =3;
				}
			},
			// 选择某一航班
			toChooseFlight(flightItem){
                this.wbType='10901'
                let bookType = flightItem.chooseCabin.bookType;
				if(bookType == 1){
                this.getChooseFlight(flightItem)
                this.searchTkVisible = false;
				}else if(bookType == 2){
                    this.getChooseFlight(flightItem)
                    // this.getDj()
					this.isShowWeiBei = true
					this.violateItem =  flightItem.chooseCabin.violateItem;
                    
				}else if(bookType == 3){
					console.log('当前舱位已售罄，您是否要去抢票？')
				}else if(bookType == 4){
					this.$message.alert({
						title:"温馨提示",
						content:"当前舱位违背差标，根据贵司差旅预订标准，您不可预订！"
					})
					console.error('当前舱位违背差标，根据贵司差旅预订标准，您不可预订！')
				}
                
			},
            // 航班参数chuli
            getChooseFlight(flightItem){
                // let bookType = flightItem.chooseCabin.bookType;
                let flyTrip=flightItem?.chooseCabin;
                let chooseFly=this.vipRouteList[this.tkIndex]
                chooseFly.queryFlag = 1;
                chooseFly.cabin = flyTrip.cabinName || "";
                chooseFly.fyys=this.$common.addPrice([flyTrip.salePrice,flightItem.airportFee,flightItem.tax,flyTrip.serviceFee ]);
                chooseFly.dj = flyTrip.salePrice || "";
				chooseFly.flightNo = flightItem.flightNo;
				// chooseFly.cabin = flightItem.minFilterPriceCabinTypeName;
                chooseFly.cabinCode = flyTrip.cabin || "";
                chooseFly.against = flyTrip.violateCode || "" ? 1 : 0;
                chooseFly.violateitem =flyTrip?.violateItem??'';
                chooseFly.violateitemCode = flyTrip?.violateCode??'';
                chooseFly.arriveTime = flightItem.arriveTime
                chooseFly.departTime = flightItem.departTime;
                chooseFly.departDateTime = 
									flightItem.departTime ? 
									this.$moment('2022-01-01 ' + flightItem.departTime).toDate() : 
									this.$moment('2022-01-01 00:00').toDate();
                chooseFly.arriveDateTime = 
									flightItem.arriveTime ? 
									this.$moment('2022-01-01 ' + flightItem.arriveTime).toDate() : 
									this.$moment('2022-01-01 23:59').toDate();
                chooseFly.extendInfo = {};
                chooseFly.extendInfo.airlineName = flightItem.airlineName;
                chooseFly.extendInfo.airportFee = flightItem.airportFee;
                chooseFly.extendInfo.tax = flightItem.tax;
                chooseFly.extendInfo.plane = flightItem.plane;
                chooseFly.extendInfo.yPrice = flightItem.yPrice;
                chooseFly.extendInfo.cPrice = flightItem.cPrice;
                chooseFly.extendInfo.fPrice = flightItem.fPrice;
                chooseFly.extendInfo.departTerminal = flightItem.departTerminal;
                chooseFly.extendInfo.arriveTerminal= flightItem.arriveTerminal;
                chooseFly.extendInfo.flyTimeStr = flightItem.flyTimeStr;
                chooseFly.extendInfo.discount = flyTrip.discount;
                chooseFly.extendInfo.cabinType = flyTrip.cabinType;
                chooseFly.extendInfo.cabinTypeName = flyTrip.cabinTypeName;
                chooseFly.extendInfo.serviceFee = flyTrip.serviceFee;
                chooseFly.extendInfo.priceId = flyTrip.priceId;
                chooseFly.extendInfo.refundRule = flyTrip.refundRule;
                chooseFly.extendInfo.changeRule = flyTrip.changeRule;
								chooseFly.departAirportName = flightItem.departAirportName;
								chooseFly.arriveAirportName = flightItem.arriveAirportName;
                chooseFly.extendInfo.flyTimeStr=flightItem.flyTimeStr
                chooseFly.extendInfo.sharFlightNo=flightItem.sharFlightNo
                chooseFly.extendInfo.airlineImg=flightItem.airlineImg
                chooseFly.extendInfo.planeName=flightItem.planeName
                chooseFly.extendInfo.minFilterPriceCabinTypeName=flightItem.minFilterPriceCabinTypeName
                chooseFly.extendInfo.shareAirlineName=flightItem.shareAirlineName
                chooseFly.extendInfo.shareAirlineImg=flightItem.shareAirlineImg

                chooseFly.extendInfo=JSON.stringify(chooseFly.extendInfo)
                this.getDj()
            },

			
		
			//添加过渡动画
			getLoading(bool){
				this.loading = bool;
			},
			
			
			// 获取航班号，车次号 ，酒店房型
			getFlightNo(item,index){

				if(item.proType=='10901' || item.proType=='10904' || item.proType=='109991'|| item.proType=='109992'|| item.proType=='109993'|| item.proType=='10999' ){
					this.vipRouteList[index].flightNo = item.flightNo;
				}else{
					this.viphtRouteList[index].flightNo = item.flightNo;
				}
                this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
				// this.$forceUpdate()
			},
			// 获取类型
			getCabin(item,index){
				if(item.proType=='10901' || item.proType=='10904' || item.proType=='109991'|| item.proType=='109992'|| item.proType=='109993'|| item.proType=='10999'){
					this.vipRouteList[index].cabin = item.cabin;
				}else{
					this.viphtRouteList[index].cabin = item.cabin;
				}
				// this.$forceUpdate()
                
                  this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
			},
			// 获取单价  设置单价
			getDj(isFillIn=false){
                // 手填餐费预算的时候，以手填的为主，如果存在有行程的情况下，以行程为主，清空手填
                let routeTypeList = this.vipRouteList.concat(this.viphtRouteList,this.vipCarRouteList);
                let result = {
                    zg: 0, //总价
                    jtf: 0,//交通费
                    zsf: 0,//住宿费
                    cyf: isFillIn?this.allfyys.cyys:0,//餐饮费
                    // qt: 0, //其他
                }
                routeTypeList.forEach(item=>{
                    if(item.proType!='10903'){
                        let allPeople=0
                        allPeople= this.$common.numMulti(item.fyys,item.cxr.split(",").length) 
                        result.jtf = this.$common.addPrice([result.jtf, this.$common.addPrice([allPeople, item.dcf])])
                    }else{
                        let days = this.$common.getDiffDate(item.departDate, item.arriveDate) ;
                            if(days!=0){
                            
                            let hotelFyys= this.$common.numMulti(this.$common.numMulti(item.fjs,days),item.fyys)
                            result.zsf=this.$common.addPrice([result.zsf, hotelFyys])
                            result.cyf = this.$common.addPrice([result.cyf, item.cf])
                        }else{
                            result.zsf=this.$common.addPrice([result.zsf, item.fyys])
                            result.cyf = this.$common.addPrice([result.cyf, item.cf])
                        }
                    }
                })
                result.zg = this.$common.addPrice([result.jtf, result.zsf, result.cyf, this.allfyys.qtys])
                this.allfyys.zys=result.zg
                this.allfyys.jtys=result.jtf
                this.allfyys.zsys=result.zsf
                this.allfyys.cyys=result.cyf
                // this.allfyys.qtys=result.qt
                // this.allfyys=this.$common.deepCopy(this.allfyys)
			},
			// 房间数
			// getFjs(item,index){
			// 	if(item.fjs>this.checkCxrList.length){
			// 		this.$message.warning('酒店房间数量不可大于出行人数量');
			// 		this.viphtRouteList[index].fjs = this.checkCxrList.length;
			// 	}
			// 	if(item.dj){
			// 		this.viphtRouteList[index].fyys =(item.fjs * item.dj * this.hotelDay);
			// 	}else{
			// 		this.viphtRouteList[index].fyys = 0;
			// 	}
			// 	// this.$forceUpdate();
            //       this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
            //       this.getDj()
			// },
			getDiffDate(startDate,endDate,index){
				this.hotelDay = this.$common.getDiffDay(startDate,endDate)
			},
			// 选中审批规则
			chooseApproval(info){
				this.appId = info.gzid;
                this.gzmc=info.gzmc
			},

			
			// 选中机票，火车票类型 （只使用与两个选项）
			chooseRouteTypeTop(code,index){
				
				for(let i=0; i<this.vipRouteList.length; i++){
					if(i==index){
                        let myRoute=this.vipRouteList[i]
						myRoute.proType = code;
						myRoute.departCityName = '';
						myRoute.arriveCityName = '';
						myRoute.flightNo = '';
						myRoute.cabin = '';
                        myRoute.fyys=0;
                        myRoute.cabinCode='';
                        myRoute.against=0;
                        myRoute.violateitem='';
                        myRoute.violateitemCode='';
                        myRoute.arriveTime='';
                        myRoute.departTime='',
                        myRoute.departAirportName = '';
                        myRoute.arriveAirportName = '';
                        myRoute.extendInfo={}
                        myRoute.wbyy = '';
                        myRoute.reasonCode ='';
						break;
					}
				}
			},
			// 搜索输入触发 强制刷新页面 赋值出发/入住地址
			queryCityName(type,name,index){
				if(type=='10901'){
					this.vipRouteList.map((item,i)=>{
						if(i==index){
							item.depJHName = name;
						}
						return item
					})
					
				}else if(type=='10904'){
					this.vipRouteList.map((item,i)=>{
						if(i==index){
							item.depJHName = name;
						}
						return item
					})
				}else if(type=='10903'){
					this.viphtRouteList.map((item,i)=>{
						if(i==index){
							item.depHtName = name;
						}
						return item
					})
				}else if(type=='10906'){
					this.vipCarRouteList.map((item,i)=>{
						if(i==index){
							item.depCarName = name;
						}
						return item
					})
				}
                  this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
				// this.$forceUpdate();
			},
			// 强制刷新页面 赋值到达地址
			queryArrCityName(type,name,index){
				this.vipRouteList.map((item,i)=>{
					if(i==index){
						item.arrJHName = name;
					}
					return item
				})
                  this.vipRouteList=this.$common.deepCopy(this.vipRouteList)
				// this.$forceUpdate();
			},
			// 删除当前行程行息
			removeCurrent(index,num,cItem){
                console.log("跳到了这里哟")
				// 1 机票 火车票
				if(num==1){
                    console.log( this.vipRouteList," this.vipRouteList")
                    console.log( this.viphtRouteList,"  this.viphtRouteList")
                    this.$confirm('删除交通行程会清除掉相关关联的酒店行程', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                        this.vipRouteList.forEach((item,i)=>{
                            if(index==i){
                                let trafficXcxh=cItem.xcxh
                                this.delRouteIdsList.push(item.routeId)
                                this.vipRouteList.splice(i,1);
                                let deepHtRouteList=this.$common.deepCopy(this.viphtRouteList)
                                let newHtRouteList=[]
                                deepHtRouteList.forEach((htItem,htIndex)=>{
                                        if(htItem.xcxh!=trafficXcxh){  // 不能直接通过htItem 来进行删除。每次删除数组索引会发生变化
                                            newHtRouteList.push(htItem)
                                        }else{
                                            this.delRouteIdsList.push(htItem.routeId)
                                        }
                                    })
                                this.viphtRouteList=newHtRouteList
                            }
                        })
                        
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                        });

					
				}else if(num==2){
					// 2 酒店
					this.viphtRouteList.forEach((item,i)=>{
						if(index==i){
							this.delRouteIdsList.push(item.routeId)
							// this.ysInfo.zsys = this.ysInfo.zsys - (item.fyys?item.fyys:0);
							this.viphtRouteList.splice(i,1);
						}
					})
				}else if(num==3){
					// 3用车
					this.vipCarRouteList.forEach((item,i)=>{
						if(index==i){
							this.delRouteIdsList.push(item.routeId)
							// this.ysInfo.jtys = this.ysInfo.jtys - (item.fyys?item.fyys:0);
							this.vipCarRouteList.splice(i,1);
						}
					})
				}	
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
                this.getDj()
			},
            getControlTime(list){ // 处理申请单自动更新之后的时间
                console.log(list,"list")
                let arrDateTimeList=list.map(item=>item.arriveDateLong)
                let departDateTimeList=list.map(item=>item.departDateLong)
                let minDepart = (departDateTimeList.reduce((a,b)=>b<a?b:a)).split(" ");
                let maxArrive = (arrDateTimeList.reduce((a,b)=>b>a?b:a)).split(" ");
                this.formInfo.dateBeginLong= `${minDepart[0]} ${minDepart[1]}`
                this.formInfo.dateEndLong=`${maxArrive[0]} ${maxArrive[1]}`
                console.log(this.formInfo.dateBeginLong,"this.formInfo.dateBeginLong",this.formInfo.dateEndLong,"this.formInfo.dateEndLong")
                this.formInfo.dateBegin=minDepart[0]
                this.formInfo.dateEnd=maxArrive[0]
                 console.log(this.formInfo.dateBegin,"this.formInfo.dateBegin",this.formInfo.dateEnd,"this.formInfo.dateEnd")
            },
			
			// 验证保存参数
			vailParams(num,info){
				let errMsg = '';
				if(num==2){
					if(!info.appId){
						errMsg = "送审前请选择审批规则"
					}
				}
				if(!this.formInfo.dateBegin){
					errMsg = "请选择出差申请单开始时间";
				}else if(!this.formInfo.dateEnd){
					errMsg = "请选择出差申请单结束时间";
				}
       
                // else if(this.formInfo.dateBegin && this.formInfo.dateEnd){
				// 	let time = this.$common.getToDay();
				// 	// 处理多天后编辑时，出差单日期问题，
				// 	//不在出差单范围内,不能保存
				// 	var sBool = this.$common.getDiffSize(time,this.formInfo.dateBegin)
				// 	var eBool = this.$common.getDiffSize(this.formInfo.dateEnd,time)
				// 	errMsg = eBool && sBool?"":"请重新选择出差申请单日期";
				// }
				if(info.fastRouteList.length>0){
					let sBool = true;
					let eBool = true;
					var boolList = info.fastRouteList.map(item=>{
						let bool = false;
						if(item.departDate){
							sBool =	this.$common.getDiffSize(item.departDate,this.formInfo.dateBegin)
						}else if(item.arriveDate){
							eBool =	this.$common.getDiffSize(this.formInfo.dateEnd,item.arriveDate) 
						}
						
						if(sBool && eBool){
							bool = true;
						}else{
							bool = false;
						}
						return bool
					})
					if(boolList.indexOf(false) != -1){
						errMsg = "行程日期不在出差日期范围，请重新选择";
					}
				}
				
				if(this.projectRequired && info.project==1){
					if(!info.projectId){
						errMsg = "请选择出差项目";
					}
				}else if(this.costCenterRequired){
					if(!info.costCenterId){
						errMsg = "请选择成本中心";
					}
				}else if(this.ccsqdYs==1 && info.zys==0){
					errMsg = "总预算必须大于0元";
				// }else if(!info.mdd){
				// 	errMsg = "请填写目的地";
				}
                else if(info.fastRouteList.length==0){
					errMsg="请添加出行信息";
				}else if(this.vipRouteList.length>0){
                    console.log(this.vipRouteList,"this.vipRouteList")
					this.vipRouteList.forEach((item,index)=>{
						if(!item.departCityName){
							errMsg= "行程计划第"+ (index+1) +"条出发地址为空";
						}else if(!item.arriveCityName){
							errMsg= "行程计划第"+ (index+1) +"条到达地址为空";
						}else if(item.cclx==''){
							errMsg= "行程计划第"+ (index+1) +"条出行类型为空";
						}else if(item.proType=='10901'){
                            let cxrList=item.cxr.split(",");
                            if(cxrList.length>9){
                                errMsg= "行程计划第"+ (index+1) +"出行人大于9人，请调整出行人";
                            }
                        }else if(item.proType=='10904'){
                            let cxrList=item.cxr.split(",");
                            if(cxrList.length>9){
                                errMsg= "行程计划第"+ (index+1) +"出行人大于9人，请调整出行人";
                            }
                        }else if( !item.ccsy  && this.ccsyRestriction==1){
                            errMsg= "根据部门管控，行程计划第"+ (index+1) +"出差事由不能为空";
                           
                        }else if((item.departCityName==item.arriveCityName) && this.localRestriction!=1){
                            errMsg= "根据部门管控，行程计划第"+ (index+1) +"行程出发地和到达地不允许重复";
                        }
					})
				}
                 if(this.viphtRouteList.length>0){
                    // this.viphtRouteList=this.$common.deepCopy(this.viphtRouteList)
					this.viphtRouteList.forEach((item,index)=>{
						if(!item.departCityName){
							errMsg= "住宿安排第"+ (index+1) +"条入住城市为空";
						}
                        let cxrList=item.cxr.split(",");
                        if(cxrList.length>10){
                            errMsg= "住宿安排第"+ (index+1) +"出行人大于10人，请调整出行人";
                        }
                        if(item.fjs>5){
                             errMsg= "住宿安排第"+ (index+1) +"房间数大于5，请调整出行人";
                        }
					})
				}
				
				if(errMsg){
					this.$message({
						type: 'error',
						message: errMsg
					})
					return false;
				}

				return true
			},
			// 保存并送审
			saveAndApproval(num){
                if(this.vipRouteList.length==0){
                    	    this.$message({
							type: 'error',
							message: '清先添加交通行程'
						})
                        return
                }
				let routeTypeList = this.vipRouteList.concat(this.viphtRouteList,this.vipCarRouteList);
                console.log(routeTypeList,"routeTypeList")
				routeTypeList.map((item)=>{
					if(item.depHtName || item.depJHName || item.arrJHName || item.depCarName){
						delete item.depHtName
						delete item.depJHName
						delete item.arrJHName
						delete item.depCarName
						return  item
					}
					if(JSON.stringify(item.extendInfo) === '{}'){
							delete item.extendInfo
					}
					delete item.isChange
					// delete item.routeStatus
					// delete item.arriveCityInternational
					if (item.departDateTime && item.departDateTime instanceof Date) {
						item.departDateLong = 
							item.departDate + ' ' + this.$moment(item.departDateTime).format('HH:mm');
					}
					if (item.arriveDateTime && item.arriveDateTime instanceof Date) {
						item.arriveDateLong = 
							item.arriveDate + ' ' + this.$moment(item.arriveDateTime).format('HH:mm');
					}
					return item
				})
				
				let querypParams = {};
				querypParams = {
					addMethod: num,
					dateBegin:this.formInfo.dateBegin,
					dateEnd:this.formInfo.dateEnd,
					dateBeginLong:this.formInfo.dateBeginLong,
					dateEndLong:this.formInfo.dateEndLong,
					appId: this.appId,
                    area:this.formInfo.area,
					by1:this.by1,
					costCenterCode: this.formInfo.costCenterCode,
					costCenterId: this.formInfo.costCenterId,
					costCenterName: this.formInfo.costCenterName,
					cyys: this.allfyys.cyys,
					delRouteIds: this.delRouteIdsList?.join(",")??'', //删除的行程id,,
					fastPersonList: this.vipPersonList,
					fastRouteList: routeTypeList,
					id: this.$route.query.ccsqdId || '',
					jtys: this.allfyys.jtys, //交通预算
					mdd: this.formInfo.mdd,
					qtys: this.allfyys.qtys, //其他预算
					zsys: this.allfyys.zsys,
					zys:  this.allfyys.zys,
					repeatFlag: this.repeatFlag,
				}
				
				let bool = this.vailParams(num,querypParams);
				if(!bool){
					return false
				}  
                if(this.timeAutoRestriction==1){
                        this.$confirm('根据后台管控，将为您自动更新出差单时间', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => { 
                            this.getControlTime(this.vipRouteList) 
                            this.timeAutoRestriction=0
                            this.saveAndApproval(num)

                        // this.$message({
                        //     type: 'success',
                        //     message: '删除成功!'
                        // });
                        }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });          
                        });
                }else{
                    this.sendAjax(num,querypParams)
                }

                


			},

            sendAjax(num,querypParams){
                if(num!=3){
                    if(num==2){
                        this.btnLoading=true
                    }
                    this.$conn.getConn('xakCcsqd.xakVipCcsqd')(querypParams,(res)=>{
					let data = res.data|| {};
					let errMsg = "";
					if(data.status==1){
						errMsg = '送审失败'
					}else if(data.status==0){
						errMsg = '草稿保存成功'
					}else if(data.status==2 && num==1){
						errMsg = '草稿保存成功'
					}else if(data.status==2 && num==2){
						errMsg = '送审成功'
					}
					if(data.status==1){
						this.$message({
							type: 'error',
							message: errMsg
						})
						return
					}else{
						this.$message({
							type: 'success',
							message: errMsg
						})
                          this.btnLoading=false
                          this.$router.push({
                            path:'/yjTravel/myTravel/userCenter/travelFormAdmin'
                        })

						
					}
				},(err)=>{
					if(err.errCode == 9883){
						 this.$confirm(err.tips , '温馨提示', {
						          confirmButtonText: '继续保存',
						          cancelButtonText: '取消',
						          type: 'warning'
						        }).then(() => {
									this.repeatFlag = 1;
                                    this.btnLoading=false
									this.saveAndApproval(num);
						        }).catch(() => {
						                this.btnLoading=false  
						        });
						
					}else{
						this.$message({
							type: 'error',
							message: err.tips
						})
					}
				})
                }else{ //预览跳转
                    let obj={
                        querypParams,
                        spgz:this.gzmc
                    }
                    this.$common.sessionset('previewParams',obj)
                    let path='/ccsqdPreview'
                    let routeUrl = this.$router.resolve({path});
                    window.open(routeUrl.href,'_blank')

                }
            },
            close(){
				window.close();
			},
			//获取时间
			getChangeDate(e){
				
				if(e.proType == 10901){
					this.setTkQuery({
						depDate: e.departDate,
					})
				}else if(e.proType == 10904){
					this.trInfo.depDate = e.departDate;
				}
				
			},
			// 关闭本页面
			closePage(){
				window.close();
			}
		}
	}
</script>

<style scoped lang="scss">
	.atf-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		.seth1{
			font-size: 23px;
			font-weight: 800;
			color: #282828;
			line-height: 32px;
			text-align: left;
			
		}
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
			.setPad{
				padding: 39px 29px 0px 29px;
				.setTitleLeft{
					width: 6px;
					height: 19px;
					background: #1C83ED;
					display: inline-block;
					vertical-align: text-bottom;
				}
				.setTitle{
					font-size: 18px;
					font-weight: 500;
					color: #000000;
					line-height: 24px;
					display: inline-block;
					padding-left: 10px;
					
				}
				.setText{
					font-size: 12px;
					font-weight: 400;
					color: #808080;
					line-height: 17px;
					display: inline-block;
					padding-left: 10px;
				}
			
				.table-main{
					margin-top: 17px;
					border: 1px solid #DFE2E9;
					.tabTitle{
						display: flex;
						width: 100%;
						font-size: 12px;
						font-weight: 400;
						color: #808080;
						background-color: #F7F7F7;
						height: 49px;
						line-height: 49px;
						div{
							border-right:1px solid #DFE2E9;
						}
						div:last-child{
							border-right:none;
						}
					}
					.tabContent{
						width:100%;
						display: flex;
						border-top: 1px solid #DFE2E9;
						line-height: 40px;
                        /deep/ .el-input__inner {
		                    padding: 0 5px;
	                    }
						.cityInput{
							.carInput{
								/deep/ .el-input__inner{
									border-right: 1px solid #DFE2E9;
								}
							}
							div{
								border-right: none;
							}
							/deep/ .el-input__inner{
								padding:0 5px;
							}
						}
                        .otherInput{
                          	/deep/ .el-input__inner {
								// border-right: none;
								padding:0 5px;
							} 
     
                        }

						.htInput{
							/deep/ .el-input__prefix{
								display: none;
							}
							/deep/ .el-input__inner{
								padding-left: 5px;
							}
						}
						
						.model-k{
							padding: 10px 3px;
							.dateW94{
								width: 96px;
								.el-input{
									width: 96px;
								}
								/deep/ .el-input__inner{
									width: 96px;
									padding:0 5px;
								}
							}
							
							.dateW90{
								width: 90px;
								.el-input{
									width: 90px;
								}
								/deep/ .el-input__inner{
									width: 90px;
									padding:0 5px;
								}
							}
							.dateW140{
								width: 140px;
								.el-input{
									width: 140px;
								}
								/deep/ .el-input__inner{
									width: 140px;
									padding:0 5px;
								}
							}
							.dateW340{
								width: 340px;
								.el-input{
									width: 340px;
								}
								/deep/ .el-input__inner{
									width: 340px;
									padding:0 5px;
								}
							}
							.against{
								width: 96px;
								/deep/ .el-input__inner{
									width: 96px;
									padding:0 5px;
								}
							}
                           /deep/ .el-input--prefix .el-input__inner {
                                padding: 0;
                            }							
						}
						.relative{
							position: relative;
						}
						.absolute{
							position: absolute;
						}
						.setdiv{
							text-align: left;
							padding-left:5px;
							width: 78px;
							height: 40px;
							line-height: 40px;
							border-radius: 2px;
							border: 1px solid #DFE2E9;
						}
						div{	
							border-right:1px solid #DFE2E9;
						}
						div:last-child{
							border-right:none;
						}
					}
					
					.el-input{
						width: 76px; 
					}
					.input56{
						.el-input{
							width: 56px;
						}
						.inputw56{
							/deep/ .el-input__inner{
								width: 56px;
								padding: 0 5px;
							}
						}
					}
					.setBtn{
						width: 68px;
						height: 29px;
						border-radius: 2px;
						text-align: center;
					}
					.carInput{
						.el-input{
							width: 120px;
						}
						.inputw124{
							/deep/ .el-input__inner{
								width: 120px;
								padding: 0 5px;
							}
						}
					}
					/deep/ .el-input__inner{
							height: 40px;
							line-height: 40px;
							border-radius: 2px;
							border: 1px solid #DFE2E9;
					}
					/deep/ .el-button--small{
						padding: 0 ;
					}
					.btn39{
						width: 40px;
						height: 40px;
						border-radius: 0px;
						font-size: 14px;
						line-height: 37px;
					}
                    .search-btn{
         			    width: 20px;
						height: 40px;
						border-radius: 0px;
						font-size: 14px;
						line-height: 37px;
                    }
					
					.inputw94{
						/deep/ .el-input__inner{
							width: 94px;
							padding: 0 5px;
						}
					}
					
					.inputw76{
						/deep/ .el-input__inner{
							width: 76px;
							padding: 0 5px;
						}
					}
					
					.inputw96{
						/deep/ .el-input__inner{
							width: 96px;
							padding: 0 5px;
						}
					}
					.w34{
                       width: 34px; 
                    }
										.w55 {
											width: 55px;
										}
                    .w78{
                        width: 78px;
                    }
                    .w80{
                        width: 80px;
                    }
                    .w73{
                        width: 73px;
                    }
                    .w90{
                        width: 90px;
                    }
                    .w100{
                          width: 100px;
                    }




                    
					.w50{
						width: 50px;
					}
					.w59{
						width: 59px;
					}
					.w77{
						width: 77px;
					}
					.w94{
						width: 94px;
					}
					.w97{
						width: 97px;
					}
					.w114{
						width: 114px;
					}
					.w120{
						width: 120px;
					}
					.w116{
						width: 116px;
					}
					.w130{
						width: 130px;
					}
					.w136{
						width: 136px;
					}
					.w220{
						width: 220px;
					}
					.w240{
						width: 240px;
					}
					.w360{
						width: 360px;
					}
				}
				
			}
			.setPadTop{
				padding: 19px 29px 39px 29px;
				.setBtn1{
					width: 78px;
					height: 40px;
					border-radius: 2px;
					text-align: center;
					/deep/ .el-button--small{
						padding: 0 ;
					}
				}
				.setBtn2{
					width: 97px;
					height: 40px;
					border-radius: 2px;
					text-align: center;
					/deep/ .el-button--small{
						padding: 0 ;
					}
				}
			}
			
		}
		/deep/ .el-card__body{
			padding: 0;
		}
		/deep/ .el-table .cell{
			text-overflow: initial;
		}
		
		.tr-search{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.dialog-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1240px;
				padding: 20px;
				.dialogTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
					.iconClose{
						font-size: 24px;
						cursor: pointer;
					}
					.iconClose:hover{
						color:#00A7FA;
					}
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.iconhc{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
				.search-content{
					width: 100%;
					height: 600px;
					overflow-x: hidden;
					overflow-y: auto;
					margin-top: 20px;
					.skelet{
						width: 100%;
						height:auto;
						background-color: rgba(0,0,0,0.2);
						border-radius: 4px;
						.flex{
							display: flex;
						}
						.between{
							justify-content: space-between;
						}
					}
				}
			
					
			}
		}
		
					
		.cxrDialog{
			text-align: left;
			/deep/ .el-dialog__body {
				padding: 10px 20px 30px 20px;
			}
			/deep/ .el-dialog{
				width: 790px;
			}
			.cxr-con{
				border: 1px solid #ccc;
				padding: 20px 0 20px 20px;
				background-color: #F7F7F7;
			}
			.cxr-content{
				width: 100%;
				text-align: left;
				line-height: 40px;
				span{
					display: inline-block;
				}
			}
		}

		.tch-search{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.search-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1200px;
				padding: 20px;
				.searchTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
				}
				.iconClose{
					font-size: 24px;
					cursor: pointer;
				}
				.iconClose:hover{
					color:#00A7FA;
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.iconhc{
						width: 45px;
						line-height: 45px;
						margin:0 40px 0 10px;
						font-size: 24px;
						color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
				.list-main{
					max-height: 300px;
					min-height: 500px;
					overflow-y: auto;
					text-align: left;
					.list-con{
						display: flex;
						min-height: 40px;
						margin-bottom: 3px;
						background-color: #eeeeee;	
						cursor: pointer;
						.img{
							width: 25px;
							height: 25px;
						}
					}
					.list-con:hover{
						background-color: #dddddd;	
						box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
						
					}
				}
			}
			
		}
		
		.ht-query{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.ht-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1200px;
				padding: 20px;
				height: 700px;
				overflow: hidden;
				.searchTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
					.font18{
						font-size: 18px;
					}
					.iconClose{
						font-size: 24px;
						cursor: pointer;
					}
					.iconClose:hover{
						color:#00A7FA;
					}
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
			}
		}
		
		
		/deep/ .el-form-item__label{
			text-align: left;
			line-height: 49px;
		}
		/deep/ .el-form-item{
			margin-right: 0;
		}
		/deep/ .el-textarea__inner{
			height: 117px;
			background: #FFFFFF;
			border: 1px solid #DFE2E9;
		}
		.setInputw{
			/deep/ .el-input__inner{
				width:76px;
			
			}
			
		}
		.cxrCheckbox{
			.line-h40{
				line-height: 40px;
			}
			span{
				display: inline-block;
			}
		}
	
		
		.ys-main{
			margin-top: 17px;
			border: 1px solid #DFE2E9;
			.ys-header{
				display: flex;
				width: 100%;
				font-size: 12px;
				font-weight: 400;
				color: #808080;
				background-color: #F7F7F7;
				height: 49px;
				line-height: 49px;
				div{
					width: 33%;
					border-right:1px solid #DFE2E9;
				}
				div:last-child{
					border-right:none;
				}
			}
			.ys-content{
				width:100%;
				display: flex;
				border-top: 1px solid #DFE2E9;
				line-height: 40px;
				div{
					width: 33%;
					border-right:1px solid #DFE2E9;
				}
				div:last-child{
					border-right:none;
				}
				.el-input{
					width: 260px; 
					margin: 15px 0;
				}
			}
			.ys-footer{
				border-top: 1px solid #DFE2E9;
				height: 67px;
				text-align: right;
				padding-right: 20px;
				
				.footer1{
					font-size: 14px;
					font-weight: 400;
					color: #808080;
					line-height: 67px;
					display: inline-block;
					padding-right: 15px;
					float:right;
				}
				.footer2{
					font-size: 23px;
					font-weight: 800;
					color: #1C83ED;
					line-height: 67px;
					display: inline-block;
					float:right;
				}
			}
			.setBtn{
				width: 68px;
				height: 29px;
				border-radius: 2px;
				text-align: center;
			}
			/deep/ .el-input__inner{
					height: 40px;
					border-radius: 2px;
					border: 1px solid #DFE2E9;
			}
			/deep/ .el-button--small{
				padding: 0 ;
			}
			.btn39{
				width: 40px;
				height: 40px;
				border-radius: 0px;
				font-size: 14px;
				line-height: 25px;
			}
			
			.w50{
				width: 50px;
			}
			.w59{
				width: 59px;
			}
			.w94{
				width: 94px;
			}
			.w97{
				width: 97px;
			}
			.w114{
				width: 114px;
			}
			.w116{
				width: 116px;
			}
			.w220{
				width: 220px;
			}
		}
		
	
		.setRemove{
			font-size: 14px;
			font-weight: 400;
			color: #1C83ED;
			line-height: 40px;
			cursor: pointer;
		}
		.toptableR{
			right: 8px;
			top: 10px;
			cursor: pointer;
		}
		.bottomtableR{
			right: 8px;
			bottom: 10px;
			cursor: pointer;
		}
		
		.flex{
			display: flex;
		}

		.flex-v {
			display: flex;
			align-items: center;
		}

		.between{
			justify-content: space-between;
		}
		.wrap{
			flex-wrap: wrap;
		}
		.first{
			justify-content: flex-start;
		}
		.textRight{
			text-align: right;
		}
		.tql-icon-t{
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 8px solid #ccc;
		}
		.tql-icon-b{
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 8px solid #ccc;
		}
		.tql-icon-t:hover{
			border-bottom: 8px solid #1C83ED;
		}
		.tql-icon-b:hover{
			border-top: 8px solid #1C83ED;
		}
		
		
		

		
		.borderB-F0{
			border-bottom:1px solid #f0f0f0;
		}
		
		

		.marT17{
			margin-top: 17px;
		}
		.marT20{
			margin-top: 20px;
		}
	
		
	}

</style>
