<template>
	<div class="tkod-main">
		<yj-progress-edit :statusList="statusList" :currentStatus="orderStatus" />
		<el-card class="textLeft marTop20">
			<el-row type="flex" justify="space-between" style="margin-bottom: 10px">
				<el-col class="flex-column" :span="6">
					<p>订单号：{{ hotelOrderInfo.orderNo }}</p>
				</el-col>
				<el-col class="flex-column text-right" :span="6">
					<p :style="getTextColor()">
						{{ hotelOrderInfo.orderStatusRemark }}
						{{
							hotelOrderInfo.orderStatus == 17
							? ': ' + hotelOrderInfo.errMsg
							: ''
						}}
					</p>
				</el-col>
			</el-row>
			<div style="padding:20px 0 20px 40px;border: 1px solid #e6e6e6; margin-bottom: 10px;">
				<!-- v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus>0"  -->
				<yj-approval-rule-detail v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus == 0" :detail="detail"
					:appId="detail.appId" @gznrList="getGznrList"></yj-approval-rule-detail>
			</div>
			<!-- v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus>0 && hotelOrderInfo.vipStatus<4" -->
			<div style="padding:20px 0 20px 40px;border: 1px solid #e6e6e6;margin-bottom: 10px;">
				<yj-approval-progress v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus > 0"
					:orderId="hotelOrderInfo.orderId" 
					:orderType="11008"
					@showProgress="showProgress"
					:resetspjd="resetspjd"
					></yj-approval-progress>
			</div>

			<div class="tk-main">
				<div class="left-main-width">
					基本信息({{
						hotelOrderInfo.tripType == 1 ? '因公' : '因私'
					}})
				</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6">
						<div>
							预订人:{{ hotelOrderInfo.bookerName }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							服务商：{{ hotelOrderInfo.supplierName }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							预订时间：{{ hotelOrderInfo.bookDate }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							支付方式:{{ hotelOrderInfo.payMothedName }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">价格信息：</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6">
						<div>
							总金额:￥{{ hotelOrderInfo.totalPrice }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>服务费：￥{{ hotelOrderInfo.fwf }}</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							房间数量：￥{{ hotelOrderInfo.rooms }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">退订信息</div>
				<div class="rooms-right">
					<el-row class="romms-info">
						<el-col class="flex-column" :span="6">
							<div>
								应退合计:￥{{ hotelRefundInfo.cuRefundAmount }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								退订日期{{ hotelRefundInfo.htRefundDays }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								退订房间{{ hotelRefundInfo.htRefundRooms }}间
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								共退{{ hotelRefundInfo.htRefundNights }}晚
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info" type="flex" justify="space-between">
						<el-col class="flex-column" :span="6">
							<div>
								入住人:{{ hotelOrderInfo.rzrxm }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div style="color:#337ab7;cursor: pointer" @click="jumpSpeed">
								查看退订详情
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">预订信息</div>
				<div class="rooms-right">
					<el-row class="romms-info">
						<el-col class="flex-column" :span="8">
							<div>
								[{{ hotelOrderInfo.cityName }}]{{
									hotelOrderInfo.jdmc
								}}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								电话：￥{{ hotelOrderInfo.phone }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								地址：{{ hotelOrderInfo.address }}
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info p-r-20">
						<el-table :data="everyPriceList" :span-method="objectSpanMethod" :header-cell-style="{
							background: '#eef1f6',
							color: '#606266',
						}" border style="width: 100%; margin-top: 20px">
							<el-table-column prop="fxmc" label="房型" width="180">
							</el-table-column>
							<el-table-column prop="day" label="日期">
							</el-table-column>
							<el-table-column prop="cbj" label="房费">
							</el-table-column>
							<el-table-column prop="fwf" label="服务费">
							</el-table-column>
							<el-table-column prop="rooms" label="房间数量">
							</el-table-column>
							<el-table-column prop="totalPrice" label="总计(￥)">
							</el-table-column>
						</el-table>
					</el-row>
					<el-row class="romms-info b-t-1">
						<el-col class="flex-column" :span="4">
							<div>
								房型:{{ hotelOrderInfo.fxmc
								}}{{ hotelOrderInfo.rooms }} 间
							</div>
						</el-col>
						<el-col class="flex-column" :span="8">
							<div>
								晚数:共{{ hotelOrderInfo.nights }}晚
								{{ hotelOrderInfo.rzrq }}至{{
									hotelOrderInfo.ldrq
								}}
							</div>
						</el-col>
						<el-col class="flex-column" :span="4">
							<div>
								早餐:{{ hotelOrderInfo.breakfastType | breakfastTypeName }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="8">
							<div>
								取消规则:{{ hotelOrderInfo.changeRule }}
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">入住人信息</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" v-for="item in hotelOrderInfo.roomList" :key="item.index" :span="6">
						<div>
							房间{{ item.roomNo }}：{{ item.custName }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">发票信息</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="12">
						<div>
							公司支付：发票将在月底统一邮寄至贵公司财务；
						</div>
					</el-col>
					<el-col class="flex-column" :span="12">
						<div>
							个人支付：请联系客服010-64581922 开取发票
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">报销方式</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="12">
						<div>配送方式：</div>
					</el-col>
					<el-col class="flex-column" :span="12">
						<div>快递方式：</div>
					</el-col>
				</el-row>
			</div>
            <yj-approval-cao-zuo   :appType="appType" :workItemId="workItemId" :orderType="orderType" :orderId="orderId" @closePage="resetDetail">
				<template slot="orderFooterSlot">
					<el-row
						type="flex"
						justify="end"
						class="left-main-right b-t-1"
						style="padding-top: 10px"
					>
						<el-button class="padButton" @click="close">关 闭</el-button>
						<!-- <el-button
							class="padButton"
							v-if="hotelOrderInfo.orderStatus == 3"
							@click="cancel"
							>取消</el-button
						>
						<el-button
							class="padButton"
							v-if="hotelOrderInfo.orderStatus == 3"
							@click="payment"
							>支付</el-button
						> -->
						<el-button class="padButton" v-if="hotelOrderInfo.isShowApproval == 1" @click="sendApp(hotelOrderInfo)">送审</el-button>

						<el-button class="padButton" v-if="hotelOrderInfo.isShowCancelApprove == 1" @click="orderCaoZuo(hotelOrderInfo,16)">撤回</el-button>
					</el-row>

				</template>
			</yj-approval-cao-zuo>

   
		</el-card>
	</div>
</template>

<script>
import caozuoMixin from '@/mixin/models/caozuoMixin.js';
export default {
	name: 'tkOrderDetail',
	mixins:[caozuoMixin.commonConfig],
	data() {
		return {
			// orderId: '', //订单id
			detail: {
				flowState: 3, // 审批中
				currentLevel: 0, //审批人层级
				appId: '',
			},
			// orderType: '', // 订单类型
			hotelOrderInfo: '', //订单信息
			orderStatus: '',
			hotelRefundInfo: '', //退订信息
			everyPriceList: [],
			isSpStatus: false,
			statusList: [],
			orderIdRes: '', //酒店退订拿到的退订id，借此获取酒店id
			resetspjd:false,
		}
	},
	computed: {
		query() {
				return this.$route.query || {}
			},
			appType() {
				return this.query.appType
			},

			// 获取订单id
			orderId() {
				return this.query.orderId
			},
			// 获取订单类型
			orderType() {
				return this.query.orderType || '11008'
			},
			workItemId() {
				return this.query.workItemId
			},
	},
	created() {
		// this.orderId = this.$route.query.orderId;
		// this.orderType = this.$route.query.orderType;
		// this.appType = this.$route.query.appType;
		// this.workItemId = this.$route.query.workItemId;
		this.getHtRefundDetail(),
			this.getAppProgress()
	},
	mounted() {

	},
	methods: {
		jumpSpeed() {
			let routeUrl = this.$router.resolve({ name: 'HtCnRefundSpeed', query: { orderId: this.orderId } });
			window.open(routeUrl.href, "_blank")
		},
		getStatus() {
			let vipStatus = this.hotelRefundInfo.vipStatus;
			let zflx = this.hotelRefundInfo.zflx;
			// let orderStatus=this.hotelOrderInfo.orderStatus;
			let htRefundStatus = this.hotelRefundInfo.htRefundStatus
			//    willApproval("1","待送审"), //待送审
			// approvaling("2","审批中"), //审批中
			// willHandle("3","待办理"), //待办理
			// handling("4","办理中"), //办理中
			// refundRoom("5","已退房"),//已退票
			// refundMoney("6","已退款"),//已退票
			// refundCancel("7","已取消") //退票被取消
			if (vipStatus != '4') {
				this.statusList = [
					{ status: "1", name: '送审' },
					{ status: "2", name: '申请已提交' },
					{ status: "3", name: '办理中' },
					{ status: "4", name: '已退房' },
					{ status: "5", name: '已退款' },
				]
				if (htRefundStatus == 1 || htRefundStatus == 2) {
					this.orderStatus = 1
				} else if (htRefundStatus == 3) {
					this.orderStatus = 2
				} else if (htRefundStatus == 4) {
					this.orderStatus = 3
				} else if (htRefundStatus == 5) {
					this.orderStatus = 4
				} else if (htRefundStatus == 6) {
					this.orderStatus = 5
				} else if (htRefundStatus == 7) {
					this.orderStatus = 5
				}
			} else if (vipStatus == 4) {
				this.statusList = [
					{ status: "1", name: '申请已提交' },
					{ status: "2", name: '办理中' },
					{ status: "3", name: '已退房' },
					{ status: "4", name: '已退款' },
				]
				if (htRefundStatus == 3) {
					this.orderStatus = 1
				} else if (htRefundStatus == 4) {
					this.orderStatus = 2
				} else if (htRefundStatus == 5) {
					this.orderStatus = 3
				} else if (htRefundStatus == 6) {
					this.orderStatus = 4
				} else if (htRefundStatus == 7) {
					this.orderStatus = 0
				}
			}

		},
		// 重新渲染详情
		resetDetail() {
			this.$router.replace({
					path: this.$dict.detailConfig(11008),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
			// 	return 
			// this.close()
			this.getHtRefundDetail();
			this.resetspjd = true;
		},
		// 审批进度
		showProgress(info, currentSpjd, status) {
			// this.spjd = info.spjd; //当前审批进度（1/2）
			this.detail.currentLevel = currentSpjd; //当前到谁审批
			this.detail.flowState = status;  // 当前审人状态
			this.getCurrentSprLevel()
		},
		// 获取当前审批人层级列表
		getCurrentSprLevel() {
			let params = {
				spgzId: this.detail.appId
			}
			if (params.spgzId) {
				this.$conn.getConn('commu.appRulesDetail')(params, (res) => {
					let data = res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				}, (err) => {

				})
			}

		},
		// 获取审批人列表
		getGznrList(list) {
			if (list.length > 0) {
				// 遍历审批人
				for (var i = 0; i < list.length; i++) {
					// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
					if (list[i].level == this.detail.currentLevel) {
						// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
						for (var j = 0; j < list[i].empList.length; j++) {
							if (list[i].empList[j].empId == this.empId && this.detail.flowState == 3) {
								this.isSpStatus = true;
							} else {
								this.isSpStatus = false;
							}
						}
					}
				}
			}

		},
			// 操作  16 撤回
		orderCaoZuo(info, type) {
			if (type == 16) {
				this.goToCancelApprove({
					orderId:info.orderId,
					orderType: 11008,
				}, (res) => {

					this.getHtRefundDetail();
				}, (err) => {

				})
			}
		},
		sendApp(info) {
			var queryParams = {};
			queryParams = {
				orderId: info.orderId,
				orderType: 11008,
				spgzId: info.appId,
			};
			this.$confirm('您确定要送审吗?', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$conn.getConn('commu.sendApp')(queryParams, (res) => {
					this.$message({
						type: 'success',
						message: '送审成功'
					});
					this.getHotelOrder();
				}, (err) => {
					this.$message({
						type: 'error',
						message: err.tips
					});
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '关闭取消'
				});
			});
		},

		//酒店退订详情
		getHtRefundDetail() {
			this.$conn
				.getConn('htRefund.htRefundDetail', { error: true })({
					hotelRefundId: this.orderId,
				})
				.then((res) => {
					this.orderIdRes = res.data.orderId
					this.hotelRefundInfo = res.data
					this.getHotelOrder()
					this.getStatus()
				})
		},

		//取消订单调用接口
		hotelCacel() {
			this.$conn
				.getConn('hotelOrder.hotelOrderCancel', { jsfile: false })({
					orderFrom: '10503',
					orderId: this.hotelOrderInfo.orderId,
				})
				.then((res) => {
					this.getHotelOrder()
					this.$message({
						type: 'success',
						message: '取消成功!',
					})
				})
				.catch((err) => {
					this.$message({
						type: 'error',
						message: err.tips,
					})
				})
		},

		// 关闭本页面
		close() {
			window.close()
		},
		// 获取单号字体颜色
		// willApproval("1", "待送审"),
		// approvaling("2", "审批中"),
		// willPay("3", "待支付"),
		// willConfirm("4", "待确认"),
		// confirmed("5", "已确认"),
		// completed("6", "已完成"),
		// canceled("7", "已取消"),
		// partHtRefund("8", "部分退房"),
		// allHtRefund("9", "全部退房"),;
		getTextColor() {
			if (this.hotelOrderInfo?.orderStatus) {
				let styleObj = {}

				return (styleObj = {
					color: '#FFA558',
				})
			}
		},
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (
				columnIndex === 0 ||
				columnIndex === 3 ||
				columnIndex === 4 ||
				columnIndex === 5
			) {
				//用于设置要合并的列
				if (rowIndex === 0) {
					//用于设置合并开始的行号
					return {
						rowspan: this.everyPriceList.length, //合并的行数
						colspan: 1, //合并的列数，设为０则直接不显示
					}
				} else {
					return {
						rowspan: 0,
						colspan: 0,
					}
				}
			}
		},
		getHotelOrder() {
			//获取当前订单详情
			this.$conn
				.getConn('hotelOrder.hotelOrderDetail', { jsfile: false })({
					orderId: this.orderIdRes,
				})
				.then((res) => {
					this.hotelOrderInfo = res.data
					this.detail.appId = this.hotelOrderInfo.appId || '';
					this.everyPriceList = res.data.roomList[0].everyPriceList
					this.everyPriceList[0].fxmc = res.data.fxmc
					this.everyPriceList[0].fwf = res.data.fwf
					this.everyPriceList[0].rooms = res.data.rooms
					this.everyPriceList[0].totalPrice = res.data.totalPrice
					console.log(this.everyPriceList)
				})
		},
		getAppProgress() {
			this.$conn
				.getConn('commu.appProgress', { jsfile: false })({
					orderId: this.orderId,
					orderType: this.orderType,
				})
				.then((res) => {
					console.log(res, 'res')
				})
		},
	},
	filters: {
		breakfastTypeName(val = 0) {
			let retStr = '';
			if (val == 1 || val == 2) {
				retStr = val + '份早';
			} else if (val > 2) {
				retStr = '多份早';
			} else {
				retStr = '不含早'
			}
			return retStr
		}
	}
}
</script>

<style scoped="scoped" lang="scss">
.padButton {
	padding: 10px 30px;
	font-size: 15px;
}

.text-right {
	justify-content: end;
}

.b-t-1 {
	border: none;
	border-top: 1px solid #e6e6e6;
}

.p-r-20 {
	padding-right: 20px;
}

.textLeft {
	text-align: left;
}

.marTop20 {
	margin-top: 20px;
}

.between {
	justify-content: space-between;
}

.flex-column {
	display: flex;
	justify-items: center;
}

.tkod-main {
	margin: 0 auto;
	width: 1200px;
	padding-top: 20px;
	padding-bottom: 50px;

	.tk-main {
		display: flex;
		margin-bottom: 10px;
		min-height: 80px;
		border: 1px solid #e6e6e6;
		box-sizing: border-box;

		.left-main-width {
			width: 160px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #fafafa;
			border-right: 1px solid #e6e6e6;
		}

		.left-main-right {
			flex: 1;
			padding-left: 40px;
			display: flex;
			align-items: center;
		}

		.rooms-right {
			flex: 1;
			width: 100%;
			flex-direction: column;
			display: flex;
			align-items: center;

			.romms-info {
				padding-left: 40px;
				border-bottom: 1px solid #e6e6e6;
				width: 100%;
				min-height: 80px;
				display: flex;
				// justify-content: center;
				align-items: center;
			}

			.romms-info:nth-child(2) {
				border-bottom: none;
				margin-bottom: 20px;
			}

			.romms-info:nth-child(3) {
				border-bottom: none;
			}
		}
	}

	.flex {
		display: flex;
	}
}
</style>
