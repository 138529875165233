<template>
	<div class="tc-main" :style="{'min-height':height+'px'}">
		<div class="main">
			<train-change-person  ref="trainChangePerson" :allData="trChangeData" @seatInfo="seatInfo"  @chooseChangeList="getChangeList" @chooseTrainInfo="changeTrainInfo"></train-change-person>

			<yj-contact-info :linkInfo="trChangeData"></yj-contact-info>
			
			<yj-reason :params="143" :marginTop="'30px'" :orderType="11011" @refundChangeReason="getReason"></yj-reason>
			<div style="padding: 45px 0 ;">
				<el-button class="w240 btn" @click="cancel">取消</el-button>
				<el-button class="w240 btn"  type="primary" @click="submitChange">
					<span v-if="loading==true">
						<span class="el-icon-loading"></span>
						改签申请中....
					</span>
					<span v-if="loading==false">提交改签申请</span>
				</el-button>
			</div> 
		</div>
		
		<!-- 12306 -->
		<train-login
			:showTrLogin="false" 
			:orderVisible="visible"
			:checkVisible="checkVisible"
			:cusTomerSendSmsProp="cusTomerSendSmsProp"
			:isQueryOrderAccount="isQueryOrderAccount"
			:orderInfo="orderInfo"
			@needSuccess="needSuccess" 
			@loginSuccess="loginSuccess" 
			@cancelLogin="cancelLogin"
			class="orderTrLogin"
		></train-login>
	</div>
</template>

<script>
	import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';
import { mapMutations } from 'vuex';
import TrainChangePerson from "./trTGComponent/TrainChangePerson";
	
	export default{
		name: 'TrainChange',
		data() {
			return { 
				trChangeData: {}, // 可改签乘车人信息
				chooseChangeTrainInfo: {}, //选中火车票改签信息
				changeTrainParams: {}, // 提交火车票改签参数
				psgIds:'', //改签人ID
				loading: false,
				tripType:1,
				orderId:'',
				visible: false, //是否显示 12036 登录页面
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				},
				reasonObj:{},
			}
			
		},
		components:{
			'train-change-person': TrainChangePerson,
			// 'refund-change-person':RefundChangePerson,
			'train-login':TrainLogin,
		},
		computed:{
			
			height(){
				let height = document.body.clientHeight;
				return height;
			}
		},
		created() {
			let query = this.$route.query || {};
			this.orderId = query.orderId;
			this.getApplyTrChange()
			
		},
		methods: {
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SetCxrList: 'train/setCxrList',
			}),
			// 获取  可改签的乘车人
			getApplyTrChange(){
				let obj = {
					orderId: this.orderId,
				};
				this.$conn.getConn('trChange.applyTrChange')(obj).then((res)=>{
					let data = res.data || {};
					this.tripType = data.tripType; 
					var obj = {};
					data.changePsgList.forEach(item=>{
						item.checked = false;
						if(data.changePsgList.length == 1){
							item.checked = true;
						}
						return item
					});
					
					this.trChangeData = data;
					if(data.tripType == 1 && data.ccsqdId){
						//出差申请单预订
						obj = {
							ccsqdId: data.ccsqdId,
							routeId: data.routeId,
						}
						
					}else if(data.tripType == 1 && !data.ccsqdId){
						//单订模式预订	
						obj = {
							ccsqdId: "",
							routeId: "",
						}
					}else if(data.tripType==2){
						//因私预订
						obj = {
							ccsqdId: "",
							routeId: "",
						}
					}
					this.SET_SearchData(obj)
					
					this.trChangeData.timestamp = res.timestamp;
				}).catch((err)=>{
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
			},
			// 获取选择的坐席
			seatInfo(seatInfo={}) {
				// 获取座位
				let seatIdList = seatInfo.seatIdList || [];
				// 选座 范围:1A-1F 2A-2F 几个人就几个(一个人时,不允许出现2A-2F) ,
				this.changeTrainParams.chooseSeats = seatIdList.length > 0 ? seatIdList.join(",") : ''; 
			},
			// 取消 关闭本页面
			cancel(){
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							  type: 'warning'
				        }).then(() => {
							// this.$common.sessionremove('trRefundData')
							window.close();
				        }).catch(() => {
				            this.$message({
				            type: 'info',
				            message: '已取消关闭'
				        });          
				});
			},
			// 火车票改签信息
			changeTrainInfo(info){
				this.chooseChangeTrainInfo = info;
				
				if(JSON.stringify(info)=='{}'){
					return
				}
				// this.changeTrainParams = {};
				let newToDate = '';
				let acceptNoseat = 0;
				if(info.arriveDays!=0){
					newToDate = this.$dateTime.getAppointDay(info.trainStartDate,info.arriveDays);
				}else{
					newToDate = info.trainStartDate;
				}
				if(info.selectSeat.seatName=="无座"){
					acceptNoseat=1;
				}else{
					acceptNoseat=0;
				}
				
				this.changeTrainParams = {
				  "acceptNoseat": acceptNoseat,
				  "appId": "",
				  "changeProveUrl": "",
				  "changeReasonVip": this.changeTrainParams.changeReasonVip || '',
				  "newFromDate": info.trainStartDate,
				  "newFromStationCode": info.fromStationCode,
				  "newFromTime": info.startTime,
				  "newSeatType": info.selectSeat.seatType,
				  "newTicketPrice": info.selectSeat.price ,
				  "newToDate": newToDate,
				  "newToStationCode": info.toStationCode,
				  "newToTime": info.arriveTime,
				  "newTrainCode": info.trainCode,
				  "newTrainNo": info.trainNo,
				  "newTravelDistance": info.distance,
				  "orderFrom": 10503,
				  "orderId": this.orderId,
				  "psgIds": this.psgIds,
				  "trainId": info.trainId || ''
				}
				
			},
			// 获取改签原因
				// gaiQianReasonGk
				// imageUrl
				// isVoluntarily
				// reasonId
				// reasonName
				// changeReasonVip
			getReason(obj){
				//自愿改签 
				if(obj.isVoluntarily == 1){
					// 如果企业开通了手动填写参数
					if(this.gaiQianReasonGk == 1){
						this.changeTrainParams.changeReasonVip = obj.changeReasonVip || '';
					}else{
						this.changeTrainParams.changeReasonVip = obj.reasonName || '';	
					}
				}else{
					this.changeTrainParams.changeReasonVip = obj.reasonName || '';	
				}
				this.reasonObj = obj || {};			
			},
			// 获取选中的改签人列表
			getChangeList(allData){
				let empRankList = [];
				let psgIdList = [];
				allData.changePsgList.forEach(item=>{
					if(item.checked){
						psgIdList.push(item.psgId)
						empRankList.push(item);
					}
				})
				var empObj = this.$common.getMinEmpRank(empRankList);
				if(allData.tripType == 1){
					this.SET_SearchData({'empRank':empObj.empRank})
				}
				this.psgIds = psgIdList.join(',');
				this.changeTrainParams.psgIds = this.psgIds;
			},
			// 验证改签参数
			vailChangeParams(){
			
				var needApprove = this.$refs['trainChangePerson']['needApprove'];
				var chooseSpgz = this.$refs['trainChangePerson']['chooseSpgz'] || {};
				var spgzList = this.$refs['trainChangePerson']['spgzList'] || [];
				var obj = {
						errMsg:'',
						bool: true
					}
				let changeTrainParams = this.changeTrainParams;
				if(!this.changeTrainParams.changeReasonVip){
					let tips = "请选择改签原因"
					if(this.reasonObj.isVoluntarily == 1 && this.reasonObj.gaiQianReasonGk == 1){
						tips = "根据公司差旅政策规定，改签单必须填写改签原因，才可提交改签"
					}
					obj.errMsg = tips;
					obj.bool = false;
					return obj
				}else if(JSON.stringify(this.chooseChangeTrainInfo)=='{}'){
					obj.errMsg = "请选择改签车次";
					obj.bool = false;
					return obj
				}else if(!this.psgIds){
					obj.errMsg = "请选择乘车人";
					obj.bool = false;
					return obj
				}else if(needApprove && !chooseSpgz.gzId && spgzList.length>0 && this.tripType==1){
					obj.errMsg = "请选择审批规则";
					obj.bool = false;
					return obj
				}
				this.changeTrainParams.appId = chooseSpgz.gzId;
				return obj
			},
			//提交改签申请
			submitChange(){
				if(this.loading){
					return
				}
				let result = this.vailChangeParams();
				if(!result.bool){
					this.$alert(result.errMsg,'提示',{
						type:'error',
					})
					return false
				}
				
				this.loading = true;
				
				var trParams = this.changeTrainParams;
				trParams.continueBook = trParams.continueBook || 0
				this.submitChangeAjax(trParams);
				
			},
			submitChangeAjax(trParams) {
				this.$conn.getConn('trChange.trChangeOrderAdd')(trParams,(res)=>{
					let data =  res.data || {};
					this.loading = false;
							
					this.$router.push({path:'/completed',query:{orderId:this.orderId,changeId:data.changeId,orderType:11011,}})
				},(err)=>{
					this.loading = false;  
					if (err.errCode == '7514' || err.errCode == '7515') {
						this.$confirm(err.tips, '提示', {
							confirmButtonText: '继续',
							cancelButtonText: '取消',
							showCancelButton: false,
							closeOnClickModal: false,
							type: 'warning'
						}).then((v) => {
							this.changeTrainParams.continueBook = 1 // 继续预订
							this.submitChange()
						}).catch(() => {
									 
						})
					} else {						
						if (err.errCode == '7527') {
						  // 重新登录
						  this.checkLogin(err.errCode);
						} else if (err.errCode == '7524' || err.errCode == '7530') {
						  // 需要核验
						  this.checkLogin(err.errCode);
						} else {						
							this.$alert(err.tips,'温馨提示',{
								type: 'error' 
							})
						}
					}
				})
			},
			// 12306核验
			checkLogin(errCode){
				let query = this.$route.query || {};
				this.orderId = query.orderId;
				this.orderInfo = {
					orderId: query.orderId,
					orderType: 11009,
					errCode: errCode
				};				
				setTimeout(()=>{
					if (errCode == 7530 || errCode == 7524) {
						this.checkVisible = true;
						this.cusTomerSendSmsProp = errCode == 7530? false: true;
					} else {
						this.visible = true;
					}
				},200)	
			},
			//登录成功
			loginSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 核验成功 重置订单详情
			needSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 取消登录
			cancelLogin(){
				this.loginSuccess();
			}
		}
	}
</script>

<style scoped lang="scss">
	.tc-main{
		font-family: '微软雅黑';
		background-color: #EEF1F6;
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
		}
		.box-card{
			margin-top: 28px;
		}
		.btn{
			width: 240px;
			height: 64px;
			box-sizing: border-box;
			font-size: 18px;	
			font-weight: 600;
		}
	}
</style>
