<template>
	<div class="bg">
		<Header :compInfo="compInfo"></Header>
		<pure class="xak-po"  :compInfo="compInfo"></pure>
		<div class="xak_bottom" style="padding-bottom:40px;color:#999999;">
			<a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_blank"  class="xak_copy">
				<!-- Copyright©2021希安康国际旅行社（北京）有限公司 京ICP备13007500号-1 -->
				{{compInfo.etCopyrightInformation}}
			</a> 
			<div class="text-center ">咨询电话：{{compInfo.etServicePhone}}</div>	
		</div>
		<yj-mask v-if="!isShowLoginPage"></yj-mask>
	</div>
</template>
<script>
	import Header from "./xakLogin/Header";
	import Pure from "./xakLogin/Pure";
	import loginMixin from "./../../mixin/models/loginMixin.js"
	export default {
		name: "XakLogin",
		mixins:[loginMixin],
		data() {
			return {
				isShowLoginPage:false, // 是否显示登录页
				compInfo: {
				},
			}
			
		},
		components: {
			'Header': Header,
			'pure':Pure,
		},
		computed:{
			
		},
		mounted() {
			this.initPageSet();
			this.getSsoLogin();
			this.getEtPageSetList()
		},
		methods: {
			
		},
		
	};
</script>
<style scoped lang="scss">
	.bg{
		.xak-po{
			margin-top: -35px;
		}
		.xak_bottom{
			margin-top: 50px;
			text-align: center;
			font-size: 14px;
			line-height: 30px;
			background-color: transparent;
			color: #323232;
			.xak_copy{
				color:#999999;
				text-decoration: none;
			}
			.xak_copy:hover{
				text-decoration: underline;
			}
		}
	}

	
</style>
