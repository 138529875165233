<template>
	<div class="itedit">
		<div class="inHeader">
			国际机票需求单
		</div>
		<div class="main">
			<div class="inmain">
				<el-form :model="ruleForm"  ref="ruleForm" label-width="30px" class="demo-ruleForm">
					<el-form-item>
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">行程信息</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>差旅类型</span>
							</span>
							<span class="ygys" :class="{active:ruleForm.tripType==1}" @click="changeTripType(1)">因公</span>
							<span class="ygys" :class="{active:ruleForm.tripType==2}" @click="changeTripType(2)">因私</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>航程类型</span>
							</span>
							<span class="ygys" :class="{active:ruleForm.voyageType==1}" @click="selectVoyage(1)">单程</span>
							<span class="ygys" :class="{active:ruleForm.voyageType==2}" @click="selectVoyage(2)">往返</span>
							<span class="ygys" :class="{active:ruleForm.voyageType==3}" @click="selectVoyage(3)">多程</span>
						</div>
						<div v-for="(item, index) in tkNeedRangesList" :key="index"  class="dxCity">
							<span class="xcTitle">
								<span class="redx" >*</span>
								<span>行程{{index+1}}</span>
							</span>
								
							
							<span class="cstext">出发城市</span>
							<yj-tk-city
								@chooseCity="chooseDepCity"  
								:keywords="item.depInTkName"
								>
								<div slot="referenceContent">
									<el-input size="large"
										class="cityInput"
										v-model="item.departCityName"
										prefix-icon="iconfont yj-icon-airplane-full"
										@input="queryDepCityName(item.departCityName,index)"
										@click.native="getNeedIndex(index)"
										placeholder="中文/拼音/三字码"
										>	
									</el-input>
								</div>
							</yj-tk-city>		
							<span class="cstext marL30">到达城市</span>
							<yj-tk-city
								@chooseCity="chooseArrCity"  
								:keywords="item.arrInTkName"
								>
								<div slot="referenceContent">
									<el-input  size="large"
											class="cityInput"
											prefix-icon="iconfont yj-icon-airplane-full"
											v-model="item.arriveCityName"
											@input="queryArrCityName(item.arriveCityName,index)"
											@click.native="getNeedIndex(index)"
											placeholder="中文/拼音/三字码"
										>
									</el-input>
								</div>
							</yj-tk-city>
							<span class="cstext marL30">出发时间</span>
							<el-date-picker
								class="setCityInput"
							    v-model="item.departTime"
							    type="date"
								value-format="yyyy-MM-dd"
								:picker-options="pickerOptions"
							    placeholder="选择出发日期">
							</el-date-picker>
							<span class="cstext marL30" v-if="ruleForm.voyageType==2">返程时间</span>
							<el-date-picker
								v-if="ruleForm.voyageType==2"
								:disabled="ruleForm.voyageType==1"
								class="setCityInput"
							    v-model="wfTime"
								value-format="yyyy-MM-dd"
							    type="date"
								:picker-options="pickerOptions"
							    placeholder="选择返程日期">
							</el-date-picker>
							<div class="addvoyage" v-if="ruleForm.voyageType==3 && index==0" @click="addVoyageData">增加一程</div>
							<div class="removevoyage" v-if="ruleForm.voyageType==3 && index>0"  @click="removeVoyageData(index)">删除</div>
							
						</div>
							
						
					</el-form-item>
					<el-form-item>
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">乘客人数</span>
						</div>
						<label>成人<el-input-number v-model="ruleForm.adultNum" :min="0" class="marL15"></el-input-number></label>
						<label class="padL20">儿童(2-12)<el-input-number v-model="ruleForm.childNum" :min="0" class="marL15"></el-input-number></label>
						<label class="padL20">婴儿(2岁以下)<el-input-number v-model="ruleForm.babyNum" :min="0" class="marL15"></el-input-number></label>
					</el-form-item>
					<el-form-item>
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">乘客类型</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>乘客类型</span>
							</span>
							<span class="ygys w94" :class="{active:ruleForm.psgType==1}" @click="selectPsgType(1)">普通成人</span>
							<span class="ygys w94" :class="{active:ruleForm.psgType==2}" @click="selectPsgType(2)">留学生</span>
							<span class="ygys w94" :class="{active:ruleForm.psgType==3}" @click="selectPsgType(3)">劳务</span>
							<span class="ygys w94" :class="{active:ruleForm.psgType==4}" @click="selectPsgType(4)">新移民</span>
						</div>
						
						
					</el-form-item>
					<el-form-item>
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">舱位类型</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>舱位类型</span>
							</span>
							<span class="ygys w94" :class="{active:ruleForm.cabin==1}" @click="selectCabin(1)">经济舱</span>
							<span class="ygys w94" :class="{active:ruleForm.cabin==2}" @click="selectCabin(2)">公务舱</span>
							<span class="ygys w94" :class="{active:ruleForm.cabin==3}" @click="selectCabin(3)">头等舱</span>
							<span class="ygys w94" :class="{active:ruleForm.cabin==4}" @click="selectCabin(4)">不限舱位</span>
						</div>
					</el-form-item>
					<el-form-item class="qwprice">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">期望价格</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>期望价格</span>
							</span>
							<el-input v-model="ruleForm.expectBudget" placeholder="请输入期望价格"></el-input>
							<span class="words">元</span>
							<span class="words2">（期望金额作为预订参考，不是实际价格）</span>
						</div>
					
					</el-form-item>
					<el-form-item class="link">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">联系人信息</span>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span class="redx">*</span>
								<span>联系人</span>
							</span>
							
							<el-input v-model="ruleForm.linkman" placeholder="请输入联系人" style="width: 100px;"></el-input>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span class="redx">*</span>
								<span >联系电话</span>
							</span>
							<el-input v-model="ruleForm.linkTel" placeholder="请输入联系电话"></el-input>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span style="padding-right: 16px;"></span>
								<span >联系邮箱</span>
							</span>
							<el-input v-model="ruleForm.linkEmail" placeholder="请输入联系邮箱"></el-input>
						</div>
					</el-form-item>
					<el-form-item class="bz">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">联系人信息</span>
						</div>
						<div style="line-height: 14px;padding-bottom: 10px;">
							输入您的特殊需求，例如:指定航空公司，指定航班，出发时间。直飞或转机、价位需求等
						</div>
						<el-input 
							v-model="ruleForm.content" 
							type="textarea" 
							:rows="2" 
							placeholder="输入您的特殊需求"></el-input>
					</el-form-item>
					<el-form-item class="line">
						
					</el-form-item>	
					<el-form-item style="margin-right: 30px;">
						<div class="btn" >
							<p style="line-height: 39px;">您的需求单提交后，客服会在工作时间（8：00-24:00）内处理并与您联系。如有疑问，可拨打24小时 <span style="padding-left:20px">客服电话：{{configData.officalPhone}}</span>	</p>	
							<el-button type="primary" v-if="loading==false" @click="submitNeed">提交需求单</el-button>
							<el-button type="primary" v-if="loading">
								<span class="el-icon-loading"></span>提交中...</el-button>
						</div>
					</el-form-item>	
				</el-form>
			</div>       
		</div>
	</div>
</template>

<script>
	export default{
		name:'InTkEdit',
		data(){
			return {
				loading: false,
				ruleForm:{
					adultNum: 1,  //成人
					babyNum: 0, // 婴儿
					cabin: 1,  //舱位
					childNum: 0, // 儿童
					content: "", //备注
					expectBudget: 0, //期望价格
					linkEmail: "", //联系邮箱
					linkTel: "", //联系电话
					linkman: "", //联系人
					orderFrom: "10503", //订单来源
					psgType:1, //乘客类型
					tkNeedRanges: [], // 航段信息
					tripType: 1, //因公因私
					voyageType: 1, //航程类型 1单程 2往返 3多程
				},
				inIndex: 0, //选择航段列表下标
				voyageList:[ // 航段列表
					{
						id: 1,
						value: '单程',
					},
					{
						id: 2,
						value: '往返',
					},
					{
						id: 3,
						value: '多程',
					},
				],
				wfTime:'',
				pickerOptions: { // 默认当天之前 日期不能选择
				    disabledDate: (time) => {
						return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
				    }
				},
			
				tkNeedRangesList: [
					{
						arriveAirport: "",
						arriveCity: "",                    
						arriveCityName: "",
						departAirport: "",
						departCity: "",
						departCityName: "",
						departTime: this.$common.getToDay(),
						depInTkName:"",
						arrInTkName:"",	
					}
				],// 国际需求单
			}
		},
		computed:{
			userInfo(){
				return this.$common.sessionget('user_info') || {};
			},
			empInfo(){
				return this.userInfo.empInfo || {};
			},
			// 总公司信息
			configData(){
				let configData = this.$common.getConfigData() || {};
				return configData
			},
		},
		mounted(){
			this.ruleForm = {
				linkEmail: this.empInfo.email, //联系邮箱
				linkTel: this.empInfo.phoneNumber, //联系电话
				linkman: this.empInfo.empName, //联系人
				voyageType: 1,
				adultNum: 1,  //成人
				babyNum: 0, // 婴儿
				cabin: 1,  //舱位
				childNum: 0, // 儿童
				orderFrom: "10503",
				expectBudget: 0, //期望价格
				psgType:1, //乘客类型
				tripType: 1, //因公因私
			}
		},
		methods:{
			
			// 因公因私
			changeTripType(e){
				this.ruleForm.tripType = e
			},
			// 选择航段
			selectVoyage(e){
				this.ruleForm.voyageType = e;
				this.tkNeedRangesList = [];
				this.tkNeedRangesList.push(this.getRangeItem())	
			},
			selectPsgType(e){
				this.ruleForm.psgType = e;
			},
			selectCabin(e){
				this.ruleForm.cabin = e;
			},
			// // 获取国际机票下标
			getNeedIndex(index){
				this.inIndex = index;
			},
			// 选择国际出发城市
			chooseDepCity(obj){
				this.tkNeedRangesList.map((item,index)=>{
					if(this.inIndex == index){
						item.departAirport = obj.threeCode || '';
						item.departCity = obj.cityId || '';
						// 带三字码 cityInput 不带三字码 cityName
						item.departCityName = obj.cityName;
					}
					
					return item
				})
				
			},
			// 选择国际到达城市
			chooseArrCity(obj){
				this.tkNeedRangesList.map((item,index)=>{
					if(this.inIndex == index){
						item.arriveAirport = obj.threeCode || '';
						item.arriveCity = obj.cityId || '';
						// 带三字码 cityInput 不带三字码 cityName
						item.arriveCityName = obj.cityName;
					}
					return item
				})
			},
			// 搜索输入触发 强制刷新页面 赋值出发/入住地址
			queryDepCityName(name,index){
				this.tkNeedRangesList.map((item,i)=>{
					if(i==index){
						item.depInTkName = name;
					}
					return item
				})	
			},
			queryArrCityName(name,index){
				this.tkNeedRangesList.map((item,i)=>{
					if(i==index){
						item.arrInTkName = name;
					}
					return item
				})
			},
			getRangeItem(){
				return { // 国际票信息
					arriveAirport: "",
					arriveCity: "",                    
					arriveCityName: "",
					departAirport: "",
					departCity: "",
					departCityName: "",
					departTime: this.$common.getToDay(),
					depInTkName:"",
					arrInTkName:"",
				}
			},
			// 加一航程数据
			addVoyageData(){
				this.tkNeedRangesList.push(this.getRangeItem());
			},
			// 减一航程数据
			removeVoyageData(index){
				this.tkNeedRangesList.splice(index,1);
			},
			// 验证需求单参数
			getVaild(){
				var errMsg = "";
				if(this.tkNeedRangesList.length>0){
					this.ruleForm.tkNeedRanges = this.$common.deepCopy(this.tkNeedRangesList) || [];
					var list = this.tkNeedRangesList
						list.forEach((item,index)=>{
							if(this.ruleForm.voyageType==3){
								if(!item.departAirport){
									errMsg = "第"+index+"项请选择出发城市";
								}else if(!item.arriveAirport){
									errMsg = "第"+index+"项请选择到达城市";
								}else if(!item.departTime){
									errMsg = "第"+index+"项请选择出发时间";
								}
							}else if(this.ruleForm.voyageType==2){
								if(!item.departAirport){
									errMsg = "请选择出发城市";
								}else if(!item.arriveAirport){
									errMsg = "请选择到达城市";
								}else if(!item.departTime){
									errMsg = "请选择出发时间";
								}else if(!this.wfTime){
									errMsg = "请选择返回时间";
								}else if(new Date(this.ruleForm.tkNeedRanges[0].departTime).getTime()>new Date(this.wfTime).getTime()){
									errMsg = "返程日期不可小于出发日期";
								}
							}else if(this.ruleForm.voyageType==1){
								if(!item.departAirport){
									errMsg = "请选择出发城市";
								}else if(!item.arriveAirport){
									errMsg = "请选择到达城市";
								}else if(!item.departTime){
									errMsg = "请选择出发时间";
								}
							}
						})
				}
				if(this.ruleForm.voyageType==2){
					let inTkInfo = {};
									
					this.tkNeedRangesList.forEach(item=>{
						inTkInfo = {
							arriveAirport: item.departAirport,
							arriveCity: item.departCity,                    
							arriveCityName: item.departCityName,
							departAirport: item.arriveAirport,
							departCity: item.arriveCity,
							departCityName: item.arriveCityName,
							departTime: this.wfTime,
						}
						this.ruleForm.tkNeedRanges.push(inTkInfo);
					})			
				}
				
				if(!this.ruleForm.linkman){
					errMsg = "请输入联系人"
				}else if(!this.ruleForm.linkTel){
					errMsg = "请输入联系电话"
				}else if(this.ruleForm.adultNum==0 && this.ruleForm.babyNum==0 && this.ruleForm.childNum==0){
					errMsg = "请添加乘客人数"
				}
				
				if(errMsg){
					this.$alert(errMsg,'',{
						type: 'error'
					})
					return false;
				}
				
				return true
			},
			// 提交需求单
			submitNeed(){
				var bool = this.getVaild();
				if(!bool){
					return 
				}
				this.loading = true;
				let tkNeedOrderAdd = this.$conn.getConn('tkInter.tkNeedOrderAdd')
				tkNeedOrderAdd(this.ruleForm,(res)=>{
					this.loading = false;
					let query = {
						orderId: res.data.orderId,
					}
					this.$router.push({path:'/yjDetail/inTkDetail',query:query})
					
				},(err)=>{
					this.loading = false;
					this.$alert(err.tips,'提示',{
						type: 'error'
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.itedit {
		width: 100%;
		background-color: #eef1f6;
		padding: 30px 0;
		font-family: '微软雅黑';
		.inHeader{
			margin: 0 auto;
			width: 1200px;
			height: 32px;
			font-size: 23px;
			font-weight: 800;
			color: #282828;
			line-height: 32px;
			text-align: left;
			margin-bottom: 20px;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.main {
			width: 1200px;
			margin: 0 auto;
			padding: 20px 0;
			background-color: #FFFFFF;
			
			.inmain{
				text-align: left;
				padding:15px 0;
				.title{
					display: flex;
					margin-bottom: 20px;
					.leftsk{
						display: inline-block;
						width: 6px;
						height: 19px;
						background: #007AFF;
						margin-top: 2px;
						margin-right:10px;
					}
					.text{
						height: 24px;
						font-size: 18px;
						font-weight: 500;
						color: #000000;
						line-height: 24px;
					}
				}
				.textCon{
					margin-bottom: 20px;
					.textbt{
						display: inline-block;
						width: 91px;
						font-size: 14px;
						font-weight: 400;
						color: #424242;
						line-height: 19px;
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					.ygys{
						display: inline-block;
						width: 67px;
						height: 34px;
						background: #FFFFFF;
						border: 1px solid #CCCCCC;
						text-align: center;
						margin-right: 10px;
						cursor: pointer;
					}
					.w94{
						width: 94px;
					}
					.active{
						border: 1px solid #007AFF;
						color: #007FE9;
						transition: all 0.3s ease;
					}
				}
				.citytext{
					display: flex;
					margin-bottom: 20px;
					.citybt{
						display: inline-block;
						width: 91px;
						font-size: 14px;
						font-weight: 400;
						color: #424242;
						line-height: 34px;
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					/deep/ .el-input__inner{
						width: 243px;
						height: 34px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #DFDFDF;
						border-radius: 0px;
					}	
				}
				
				.padL20{
					padding-left: 20px;
				}
				.marL15{
					margin-left: 15px;
				}
				/deep/ .el-textarea__inner{
					border-radius: 0px;
				}
				.dxCity{
					display: flex;
					margin-bottom: 20px;
					.xcTitle{
						display: inline-block;
						width: 91px;
						.redx{
							color: red;
							padding-right: 10px;
						}
					}
					.marT20{
						margin-top: 20px;
					}
					.cstext{
						width: 60px;
						display: inline-block;
					}
					.marL30{
						margin-left: 30px;
					}
					.el-input{
						width: 155px;
						margin-left: 15px;
					}
					
					.cityInput{
						/deep/ .el-input__inner{
							border-radius: 0px;
							height: 34px;
							line-height: 34px
						}
						/deep/ .el-input__icon{
							line-height: 34px;
						}
					}
					.setCityInput{
							height: 34px;
							line-height: 34px;
						/deep/ .el-input__inner{
							height: 34px;
							line-height: 34px;
							border-radius: 0px;
						}
					}
					.addvoyage,.removevoyage{
						margin-left: 30px;
						font-size: 14px;
						line-height: 34px;
						cursor: pointer;
						color: #0076F5;
					}
					.addvoyage:hover{
						opacity: 0.8;
						transition: all 0.3s ease;
					}	
					.removevoyage:hover{
						opacity: 0.8;
						transition: all 0.3s ease;
					}	
				}
					
				
				.line{
					border-top: 1px solid #F0F0F0;
					margin: 39px 0 ;
				}
				.qwprice{
					margin-bottom: 18px;
					.el-input{
						width: 125px;
						/deep/ .el-input__inner{
							border-radius: 0px;
						}
					}
					.words{
						padding: 0 5px;
					}
					.words2{
						font-size: 14px;
						color: #aaaaaa;
					}
				}
				.link{
					.lspan{
						display: inline-block;
						width: 91px;
						.prdR10{
							padding-right: 10px;
						}
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					.el-input{
						width: 200px;
						margin-right: 20px;
						/deep/ .el-input__inner{
							border-radius: 0px;
						}
					}
				}
				.bz{
					.el-textarea{
						width: 70%;
					}
				}
				.btn{
					display: flex;
					justify-content: space-between;
					.el-button {
						width: 200px;
						height: 39px;
						font-size: 16px;
					}
				}
			}
			
		}
	}
</style>
