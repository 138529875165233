<template>
	<div class="indetail">
		<el-card class='crad'>
			<div class="header">
				<p>订单信息</p>
				<p>需求单状态：
					<span v-if="orderDetail.orderStatus==1">待处理</span>
					<span v-if="orderDetail.orderStatus==2">已转单</span>
					<span v-if="orderDetail.orderStatus==3">已忽略</span>
				</p>
			</div>
			<div class="incontent">
				<div class="inLeft">
					行程信息
				</div>
				<div class="inRight" :class="{padTB20:length>1}" >
					<div v-for="(item, index) in orderDetail.tkNeedOrderRanges" :key="index" 
						class="text"
						:style="{'line-height':length==1?78+'px':30+'px',}">
						<div><span>出发/到达城市：</span> {{item.departCityName}} - {{item.arriveCityName}} 
							<span style="padding-left: 30px;">出发时间：</span>{{item.departTime}}
						</div>
					</div>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					席别
				</div>
				<div class="inRight lineH78 text" >
					坐席：
					<span v-if="orderDetail.cabin==1">经济舱</span>
					<span v-if="orderDetail.cabin==2">公务舱</span>
					<span v-if="orderDetail.cabin==3">头等舱</span>
					<span v-if="orderDetail.cabin==4">不限舱位</span>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					出行人数
				</div>
				<div class="inRight lineH78 text" >
					<span>成人数：{{orderDetail.adultNum}}</span>
					<span class="text">儿童数：{{orderDetail.babyNum}}</span>
					<span class="text">婴儿数：{{orderDetail.childNum}}</span>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					乘客类型
				</div>
				<div class="inRight lineH78 text" >
					乘客类型：
					<span v-if="orderDetail.psgType==1">普通成人</span>
					<span v-if="orderDetail.psgType==2">留学生</span>
					<span v-if="orderDetail.psgType==3">劳务</span>
					<span v-if="orderDetail.psgType==4">新移民</span>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					其他信息
				</div>
				<div class="inRight bzxx text" >
					<div>期望价格：<span style="color:#f5a337;">{{orderDetail.expectBudget}}￥</span></div>
					<div style="display: inline-block;word-break:break-all">
						备注：{{orderDetail.content?orderDetail.content:'无'}}
					</div>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					联系人信息
				</div>
				<div class="inRight lineH78 text" >
					<span>联系人：{{orderDetail.linkman}}</span>
					<span class="text">联系电话：{{orderDetail.linkTel}}</span>
					<span class="text">联系邮箱：{{orderDetail.linkEmail}}</span>
				</div>
			</div>
		</el-card>	
	</div>
</template>

<script>
	export default{
		name: 'InTkDetail',
		data() {
			return {
				orderDetail:{},
				length:0,
			}
		},
		mounted(){
			let orderId= this.$route.query.orderId || '';
			this.getDetail(orderId);
		},
		methods: {
			getDetail(orderId){
				var params = {
					orderId: orderId
				};
				let tkNeedOrderDetail = this.$conn.getConn('tkInter.tkNeedOrderDetail')
				tkNeedOrderDetail(params,(res)=>{
					this.orderDetail = res.data || {};
					this.length = this.orderDetail.tkNeedOrderRanges.length
				},(err)=>{
					this.$alert(err.tips,'提示',{
						type:'error'
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.indetail{
		margin: 0 auto;
		width: 1200px;
		.crad{
			margin: 40px 0;
			.header{
				display: flex;
				justify-content: space-between;
				line-height: 40px;
			}
			.incontent{
				display: flex;
				margin-bottom: 10px;
				min-height: 80px;
				border: 1px solid #E6E6E6;
				box-sizing: border-box;
				.inLeft{
					width: 160px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #FAFAFA;
					border-right: 1px solid #E6E6E6;    
				}
				.inRight{
					width: 960px;
					.text{
						text-align: left;
						padding-left: 40px;
					}
				}
				.padTB20{
					padding: 20px 0;
				}
				.lineH78{
					line-height: 78px;
				}
				.bzxx{
					line-height: 30px;
					padding: 20px;
				}
				.text{
					text-align: left;
					padding-left: 40px;
				}
			}
		}
		.button{
			width: 150px;
			height: 50px;
			font-size: 14px;
		}
	}
</style>
