<template>
	<div class="ccsqd">
		<div class="con-div">
			<div class="conl-flex">
				<p class="text">出差申请单</p>
				<el-button type="primary" v-if="ccsqdButton==1" class="el-icon-plus btn" @click="goToCcsqd"> 新建申请单</el-button>
			</div>
			<div class="main-left">
				<el-collapse v-model="activeCollapse" accordion @change="getCcsqdData(activeCollapse)">
					<el-collapse-item v-for="item in ccTypeList" :name="item.id"
						:title="item.title+'('+item.total+')'" :key="item.id">
						<div class="collItem"
							v-loading = "loading"
							element-loading-background = "rgba(255, 255, 255, .5)"
							element-loading-text = "加载中，请稍后..."
							element-loading-spinner = "el-icon-loading"
							>
							<div class="content" v-for="ccItem in item.childrenList" :key="ccItem.ccsqdId">
								<div class="itemIcon">
									<div :class="item.icon"></div>
								</div>
								<div class="item-con">
									<div class="item-con-t">
										<div class="item-con-tl">{{ccItem.ccrAndSxr}}</div>
										<div class="item-con-tc">{{ccItem.dateBegin}} 至 {{ccItem.dateEnd}}</div>
										<div class="item-con-tr">{{ccItem.mdd}}</div>
									</div>
									<div class="item-con-f">
										<div class="item-con-fl">{{ccItem.ccsy}}</div>
										<div class="item-con-fc"><span
												class="textColor">{{ccItem.zys}}</span>(预算)</div>
										<div class="item-con-fr" @click="goToDetail(ccItem,item.id)">详细<span
												class="el-icon-d-arrow-right"></span></div>
									</div>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<el-button type="primary" class="allSee" @click="goToApprovalRecord">
				查看全部
			</el-button>
		</div>
	</div>
</template>

<script>
	import {mapState, mapMutations, mapGetters} from 'vuex';
	export default{
		name: 'CcsqdInfo',
		data(){
			return {
				loading: false,
				activeCollapse: '10', // 展示类型
				ccTypeList: [ // 出差类型列表
					{
						id: '2',
						title: '待预订',
						childrenList: [],
						icon: 'el-icon-odometer',
						total:0,
					},
					{
						id: '1',
						title: '待审批',
						childrenList: [],
						icon: 'el-icon-s-check',
						total:0,
					},
					{
						id: '0',
						title: '草稿',
						childrenList: [],
						icon: 'el-icon-tickets',
						total:0,
					},
					{
						id: '5',
						title: '待报销',
						childrenList: [],
						icon: 'el-icon-document',
						total:0,
					}
				],
				statusList: [2, 1, 0, 5],
			}
		},
		computed:{
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 出差申请单按钮
			ccsqdButton() {			
			   let params = this.getVipParamterByParNo(20023,0)
				return params ;
			},
		},
		mounted(){
			this.getCcsqdStatus();
			this.getCcsqdCount();
		},
		methods:{
			// 获取出差申请单状态
			getCcsqdStatus() {
				// 等到有数据接口，这个就可以删掉了
				for (let i = 0; i < this.statusList.length; i++) {
					this.getCcsqdList(this.statusList[i])
				}
			},
			// 获取出差申请单各项数据条数
			getCcsqdCount(){
				// this.loading = false;
				// this.$conn.getConn('travelRequest.ccsqdGet')(queryParams, (res) => {
				// 	this.ccTypeList.map((item) => {
				// 		if (status == 2 && item.id == '1') {
				// 			item.childrenList = list;
				// 			item.total = data.total || 0;
				// 		} else if (status == 1 && item.id == '2') {
				// 			item.childrenList = list;
				// 			item.total = data.total || 0;
				// 		} else if (status == 0 && item.id == '3') {
				// 			item.childrenList = list;
				// 			item.total = data.total || 0;
				// 		} else if (status == 5 && item.id == '4') {
				// 			item.childrenList = list
				// 			item.total = data.total || 0;
				// 		}
				// 		return item
				// 	})	
					
				// })
			},
			// 获取出差申请单 不同类型数据
			getCcsqdData(e){
				// getCcsqdList(e)
			},
			// 获取出差申请单列表
			getCcsqdList(status) {
				let queryParams = {};
				queryParams = {
					count: 100,
					pageNum: 1,
					status: status,
				}
				if(status==1 || status==0 || status==2){
					let toDay = this.$common.getToDay();
					queryParams.dateBegin = this.$common.getAppointDay(toDay,-30);
					queryParams.dateEnd = this.$common.getAppointDay(toDay,365);
				}
				this.$conn.getConn('travelRequest.ccsqdGet')(queryParams, (res) => {
					let data = res.data || {};
					let list = data.list || [];
					// 等列表数据条数 接口出现， 此处优化一下
					this.ccTypeList.map((item) => {
						if (status == 2 && item.id == '2') {
							item.childrenList = list;
							item.total = data.total || 0;
						} else if (status == 1 && item.id == '1') {
							item.childrenList = list;
							item.total = data.total || 0;
						} else if (status == 0 && item.id == '0') {
							item.childrenList = list;
							item.total = data.total || 0;
						} else if (status == 5 && item.id == '5') {
							item.childrenList = list
							item.total = data.total || 0;
						}
						return item
					})
			
				}, (err) => {
					if(err.errCode=='3901'){
						return
					}
				})
				this.loading= false;
			},
			// 新增出差审清单
			goToCcsqd(){
			    let parsm20033=this.getVipParamterByParNo(20033, 0)  //1标准版,2定制版,默认为1"
				let path = parsm20033==1? "/newCcsqd":'/xakCcsqd';
				let routerUrl = this.$router.resolve({
					path: path,
				})
				window.open(routerUrl.href, "_blank")
			},
			// 跳转详情
			goToDetail(item, num) {
				let query = {};
				
				let path = "";
				if (num == 2 || num == 1 || num == 5) {
					query = {
						orderId: item.ccsqdId,
					    id:item.ccsqdId,
						orderType: '11099'
					}
			       path=item.customizeFlag==2?'/xakCcsqdDetail':'/ccsqdDetail'
				} else if (num == 0) {
					query = {
					    ccsqdId:item.ccsqdId,
					}
			         path=item.customizeFlag==2?'/xakCcsqd':'/newCcsqd'
				}
				let routerUrl = this.$router.resolve({
					path: path,
					query: query
				})
				window.open(routerUrl.href, "_blank")
			
			},
			// 出差申请单列表
			goToApprovalRecord(){
					let path = "myTravel/userCenter/approvalRecord";
					let routerUrl = this.$router.resolve({
						path: path,
					})
					this.$router.push({path: path,})
					// window.open(routerUrl.href, "_blank")
				
			}		
		}
	}
</script>

<style lang="scss" scoped>
	.ccsqd{
		.con-div{
			margin:  20px 22px;
		}
		/deep/ .el-collapse-item__content{
			padding-bottom: 5px;
		}
		.conl-flex {
			display: flex;
			justify-content: space-between;
		
			.text {
				font-size: 15px;
				font-weight: bold;
				line-height: 25px;
				color: #282828;
			}
		
			.btn {
				padding: 2px 5px;
				font-size: 12px;
				font-weight: bold;
				line-height: 15px;
			}
			
		}
		.main-left {
			margin-top: 21px;
			height: 366px;
			overflow: hidden;
		
			/deep/ .is-active {
				color: #398EFE;
			}
		
			.collItem {
				height: 150px;
				overflow-y: auto;
		
				.content {
					height: 50px;
					width: 100%;
					display: flex;
					justify-content: space-between;
					border-top: 1px solid #EBEEF5;
		
					.itemIcon {
						width: 10%;
						font-size: 25px;
						color: #f5a337;
						line-height: 50px;
					}
		
					.item-con {
						width: 90%;
						padding-left: 5px;
		
						.item-con-t {
							width: 100%;
							display: flex;
		
							.item-con-tl,
							.item-con-tc,
							.item-con-tr {
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-size: 12px;
								line-height: 25px;
							}
		
							.item-con-tl {
								width: 60px;
								text-align: left;
		
							}
		
							.item-con-tc {
								width: 140px;
								text-align: left;
		
							}
		
							.item-con-tr {
								width: 60px;
								text-align: right;
								padding-right: 5px
							}
						}
		
						.item-con-f {
							width: 100%;
							display: flex;
		
							.item-con-fl,
							.item-con-fc,
							.item-con-fr {
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-size: 12px;
								line-height: 25px;
							}
		
							.item-con-fl {
								width: 110px;
								text-align: left;
							}
		
							.item-con-fc {
								width: 90px;
								text-align: right;
								color: #282828;
		
								.textColor {
									color: #f5a337;
									padding-right: 5px;
								}
							}
		
							.item-con-fr {
								width: 60px;
								text-align: right;
								padding-right: 5px;
								color: #40A9FF;
								cursor: pointer;
							}
						}
					}
				}
		
			}
		
			.collItem::-webkit-scrollbar {
				width: 6px;
			}
		
			.collItem::-webkit-scrollbar-track {
				background-color: #E4E7ED;
			}
		
			.collItem::-webkit-scrollbar-thumb {
				background-color: #398EFE;
			}
		}
		
		.allSee {
			font-size: 12px;
			font-weight: bold;
			padding: 10px ;
			width:100%;
			color: #FFFFFF;
			
		}
	}
</style>
