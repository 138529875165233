<template>
	<div class="nofind">
		<div class="no-main">
			<div class="no-con">
				<div>
					<img :src="img1" alt="">
				</div>
				<div>
					<div>
						<img :src="img2" alt="">
					</div>
					<el-button style="" class="btn" size="medium"  @click="toHome">返回首页</el-button>
				</div>	
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		name:"NotFound",
		data(){
			return {
				img1:require('@/assets/image/notFound.png'),
				img2:require('@/assets/image/sls.png'),
			}
		},
		methods:{
			toHome(){
				this.$router.push('/yjTravel/home');	
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.nofind{
		height: 100%;
		// background-color: beige;
		.no-main{
			margin: 0 auto;
			width: 600px;
			height: 100%;
			.no-con{
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 100%;
				.btn{
					width:70%;
					background-color: #C49461;
					padding:14px 0;
					margin-top:20px;
					color: #FFFFFF;
					border: none;
				}
				.btn:hover{
					opacity: 0.8;
				}
			}
		}
	}
</style>
