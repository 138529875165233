<template>
	<div class="pp-main">
		<div class="pured">
			<div class="title-t">
				服务优势 引领未来
			</div>
			<div class="title-f">
				Service Advantage Leading the Future
			</div>
			<div class="flex">
				<div class="zsmain">
					<div class="setImg">
						<div class="setcon">
							<img src="../../../assets/image/login/oa.png" alt="OA无缝对接">
						</div>
					</div>
					<div class="text-top">OA无缝对接</div>
					<div class="text-footer">
						近十家大型企业OA对接经验，提供标准的OA对接方案，与企业出差申请及审批流程无缝对接；业务情况特殊的企业，可派技术人员上门沟通需求，制定个性化OA对接方案。
					</div>
				</div>
				<div class="zsmain">
					<div class="setImg">
						<div class="setcon">
							<img src="../../../assets/image/login/cw.png" alt="财务核算简单方便">
						</div>
					</div>
					<div class="text-top">财务核算简单方便</div>
					<div class="text-footer">
						每月定时在系统中生成结算账单，并自动发送短信和邮件提醒；有专职的结算服务小组配合进行人工对账。
					</div>
				</div>
				<div class="zsmain">
					<div class="setImg">
						<div class="setcon">
							<img src="../../../assets/image/login/jcfw.png" alt="优质的机场服务">
						</div>
					</div>
					<div class="text-top">优质的机场服务</div>
					<div class="text-footer">
						VIP旅客免费提供接送机服务；在北京、上海、厦门、乌鲁木齐机场设有专职的服务人员，旅客到达机场即有服务人员接待，代办值机选座，行李托运，引领旅客到安检口。
					</div>
				</div>
				<div class="zsmain">
					<div class="setImg">
						<div class="setcon">
							<img src="../../../assets/image/login/cp.png" alt="产品资源丰富">
						</div>
					</div>
					<div class="text-top">产品资源丰富</div>
					<div class="text-footer">
						国内/国际机票、国内/国际酒店、火车票、用车、会议旅游、签证、保险一站式服务。
					</div>
				</div>
				<div class="zsmain">
					<div class="setImg">
						<div class="setcon">
							<img src="../../../assets/image/login/pjyd.png" alt="预订便捷">
						</div>
					</div>
					<div class="text-top">预订便捷</div>
					<div class="text-footer">
						支持APP、PC、微信、电话等多种渠道预订方式；
						{{compInfo.etServicePhone}}提供24小时人工服务。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Pure',
		props:{
			compInfo:{
				type:Object,
				default(){
					return {};
				}
			}
		},
		
	}
</script>

<style lang="scss" scoped>
	.pp-main{
		width: 100%;
		font-family: '微软雅黑';
		.pured{
			width: 1200px;
			margin: 0 auto;
			padding: 42px 0;
			text-align: center;
			.title-t{
				font-size: 22px;
				font-weight: bold;
				color: #4D4D4D;
			}
			.title-f{
				font-size: 12px;
				font-weight: bold;
				color: #999999;
				margin-top: 10px;
			}
			.flex{
				display: flex;
				.zsmain{
					width: 20%;
					height: 360px;
					margin:30px 0 0 0;
					padding: 20px 0 0 0;
					cursor: pointer;
					overflow: hidden;
					.setImg{
						height: 125px;
						opacity: 0.8;
						.setcon{
							display: inline-block;
							width: 134px;
							height: 117px;
							overflow: hidden;
						}
					}
					.text-top{
						height: 14px;
						font-size: 15px;
						font-weight: bold;
						color: #4D4D4D;
						line-height: 15px;
						margin: 15px 0;
					}
					.text-footer{
						font-size: 12px;
						line-height: 24px;
						font-weight: 300;
						color: #808080;
						padding: 0 28px;
						text-align: left;
					}
				}
				.zsmain:hover{
					box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
					border-radius: 4px;
					transition: all 0.5s ease;
				}
				// .zsmain:hover .text-top{
				// 	color: #007AFF;
				// 	transition: all 0.5s ease;
				// }
				.zsmain:hover img{
					-webkit-transform: scale(1.1);
					-moz-transform: scale(1.1);
					-ms-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
					// font-size: 120px;
					transition: all 0.5s ease;
				}
				
			}
		}
	}
</style>
