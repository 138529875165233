<template>
	<div class="tcd-main" :style="{'min-height':height+'px'}">
		<div class="main">
			<el-card>
				<div class="cd-top">
					<h1 class="to-title">改签订单 
						<span class="to-title-span">{{changeDetail.payDate}}</span>
						<span class="to-title-span">申请人：{{changeDetail.applyUserName}}</span>
						<span class="to-title-span">{{'('+changeDetail.applyDeptName+')'}}</span>
					</h1>
					<div class="to-status">
						<span >订单状态：</span>
						<span class='colorzs' >{{changeDetail.orderStatusRemark}}</span>
					</div>
				</div>
				<div class="cd-f">
					<div>改签订单：{{changeDetail.changeNo}} <el-button type="text" @click="lookOldOrder(changeDetail)">查看原订单</el-button></div>
					<div>服务商：{{changeDetail.supplierName}}</div>
					<div>
						<span class="orange" style="color:orangered">{{changeDetail.changeType==1?'自愿':'非自愿'}}</span>
						<span>改签原因：
							<span v-show="changeDetail.changeType==1">{{changeDetail.changeReasonVip }}</span>
							<span v-show="changeDetail.changeType!=1">{{changeDetail.changeReason}}</span>
						</span>
						<span v-show="changeDetail.changeProveUrl">&#x3000;<a :href="changeDetail.changeProveUrl" target="_blank">查看改签证明</a></span>
						
						
					</div>
				</div>
			</el-card>

			<el-card class="marT20">
				<h1 class="cd-title">原航程</h1>
				<div class="cd-info" v-for="(item, index) in changeDetail.rangeList" :key="index">
					<div class="borderR cd-l1">
						<img :src="item.logo" class="cd-img">
						<span class="cd-text">{{item.airlineName}}</span>
					</div>
					<div class="borderR cd-l2">
						<div class="cd-l2-l" >
							<p class="time">{{item.oDepartTime}}</p>
							<p class="city">{{item.oDepartAirportName?item.oDepartAirportName :'-'}}{{item.oDepartTerminal}}</p>
						</div>
						<div class="cd-l2-c">
							<p>{{item.oFlyTime}}</p>
							<p class="cd-jt"></p>
						</div>
						<div class="cd-l2-r">
							<p class="time">{{item.oArriveTime}}</p>
							<p class="city">{{item.oArriveAirportName?item.oArriveAirportName:'-' }}{{item.oArriveTerminal}}</p>
						</div>
					</div>
					<div class="borderR cd-l3">{{item.oDepartDate+' '+item.oDepartWeek}}</div>
					<div class="borderR cd-l4">{{item.oCabinName?item.oCabinName:"-"}}</div>
					<el-button type="text" class="cd-l5" @click="visible = true">退改签规则</el-button>
					<el-dialog title="退改签规则" class="tggzDialog"  :visible.sync="visible" :lock-scroll="false">
						<div class="tgqDialog">
							<div class="gzleft">退票规则</div>
							<div class="gzright">
								<p>{{item.nRefundRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">改签规则</div>
							<div class="gzright">
								<p>{{item.nChangeRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">升舱规则</div>
							<div class="gzright">
								<p>{{item.nUpgradeRule}}</p>
							</div>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="visible = false">关 闭</el-button>
						</span>
					</el-dialog>
				</div>
			
			</el-card>
			<el-card class="marT20">
				<h1 class="cd-title">新航程</h1>
				<div class="cd-info" v-for="(item, index) in changeDetail.rangeList" :key="index">
					<div class="borderR cd-l1">
						<img :src="item.logo" class="cd-img">
						<span class="cd-text">{{item.airlineName}}</span>
					</div>
					<div class="borderR cd-l2">
						<div class="cd-l2-l" >
							<p class="time">{{item.nDepartTime}}</p>
							<p class="city">{{item.nDepartAirportName?item.nDepartAirportName:'-'}}{{item.nDepartTerminal}}</p>
						</div>
						<div class="cd-l2-c">
							<p>{{item.nFlyTime}}</p>
							<p class="cd-jt"></p>
						</div>
						<div class="cd-l2-r">
							<p class="time">{{item.nArriveTime}}</p>
							<p class="city">{{item.nArriveAirportName?item.nArriveAirportName:'-' }}{{item.nArriveTerminal}}</p>
						</div>
					</div>
					<div class="borderR cd-l3">{{item.nDepartDate+' '+item.nDepartWeek}}</div>
					<div class="borderR cd-l4">{{item.nCabinName?item.nCabinName:"-"}}</div>
					<el-button type="text" class="cd-l5" @click="visible = true">退改签规则</el-button>
					<el-dialog title="退改签规则"  class="tggzDialog" :visible.sync="visible" :lock-scroll="false">
						<div class="tgqDialog">
							<div class="gzleft">退票规则</div>
							<div class="gzright">
								<p>{{item.nRefundRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">改签规则</div>
							<div class="gzright">
								<p>{{item.nChangeRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">升舱规则</div>
							<div class="gzright">
								<p>{{item.nUpgradeRule}}</p>
							</div>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="visible = false">关 闭</el-button>
						</span>
					</el-dialog>
				</div>
			
			</el-card>
			
			<el-card class="marT20">
				<h1 class="cd-title">乘机人</h1>
				<div class="cd-info" v-for="(psgItem, index) in changeDetail.psgList" :key="index">
					<div class="cd-person">
						<div class="cd-pl1">
							<span>{{psgItem.psg}}</span>
							<span>{{' ('+ psgItem.idNo +')'}}</span>
						</div>
						<!-- <div class="cd-pl2" >
							<div style="text-align: left;">票号：{{psgItem.oTkno}}</div>
							<div style="text-align: left;" v-show="psgItem.nTkno">新票号：{{psgItem.nTkno}}</div>
							
						</div> -->
						<div class="cd-pl3">
							<span v-if="psgItem.psgType==1">成人</span>
							<span v-if="psgItem.psgType==2">儿童</span>
							<span v-if="psgItem.psgType==3">婴儿</span>
						</div>
						<div class="cd-pl4">
							原票价：¥{{psgItem.oFcny}}
						</div>
						<div class="cd-pl4">
							新票价：¥{{psgItem.nFcny}}
						</div>
						<div class="cd-pl5">
							改签差价：¥{{psgItem.priceDifference }}
						</div>
						<div class="cd-pl6">
							服务费：¥{{psgItem.serviceFee}}
						</div>
					</div>
				</div>
			</el-card>
			
			<yj-contact-info :marginTop="'20px'" ></yj-contact-info>
			<el-card v-if="changeDetail.appId && changeDetail.vipStatus==0" class="marT20">
				<div class="title">审批规则详情</div>
				<div class="trd-jd">
					<yj-approval-rule-detail :detail="detail" :appId="detail.appId"></yj-approval-rule-detail>	
				</div>	
			</el-card>
			<el-card v-if="changeDetail.appId && changeDetail.vipStatus>0 &&  changeDetail.vipStatus<4" class="marT20">
				<div class="rd-title">审批进度</div>
				<div class="rd-jd">
					<yj-approval-progress 
					:orderId="query.orderId" 
					:orderType="query.orderType" 
					:resetspjd="resetspjd"
					></yj-approval-progress>
				</div>	
			</el-card>
			<!-- <yj-need-my-approval v-if="isSpStatus && query.workItemId" :workItemId="query.workItemId" :orderId="query.orderId" @spSuccess="resetDetail"></yj-need-my-approval> -->
			<div class="marT20">
				<!-- 订单操作和审批操作 -->
				<yj-approval-cao-zuo
					:appType="query.appType"
					:workItemId="query.workItemId"
					:orderType="query.orderType"
					:orderId="query.orderId"
					@closePage="spResetDetail" 
					>
					<div slot="orderFooterSlot">
						<div class=" pad20" style="text-align: center;">
							<!-- getCjrInfo -->
							<el-button class="button" v-show="changeDetail.showCancelButton==1"  @click="orderCaoZuo(1,changeDetail)">取消改签</el-button>
							<el-button class="button" v-show="changeDetail.showPayButton==1"  @click="orderCaoZuo(2,changeDetail)">支 付</el-button>
							<el-button class="button" v-show="changeDetail.showSendCkButton==1"  @click="orderCaoZuo(3,changeDetail)">送 审</el-button>
							<el-button class="button" v-show="changeDetail.showRefund==1" @click="orderCaoZuo(4,changeDetail)">退 票</el-button>
							<el-button class="button" v-show="changeDetail.showEndorse==1"  @click="orderCaoZuo(5,changeDetail)">改 签</el-button>
							<el-button class="button" v-show="changeDetail.isShowCancelApprove==1"  @click="orderCaoZuo(16,changeDetail)">撤 回</el-button>
						</div>
					</div>
				</yj-approval-cao-zuo>
				
			</div>
		</div>
	</div>
</template>

<script>
	import caozuoMixin from '@/mixin/models/caozuoMixin.js';
	export default{
		name: 'TkChangeDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				changeDetail: {}, // 改签详情
				visible: false,
				// query:{},
				detail:{
					currentLevel: 0,
					flowState: 3,
					appId: '',
					
				},
				resetspjd: false,
				isSpStatus:false,
			}
		},
		computed:{
			userInfo(){
				return this.$common.sessionget('user_info') || {};
			},
			empId(){
				return this.userInfo.empInfo.empId;
			},
			height(){
				let height = document.body.clientHeight;
				return height
			},
			query(){
				return this.$route.query || {}
			}
		},
		mounted() {
			// this.query =  this.$route.query || {};
			this.getChangeDetail();
		},
		methods:{
			spResetDetail(){
				this.$router.replace({
					path: this.$dict.detailConfig(11003),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				this.getChangeDetail();
				this.resetspjd = true;
			},
			//获取详情信息
			getChangeDetail(){
				let params = {
					id: this.query.orderId
				}
				let queryUrl = 'tkQuery2023.tkChangeDetail3';
				// 'change.tkChangeOrderDetail'
				this.$conn.getConn(queryUrl)(params,(res)=>{
					let data = res.data || {};
					this.changeDetail = data;
					// this.changeDetail.changeProveUrl = 'https://img.gexingshuo.com/uploads/allimg/160409/1-160409101522456.jpg';
					this.detail.appId = data.appId;
				},(err)=>{
					this.$message.error(err.tips)
				})
			},
			
			// 查看原订单
			lookOldOrder(changeOrderDetail){

				let path =  this.$dict.detailConfig(11001);
				let query = {
					orderId: changeOrderDetail.orderId,
					orderType: 11001,
					
				}
				let routeUrl = this.$router.resolve({path:path,query:query});
				window.open(routeUrl.href,"_blank")
			},
			
			// // 订单操作枚举：1.取消  2. 支付 3.送审 4.退票 5.改签
			orderCaoZuo(type,detail){	
				if(type == 1){
					this.cancleOrder(detail)
				}else if(type == 2){
					this.$router.push({
						path:"/yjDetail/payment",
						query:{
							orderId:this.query.orderId,
							orderType:this.query.orderType,
						}
					})
				}else if(type == 3){
					this.$confirm('您确认要送审吗?', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
						}).then(() => {
							let queryUrl = "commu.sendApp";
							var queryString = {
			
								orderId: this.query.orderId,
								orderType: this.query.orderType,
								spgzId: detail.appId,
							}
							this.$conn.getConn(queryUrl)(queryString,(res)=>{
								this.$message({
									type: 'success',
									message: '送审成功！'
								});  
								this.getChangeDetail();
							},(err)=>{
								this.$message.error(err.tips)
							})
						}).catch(() => {
						              
						});

					
				}else if(type ==4){
					let path =  this.$dict.orderRefundConfig(this.query.orderType);
					let query = {
						orderId: this.query.orderId,
						orderType: this.query.orderType,
						refundFlag: 2
					}
					let routeUrl = this.$router.resolve({path:path,query:query});
					window.open(routeUrl.href,"_blank")
				}else if(type == 5){
					let path =  this.$dict.orderChangeConfig(this.query.orderType);
					let query = {
						orderId: this.query.orderId,
						orderType: this.query.orderType,
						changeFlag: 2
					}
					let routeUrl = this.$router.resolve({path:path,query:query});
					window.open(routeUrl.href,"_blank")
				}else if(type == 16){
					this.goToCancelApprove({
						orderId: this.query.orderId,
						orderType:this.query.orderType,
					},(res)=>{
	
							this.getChangeDetail();
					},(err)=>{
			
					})
				
				}
			},
			// 取消订单
			cancleOrder(orderDetail){
				var queryString = {
				  // "cancelPnr": 0,
				  "id": orderDetail.changeId ,
				  // "reason": "string",
				  // "sendNotice": "string",
				  // "userId": "string",
				  "version": orderDetail.version
				};
				var url = "tkQuery2023.changeCancel";
				this.$conn.getConn(url)(queryString,(res)=>{
					this.$alert('','取消成功！',{ 
						type:'success',
						confirmButtonText: '确定',
						callback: action => {
							this.getChangeDetail();
	
						}
					})
					
				},(err)=>{
					this.$alert(err.tips,'取消失败！',{
						type:'error',
					})
				})
			},
			// 审批进度
			showProgress(info,currentSpjd,status){
				// this.spjd = info.spjd; //当前审批进度（1/2）
				this.resetspjd = false;
				this.detail.currentLevel = currentSpjd; //当前到谁审批
				this.detail.flowState = status;  // 当前审人状态
				this.getCurrentSprLevel()
			},
			// 获取当前审批人层级列表
			getCurrentSprLevel(){
				let params = {
					spgzId: this.detail.appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					let data =  res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				},(err)=>{
					
				})
			},
			// 获取审批人列表
			getGznrList(list){
				if(list.length>0){
					// 遍历审批人
					for(var i=0; i<list.length; i++){
						// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
						if(list[i].level == this.detail.currentLevel){
							// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
							for(var j=0; j<list[i].empList.length;j++){
								if(list[i].empList[j].empId == this.empId && this.detail.flowState==3){
									this.isSpStatus = true;
								}else{
									this.isSpStatus = false;
								}
							}
						}
					}
				}
				
			},
			resetDetail(){
				this.getChangeDetail();
				this.resetspjd = true;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.tcd-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		/deep/ .el-card__body{
			padding: 30px;
		}
		.main{
			margin: 0 auto;
			width: 1200px;
			padding: 40px 0 50px;
			.marT20{
				margin-top: 20px;
			}
			.button{
				width: 140px;
				height: 50px;
				font-size: 15px;
			}
			.cd-top{
				display: flex;
				justify-content: space-between;
				.to-title{
					font-size: 24px;
					font-weight: 600;
					color: #424242;
					line-height: 24px;
					.to-title-span{
						font-size: 15px;
						font-weight: 400;
						color: #808080;
						padding-left: 20px;
						line-height: 24px;
					}
				}
				.to-status{
					font-size: 18px;
					font-weight: 400;
					color: #808080;
					padding-top: 2px;
					line-height: 24px;
					.colorzs{
						color: #FF8400;
					}
				}
				
			}
			
			.cd-f{
				margin-top: 29px;
				border-top: 1px solid #E6E6E6;
				font-size: 15px;
				font-weight: 400;
				color: #424242;
				text-align: left;
				div{
					padding-top: 22px;
				}
			}
			.borderR{
				border-right:1px solid #D1DBE7;
			}
			.cd-title{
				font-size: 24px;
				font-weight: 600;
				color: #424242;
				line-height: 24px;
				text-align: left;
			}
			.cd-info{
				display: flex;
				height: 80px;
				background: #F3F5F8;
				border-radius: 5px;
				margin-top: 27px;
				box-sizing: border-box;
				padding: 20px 0;
				.tggzDialog{
					/deep/ .el-dialog {
						width: 600px;
					}
					.tgqDialog {
						display: flex;
						justify-content: space-between;
						text-align: left;
						border: 1px solid #e6e6e6;
					
						.gzleft {
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #FAFAFA;
							width: 92px;
							min-height: 40px;
							border-right: 1px solid #e6e6e6;
							font-size: 15px;
						}
					
						.gzright {
							width: 508px;
							line-height: 24px;
							padding: 15px 15px 15px 20px;
						}
					}
				}
				.cd-l1{
					width: 215px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.cd-l2{
					width: 480px;
					display: flex;
					padding:0 10px;
					.cd-l2-l,.cd-l2-r{
						width: 153px;
						.time{
							font-weight: bold;
						}
						.city{
							padding-top:5px;
						}
					}
					.cd-l2-c{
						width: 154px;
						.cd-jt{
							height: 1px;
							background-color: #D1DBE7;
							width: 100%;
							margin-top: 10px;
						}
					}
				}
				.cd-l3{
					width: 194px;
					line-height: 40px;
				}
				.cd-l4{
					width: 120px;
					line-height: 40px;
				}
				.cd-l5{
					font-size: 15px;
					width: 152px;
				}
				.cd-img{
					width: 25px;
					height: 25px;
				}
				.cd-text{
					padding-left: 5px;
					font-size: 14px;
					font-weight: bold;
					color: #223344;
					
				}
			}
			.cd-person{
				display: flex;
				height: 40px;
				background: #F3F5F8;
				border-radius: 5px;
				// margin-top: 15px;
				box-sizing: border-box;
				padding: 10px 0;
				.cd-pl1,.cd-pl2,.cd-pl3,.cd-pl4,.cd-pl5{
					border-right: 1px solid #D1DBE7;
				}
				.cd-pl1{
					width: 316px;
				}
				.cd-pl2{
					width: 209px;
					
				}
				.cd-pl3{
					width: 84px;
				}
				.cd-pl4{
					width: 174px;
				}
				.cd-pl5{
					width: 179px;
				}
				.cd-pl6{
					width: 174px;
				}
			}
		}
	}
</style>