<template>
	<div class="in-main">
		<el-card>
			<div class="flex between">
				<div class="fp">
					发票
				</div>
				<el-button class="el-icon-plus" type="primary" @click="addInvoice">
					新增发票
				</el-button>
			</div>
			<div style="height: 20px;"></div>
			<el-table
				:data="invoiceList"
				style="width: 100%"
				:header-cell-style="header"
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .8)"
				element-loading-spinner = "el-icon-loading"
				element-loading-text = "加载中，请稍后..."
				class="bghover"		
			>
				<el-table-column 
					type="index"
					width="50">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
					    <el-form label-position="left" label-width="100px" class="demo-table-expand" v-if="props.row.titleType==1">
					      <el-form-item label="税号:">
					        <div>{{ props.row.receNo}}</div>
					      </el-form-item>
					      <el-form-item label="公司地址:">
					        <div>{{ props.row.compAddress}}</div>
					      </el-form-item>
					      <el-form-item label="公司电话:">
					        <div>{{ props.row.compPhone}}</div>
					      </el-form-item>
					      <el-form-item label="银行名称:">
					        <div>{{ props.row.bankName}}</div>
					      </el-form-item>
					      <el-form-item label="银行账户:">
					        <div>{{ props.row.bankAccount}}</div>
					      </el-form-item>
					    </el-form>    
					</template>
				</el-table-column>
				<el-table-column
				  label="抬头"
				  prop="title">
				</el-table-column>
				<el-table-column label="发票抬头">
					<template slot-scope="scope">
						<span v-if="scope.row.titleType==1">公司</span>
						<span v-if="scope.row.titleType==2">个人</span>
					</template>
				</el-table-column>
				<el-table-column label="发票类型" >
					<template slot-scope="scope">
						<span v-if="scope.row.receiptType==1">专票</span>
						<span v-if="scope.row.receiptType==2">普票</span>					
					</template>  
				</el-table-column>     
				<el-table-column label="是否默认发票" >
					<template slot-scope="scope">
						<span v-if="scope.row.dft==1">默认</span>
						<span v-else>否</span>					
					</template>  
				</el-table-column>
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<el-button type="text" @click="editInvoice(scope.row)">编辑</el-button>
						<el-button type="text" @click="removeInvoice(scope.row.id)">删除</el-button>				
					</template>  
				</el-table-column>  	   
			</el-table>
		</el-card>
		<el-dialog
			:title="title"
			:close-on-click-modal="false"
			:visible.sync="visible"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-row :gutter="0">
					<el-col :span="12">
						<el-form-item label="发票抬头">
							<el-radio v-model="ruleForm.titleType" label="1" @change="getTitleType" >企业</el-radio>
							<el-radio v-model="ruleForm.titleType" label="2" @change="getTitleType">个人</el-radio>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="发票类型">
							<el-input v-model="receiptName" readonly></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0" v-if="ruleForm.titleType==2">
					<el-col :span="12">
						<el-form-item label="设置默认发票">
							<el-checkbox v-model="ruleForm.dft"></el-checkbox>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="抬头" prop="title">
							<el-input v-model="ruleForm.title"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0" v-if="ruleForm.titleType==1">
					<el-col :span="12">
						<el-form-item label="抬头" prop="title">
							<el-input v-model="ruleForm.title"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="税号" prop="receNo">
							<el-input v-model="ruleForm.receNo"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0" v-if="ruleForm.titleType==1">
					<el-col :span="12">
						<el-form-item label="公司地址" prop="compAddress">
							<el-input v-model="ruleForm.compAddress"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="公司电话" prop="compPhone">
							<el-input v-model="ruleForm.compPhone"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0" v-if="ruleForm.titleType==1">
					<el-col :span="12">
						<el-form-item label="银行名称"  prop="bankName">
							<el-input v-model="ruleForm.bankName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="银行账户" prop="bankAccount">
							<el-input v-model="ruleForm.bankAccount"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0" v-if="ruleForm.titleType==1">
					<el-col :span="12">
						<el-form-item label="设置默认发票">
							<el-checkbox v-model="ruleForm.dft"></el-checkbox>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>	
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="submit('ruleForm')">保存</el-button>
			</span>
		</el-dialog>	
	</div>
</template>

<script>
	export default{
		name: "Invoice",
		data(){
			return {
				header:{
					background:'#F2F2F2',
				},
				title: '',
				loading: false,
				visible: false,
				isEmpty: false, //发票列表为空时展示
				invoiceList:[], //发票列表
				ruleForm:{
					"bankAccount": "",
					"bankName": "",
					"compAddress": "",
					"compPhone": "",
					"dft": 0, // 1默认 ，
					"id": "",
					"receNo": "", //纳税人编码 ,
					"receiptType": '2', //票类型 1专票 2普票 ,
					"title": "", // 
					"titleType": "2", //发票抬头 1公司 2个人
				},
				receiptName:'增值税普通发票',
				rules:{
					bankAccount:[
						{ required: true,message: '请输入账户', trigger: 'blur'  }
					],
					bankName: [
						{ required: true, message: '请输入银行名称', trigger: 'blur'},		
					],
					compAddress: [
						{ required: true, message: '请输入地址', trigger: 'blur'},		
					],
					compPhone: [
					    { required: true, message: '请输入手机号', trigger: 'blur' },
					],
					receNo: [
						{ required: true, message: '请输入编号', trigger: 'blur'},		
					],
					title:[
						{required:true, message: '请输入抬头', trigger: 'blur'}
					]
				}
			}
		},
		mounted(){
			this.getInvoiceList();
		},
		methods:{
			// 获取发票列表
			getInvoiceList(){
				this.$conn.getConn("user.getReceiptList")({},(res)=>{
					let data = res.data || [];
					this.invoiceList = data;
				},(err)=>{
					
				})
			},
			// 新增发票
			addInvoice(){
				this.visible = true;
				this.title = '新增发票';
				if(this.ruleForm.titleType == "1"){
					this.ruleForm = {
						"dft": 0,
						"id": "",
						"receiptType": this.ruleForm.receiptType,
						"title": "", //
						"titleType": this.ruleForm.titleType, 
					}
				}else{
					this.ruleForm = {
						"bankAccount": "",
						"bankName": "",
						"compAddress": "",
						"compPhone": "",
						"dft": 0,
						"id": "",
						"receNo": "", //纳税人编码 ,
						"receiptType": this.ruleForm.receiptType, //票类型 1专票 2普票 ,
						"title": "", // 
						"titleType": this.ruleForm.titleType,  //发票抬头 1公司 2个人
					}
				}
			},
			// 编辑发票
			editInvoice(item){
				this.visible = true;
				this.title = '编辑发票';
				if(item.titleType==2){
					this.ruleForm = {
						"dft": item.dft?true:false,
						"id": item.id,
						"receiptType": item.receNo, //票类型 1专票 2普票 ,
						"title": item.title, // 
						"titleType": item.titleType,  //发票抬头 1公司 2个人
					}
				}else{
					this.ruleForm = {
						"bankAccount": item.bankAccount,
						"bankName": item.bankName,
						"compAddress": item.compAddress,
						"compPhone": item.compPhone,
						"dft": item.dft?true:false,
						"id": item.id,
						"receNo": item.receNo, //纳税人编码 ,
						"receiptType": item.receNo, //票类型 1专票 2普票 ,
						"title": item.title, // 
						"titleType": item.titleType,  //发票抬头 1公司 2个人
					}
				}
			},
			// 删除发票
			removeInvoice(id){
				this.$confirm('此操作将永久删除该选项, 是否继续?', '删除', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn('user.deleteReceipt')({id:id},(res)=>{
							this.$message({
								type: 'success',
								message: '删除成功'
							})
							this.getInvoiceList()
						},(err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})  							 
					}).catch(() => {
						 this.$message({
							type: 'info',
							message: '已取消删除'							
						 });     
					});   
			},	
			submit(formName){
				var params = {};
				if(this.ruleForm.titleType==1){
					params = {
						"bankAccount": this.ruleForm.bankAccount,
						"bankName": this.ruleForm.bankName,
						"compAddress": this.ruleForm.compAddress,
						"compPhone": this.ruleForm.compPhone,
						"dft": this.ruleForm.dft?1:0,
						"receNo": this.ruleForm.receNo, //纳税人编码 ,
						"id": this.ruleForm.id,
						"receiptType": this.ruleForm.receiptType, //票类型 1专票 2普票 ,
						"title": this.ruleForm.title, // 
						"titleType": this.ruleForm.titleType,  //发票抬头 1公司 2个人
						
					}
				}else{
					params = {
						"dft": this.ruleForm.dft?1:0,
						"id": this.ruleForm.id,
						"receiptType": this.ruleForm.receiptType, //票类型 1专票 2普票 ,
						"title": this.ruleForm.title, // 
						"titleType": this.ruleForm.titleType,  //发票抬头 1公司 2个人
					}
					
				}
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						this.$conn.getConn('user.saveReceipt')(params,(res)=>{
							this.$message({
								type: 'success',
								message: '保存成功'
							})
							this.visible = false;
							this.getInvoiceList()
						},(err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})
				    } else {
				      return false;
				    }      
				});
			},
			// 获取发票抬头类型
			getTitleType(e){
				this.ruleForm.receiptType = e;
				if(e==1){
					this.receiptName = '增值税专用发票';
				}else{
					this.receiptName = '增值税普通发票';
				}
				
			},
			// 关闭页面
			handleClose(){
				this.visible = false;
			}
		}
	}
</script>

<style scoped lang='scss'>
	.in-main{
		.bg-purple {
		    background: #d3dce6;
		  }
		 
		  .grid-content {
		    border-radius: 4px;
		    min-height: 36px;
		  }
		/deep/ .el-dialog{
			width: 660px;
		}
		/deep/ .el-dialog__header{
			padding: 20px;
			border-bottom: 1px solid #DCDCDC;
		}
		/deep/ .el-dialog__body{
			padding: 20px;
		}
		/deep/ .el-dialog__footer{
			padding: 15px 30px 20px ;
			border-top: 1px solid #DCDCDC;
		}
		/* /deep/ .el-select .el-input__inner{
			width: 180px;
		} */
		/deep/ .el-input__inner{
			border-radius: 0;
		}
		/* /deep/ .el-date-editor--date{
			width: 200px;
		} */
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.fp{
			font-size: 15px;
			line-height: 32px;
			font-weight: bold;
		}
		.add-title{
			font-size: 12px;
			line-height: 12px;
			font-weight: 400;
			color: #808080;
			background: #F2F2F2;
			margin-top: 20px;
			padding: 14px 0;
		}
		.add-text{
			font-size: 12px;
			font-weight: 500;
			line-height: 32px;
			color: #282828;
			padding: 15px 0;
			border-bottom: 1px solid #DCDCDC;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w30{
			width: 30%;
		}
	
	}
</style>
