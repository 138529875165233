<template>
	<div class="trh-main">
		<el-card>
			<div class='trh-top'>
				<span class="jp-title">机票退票<span class="tripType-title">({{allData.tripType==2?'因私':'因公'}})</span></span>			
			</div>
			<div class="trh-con">
				<!-- 退票人 -->
				<div class="trh-fl-main" v-for="reItem in psgList" :key="reItem.psgId">
					<div class="trh-fl1">
						<el-checkbox
									:disabled="isCanCheck(reItem,chooseRange)"
									:checked="!!choosePsg[reItem.psgId]"
									@change="getCheckStatus(reItem)"></el-checkbox>
					</div>
					<div class="trh-fl2">
						<span>{{reItem.psgName}}</span>
						<span class="padL10">{{'('+ reItem.idNo +')'}}</span>
					</div>
					<div class="trh-fl3">票号：{{reItem.tkno}}</div>
					<div class="trh-fl4">
						<span v-if="reItem.psgType==1">成人</span>
						<span v-if="reItem.psgType==2">儿童</span>
						<span v-if="reItem.psgType==3">婴儿</span>
					</div>
					<div class="trh-fl5">票价：¥{{reItem.salePrice}}</div>
					<div class="trh-fl6">基建/燃油：¥{{reItem.airportFee + reItem.tax}}</div>
					<div class="trh-fl7">服务费：¥{{reItem.serviceFee}}</div>
				</div>
				
				<!-- 要退的航段 -->
				<div v-for="(item, index) in rangeList" :key="index">
					<div  class="trh-wf">
						<span>起飞到达: {{item.departCityName+' - '+item.arriveCityName}}</span>
					</div>
					<div class="trh-con-t">
						<div class="tch-l0">
							<el-checkbox
										:disabled="isCanChooseRange(item,choosePsg)"
										:checked="!!chooseRange[item.sn]"
										@change="getRangeCheckStatus(item,index)"></el-checkbox>
						</div>
						<div class="trh-l1 ">
							<div>{{item.airlineName}}</div>
							<div>{{item.flightNo}}</div>
							
						</div>
						<div class="trh-l2" >
							<div class='trh-l2-hbt'>
								<div class="trh-l2-hbl">{{item.departTime}}</div>
								<div class="trh-l2-hbc">{{item.flightTime}}</div>
								<div class="trh-l2-hbr">{{item.arriveTime}}</div> 
							</div>
							<div class='trh-l2-hbf'>
								<div class="trh-l2-hbfl">{{item.departAirportName}}{{item.departTerminal}}</div> 
								<div class="trh-l2-hbfc"></div>
								<div class="trh-l2-hbfr">{{item.arriveAirportName}}{{item.arriveTerminal}}</div>
							</div> 
						</div>
						<div class="trh-l3">
							<div>{{item.departDate}}</div>
							<div class="trh-l3-pad">{{item.week}}</div>
						</div>
						<div class="trh-l4">
							<div>{{item.cabinName}}/{{item.cabin}}</div>
						</div>
						<div class="trh-l5">
							<el-button type="text" @click="showgz">退改签规则</el-button>
						</div>
					</div>
					
								
					<el-dialog
						title="退改签规则"
						:visible.sync="visible"
						:before-close="handleClose">
						<div style="line-height: 30px;padding: 0 0 10px 0 ;">
							<p>1.退票前请先确认是否已经取消值机,取消值机后,才可以退票!</p>
							<p>2.退票可能产生退票手续费，请仔细查看退改签规则!</p>
						</div>
						<div class="dmain">
							<div class="dia-gz">
								<div class="gz-l">退票规则</div>
								<div class="gz-r">{{item.refundRule}}</div>
							</div> 
							<div class="dia-gz">
								<div class="gz-l">改签规则</div>
								<div class="gz-r">{{item.changeRule}}</div>
							</div> 
							<div class="dia-gz">
								<div class="gz-l">升舱规则</div>
								<div class="gz-r">{{item.upgradeRule}}</div>
							</div> 
						</div>
						<span slot="footer" class="dialog-footer">
						  <el-button @click="visible = false">取 消</el-button>
						  <el-button type="primary" @click="visible = false">确 定</el-button>
						</span>
					</el-dialog>			
				</div>
				
				
				
			</div>
		</el-card>
		<el-card style="margin-top: 20px;" v-if="needApprove">
			<!-- 审批规则 -->
			<div class="setSelect approval-rule" >
				<h1 slot="header" class="text-left approval-rule-title">审批规则</h1>
				<el-select
					v-model="chooseSpgz.gzmc" 
					:loading="loadingSpgzList"
					value-key
					automatic-dropdown
					@focus="getSpgzList()"
					@change="getCurrentSpgz" 
					:placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'"
					class="bfb-w100"
					>
				    <el-option
				      v-for="item in spgzList"
				      :key="item.gzId"
				      :label="item.gzmc"
				      :value="item">
				    </el-option>
				</el-select> 
			</div>
		</el-card> 
	</div>
</template>

<script>

	export default{
		name: 'TkRefundPerson',
		data() {
			return {
				psgList:[], // 机票退票人列表
				rangeList:[], // 机票退票航班信息
				visible: false,
				
				chooseRange:{}, // 选中的航段
				choosePsg:{}, // 选中的乘机人
				// 审批规则模块
				needApprove:false, // 是否需要审批
				spgzList: [], // 审批规则列表
				loadingSpgzList:false, // 加载审批规则列表
				// 选中的审批规则
				chooseSpgz:{
					gzId:'', // 审批规则id
					gzmc:'', // 审批规则名称
					gzdm:''  // 审批规则代码
				},
				isWuXuShenPi:false, // 默认无需审批
			}
		},
		props:{
			allData:{
				type: Object,
				default(){
					return {}
				}
			}
		},
		computed:{
	
			// 员工信息
			userInfo(){
				return this.$common.getUserInfo();
			}
			
		},
		watch:{
			allData:{
				handler(val){
					this.psgList = val.psgList || [];
					this.rangeList = val.rangeList  || [];
					
					// 默认选择
					if(this.psgList.length == 1){
						this.getCheckStatus(this.psgList[0])
					}
					
					if(this.rangeList.length == 1){
						let rangeItem = this.rangeList[0];
						// if(!(rangeItem.refundFlag>0)){
							this.getRangeCheckStatus(rangeItem)
						// }
						
					}
					
					if(val.tripType == 1){
						this.getNeedApprove();
					}
				},
				immediate: true
			}
		},
		methods:{
			// 退票是否需要审批
			getNeedApprove(){
				var query = this.$conn.getConn('commu.getUserTravelInfo');
				query({},res=>{
					var data = res.data||{};
					var approvalBean = data.approvalBean||{};
					var spkzMap = approvalBean.spkzMap||{};
					spkzMap['11002'] = spkzMap['11002']||{};
					this.needApprove = spkzMap['11002'].sfkq==1 ;
				},err=>{
					
				})
			},
			
			// 是否可以选择乘机人
			isCanCheck(psgItem,chooseRange){
				let bool = false; // 默认可选择
				let refundRangeSnList = psgItem.refundRangeSnList ;
				/*
				*   判断当前乘机人，在已选乘机人的可以选择的航段，是否在已选择的航段中都可以改签？
				*   如果都满足，该乘机人就可以进行选择
				*   如果有一个不满足，该乘机人就被禁用，不可以选择
				* 
				***/
				// 如果没有选中航段
				if(refundRangeSnList.length == 0){
					bool = true;
				}else{
					// 判断当前乘机人的可退航段和 已经选择的航段是否匹配， 
					// 只要选中的航段，有一段乘机人中没有，则不可选择
					for(var rangeKey in chooseRange){
					
						let filterList =  refundRangeSnList.filter(snItem=>{
							return snItem == rangeKey
						})
						
						if(filterList.length == 0){
							bool = true;
						}

					}
				}

				return bool
			},
			// 是否可以选择航段
			isCanChooseRange(rangeItem,choosePsg){
				let bool = false;
				let rangId = rangeItem.sn;
				// 如果航段已经非正常航段 refundFlag (integer, optional): 退票状态,0正常,1 已退 ,
				if(rangeItem.refundFlag>0){
					bool = true;
				}
				/*
				*  判断当前航段，在已选乘机人的每一个人中，是否都满足可以退票？
				*   如果都满足，该航段就可以进行选择
				*   如果有一个不满足，该航段就被禁用，不可以选择
				***/ 
				let isAll = true;
				for(var psgKey in choosePsg){
					if(choosePsg[psgKey]['refundRangeSnList'].indexOf(rangId) < 0){
						isAll = false
					}
				}
				if(!isAll){
					bool = true
				}
				return bool
			},
			
			
			// 选中改签的航段
			getRangeCheckStatus(rangeItem,rangeIndex){
				let sn = rangeItem.sn;
				if(this.chooseRange[sn]){
					delete this.chooseRange[sn]
				}else{
					this.chooseRange[sn] = rangeItem;
				}
				this.$forceUpdate()
			},
			// 选中改签人
			getCheckStatus(psgItem){
				// 使用航班信息 添加上已选中 乘机人
				if(this.choosePsg[psgItem.psgId]){
					delete this.choosePsg[psgItem.psgId]
				}else{
					this.choosePsg[psgItem.psgId] = psgItem;
				}
				this.$forceUpdate()
				
				this.getSpgzList();
				
			},
			// 审批规则处理
			// 审批规则模块
			getSpgzList(){
				let isWei = false;
				
				
				// 获取改签人的员工id
				let psgArr = [];
				 for(var psgKey in this.choosePsg){
					 let psgItem = this.choosePsg[psgKey];
					 
					 if(psgItem['empId']){
						 psgArr.push(psgItem['empId'])
					 }
				 }
				 this.loadingSpgzList = true;
				// 出差申请单模式
				// if(this.userInfo.vipCorp.bookStyle == 2){
				// 	this.getTravelApprovalRules(isWei,psgArr);
				// }else{ // 单订模式
					this.getSingleApprovalRules(isWei,psgArr);
				// }
			},
			
			
			// 出差申请单模式下匹配审批规则
			getTravelApprovalRules(isWei,psgArr){
			
				let objParams = {};
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "fcny":  0,
				  "ifViolate": isWei?1:0,
				  "orderType": 11002, // 单据类型
				  "projectId": '',
				  "routeId": this.allData.routeId,
				  "travelEmpIds": psgArr.join(','),
				}
				this.$conn.getConn('commu.travelRouteMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
				},(err)=>{
					this.spgzErrorAjax(err)
				})
			},
			// 单订模式下批配审批规则
			getSingleApprovalRules(isWei,psgArr){
				let objParams = {};
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "ifViolate": isWei?1:0,
				  "orderType": 11002, // 单据类型
				  "projectId": '',
				  "travelEmpIds":  psgArr.join(','),
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
				},(err)=>{
					this.spgzErrorAjax(err)
				})
			},
			// 审批规则成功回调
			spgzSuccessAjax(res){
				this.spgzList = res.data || [];
				if(this.spgzList.length == 0 ){
					this.isWuXuShenPi = true;
				}else{
					// 如果是只有一个审批规则，则直接默认
					if(this.spgzList.length == 1){
						this.getCurrentSpgz(this.spgzList[0]);
					}
					this.isWuXuShenPi = false;
				}
				this.loadingSpgzList = false;
			},
			//  审批规则失败回调
			spgzErrorAjax(err){
				this.spgzList = [];
				this.isWuXuShenPi = false;
				this.$alert(err.tips,'温馨提示',{
					type:'error'
				})
				this.loadingSpgzList = false;
			},
			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzId || spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
				this.$emit('chooseSpgz',this.chooseSpgz)
			},
			
			
			
			
			// 关闭退改签 
			handleClose(){
				this.visible = false;
			},
			// 显示退改签规则
			showgz(){
				this.visible = true;
			},
			
			
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
	    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
	    border-radius: 5px;
	}
	.trh-main{
		font-family: '微软雅黑';
		color: #223344;
		.padL10{
			padding-left: 10px;
		}
		.el-card /deep/ .el-card__body{
			padding: 0;
		}
		.marT20{
			margin-top: 20px;
		}
		.trh-top{
			display: flex;
			height: 80px;
			text-align: left;
			background-color: #006BB9;
			padding:0 30px; 
			.jp-title{
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 80px;
				.tripType-title{
					font-size: 14px;
					margin-left: 20px;
				}
			}
		}
		.trh-con{
			padding: 30px;
			.trh-wf{
				text-align: left;
				line-height: 30px;
				font-weight: 600;
			}
			.trh-con-t{
				height: 80px;
				display: flex;
				justify-content: space-between;
				background-color: #F3F5F8 ;
				padding: 20px 0;
				box-sizing: border-box;
				margin-bottom: 15px;
				.tch-l0 {
					line-height: 40px;
					width: 45px;
					// border-right: 1px solid #D1DBE7;
					font-size: 14px;
					font-weight: bold;
					
				}
				.tch-l1 {
					// line-height: 40px;
					width: 165px;
					border-right: 1px solid #D1DBE7;
					font-size: 14px;
					font-weight: bold;
					
				}
				
				.trh-l2{
					width:460px;
					padding: 0 20px;
					border-right: 1px solid #D1DBE7;
					.trh-l2-hbt{
						display: flex;
						height: 18px;
						line-height: 16px;
						.trh-l2-hbl,.trh-l2-hbr{
							font-weight: bold;
							font-size: 18px;
							width: 133px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.trh-l2-hbc{
							width: 154px;
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;
						}
					}
					.trh-l2-hbf{
						display: flex;
						height: 18px;
						line-height: 16px;
						.trh-l2-hbfl,.trh-l2-hbfr{
							width: 133px;
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							display: flex;
							align-items: flex-end;
							justify-content: center;
						}
						.trh-l2-hbfc{
							width: 154px;
							background-color: #D1DBE7;
							height: 1px;
							margin-top: 7px;
						}
					}
				}
				
				.trh-l3{
					width: 194px;
					display: flex;
					justify-content: center;
					line-height: 40px;
					border-right: 1px solid #D1DBE7;
					.trh-l3-pad{
						padding-left:10px;
					}
				}
				
				.trh-l4{
					width: 144px;
					line-height: 40px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-l5{
					width: 150px;
					font-size: 14px;
					line-height: 40px;
				}
			}
			.trh-fl-main{
				height: 45px;
				background-color: #F3F5F8;
				padding: 12.5px 0;
				margin-bottom: 10px;
				display: flex;
				.trh-fl1{
					width: 45px;
					height: 20px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl2{
					width: 274px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl3{
					width: 209px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl4{
					width: 84px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl5{
					width: 174px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl6{
					width: 179px;
					border-right: 1px solid #D1DBE7;
				}
				.trh-fl7{
					width: 169px;
				}
			}
		}
		.approval-rule{
			margin-bottom: 30px;
			text-align: left;
			padding-left: 30px;
		}
		.approval-rule-title{
			margin: 30px 0 15px 0;
		}
		.setSelect{
			/deep/ .el-input__inner{
				width: 675px;
				height: 45px;
				background: #F4F9FF;
				border: 3px solid #006BB9;
				border-radius: 1px;
			}
		}
		// .trh-footer{
		// 	padding:0 30px 30px;
			
		// }
		/deep/ .el-dialog{
			width: 500px;
			text-align: left;
		}
		.dmain{
			.dia-gz{
				display: flex;
				margin-bottom: 15px;
				
				border: 1px solid #DBDBDB;
				.gz-l{
					width: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #EFEFEF;
				}
				.gz-r{
					width: 380px;
					padding:15px;
					line-height: 20px;
				}
			}
		}
	}
</style>
