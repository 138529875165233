<template>
	<div class="tkr-main">
		<yj-nav-detail></yj-nav-detail>	
		<div class="main">
			<tk-refund-person ref="tkRefundPerson" :allData="tkRefundData"></tk-refund-person>
			<yj-contact-info></yj-contact-info>
			<yj-reason :orderType="11002" :params="135" :showTGtext="true" :marginTop="'30px'" @refundChangeReason="getReason"></yj-reason>
			<div style="margin-top: 45px;padding-bottom: 50px;">
				<el-button class="w240 h64 font16" @click="cancel()">取消</el-button>
				<el-button class="w240 h64 font16"  type="primary" @click="submitRefund()">提交退票申请</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import TkRefundPerson from "./tkComponent/tkRefundChange/TkRefundPerson";
	export default{
		name: 'TkRefund',
		data() {
			return {
				tkRefundData: {}, //可退票乘机人信息
				psgIdList: [], //机票乘机人psgId集合
				reasonName:'', // 退票原因
				refundType:1, // 1自愿退票 2非自愿 
				snList:[], //航段id
				query:{},
				reasonObj:{},
			}
		},
		components:{
			'tk-refund-person': TkRefundPerson,
		},

		created() {
			this.query = this.$route.query;
			this.getApplyTrRefund();
		},
		methods: {
			// 获取  可申退的乘机人
			getApplyTrRefund(){
				let id = this.query.orderId;
				let refundFlag = this.query.refundFlag ==2?2:1;
				let obj = {
					orderId: id,
					// refundFlag: refundFlag,
				};
				let queryUrl = "tkQuery2023.refundApply"; // 'tkRefund.applyRefund'
				this.$conn.getConn(queryUrl,{jsfile:false})(obj,(res)=>{
					let data = res.data || {};
					let base = data.base || {};
					this.tkRefundData = {
						...data,
						...base
					}
			
				},(err)=>{
					this.$alert({
						message:err.tips,
						type: 'error',
					})
				})
			},
			// 取消 关闭本页面
			cancel(){
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							  type: 'warning'
				        }).then(() => {
							window.close();
				        }).catch(() => {
				            
				        });        
				
			},
			// 获取退票原因
			getReason(reasonObj){
				this.reasonObj = reasonObj || {};
			
			},
			getRefundTicketList() {
			  let ticketList = [],
						choosePsg = this.$refs['tkRefundPerson']['choosePsg'] || {};
						
				for (var psgId in choosePsg) {
					var psg = choosePsg[psgId] || {},
							obj = {
								psgId: psg.psgId,
								tkno: psg.tkno,
								psgName: psg.psgName,
							};
							
					ticketList.push(obj);
						
				}
			  return ticketList;
			},
			// 获取退票的人员信息
			getRefundPsgList(ticketList) {
			  let psgList = [];
			  if (ticketList) {
			    for(let i = 0;i<ticketList.length;i++) {
			      var psg = ticketList[i] || {};
			      psgList.push({
			        // psgId: psg.psgId, // 乘机人id
			        // refundSalePrice: psg.refundSalePrice, // 退销售费
			        // refundTax: psg.refundTax, // 退燃油费
			        // refundAirportFee: psg.refundAirportFee, // 退基建
			        // refundUpFee: psg.refundUpFee, // 退升舱费
			        // serviceFee: psg.serviceFee, // 退票加收服务费
			        // refundPoundage: psg.refundPoundage, // 退票手续费
			        // refundAmount: psg.refundAmount, // 应退金额
			        // airlineRefundPoundage: psg.airlineRefundPoundage, // 采购退票手续费
					// addRefundRate:psg.addRefundRate, // 加收退票费率
					refundServiceFee: psg.refundServiceFee, // 退服务费

					"addRefundRate": psg.addRefundRate, // 加收退票费率
					"cuRefundRate": psg.refundRate || psg.cuRefundRate,
					// "jj": 0,
					"psgId": psg.psgId, // 乘机人id
					"purchaseFee": psg.purchaseFee ,
					// "purchaseRefundAmount": 0,
					"purchaseRefundPoundage": psg.purchaseRefundPoundage ,
					"purchaseRefundRate":psg.purchaseRefundRate ,
					// "purchaseRefundUpFee": 0,
					"refundAmount": psg.refundAmount, // 应退金额
					"refundPoundage":  psg.refundPoundage, // 退票手续费
					"refundUpFee": psg.refundUpFee, // 退升舱费
					"serviceFee": psg.serviceFee, // 退票加收服务费
			      })
			    }
			  } else {
				let choosePsg = this.$refs['tkRefundPerson']['choosePsg'] || {},
					choosePsgList = Object.values(choosePsg);
					
			    for (let i=0;i<choosePsgList.length;i++) {
			      let psg = choosePsgList[i] || {};
			      psgList.push({
			        // psgId: psg.psgId || '', // 乘机人id
			        // refundSalePrice: 0, // 退销售费
			        // refundTax: 0, // 退燃油费
			        // refundAirportFee: 0, // 退基建
			        // refundUpFee: 0, // 退升舱费
			        // serviceFee: 0, // 退票加收服务费
			        // refundPoundage: 0, // 退票手续费
			        // refundAmount: 0, // 应退金额
			        // airlineRefundPoundage: 0, // 采购退票手续费
					// addRefundRate:0, // 加收退票费率
					// refundServiceFee: 0, // 退服务费


					"addRefundRate": 0, // 加收退票费率
					"cuRefundRate": 0,
					// "jj": 0,
					"psgId": psg.psgId, // 乘机人id
					// "purchaseFee": 0,
					// "purchaseRefundAmount": 0,
					// "purchaseRefundPoundage": 0,
					// "purchaseRefundRate": 0,
					// "purchaseRefundUpFee": 0,
					"refundAmount": 0, // 应退金额
					"refundPoundage":  0, // 退票手续费
					"refundUpFee": 0, // 退升舱费
					"serviceFee": 0, // 退票加收服务费
			      })
			    }
			  }
			  return psgList;
			},
			// 获取退票费 /1.0.0/et/queryRefundFee
			getRefundPrice(params) {
			  // 乘机人信息
			  let ticketList = this.getRefundTicketList();
			  let queryParams = {
			    refundFlag: params.refundFlag,
			    ticketList: ticketList,
			    orderId: params.orderId,
			  }
			  let queryUrL = "tkQuery2023.queryRefundFee"; // "tkRefund.queryRefundFee"
			  let queryRefundFee = this.$conn.getConn(queryUrL);
			  queryRefundFee(queryParams, (resp) =>{
			    let data = resp.data || {};
			    if (JSON.stringify(data) != '{}') {
			      let refundPoundage = data.refundPoundage? Math.abs(data.refundPoundage): 0,
			          refundAmount = data.refundAmount? Math.abs(data.refundAmount): 0;
			      let str = '根据退改签规则计算，提交退票将会产生<span class="huang">' + refundPoundage + '元</span>手续费，退还金额<span class="huang">' + refundAmount + '元</span>，是否继续'
						this.$confirm(str, '提示', {
							confirmButtonText: '继续',
							cancelButtonText: '取消',
							dangerouslyUseHTMLString: true, // 是否把message当html标签
							type: 'warning',
						}).then(() => {
							  // 是否含有退票信息 0-不含有 1-含有
							  params.hasRefundFee = 1;
							  // 乘机人
							  params.psgList = this.getRefundPsgList(data.ticketList);
							  // 申请退
							  this.submitRefundApply(params);
						}).catch(() => {
									
						})
			    } else {
			      // 是否含有退票信息 0-不含有 1-含有
			      params.hasRefundFee = 0;
			      // 乘机人
			      params.psgList = this.getRefundPsgList();
			      // 申请退
			      this.submitRefundApply(params);
			    }
			  }, () => {
			    // 是否含有退票信息 0-不含有 1-含有
			    params.hasRefundFee = 0;
			    // 乘机人
			    params.psgList = this.getRefundPsgList();
			    // 申请退
			    this.submitRefundApply(params);
			  })
			},
			// 提交退票申请
			submitRefund(){	
				var choosePsg = this.$refs['tkRefundPerson']['choosePsg'] || {};
				var chooseRange =  this.$refs['tkRefundPerson']['chooseRange'] || {};
				var needApprove = this.$refs['tkRefundPerson']['needApprove'];
				var chooseSpgz = this.$refs['tkRefundPerson']['chooseSpgz'] || {};
				var isWuXuShenPi = this.$refs['tkRefundPerson']['isWuXuShenPi'] ;
				
				let snList = Object.keys(chooseRange) || [];
				if(snList.length<1){
					this.$alert('请选择要退票的航段','',{
						type:"error",
					})
					return false
				}
				
				if(JSON.stringify(choosePsg) == '{}'){
					this.$alert('请选择要退票的人员','',{
						type:'error',
					})
					return false
				}
				if(!this.reasonObj.reasonName){
					this.$alert('请选择退票的原因','',{
						type:'error',
					})
					return false
				}
				if(this.reasonObj.isVoluntarily ==2 && !this.reasonObj.imageUrl){
					this.$alert('非自愿改签，请上传证明！','',{
						type:'error',
					})
					return false
				}
				// 审批  企业参数  个人匹配结果  个人选择结果
				if(this.tkRefundData.tripType == 1 && needApprove && !isWuXuShenPi && !chooseSpgz.gzId){
					this.$alert('请选择审批规则','',{
						type:'error',
					})
					return false
				}
				
				// let queryParams = {
				// 		  "appId":chooseSpgz.gzId,
				// 		  "ccsqdId": this.tkRefundData.ccsqdId,
				// 		  "orderFrom": "10503",
				// 		  "orderId": this.query.orderId,
				// 		  // "psgIdList": psgIdList.join(","),
				// 		  "snList": snList,
				// 		  "refundFlag": this.query.refundFlag == 2?2:1,
				// 		  "refundProveUrl":this.reasonObj.imageUrl,
				// 		  "refundReasonVip": this.reasonObj.reasonName,
				// 		  "refundResaon": this.reasonObj.reasonName,
				// 		  "refundType": this.reasonObj.isVoluntarily,
				// 		  "routeId":this.tkRefundData.routeId,
						
				// }
				let queryParams = {
					"appId": chooseSpgz.gzId,
					"ccsqdId": this.tkRefundData.ccsqdId,
					// "hasRefundFee": 0,
					// "jsBz": "string",
					// "keepSeat": 0,
					"linkEmail": this.tkRefundData.linkEmail,
					"linkman": this.tkRefundData.linkman,
					"linktel": this.tkRefundData.linkTel,
					"orderFrom": "10503",
					"orderId": this.query.orderId,
					"psgList": [
						// {
						// "addRefundRate": 0,
						// "cuRefundRate": 0,
						// "jj": 0,
						// "psgId": "string",
						// "purchaseFee": 0,
						// "purchaseRefundAmount": 0,
						// "purchaseRefundPoundage": 0,
						// "purchaseRefundRate": 0,
						// "purchaseRefundUpFee": 0,
						// "refundAmount": 0,
						// "refundPoundage": 0,
						// "refundUpFee": 0,
						// "serviceFee": 0
						// }
					],
					// "refundBxList": [
					// 	{
					// 	"bxId": "string"
					// 	}
					// ],
					"refundFlag": this.query.refundFlag == 2?2:1,
					"refundProveUrl": this.reasonObj.imageUrl,
					"refundReasonVip": this.reasonObj.reasonName,
					"refundResaon": this.reasonObj.reasonName,
					"refundType": this.reasonObj.isVoluntarily,
					// "remarksCu": "string",
					"returnType": 1, // 1.退票  2废票
					"routeId": this.tkRefundData.routeId,
					"snList": snList,
					// "userId": "string",
					// "ztSupplierCode": "string"
				}
								
				this.$confirm('1.退票前请先确认是否已经取消值机,取消值机后,才可以退票!\n2.退票可能产生退票手续费，请仔细查看退改签规则，是否继续退票？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					if (queryParams.refundType == 1) {
						this.getRefundPrice(queryParams);
					} else {
						// 是否含有退票信息 0-不含有 1-含有
						queryParams.hasRefundFee = 0;
						// 乘机人
						queryParams.psgList = this.getRefundPsgList();
						// 申请退
						this.submitRefundApply(queryParams);
					}
				}).catch(() => {
			
				})
			},
			// 申请退订  POST /1.0.0/et/refundApply     POST /1.0.0/et/createRefund
			submitRefundApply(params) {

				let queryUrl = "tkQuery2023.createRefund"; // 'tkRefund.refundAdd2'
				this.$conn.getConn(queryUrl,{jsfile:false})(params,(res)=>{
				
					let data = res.data || [];
					var obj = data[0] || {}; 
					if(obj.refundId){
						this.$router.push({path:'/yjDetail/tkRefundDetail',query:{orderId:obj.refundId,orderType:11002,}})
					}else {
						this.$alert('已提交退票，请到全部订单查看订单详情','温馨提醒',{
							type:"warning",
							confirmButtonText: '确定',
							callback:()=>{
								this.$router.push({
									path:'/yjTravel/myTravel/userCenter/order',
									query:{toBePaid:3}
								})
							}
						})
					}
				},(err)=>{
					this.$alert(err.tips,'申请退票失败',{
						type:"error",
						confirmButtonText: '确定',
						callback:()=>{
							
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.tkr-main{
		background-color: #EEF1F6;
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 40px;
		}
		.box-card{
			margin-top: 28px;
		}
		.w240{
			width: 240px;
		}
		.h64{
			height: 64px;
		}
		.font16{
			font-size: 16px; 
		}
	}
</style>

