<template>
	<div class="com-info">
		<el-card style="text-align: left;">
			<el-button :type="type==1?'primary':''" @click="type=1" >常旅客</el-button>
			<el-button :type="type==2?'primary':''" @click="type=2">常用联系人</el-button>
			<el-button :type="type==3?'primary':''" @click="type=3">常用地址</el-button>
			<!-- <el-button :type="type==4?'primary':''" @click="type=4">发票</el-button> -->
		</el-card>
		<div style="margin-top:20px;">
			<!-- 常旅客 -->
			<c-pssenger v-if="type==1"></c-pssenger>
			<!-- 常用联系人 -->
			<c-linkman  v-if="type==2"></c-linkman>
			<!-- 常用地址 -->
			<c-address  v-if="type==3"></c-address>
			<!-- 发票 -->
			<!-- <c-invoice  v-if="type==4"></c-invoice> -->
		</div>
	</div>
</template>

<script>
	import Passenger from "./comInfo/Passenger";
	import Linkman from "./comInfo/Linkman";
	import Address from "./comInfo/Address";
	import Invoice from "./comInfo/Invoice";
	export default{
		name:"ComInfo",
		data(){
			return {
				type:1, //枚举 1: 常旅客  2：常用联系人 3:常用地址 4：发票
			}
		},
		components:{
			"c-pssenger": Passenger,
			"c-linkman": Linkman,
			"c-address": Address,
			// "c-invoice": Invoice,
		},
		created(){
			this.type = 1;
		},
	}
</script>

<style scoped lang="scss">
	.com-info{
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
	}
</style>
