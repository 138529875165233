<template>
	<div class="ti-main">
		<div class="ti-dwhs">
			<div class="ti-swtich">
				<div class="ch-en">
					<span class="ch" @click="getCurrentStatus" :class="{color:currentType==1}">中文版</span>
					<span class="en" @click="getCurrentStatus" :class="{color:currentType==2}">English</span>
					<span :style="chooseStyle()"></span>
				</div>
			</div>
		</div>
		<div class="bg">
			<div class="setMarginT"></div>
			<div class="main">
				<div class="jh-top" >
					<img :src="corpInfo.image" alt="" class="img">
					<div class="jh-title">
						<p class="bold font16">{{corpInfo.corpName}}</p>
						<p>{{corpInfo.industryName}}</p>
						<p class="bold font16">{{corpInfo.english}}</p>
					</div>

				</div>
				<div class="borderT"></div>
				<div class="jh-main" v-if="currentType==1">
					<p class="bold font16 h21">行 程 单</p>
					<p class="textLeft marT10">
						<span>
							<span>尊敬的：</span>
							<span class="bold">{{chineseInfo.psgName}}</span>
						</span>
						<span class="padL20">
							<span>证件号：</span>
							<span class="bold">{{chineseInfo.idNo}}</span>
						</span>
					</p>
					<p class="textLeft marT10 line-h24">
						<span>感谢您选择我公司为您安排的旅行行程。以下是您此次行程的具体航班信息，请查收：</span>
					</p>
					<el-table :data="chineseInfo.orderRangeBeanList" class="marT10" border :header-cell-style="header">
						<el-table-column label="日期"  align="center" min-width="14%">
							<template slot-scope="scope">
								<div>{{scope.row.departDate}}</div>
								<div>{{scope.row.week}}</div>
							</template>
						</el-table-column>
						<el-table-column label="行程"  align="center" min-width="28%">
							<template slot-scope="scope">
								<div>
									<span>{{scope.row.departCityName}} {{scope.row.departAirportName}}</span>
									-
									<span>{{scope.row.arriveCityName}} {{scope.row.arriveAirportName}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="flightNo" label="航班" align="center" min-width="10%"></el-table-column>
						<el-table-column prop="model" label="机型" align="center" min-width="6%"></el-table-column>
						<el-table-column label="起飞-到达" align="center" min-width="14%">
							<template slot-scope="scope">
								<div>
									<span>{{scope.row.departTime}} - {{scope.row.arriveTime}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="flightTime" label="飞行时间" align="center" min-width="12%"></el-table-column>
						<el-table-column prop="cabinName" label="舱位" align="center" min-width="8%"></el-table-column>
						<el-table-column prop="status" label="状态" align="center" min-width="8%"></el-table-column>
					</el-table>
					<div class="textLeft">
						<p class="marT10">票号：{{chineseInfo.tkno}}</p>
						<p class="marT10 bold">提醒您注意：</p>
						<p class="padL20">国内航班请提前90分钟，国际航班请提前2个小时到达机场，办理乘机和安检手续。</p>
						<p class="marT10 bold">免责声明：</p>
						<p class="padL20">该内容仅供参考不作为值机依据。如遇航班号、起飞时间、舱位、机型等行程信息发生变更，请以航空公司实际公示信息为准，
							对此给乘机人造成的损失，本公司不承担任何责任。</p>
						<p class="padL20 marT10">若您在旅行过程中有任何需要解决的问题，请随时拨打我们的24小时免费电话{{corpInfo.officalPhone}}</p>
					</div>
					<div class="textLeft marT30">
						<p>{{corpInfo.corpFullName}} 为您开启空中旅程嘉年华</p>
						<p class="font12 marT10">
							<span>地址：{{corpInfo.address}}</span>
							<span class="padL20">电话：{{corpInfo.officalPhone}}</span>
							<span class="padL20" v-if="corpInfo.fax">传真：{{corpInfo.fax}}</span>
						</p>
						<p class="font12 marT10">
							<span>邮箱：<span class="color6F">{{corpInfo.email}} </span></span>
							<span class="padL20">邮编：{{corpInfo.postalCode}}</span>
						</p>
					</div>
					<div class="marT30 padB20 button">
						<el-button class="btn" type="primary" @click="getWord">导出 word</el-button>
						<el-button class="btn" type="primary" @click="toPrint">打印</el-button>
						<el-button class="btn" @click="close">关闭</el-button>
					</div>
					<div class="h21"></div>
				</div>
				<div class="jh-main" v-if="currentType==2">
					<p class="bold font18 h21">Itinerary</p>
					<p class="textLeft marT10">
						<span>
							<span>Dear：</span>
							<span class="bold">{{englishInfo.psgName}}</span>
						</span>
						<span class="padL20">
							<span>Passport no：</span>
							<span class="bold">{{englishInfo.idNo}}</span>
						</span>
					</p>
					<p class="textLeft marT10 line-h24">
						<span  >Thank you for choosing the itinerary arranged by our company for you. The following is the
							specific flight information of your itinerary, please check:</span>
					</p>
					<el-table :data="englishInfo.orderRangeBeanList" class="marT10" border :header-row-style="header" >
						<el-table-column label="Date" align="center" min-width="12%">
							<template slot-scope="scope">
								<div>{{scope.row.departDate}}</div>
								<div>{{scope.row.week}}</div>
							</template>
						</el-table-column>
						<el-table-column label="Route" align="center"  min-width="26%">
							<template slot-scope="scope">
								<div>
									<span>{{scope.row.departCityName}} {{scope.row.departAirportName}}</span>
									-
									<span>{{scope.row.arriveCityName}} {{scope.row.arriveAirportName}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="flightNo" label="Flight No."  align="center"  min-width="11%"></el-table-column>
						<el-table-column prop="model" label="Aerocraft" align="center"  min-width="9%"></el-table-column>
						<el-table-column label="Dep.-Arr."  align="center"  min-width="12%">
							<template slot-scope="scope">
								<div>
									<span>{{scope.row.departTime}} - {{scope.row.arriveTime}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="flightTime" label="Duration"  align="center"  min-width="10%"></el-table-column>
						<el-table-column prop="cabinName" label="Class"  align="center" min-width="10%"></el-table-column>
						<el-table-column prop="status" label="Status"  align="center" min-width="10%"></el-table-column>
					</el-table>
					<div class="textLeft">
						<p class="marT10">TICKETS：{{chineseInfo.tkno}}</p>
						<p class="marT10 bold">Note:</p>
						<p class="padL20">Please go to the check-in desk 90minutes/2hours before departure for
							domestic/international flight.</p>
						<p class="marT10 bold">Liability Disclaimer:</p>
						<p class="padL20">This content will only serve as a reference. In case of flight information
							change,the notice of airlines shall prevail. We are not responsibility to the loss of
							passengers occur with flight information change.

							(The above contents may be translated into other languages, but the Chinese version shall
							always prevail)</p>
						<p class="padL20 marT10">Should you have any further information, please feel free to contact us
							at {{corpInfo.officalPhone}}</p>
					</div>
					<div class="textLeft marT30">
						<p>{{corpInfo.corpFullName}} 为您开启空中旅程嘉年华</p>
						<p class="font12 marT10">
							<span>地址：{{corpInfo.address}}</span>
							<span class="padL20">电话：{{corpInfo.officalPhone}}</span>
							<span class="padL20" v-if="corpInfo.fax">传真：{{corpInfo.fax}}</span>
						</p>
						<p class="font12 marT10">
							<span>邮箱：<span class="color6F">{{corpInfo.email}}</span></span>
							<span class="padL20">邮编：{{corpInfo.postalCode}}</span>
						</p>
					</div>
					<div class="marT30 padB20 button">
						<el-button class="btn" type="primary" @click="getWord">export word</el-button>
						<el-button class="btn" type="primary" @click="toPrint">print</el-button>
						<el-button class="btn" @click="close">close</el-button>
					</div>
					<div class="h21"></div>
				</div>
			</div>
			<div class="setMarginB"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TkRouteItinerary',
		data() {
			return {
				currentType: 1, // 中文1 英文2

				chineseInfo: {}, // 中文信息
				englishInfo: {}, // 英文信息
				header:{ // 头部信息
					color: '#000000',
					'font-family': '微软雅黑',
				},
				corpInfo:{}, //当前企业信息
			}
		},
	
		computed: {
			query() {
				return this.$route.query
			},
		},
		
		mounted() {
			this.getChinese(1);
			this.getEnglish(1);
			
			this.corpInfo = this.$common.getCompanyDetails()
		},
		methods: {
			
			// 选中 中英文
			chooseStyle() {
				var styleObj = {
					position: 'absolute',
					left: `${(this.currentType-0-1)*82}px`,
					top: 0,
					transition: 'all 0.4s ease',
					'z-index': 1,
					width: 82 + 'px',
					height: 28 + 'px',
					background: '#FFFFFF',
					'border-radius': 28 + 'px',
				}
				return styleObj;
			},
			getCurrentStatus() {
				this.currentType = (this.currentType == 1 ? 2 : 1);
			},
			
			// 获取中文
			getChinese(num) {
				let queryParams = {};
				queryParams = {
					id: this.query.orderId,
					printOrQuery: num,
				}
				this.$conn.getConn('tkOrder.chineseItinerary')(queryParams, (res) => {
					this.chineseInfo = res.data || {};
					if(num==2){
						window.location.href =res.data.path;
					}
				}, (err) => {

				})
			},
			// 获取英文
			getEnglish(num) {
				let queryParams = {};
				queryParams = {
					id: this.query.orderId,
					printOrQuery: num,
				}
				this.$conn.getConn('tkOrder.englishItinerary')(queryParams, (res) => {
					this.englishInfo = res.data || {};
					if(num==2){
						window.location.href =res.data.path;
					}
				}, (err) => {

				})
			},
			getWord(){
				if(this.currentType==1){
					this.getChinese(2)
				}else{
					this.getEnglish(2)
				}
				
			},
			// 打印
			toPrint() {
				window.print();
			},
			// 关闭当前页面
			close() {
				window.close();
			}

		}
	}
</script>

<style lang="scss" scoped>
	.ti-main {
		color: #000000;
		font-family: "微软雅黑";

		.ti-dwhs {
			width: 100%;
			height: 80px;
			text-align: center;
			background: #606266;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 10;

			.ti-swtich {
				display: inline-block;
				width: 164px;
				height: 28px;
				padding: 26px 0;

				.ch-en {
					width: 164px;
					height: 28px;
					line-height: 28px;
					border-radius: 28px;
					background-color: #cccccc;
					font-size: 14px;
					font-weight: 400;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					text-align: center;

					.ch,
					.en {
						display: inline-block;
						width: 82px;
						height: 28px;
						color: #000000;
						position: absolute;
					}

					.ch {
						left: 0;
						top: 0;
						z-index: 4;
					}

					.en {
						left: 82px;
						top: 0;
						z-index: 3;
					}

					.color {
						color: #00A7FA;
						transition: all 0.3s ease;
					}
				}
			}
		}

		

		.bg {
			position: absolute;
			width: 100%;
			height: auto;
			z-index: 8;
			background-color: rgba(0, 0, 0, 0.5);
			
			.main {
				width: 762px;
				background: #fff;
				padding-left: 20px;
				padding-right: 20px;
				margin: 0 auto;
				.jh-top {
					// display: flex;
					// justify-content: space-between;
					padding-top: 30px;
					.img{
						width: 200px;
						height: 100px;
					}
					.jh-title {
						text-align: right;

					}
				}

				

				.jh-main {
					margin-top: 30px;
					font-size: 16px;

					/deep/ .el-table th>.cell {
						padding-left: 2px;
						padding-right: 2px;
					}

					/deep/ .el-table .cell {
						padding-right: 2px;
						padding-left: 2px;
						word-break: break-word;
					}

					.btn {
						width: 120px;
						height: 46px;
						font-size: 14px;
						border-radius: 0;
					}
				}
			}
			
			.setMarginT{
				margin-top: 120px;
			}
			.setMarginB{
				margin-bottom: 40px;
			}
		}


		.bold {
			font-weight: bold;
		}

		.h21 {
			height: 21px;
		}

		.font12 {
			font-size: 12px;
		}

		.font15 {
			font-size: 15px;
		}

		.font16 {
			font-size: 16px;
		}
		.font18{
			font-size: 18px;
		}

		.padL20 {
			padding-left: 20px;
		}

		.padB20 {
			padding-button: 20px;
		}

		.marT10 {
			margin-top: 10px;
		}

		.marT30 {
			margin-top: 30px;
		}

		.marB20 {
			margin-bottom: 20px;
		}

		.line-h24 {
			line-height: 24px;
		}

		.textLeft {
			text-align: left;
		}

		.textRight {
			text-align: right;
		}

		.borderT {
			border: 1px solid #e7e7e7;
			margin-top: 16px;
		}
		.color6F{
			color: #66b1ff;
		}
		@media print {
			.ti-dwhs {
				display: none;
			}
			.button {
				display: none;
			}
			.setMarginT{
				display: none;
			}
			.setMarginB{
				display: none;
			}
		}
		
	}
</style>
