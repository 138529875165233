<template>
	<div style="height:100%;">

			<!-- 顶部导航栏 -->
			<yj-nav></yj-nav>
			<!-- 头部 -->
			<book-header></book-header>
			<!-- 主体 -->
			<el-main>
					<router-view />
			</el-main>
			<!-- footer -->

		
	</div>
</template>

<script>
	import BookHeader from './components/book/BookHeader.vue'
	export default {
		name: "YjTravel",
		data() {
			return {
			
				
			};
		},
		components: {
			BookHeader
		},
		
	
	};
</script>

<style lang="scss">
	.pointer{
		cursor: pointer;
	}
	.el-popover.setPopover{
		padding: 0;
		.marL20{
			margin-left: 20px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.flexStart{
			justify-content: flex-start;
		}
		.setButton{
			width: 100%;
			background-color:#F1F4F9;
			border:none;
			height: 43px;
			font-family: '微软雅黑';
			font-weight: 400;
			color: #637697;
			opacity: 0.8;
		}
		.setButton:hover{
			background-color:#dadbde;
			transition: all 0.3s ease;
		}
		.hhsz{
			white-space: pre-wrap;
			font-size: 13px;
		}
		
		
		.setAutoMain{
			font-size:14px;
			line-height: 15px;
			font-family: '微软雅黑';
			font-weight: 600;
			color: #637697;
			padding-top: 8px;
			min-width: 160px;
			max-width: 170px;
			.setSpan1{
				height:15px;
				display:inline-block;
				width: auto;
			
			}
			.setSpan{
				height:15px;
				display:inline-block;
				margin-left: 10px;
				padding-left: 10px;
				border-left: 2px solid #637697;
			}
		}
		.setQX{
			margin-top: 10px;
			.setwidth{
				font-size: 16px;
				margin-right: 11px;
				cursor: pointer;
			}
			.setBorder1{
				color:#4E90F1;
			}
			.setBorder2{
				color:#67C2A5;
			}
			.setBorder3{
				color:#64B2F5;
			}
			.setBorder4{
				color:#FFA21D;
			}
		}
	}
	
	.xzApp{
		width: 70px;
		height: 30px;
		border: 1px solid #E5EAF3;
		border-radius: 15px;
		color: #637697;
		line-height: 28px;
		font-size: 12px;
		cursor: pointer;
	}
	.cxzApp{
		background: #637697;
		color: #FFFFFF;
	}
	
	.setAvatar{
		font-family: '微软雅黑';
		.setname{
			font-size: 14px;
			font-weight: 400;
			color: #0B387A;
			padding-right: 13px;
		}
		
	}
	.el-container {
	   
		width: 100%;
		height: 100%;
	}

	.el-header {
		padding: 0 !important;
		margin: 0;
		z-index: 9999;
		background-color: #fff;
	}

	.el-header-content {
		display: flex;
		justify-content: space-between;
		align-content: center;
		width: 1200px;
		padding: 0px;
		height: 100%;
		margin: 0 auto;
		//background-color: #fff;

		.logo {
			width: 280px;
			padding: 10px 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 28px;
			height: 60px;
			img {
				height: 100%;
				width: auto;
				line-height: 60px;
				margin-right: 15px;
			}
			.shortname{
				display: inline-block;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.block {
			height: 100%;
			display: flex;
			align-items: center;
			.el-dropdown {
				height: 100%;

				display: flex;
				align-items: center;

			}

			.color-theme {
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
	}

	.el-main {
		// height: 100%;
		width: 100%;
		padding: 0 !important;
		margin: 0 auto;
	}
	
</style>
