<template>
	<div class="aol-main">
		<el-table :data="orderList" style="width: 100%;">
		    <el-table-column label="行程类型" width="200" show-overflow-tooltip >
				<template slot-scope="scope">
					<div class="flex" 
						v-if="(titleType==0 || titleType==1) && (scope.row.orderType == '11001' || scope.row.orderType== '11004' || scope.row.orderType== '11006' || scope.row.orderType== '11005' || scope.row.orderType== '11002' || scope.row.orderType== '11003') ">
						<div>
							<el-button type="text" class="iconfont yj-icon-airplane-full setIcon"></el-button>
						</div>
						<div class="aol-title">
							<div class="aol-con-title">
								{{scope.row.international==1?'国内机票':'国际机票'}}
								<span  style="font-size: 12px;">
									<span v-if="scope.row.tripType==1" style="color:#409EFF;">
										[公]
									</span>
									<span v-if="scope.row.tripType==2" style="color:#FF9524;">
										[私]
									</span>
									<span v-if="tabIndex==1">
										<span v-if="scope.row.isrefund && scope.row.isrefund>1"  style="color:#ff6666;">[退]</span>
										<span v-if="scope.row.ischange && scope.row.ischange>1" style="color:#1FBA65;">[改]</span>
									</span>
									<span v-if="(tabIndex==4 || tabIndex==3)">
										<span style="color:#ff6666;" v-if="scope.row.orderType== '11002' || scope.row.orderType== '11005'">[退]</span>
										<span style="color:#1FBA65;" v-if="scope.row.orderType== '11003' || scope.row.orderType== '11006'">[改]</span>
									</span>
								</span>
							</div>
							<div class="padT12">{{scope.row.routeName}}</div>
							<div class="padT12 colorB3">预:{{scope.row.bookDate}}</div>
						</div>
					</div>
					<div class="flex"  v-if="(titleType==0 || titleType==2) && (scope.row.orderType == '11007' || scope.row.orderType == '11008')">
						<div>
							<el-button type="text" class="iconfont yj-icon-jiudian1 setIcon"></el-button>
						</div>
						<div class="aol-title">
							<div class="aol-con-title">酒店
							<span  style="font-size: 12px;">
								<span v-if="scope.row.tripType==1" style="color:#409EFF;">
									[公]
								</span>
								<span v-if="scope.row.tripType==2" style="color:#FF9524;">
									[私]
								</span>
								<span v-if="tabIndex==1">
									<span v-if="scope.row.isrefund && scope.row.isrefund>1"  style="color:#ff6666;">
										[退]
									</span>
								</span>
								<span v-if="(tabIndex==4 || tabIndex==3) && scope.row.orderType== '11008'" style="color:#ff6666;">
									[退]
								</span>
							</span>	
							</div>			
							<div class="padT12">{{scope.row.routeName}}</div>
							<div class="padT12 colorB3">预:{{scope.row.bookDate}}</div>
						</div>
					</div>
					<div class="flex"  v-if="(titleType==0 || titleType==3) && (scope.row.orderType== '11009' || scope.row.orderType == '11010' ||  scope.row.orderType== '11011')">
						<div>
							<el-button type="text" class="iconfont yj-icon-train2huoche setIcon"></el-button>
						</div>
						<div class="aol-title">
							<div class="aol-con-title">火车票
							<span  style="font-size: 12px;">
								<span v-if="scope.row.tripType==1" style="color:#409EFF;">
									[公]
								</span>
								<span v-if="scope.row.tripType==2" style="color:#FF9524;">
									[私]
								</span>
								<span v-if="tabIndex==1">
									<span v-if="scope.row.isrefund && scope.row.isrefund>1"  style="color:#ff6666;">[退]</span>
									<span v-if="scope.row.ischange && scope.row.ischange>1" style="color:#1FBA65;">[改]</span>
								</span>
								<span v-if="(tabIndex==4 || tabIndex==3)" >
									<span style="color:#ff6666;" v-if="scope.row.orderType== '11010'">[退]</span>
									<span style="color:#1FBA65;" v-if="scope.row.orderType== '11011'">[改]</span>
								</span>
							</span>
								
							</div>
							
							<div class="padT12">{{scope.row.routeName}} {{scope.row.oFlightNo}}</div>
							<div class="padT12 colorB3">预:{{scope.row.bookDate}}</div>
						</div>
					</div>
					<!-- 用车 -->
					<div class="flex"  v-if="(titleType==0 || titleType==5) && scope.row.orderType == '11018'">
						<div>
							<el-button type="text" class="iconfont yj-icon-car-full setIcon"></el-button>
						</div>
						<div class="w300 aol-title">			
							<div class="aol-con-title">用车</div>
							<div class="padT12">{{scope.row.routeName}}</div>
							<div class="padT12 colorB3">预:{{scope.row.bookDate}}</div>
						</div>
					</div>
				</template>
		    </el-table-column>
		    <el-table-column prop="oDepartTime" label="出行/入住时间" show-overflow-tooltip width="135"></el-table-column>
		    <el-table-column prop="travelEmp"  label="出行人" show-overflow-tooltip width="110"></el-table-column>
			<el-table-column label="价格" width="85">
				<template slot-scope="scope">
					<span v-if="tabIndex==4">
						<span style="color:#FF9524;font-size:14px;font-weight: bold;" v-if="(scope.row.salePrice>=0 || scope.row.salePrice<0) && scope.row.orderStatus != 3">¥{{scope.row.salePrice}}</span>
						<span style="color:#FF9524;font-size:14px;" v-else>待定</span>
					</span>
					<span v-else>
						<span style="color:#FF9524;font-size:14px;font-weight: bold;" v-if="scope.row.salePrice>=0 || scope.row.salePrice<0">¥{{scope.row.salePrice}}</span>
						<span style="color:#FF9524;font-size:14px;" v-else>待定</span>
					</span>
					
				</template>
			</el-table-column>
			<el-table-column prop="orderNo" label="订单号" width="130">
				<template slot-scope="scope">	
					<span class="setHiden colOrderNo pointer" @click="goToDetail(scope.row)">{{scope.row.orderNo}}</span>
				</template>
				
			</el-table-column>
			<el-table-column prop="orderStatus" label="状态" width="70">
				<template slot-scope="scope">
					<div class=" textLeft color42">
						<div class="color42" v-if="scope.row.ischange==2">部分改签</div>
						<div class="color42" v-if="scope.row.ischange==3">全部改签</div>
						<div class="color42" v-if="scope.row.isrefund==2">部分退票</div>
						<div class="color42" v-if="scope.row.isrefund==3">全部退票</div>
						<div v-if="(scope.row.ischange<=1 || !scope.row.ischange) && (scope.row.isrefund<=1 || !scope.row.isrefund)" :class="{colorRed:scope.row.orderStatus==7 || scope.row.orderStatus==8}">{{scope.row.orderStatusRemark}}</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="address" label="操作" width="177" padding="0">
				<template slot-scope="scope">
					<div>
						<el-button size="mini" class="btnpad" v-if='scope.row.isShowRefund==1 && !(scope.row.failureCode == "602020" || scope.row.failureCode == "600108" || scope.row.failureCode == "600109" || scope.row.failureCode == "602021" || scope.row.failureCode == "602001" || scope.row.failureCode == "602009")' @click="refund(scope.row)">{{scope.row.orderType | filterExitText}}</el-button>
						<!--火车票 正常单 14-手机未核验，改签单 16-手机未核验 引导用户去核验-->
						<!-- <el-button size="mini" class="btnpad" v-if="((scope.row.orderType == 11009 && scope.row.orderStatus == 14) || (scope.row.orderType == 11011 && scope.row.orderStatus == 16)) && scope.row.isShowRefund==1" @click="checkLogin">12306核验</el-button> -->
						
						<!-- <el-button size="mini" class="btnpad" v-if="scope.row.orderType!=11009 && scope.row.orderType!=11010 && scope.row.isShowEndorse == 1" @click="change(scope.row)">改签</el-button> -->
						<el-button size="mini" class="btnpad" v-if='scope.row.isShowEndorse == 1 && !(scope.row.failureCode == "602020" || scope.row.failureCode == "600108" || scope.row.failureCode == "600109" || scope.row.failureCode == "602021" || scope.row.failureCode == "602001" || scope.row.failureCode == "602009")' @click="change(scope.row)">改签</el-button>
						<!--火车票 正常单 14-手机未核验，改签单 16-手机未核验 引导用户去核验-->
						<!-- <el-button type="mini" class="btnpad" v-if="(scope.row.orderType == 11009 &&  && scope.row.orderStatus == 14) || (scope.row.orderType == 11011 && scope.row.isShowEndorse == 1 && scope.row.orderStatus == 16)" @click="checkLogin">12306核验</el-button> -->
						
						<el-button type="mini" class="btnpad" v-if='(scope.row.orderType == 11009 || scope.row.orderType == 11011) && (scope.row.failureCode == "602020" || scope.row.failureCode == "600108" || scope.row.failureCode == "600109" || scope.row.failureCode == "602021" || scope.row.failureCode == "602001" || scope.row.failureCode == "602009")'  @click="checkLogin(scope.row)">12306核验</el-button>
						
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowApproval==1" @click="sendApprove(scope.row,tabIndex)">送审</el-button>
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowCancel==1" @click="cancel(scope.row,scope.row.orderType,scope.row.id)">取消</el-button>
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowConfirm==1" @click="confirmTicket(scope.row,tabIndex)">确认出票</el-button>
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowPay==1" @click="payment(scope.row)">支付</el-button>
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowEarlyDeparture == 1"  @click="refund(scope.row)">提前离店</el-button>
						<el-button size="mini" class="btnpad" v-if="scope.row.isShowCancelApprove == 1"  @click="cheHui(scope.row,tabIndex)">撤回</el-button>
						<el-button 
							size="mini" 
							class="btnpad" 
							v-if="canExtend(scope.row)"
						 	@click="beforeAgainOrder(scope.row)"
						 >续住</el-button>						
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div v-if="showVisible">
			<train-login
				:showTrLogin="false" 
				:orderVisible="visible"
				:checkVisible="checkVisible"
				:cusTomerSendSmsProp="cusTomerSendSmsProp"
				:isQueryOrderAccount="isQueryOrderAccount"
				:orderInfo="orderInfo"
				@needSuccess="needSuccess" 
				@loginSuccess="loginSuccess" 
				@cancelLogin="cancelLogin"
				class="orderTrLogin"
				></train-login>
		</div>
		
	</div>
</template>

<script>
	import caozuoMixin from '@/mixin/models/caozuoMixin.js';
import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';
	export default{
		name: 'AllOrderList',
		mixins: [caozuoMixin.commonConfig],
		data(){
			return {
				visible: false, //是否显示 12036 登录账号
				showVisible: false,  //是否显示 12036登录账号
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				} 
			}
		},
		props:{
			orderList:{
				type: Array,
				default(){
					return [];
				}
			},
			titleType:{
				type: [Number,String],
				default: 0 || '0',
			},
			tabIndex:{
				type: [Number,String],
				default: 1 || '1',
			},
			params20032:{
				type: [Number,String],
				default: 1 || '1',
			}
		},
		components:{
			'train-login':TrainLogin
		},
		methods:{
			// 是否可以续住 国际酒店隐藏续住
			canExtend(orderDetail) {
				if (!orderDetail) return false;
				if (
					(orderDetail.orderStatus == 5 || 
					orderDetail.orderStatus == 6 || 
					orderDetail.orderStatus == 7 || 
					orderDetail.orderStatus == 8 || 
					orderDetail.orderStatus == 9) && 
					orderDetail.international == 1 && 
					(orderDetail.orderType == '11007' || 
					orderDetail.orderType == '11008')
				) {
					return true;
				}
				return false;
			},
			// 酒店续住
			beforeAgainOrder: async function(orderItem) {
				let self = this;
				const loading = this.$loading({
					target: 'aol-main',
					lock: true,
					text: '请稍后...',
					spinner: 'el-icon-loading',
					// background: 'rgba(0, 0, 0, 0.7)'
				});
				
				try {
					let minEmp;
					// 先获取订单详情 
					const {data: hotelOrderData} = await this.$conn.getConn('hotelOrder.hotelOrderDetail')({orderId: orderItem.id});
					// 因公-验证有房
					if (hotelOrderData.tripType == 1) {						
						const {httpStatus: validStatus, errMsg} = await this.$conn.getConn('htbook.isHotelValid')({jdid: hotelOrderData.jdid});
						if (validStatus != 200) {
							this.$alert(errMsg,'',{
								type: 'error'
							})
							loading.close();
							return;
						}
					}
					let userInfo = this.$common.sessionget('user_info') || {};
					// 当前日期
					let nowDay = this.$common.getDateMing(new Date(), 0);
					let now = hotelOrderData.ldrq >= nowDay ? hotelOrderData.ldrq : nowDay;
					let next = this.$common.getDateMing(now, 1);

					// 赋值roomList
					// self.$store.commit('htCn/COPY_ROOMS', hotelOrderData.roomList);
					// tripType 1 因公 2 因私 bookStyle 1单订模式 2出差申请单模式
					// if (hotelOrderData.tripType == 2 || userInfo.vipCorp.bookStyle == 1) {
					// 	let empInfo = userInfo.empInfo;
					// 	empInfo.useCardType ='1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
					// 	empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
					// 	empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
					// 	empInfo.cxrName = empInfo.empName; //当前出行人的中文名字
					// 	self.$store.commit('htCn/SET_LSR_LIST', [empInfo])
					// }
					// 出差申请单模式
					if (hotelOrderData.tripType == 1 && userInfo.vipCorp.bookStyle == 2 && hotelOrderData.ccsqdId) {
						// 获取申请单数据
						const {data: ccsqdInfo} = await this.$conn.getConn('travelRequest.travelRequestInfo')({ccsqdId: hotelOrderData.ccsqdId});
						// 任务 7975 多人并行 【正式环境】【紧急更新】申请单已报销了，前端还关联上了出差申请单预订了
						// 如果出差申请单已经报销，则增加出差申请单已报销提醒
						// canBook (integer, optional): 0不可预订 1可预订 ,
						// status (string, optional): 0草稿；1审批中；2审批通过；3审批拒绝；4已删除；5报销中；6已报销 ,
						if(ccsqdInfo && ccsqdInfo.canBook == 0  ){
							 let reasonMap = {
								 0:"草稿",
								 1:"审批中",
								 2:"审批通过",
								 3:"审批拒绝",
								 4:"已删除",
								 5:"报销中",
								 6:"已报销"
							 }
							 let ccsqdStatusTip = reasonMap[ccsqdInfo.status];
							 this.$alert(`当前订单关联的出差申请单[${ccsqdInfo.ccsqdNo}]不可预订;申请单状态:${ccsqdStatusTip || ''}`,'温馨提示',{
							 	type: 'error'
							 })
							 loading.close();
							 return;
							
						}
						
						// 根据routeId获取行程
						if (ccsqdInfo.allRouteList && ccsqdInfo.allRouteList.length > 0) {
							let allRouteList = [];
							ccsqdInfo.allRouteList.forEach(routers => {
								allRouteList = allRouteList.concat(routers);
							});
							let route = allRouteList.find(s => s.routeId == hotelOrderData.routeId)
							if (!route) {
								this.$alert('行程信息错误！','',{
									type: 'error'
								})
								loading.close();
								return;
							}
							let book = now >= route.routeStart && next <= route.routeEnd;
							if (!book) {
								let alertStr = '';
								if (!route.routeStart || !route.routeEnd) {
									alertStr = '行程变更，不能预订';
								} else {
									alertStr = '预定日期不在出差单期限内,无法预订！可预订日期范围为：' + (route.routeStart || '无') + '-' + (
										route.routeEnd || '无')
								}
								this.$alert(alertStr,'',{
									type: 'error'
								})
								loading.close();
								return;
							}

							let obj = {
								cxrList: [], // 出行人列表
								detail: ccsqdInfo,  // 出差申请单信息
								proType: route.proType, // 业务类型 机票 火车票
								currentRoute: route, // 当前行程信息
								routeId: route.routeId,  //出行id
								ccsqdNo: ccsqdInfo.ccsqdNo, //出差单号
								ccsqdId: ccsqdInfo.id, //申请单ID ,
							}
							
							self.$common.sessionset('chooseCcsqdObj',obj)

						}
						// 设置出差申请单数据
						self.$store.commit('common/setCcsqdInfo', {ccsqdInfo})
						// 设置酒店出差申请单数据
						self.$store.commit('htCn/SET_HTCN_CCSQD', ccsqdInfo)
					}

					//房间人员信息
					let roomList = hotelOrderData.roomList;
					let cxrListArr = [];
					for (let i = 0; i < roomList.length; i++) {
						let room = roomList[i];
						let cxrList = room.customerList;
						for (let c = 0; c < cxrList.length; c++) {
							var cxr = cxrList[c];
							cxr.deptId = cxr.empDeptid;
							cxr.empName = cxr.psgName;
							cxr.phoneNumber = cxr.psgPhone;
							cxr.costCenterCode = cxr.costCenterCode;
							cxr.costCenterId = cxr.costCenterId;
							cxr.costCenterName = cxr.costCenterName;
							cxrListArr.push(cxr);
						}
					}
					// 设置出行人 根据订单上人员
					self.$store.commit('htCn/SET_CXR_LIST', cxrListArr);
					minEmp = this.$common.getMinEmpRank(cxrListArr)


					let hotelQuery = {
						tripType: hotelOrderData.tripType,
					};
					if (minEmp != void 0) {
						hotelQuery = Object.assign(hotelQuery, {
							empRank: minEmp.empRank || 0, //   职级
							useNameclbz: minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
							minEmpRankEmpId: minEmp.empId, // 当前员工差旅标准的职级
						})
					}
					self.$store.commit('htCn/SET_HTCN_QUERY', hotelQuery)

					// 跳转续住
					let hotelItem = {
						checkInDate: now,
						checkOutDate: next,
						jdid: hotelOrderData.jdid,
						cityId: hotelOrderData.cityId,
						delayOrdreType:1, // 标识续住
					}
					let routeUrl = this.$router.resolve({
						name: 'HtCnDetails',
						query: hotelItem,
					})
					loading.close();
					window.open(routeUrl.href, '_blank')
				} catch(err) {
					let {tips} = err || {};
					tips && this.$alert(tips,'',{
						type: 'error'
					})
					loading.close();
				}
			},
			// 退票
			refund(orderItem){
				let orderType = orderItem.orderType;
				let path = this.$dict.orderRefundConfig(orderType);
				let refundFlag = '';
				let applyType = ''; //申请类型1火车票申请类型
				let findType = this.$route.query.findType;
				let query = {};
				if(orderType == 11001) {
					refundFlag = findType == 1 ? 1 : 2;
					refundFlag = findType !=4 ? 1 : 2;
				} else if(orderType == 11003) {
					refundFlag = 2;
				}
				if(orderType!=11009){
					applyType = 1;
				}
				
				query = {
					orderId:orderItem.id,
					orderType:orderType,
					tripType:orderItem.tripType,
					refundFlag:refundFlag,
					applyType:applyType,
                    isShowEarlyDeparture:orderItem.isShowEarlyDeparture || ''

				}
				
				if(this.params20032 == 0 && (orderType == 11001 || orderType == 11003 || orderType == 11004 || orderType == 11006  )){
					let refundMsg = '该订单无法进行退票，如需退票，请提交BPM机票更改申请流程！'
					 this.$alert(refundMsg, '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					            
					    }
					});
					
					return
				} else if ((orderType == 11009 || orderType == 11011) && orderItem.productType == 2) {
					let refundMsg = '当前订单暂无法提交，请联系空港嘉华客服热线：010-64581922协助处理！'
					 this.$alert(refundMsg, '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					            
					    }
					});
					
					return
				}
				if(path) {
					// 此处酒店单独处理
					if(path == "/yjTravel/book/htCnRouter/htRefundRooms" && orderItem.isShowEarlyDeparture!=1){
						this.$confirm('酒店仅支持全退，如需部分退请联系客服人员!', '温馨提示', {
						    confirmButtonText: '确定',
						    cancelButtonText: '取消',
						    type: 'warning'
						    }).then(() => {
						    	let routeUrl = this.$router.resolve({path: path,query:query});
						    	window.open(routeUrl.href,"_blank")
						    }).catch(() => {
						      this.$message({
						        type: 'info',
						        message: '已取消'
						    });              
						});
						
					}else{
						let routeUrl = this.$router.resolve({path: path,query:query});
						window.open(routeUrl.href,"_blank")
					}
				}else{
					this.$message({
						type:'error',
						message:'该订单无法进行退票,如需退票，请联系客服处理！'
					})	
				}
			},
			// 改签
			change(orderItem){
				// 改签标识
				let orderType = orderItem.orderType;
				let orderId = orderItem.id;
				let changeFlag = 1;
				if(orderType == 11003){
					changeFlag = 2;
				}
				
				if(this.params20032 == 0 && (orderType == 11001 || orderType == 11003 || orderType == 11004 || orderType == 11006  )){
					let refundMsg = '该订单无法进行改签，如需改签，请提交BPM机票更改申请流程！'
					 this.$alert(refundMsg, '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					            
					    }
					});
					
					return
				} else if ((orderType == 11009 || orderType == 11011) && orderItem.productType == 2) {
					let refundMsg = '当前订单暂无法提交，请联系空港嘉华客服热线：010-64581922协助处理！'
					 this.$alert(refundMsg, '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					            
					    }
					});
					
					return
				}
				
				let query = {
						orderId: orderItem.id,
						changeFlag: changeFlag,
						orderType: orderType,
						tripType: orderItem.tripType || "",
					}
					
					
					
				if(this.$dict.orderChangeConfig(orderType)) {
					let path = this.$dict.orderChangeConfig(orderType);
					let routeUrl = this.$router.resolve({path: path,query:query});
					window.open(routeUrl.href,"_blank")
				}else{
					this.$message({
						type:'error',
						message:'该订单详情路径，需自行配置！'
					})	
				}	
			},
			// 火车票支付时间
			overTime(orderItem){
				/*
					以下单时间为准 因为 倒计时组件是以下单时间与 分钟数 做倒计时计算
				*/
				// let today = this.$common.allToDay(); //今日日期 不能以当前时间做倒计时 
				let diffTime = 0; //对比分钟
				let time = 0;
				if(orderItem.terminal){
				  // 计算对比最晚支付时间
				  diffTime = this.$common.getDiffMinute(orderItem.bookDate ,orderItem.terminal);
				  let sTime = diffTime.toFixed(2).toString().split(".");
				  if(sTime.length==2){
					let second = sTime[1]-0>=10?((sTime[1]-0)*60/100).toFixed(0):((sTime[1]-0)*60/10).toFixed(0)
				  	time = sTime[0]+'.'+second;
				  }else{
				  	time = Math.ceil(diffTime)
				  }
				}
				return time;
			},
			// 支付
			payment(orderItem){
				let query = {};
				if(orderItem.orderType==11018){
					// 酒店
					query = {
						orderId: orderItem.id,
						orderType: orderItem.orderType,
						
					}		
				}else if(orderItem.orderType==11009 || orderItem.orderType==11011 || orderItem.orderType==11010){
					let time = this.overTime(orderItem);
					query = {
						orderId: orderItem.id,
						orderType: orderItem.orderType,	
						timeOut:  time,	
					}
				}else{
					query = {
						orderId: orderItem.id,
						orderType: orderItem.orderType,		
					}
				}
				let path = "/yjDetail/payment"
				
				let routeUrl = this.$router.resolve({path: path,query:query});
				window.open(routeUrl.href,"_blank")
			},
			//去详情
			goToDetail(orderItem){
				let url = this.$dict.detailConfig(orderItem.orderType)
				let query= {
						orderId :orderItem.id,
						orderType :orderItem.orderType,
					}
					
				if(url){
					let routeUrl = this.$router.resolve({path: url,query:query});
					window.open(routeUrl.href,"_blank")
				}else{
					this.$message({
						type: 'error',
						message:'请配置路由信息'
					})
				}
				
			},
			// 确认改签出票
			confirmTicket(orderItem,tabIndex){
				let queryParams = {};
					queryParams = {
						changeId: orderItem.id
					};
					this.$confirm('改签单确定要出票吗?', '', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'
					    }).then(() => {
					    	this.$conn.getConn('trChange.confirmTrChangeOrder',{jsfile:false})(queryParams).then((res)=>{
								this.$message({
								    type: 'success',
								    message: '出票成功'
								}); 
								this.$emit('resetOrderList', 0,tabIndex)
							}).catch((err)=>{
								this.$message({
								    type: 'error',
								    message: err.tips
								});   
							})
					    }).catch(() => {
					      this.$message({
					        type: 'info',
					        message: '关闭取消'
					    });              
					});
			},
			// 12306核验
			checkLogin(info={}){
				this.showVisible = true;
				this.orderInfo = {
					orderId: info.id,
					orderType: info.orderType,
				};				
				if(this.showVisible){
					setTimeout(()=>{
						if (info.failureCode == '602020' || info.failureCode == '600109') {
							this.checkVisible = true;
							this.cusTomerSendSmsProp = info.failureCode == '602020'? false: true;
						} else {
							this.visible = true;
						}
					},200)	
				}
			},
			//登录成功 
			loginSuccess(){
				this.showVisible = false;
				if(!this.showVisible){
					setTimeout(()=>{
						this.visible = false;
						this.checkVisible = false;
					},200)	
				}
			
			},
			//取消登录
			cancelLogin(){
				this.loginSuccess()
			},
			// 核验成功 重置订单列表
			needSuccess(){
				this.$emit('resetOrderList', this.titleType,this.tabIndex)
			},
			// 撤回
			cheHui(orderItem,tabIndex){
				this.goToCancelApprove({
							orderId: orderItem.id,
							orderType:orderItem.orderType,
						},(res)=>{
					
							this.$emit('resetOrderList', 0,tabIndex)
						},(err)=>{
						 	
					})
			},
			//送审 
			sendApprove(orderItem,tabIndex){
				let queryParams = {};
					queryParams = {
						orderId: orderItem.id,
						orderType: orderItem.orderType,
						spgzId: orderItem.appId
					};
				this.$confirm('您确定要送审吗?', '', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				    }).then(() => {
				    	this.$conn.getConn('commu.sendApp',{jsfile:false})(queryParams).then((res)=>{
							this.$message({
							    type: 'success',
							    message: '送审成功'
							}); 
							this.$emit('resetOrderList', 0,tabIndex)
						}).catch((err)=>{
							this.$message({
							    type: 'error',
							    message: err.tips
							});   
						})
				    }).catch(() => {
				      this.$message({
				        type: 'info',
				        message: '关闭取消'
				    });              
				});
			},
			//取消
			cancel(orderItem,orderType,orderId){
				
				let queryParams = {};
				queryParams = {
					orderId: orderId,
				}
				if(orderType == 11001 || orderType == 11004){
					// 机票	
					let path = 'tkOrder.cancelTkOrder';
					this.isCancelOrder(queryParams,path,0,this.tabIndex);
				}else if(orderType==11007){
					//酒店
					let path = 'htOuter.interHotelOrderCancel'
					this.interHotelOrderCancel(orderId, path,0,this.tabIndex);
				}else if(orderType==11009){
					//火车票 正常单
					let path = "trBook.trOrderCancel";
					if(orderItem.grab && orderItem.grab == 1) {
						path = "trBook.cancelTrGrad";
					}	
					this.isCancelOrder(queryParams,path,0,this.tabIndex);
				}else if(orderType==11011){
					//火车票 改签单
					let path = 'trChange.cancelTrChangeOrder';
					queryParams = {};
					queryParams = {
						changeId: orderId,
					}
					this.isCancelOrder(queryParams,path,0,this.tabIndex);
				}else if(orderType==11012){
					// 机场服务
					let path = "airService.cancelServiceOrder";
					this.isCancelOrder(queryParams,path,0,this.tabIndex);
				}else if(orderType==11014){
					// 代客泊车
					let path = "park.cancelParkOrder";
					this.isCancelOrder(queryParams,path,0,this.tabIndex);
				}else if(orderType==11018){
					// 用车
					let path = "carBook.getYcCancelFee";
					this.queryCarFine(queryParams,orderItem.id,path,this.tabIndex)
				}else if(orderType==11019){
					// 代办值机
					let path = "dbzj.checkInOrderCancel";
					queryParams = {
						id: orderId,
					}
					this.isCancelOrder(queryParams,path,0,this.tabIndex)
				}
			},
			// 订单是否  取消 此方法为处理重复代码
			isCancelOrder(queryParams,path,number,tabIndex){
				this.$confirm('您确定要取消订单吗?', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				    }).then(() => {
				    	this.toCancelOrder(queryParams,path,0,tabIndex);
				    }).catch((err) => {
				      this.$message({
				        type: 'info',
				        message: '关闭取消'
				    });              
				});
			},
			// 取消订单
			toCancelOrder(queryParams,path,number,tabIndex){
				this.$conn.getConn(path)(queryParams,(res)=>{
					this.$message({
						type: 'success',
						message: '取消成功!'
					});
					this.$emit('resetOrderList', number,tabIndex)
				},(err)=>{
					this.$message({
						type: 'success',
						message: err.tips
					});
				})
			},
			// 获取酒店 取消产生的罚金 
			interHotelOrderCancel(orderId,path,number){
				let queryParams= {};
				queryParams = {
					orderFrom : '10503',
					orderId: orderId
				}
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					let amount = data.amount?data.amount:0;
					let text = '';
					if(amount == 0){
						text = '您确定要取消订单吗?';	
					}else{
						text = '取消会产生罚金: ￥'+data.amount;
					}
					this.$confirm(text, '', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'
					    }).then(() => {
							let path = 'hotelOrder.hotelOrderCancel'
					    	this.toHtCancelOrder(data,orderId,path,0,this.tabIndex);
					    }).catch(() => {
					      this.$message({
					        type: 'info',
					        message: '关闭取消'
					    });              
					});
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					});
				})
			},
			// 酒店订单取消
			toHtCancelOrder(data,orderId,path,number,tabIndex){
				let queryParams = {};
				queryParams = {
					confirmId: data.confirmId,
					orderFrom: '10503',
					orderId: orderId,
				}
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type: 'success',
						message: '取消成功!'
					});
					this.$emit('resetOrderList', number,tabIndex)
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					});
				})
			},
			// 获取用车 取消产生的罚金
			queryCarFine(queryParams,id,path,tabIndex){
				let orderId = id;
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					let khFine = data.khFine ? data.khFine: 0;
					var text = '';
					if(khFine==0){
						text = '您确定要取消吗？';
					}else{
						text = '违约金'+khFine+'元，您确定要取消吗？';
					}
					this.$confirm(text, '', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'
					    }).then(() => {
							let path = 'carBook.cancelCarOrder'
					    	this.toCarCancelOrder(data,orderId,path,0,tabIndex);
					    }).catch(() => {
					      this.$message({
					        type: 'info',
					        message: '关闭取消'
					    });              
					});
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},
			// 用车订单取消
			toCarCancelOrder(data,orderId,path,numbe,tabIndex){
				let queryParams = {};
					queryParams = {
						confirmId: data.confirmId,
						orderId: orderId,
					}
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type:'success',
						message: '取消成功'
					})
					this.$emit('resetOrderList', number,tabIndex)
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},
			// 取消改签
			cancelChange(orderItem){
				let path = "";
				if(orderItem.orderType == 11003) {
					path = 'change.cancelChangeOrder';
				} else if(orderItem.orderType == 11011) {
					path = 'trChange.cancelTrChangeOrder';
				} 
				this.$confirm('您确定要取消改签吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.toCancelChange(orderItem.id,path);
				        }).catch(() => {
							
				          this.$message({
				            type: 'info',
				            message: '关闭取消'
				          });          
				        });
			},
			// 确认 取消改签
			toCancelChange(orderId,path){
				
				let queryParams = {};
					queryParams = {
						changeId : orderId,
					}
				this.$conn.getConn(path)(queryParams,(res)=>{
					this.$message({
						type:'success',
						message: '取消成功'
					})
					this.$emit('resetOrderList', 0,this.tabIndex)
				},(err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},
			// 取消退票
			cancelRefund(orderItem){
				let path = '';
				let reForm = '';
				let queryParams = {};
				if(orderItem.orderType == 11002){ // POST /1.0.0/et/refundCancel
					path = "tkQuery2023.refundCancel";
					queryParams = {
						id: orderItem.id,
						version:orderItem.verison
					}
					// path = 'tkRefund.cancelRefund';
					// queryParams = {
					// 	refundOrderId: orderItem.id,
					// }
				}else if(orderItem.orderType == 11010){
					path = 'trRefund.cancelTrRefund';
					queryParams = {
						id: orderItem.id,
					}
				}else if(orderItem.orderType == 11008){
					path = 'htRefund.cancelHtRefund';
					queryParams = {
						hotelRefundId: orderItem.id,
						orderFrom: '10503'
					}
				}
				this.$confirm('您确定要取消退票吗？', '', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				    }).then(() => {
				    	this.toCancelRefund(queryParams,path,0,this.tanIndex);
				    }).catch(() => { 
				      this.$message({
				        type: 'info',
				        message: '关闭取消'
				    });              
				});	
			},
			// 确认取消退票
			toCancelRefund(queryParams,path,number,tanIndex){
				this.$conn.getConn(path,{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type:'success',
						message: '取消成功'
					})
					this.$emit('resetOrderList', number,tanIndex)
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},
					
		},
	
		filters:{
			filterExitText(orderType){
				var text = '';
				if(orderType == 11007){
					text = '退订'
				}else{
					text = '退票'
				}
				return text
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	.aol-main{
		font-family: '微软雅黑';
		font-weight: Regular;
		color: #424242;
		.ol-main-title{
			padding: 30px 0;
			font-size: 12px;
			font-weight: bold;
			color: #808080;
			line-height: 12px;
			border-bottom:  1px solid #F2F2F2;
		}
		.aol-title{
			font-size: 12px;
			line-height: 12px;
		}
		.setIcon{
			font-size: 16px;
			padding: 0;
			margin: 0 10px 0 10px;
			vertical-align: initial;
			// color: #006BB9;
		}
		.aol-con-title{
			font-size: 16px;
			line-height: 16px;
			font-weight: Regular;
			color: #424242;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.btnpad{
			padding:8px 10px;
		}
		.textLeft{
			text-align: left;
		}
		.orderTrLogin{
			/deep/ .el-input__inner{
				height: 35px;
				background: #FFFFFF;
				border: 1px solid #CCCCCC!important;
				border-radius: 0;
				
			}
		}
		.setContent{
			text-align: left;
			border-bottom: 1px solid #F2F2F2;
			padding: 29px 0;
			
		}
		.setContent:hover{
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
		}
		.setPrice{
			font-size: 16px;
			font-weight: bold;
			color: #FF9524;
			line-height: 70px;
		}
		.cxrItem{
			line-height: 14px;
			padding: 28px 0;
		}
		// .colOrderNo{
		// 	line-height: 14px;
		// 	padding: 28px 0;
		// 	color: #00A7FA;
		// }
		.colOrderNo{
			color: #007FE9;
		}
		.padT12{
			padding-top: 12px;
		}
		.line-h70{
			line-height: 70px;
		}
		.colorB3{
			color: #B3B3B3;
		}
		.padTB19{
			padding: 19px 0;
		}
		.setlineH20{
			padding: 15px 0;
			line-height: 20px;
		}
		.setlineH16{
			padding: 11px 0;
			line-height: 16px;
		}
		.setHiden{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		// .w65{
		// 	width: 65px;
		// }
		// .w80{
		// 	width: 80px;
		// }
		// .w100{
		// 	width: 100px;
		// }
		// .w120{
		// 	width: 120px;
		// }
		// .w140{
		// 	width: 140px;
		// }
		// .w150{
		// 	width: 150px;
		// }
		// .w190{
		// 	width: 190px;
		// }
		// .w210{
		// 	width: 210px;
		// }
		
	}
</style>
