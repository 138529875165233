<template>
	<el-card class="tsc-main">
		<div class="tsc-title">
			<span class="tsc-title-name">筛选条件</span>
			<el-button type="text" class="tsc-title-close" @click="clearAll">全部清除</el-button>
		</div>
		<div class="tsc-content">
			<div v-for="item in trScreenData.trCheckListOption" :key="item.label" class="tsc-content-one">		  
			  <el-checkbox
			    @change="changeCheck(item,1)"
			    v-model="item.checked"
			  >{{ item.value }}</el-checkbox>
			</div>
		</div>
		<div class="tsc-content">
			<div class="tsc-content-title">出发时间</div>
			<div v-for="item in trScreenData.trStartTimeOption" :key="item.key" class="tsc-content-one">
			  <el-checkbox
			    @change="changeCheck(item,2)"
			    v-model="item.checked"
			  >{{ item.value }}</el-checkbox>
			</div>
			<div class="tsc-content-title ">到达时间</div>
			<div v-for="item in trScreenData.trEndTimeOption" :key="item.key" class="tsc-content-one">	 
			  <el-checkbox
			    @change="changeCheck(item,3)"
			    v-model="item.checked"
			  >{{ item.value }}</el-checkbox>
			</div>
		</div>
		<div class="tsc-content">
			<div class="tsc-content-title">出发车站</div>
			<div v-for="item in trScreenData.trDepartureStationOption" :key="item.label" class="tsc-content-one">
			  <el-checkbox
			    @change="changeCheck(item,4)"
			    v-model="item.checked"
			  >{{ item.fromStationName }}</el-checkbox>
			</div>
			<div class="tsc-content-title ">到达车站</div>
			<div v-for="item in trScreenData.trArriveStationOption" :key="item.label" class="tsc-content-one">	 
			  <el-checkbox
			    @change="changeCheck(item,5)"
			    v-model="item.checked"
			  >{{ item.toStationName }}</el-checkbox>
			</div>
		</div>
	</el-card>
</template>

<script>
	import {mapState,mapMutations} from 'vuex';
	export default{
		name: 'TrainScreenCheck',
		
		data() {
			return {
				trScreenData:{
						trCheckListOption:[
							{
								checked: false,
								value: "查看高铁",
								label: '1',
								text: 'G/C',
							},
							{
								checked: false,
								value: "查看动车",
								label: '2',
								text: 'D',
							},
							{
								checked: false,
								value: "查看普通",
								label: '3',
								text: 'K/Z/T',
							},
							{
								checked: false,
								value: "其他",
								label: '4',
								text: 'Q',
							}
						], // 可供预订 仅看高铁
						trStartTimeOption:[
							{
								checked: false,
								value: "00:00-06:00",
								label: "00:00",
								key:'startTime1'
							},
							{
								checked: false,
								value: "06:00-12:00",
								label: "06:00",
								key:'startTime2'
							},
							{
								checked: false,
								value: "12:00-18:00",
								label: "12:00",
								key:'startTime3'
							},
							{
								checked: false,
								value: "18:00-24:00",
								label: "18:00",
								key:'startTime4'
							}
						],
						trEndTimeOption:[
							{
								checked: false,
								value: "00:00-06:00",
								label: "06:00",
								key:'endTime1'
							},
							{
								checked: false,
								value: "06:00-12:00",
								label: "12:00",
								key:'endTime2'
							},
							{
								checked: false,
								value: "12:00-18:00",
								label: "18:00",
								key:'endTime3'
							},
							{
								checked: false,
								value: "18:00-24:00",
								label: "24:00",
								key:'endTime4'
							}
						],
						trDepartureStationOption:[],
						trArriveStationOption:[],	
					},
				
				screenTrainAllData:{}, //选中火车票所有筛选条件
				
				screenTrainTypeList:[], // 筛选后火车票类型条件列表
				screenTrainStartTimeList: [], // 筛选后 火车开始时间列表
				screenTrainEndTimeList: [], // 筛选后 火车票结束时间列表
				screenTrainFromStationList: [],// 筛选后 火车出发车站列表
				screenTrainToStationList: [], //筛选后 火车到达车站列表
				reFreshPage: false,
			}
		},
		computed:{
			...mapState({
				trainData: state => state.train.trainData, 
				searchData: state => state.train.trainQuery.searchData, 
			}),
			reFresh(){
				return this.searchData.reFresh;
			}
			
		},
		mounted() {
			
			if(this.trainData.trainList && this.trainData.trainList.length>0){
				this.trainDataEdit();
			}	
			
		},
		watch:{
			reFresh(val){
				if(val){
					if(this.trainData.trainList && this.trainData.trainList.length>0){
						this.clearAll();
						this.trainDataEdit();
					}
				}
			}
		},
		methods: {
			// 处理火车票列表中出发站与到达站选项
			trainDataEdit() {
				let trainToList = []; //到达城市列表
				let trainFromList = [];// 出发城市列表
				let trainToObj = {}; //到达城市对象
				let trainFromObj = {}; //出发城市对象
				let resultTrainFromList = []; //去重后  出发城市列表
				let resultTrainToList = []; //去重后  到达城市列表
				let toObj = {}; //去重 到达城市对象 
				let fromObj = {};  //去重 出发城市对象 
				trainToList = this.trainData.trainList.map((item)=>{
					trainToObj = {
						toStationCode:item.toStationCode,
						toStationName:item.toStationName,
						checked:false,
						key:item.trainNo+""+item.toStationCode,
					}
					return trainToObj
				})
				//去重 到达车站
				for(var j =0; j<trainToList.length; j++){
				    if(!toObj[trainToList[j].toStationCode]){
				        resultTrainToList.push(trainToList[j]);
				        toObj[trainToList[j].toStationCode] = true;
				    }
				}
							
				this.trScreenData.trArriveStationOption = resultTrainToList;
				 
				trainFromList = this.trainData.trainList.map((item)=>{
					trainFromObj = {
						fromStationCode:item.fromStationCode,
						fromStationName:item.fromStationName,
						checked:false,
						key:item.trainNo+""+item.fromStationCode,
					}
					
					return trainFromObj
				})
				//去重 出发车站
				for(var i =0; i<trainFromList.length; i++){
				    if(!fromObj[trainFromList[i].fromStationCode]){
				        resultTrainFromList.push(trainFromList[i]);
				        fromObj[trainFromList[i].fromStationCode] = true;
				    }
				}
				this.trScreenData.trDepartureStationOption = resultTrainFromList
			},		
			// 选中筛选条件
			changeCheck(checkObj,num) {
				if(num==1){
					this.screenTrainTypeList = [];
					this.trScreenData.trCheckListOption.map((item)=>{
						if(item.checked == true) {
							this.screenTrainTypeList.push(item)
						}
					})
				}else if(num==2){
					this.screenTrainStartTimeList = [];
					this.trScreenData.trStartTimeOption.map((item)=>{
						if(item.checked == true) {
							this.screenTrainStartTimeList.push(item)
						}
					})
				}else if(num==3){
					this.screenTrainEndTimeList = [];
					this.trScreenData.trEndTimeOption.map((item)=>{
						if(item.checked == true) {
							this.screenTrainEndTimeList.push(item)
						}
					})
				}else if(num==4){
					this.screenTrainFromStationList = [];
					this.trScreenData.trDepartureStationOption.map((item)=>{
						if(item.checked == true) {
							this.screenTrainFromStationList.push(item)
						}
					})
				}else if(num==5){
					this.screenTrainToStationList = [];
					this.trScreenData.trArriveStationOption.map((item)=>{
						if(item.checked == true) {
							this.screenTrainToStationList.push(item)
						}
					})
				}
				this.screenTrainAllData = {
					'screenTrainTypeList':this.screenTrainTypeList,
					'screenTrainStartTimeList':this.screenTrainStartTimeList,
					'screenTrainEndTimeList':this.screenTrainEndTimeList,
					'screenTrainFromStationList':this.screenTrainFromStationList,
					'screenTrainToStationList':this.screenTrainToStationList,
				}
				this.$emit("trCheckScreen", this.screenTrainAllData);
			},
			//全部清除，清除所有选中参数回归默认
			clearAll() {
				for(let key in this.trScreenData){
					this.trScreenData[key].map(item=>{
						item.checked = false;
						return item
					})
				}
			
				this.screenTrainAllData = {
					'screenTrainTypeList':[],
					'screenTrainStartTimeList':[],
					'screenTrainEndTimeList':[],
					'screenTrainFromStationList':[],
					'screenTrainToStationList':[],
				}
				this.$emit("trCheckScreen", this.screenTrainAllData);		
			}
		}
		
	
	}
</script>

<style scoped lang="scss">
	.tsc-main{
		margin-top: 15px;
		font-family: '微软雅黑';
		.tsc-title{
			display: flex;
			justify-content: space-between;
			// line-height: 49px;
			border-bottom: 1px solid #E6E6E6;
			padding-bottom: 15px;
			.tsc-title-name{
				font-size: 16px;
				font-weight: bold;
				color: #424242;
			}
			.tsc-title-close{
				font-size: 12px;
				font-weight: 400;
				color: #7F7F84;
				padding: 0;
				:hover{
					cursor: pointer;
					color: #00A7FA;
				}
			}
		}
		.tsc-content{
			padding:10px 0;
			border-bottom: 1px solid #E6E6E6;;
			
			.tsc-content-title{
				text-align: left;
				line-height: 34px;
			}
			.tsc-content-one{	
				display: flex;
				justify-content: flex-start;
				line-height: 34px;
				/deep/ .el-checkbox__label{
					padding-left: 20px;
					font-weight: 400;
					color: #424242;
				}
				
			}
		}
	}
</style>
