<template>
	<div class="tyr-main">
		<el-card>
			<div class="tyr-title">
				<span class="tyr-title-l">{{train.trainCode || train.flightNo}} ( {{seatName}} ) | {{monthDay}} {{week | filterWeekformat}}</span> 
				<span class="tyr-title-r" >
					<train-stop-over :stopOverObj="trStopOverObj" :stapBool="true" :textType="textColorType"></train-stop-over>
				</span>
			</div>
			<div class="tyr-pad tyr-w">
				<span class="bfb-w25 tyr-ilb">
					<div class="font18 bold">{{train.startTime || train.departTime}}</div>
					<div>{{train.fromStationName || train.departAirportName}}</div>
				</span>
				<span class="bfb-w50 tyr-ilb">
					<div class="line-h">{{train.runTime}}</div>
					<div class="relative tyr-line"></div>
				</span>
				<span class="bfb-w25 tyr-ilb">
					<div class="font18 bold">{{train.arriveTime}}</div>
					<div>{{train.toStationName || train.arriveAirportName}}</div>
				</span>
			</div>
		</el-card>
		<el-card class="mar-top">
			<div class="tyr-ft">
				<div class="border-btm">
					<span class="tyr-l">票价</span><span class="tyr-r">¥{{price}}/人</span>
					<span v-if="bxOrderList.length>0">
						<span v-for="item in bxOrderList" :key="item.bxid">
							<span class="tyr-l">{{item.bxxzmc}}</span><span class="tyr-r">¥{{item.saleprice}}/人</span>
						</span>
					</span>
					<span class="tyr-l">服务费</span><span class="tyr-r">¥{{fwfPrice}}/人</span>
					<p style="margin-top:10px;">
						<span class="tyr-l">乘车人数</span><span class="tyr-r" style="color: #007FE9;">{{cxrListNum}} 人</span>
					</p>
				</div>
				<span class="tyr-wr tyr-r">总计：<span class="tyr-cl">¥ {{totalPrice}}</span></span>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {mapState,mapStution} from 'vuex';
	import trainStopOver from '../trQuery/trainDialog/TrainStopOver';
	export default{
		name:'TrainYdRight',
		data() {
			return {
				train:{},
				monthDay:'',
				trStopOverObj:{},
				textColorType:2,
				seatName:'',
				price:0, // 票价
				week:'', 
        isXak: this.$route.query.sqdType == 'xak',
			}
		},
		
		components:{
			'train-stop-over':trainStopOver
		},
		
		computed: {
			...mapState({
				searchData: state => state.train.trainQuery.searchData,
				cxrList: state => state.train.cxrList,
				passengerLength: state=>state.train.passengerLength,
				bxOrderList: state=>state.train.trainEdit.bxOrderList,
				trainYdInfo: state=> state.train.trainYdInfo,
			}),
			tripType(){
				return this.searchData.tripType;
			},
			userInfo(){
				let userInfo = this.$common.sessionget('user_info') || {};
				return userInfo
			},
			vipCorp(){
				return this.userInfo.vipCorp || {};
			},
			// 经停站信息
			stopOverObj(){
				return this.trStopOverObj || {}
			},
			//保险订单金额
			bxPrice(){
				let price = 0 
				if(this.bxOrderList.length>0){
					this.bxOrderList.forEach((item)=>{
						price+=item.saleprice
					})
				}else{
					price = 0
				}
				return price
			},
			// 出行人数量
			cxrListNum(){
				let lenNum = 0;
				// 出差申请单模式
				if(this.tripType == 1 && this.vipCorp.bookStyle == 2){
					lenNum = this.cxrList.length;
				}else if(this.tripType == 1 && this.vipCorp.bookStyle == 1){
					lenNum = this.passengerLength;
					//单订模式
				}else {
					//因私订单
					lenNum = this.passengerLength;
				}
				return lenNum
			},
			// 服务费
			fwfPrice(){
				let num = 0
				if(this.tripType == 1){
					num = (this.trainYdInfo.fee && this.trainYdInfo.fee.feeAutoB) || 0;
				}else {
					num = (this.trainYdInfo.fee && this.trainYdInfo.fee.feeAutoP) || 0;
				}
				return num
			},
			totalPrice(){
				let zPrice = 0;
        if(this.isXak){
          zPrice = this.$common.addition((this.fwfPrice * this.cxrListNum),(this.bxPrice * this.cxrListNum),(this.trainYdInfo.dj *  this.cxrListNum));
        }else{
          zPrice = this.$common.addition((this.fwfPrice * this.cxrListNum),(this.bxPrice * this.cxrListNum),(this.trainYdInfo.selectSeat.price *  this.cxrListNum));
        }
				return zPrice
			}
		},
	
		mounted(){
      if(this.isXak){
        this.train = this.trainYdInfo;
        let date = this.trainYdInfo.departDate.slice(5);
        this.monthDay = date.substring(0,2)+"月"+date.substring(3,5)+'日';
        this.trStopOverObj = this.trainYdInfo;
        this.seatName = this.train.cabin;
        this.price = this.train.dj;
        this.week = this.trainYdInfo.departDate;
      }else{
        this.train = this.trainYdInfo;
        let date = this.trainYdInfo.trainStartDate.slice(4);
        this.monthDay = date.substring(0,2)+"月"+date.substring(2,4)+'日';
        this.trStopOverObj = this.train;
        this.seatName = this.train.selectSeat.seatName;
        this.price = this.train.selectSeat.price;
        let startDate = this.trainYdInfo.trainStartDate;
        let trainStartDate = "";
        if(startDate && startDate.length==8){
        	this.week = startDate.substring(0,4)+"-"+startDate.substring(4,6)+'-'+startDate.substring(6,8)
        }
      }
		}
	}
</script>

<style scoped lang="scss">
	/* bfb-w  表示百分比宽度*/
	.tyr-main{
		.el-card /deep/ .el-card__body{
			padding:0;			
		}
		.tyr-title{
			background: #006BB9;
			color:#fff;
			padding:10px 20px;
			line-height: 40px;
			.tyr-title-l{
				display: inline-block;
				text-align: left;
				width:80%;
				font-size: 18px;
			}
			.tyr-title-r{
				display: inline-block;
				text-align: right;
				width:20%;
			}
		}
		.tyr-pad{
			padding: 20px 0;
		}
		.mar-top{
			margin-top: 15px;
		}
		.tyr-w{
			.bfb-w25{
				width:25%;
			}
			.bfb-w50{
				width:50%;
			}
			.tyr-ilb{
				display: inline-block;
				vertical-align: middle;
				.line-h{
					    line-height: 30px;
					    margin-top: -10px;
				}
			}
			.relative{
				position: relative;
			}
			.tyr-line{
				width:100%;
				height:2px;
				background: #ccc;
				padding:-5px;
			}
			.tyr-line::before{
				content:'';
				position: absolute;
				left: 0;
				top: -4.5px;
				background: #ffffff;
				width: 9px;
				height: 9px;
				border:1px solid #ccc;
				border-radius: 50%;
				
			}
			.tyr-line::after{
				content:'';
				position: absolute;
				right: 0;
				top: -4.5px;
				background: #ffffff;
				width: 9px;
				height: 9px;
				border:1px solid #ccc;
				border-radius: 50%;
				
			}
			.font18{
				font-size:18px;
			}
			.bold{
				font-weight: bold;
			}
		}
		.tyr-ft{
			
			padding:20px;
			.tyr-l,.tyr-r{
				line-height: 20px;
				width:50%;
				display: inline-block;
			}
			.border-btm{
				padding-bottom: 20px;
				border-bottom:1px dashed #ccc;
			}
			.tyr-wr{
				margin:15px 0 0 0 ;
				width:100%
			}
			.tyr-l{	
				text-align: left;
			}
			.tyr-r{
				text-align: right;
			}
			.tyr-cl{
				color: #FF9524;
				font-size: 24px;
			}
		}
	}
</style>
