/**
 *  登录  局部混入，仅需要的地方混入
 *  用于登录跳转
 *  
 */
import {
	mapActions
} from "vuex";
	
var loginMixin = {
	data() {
		return {
			
		}
	},
	computed:{
		// 公司信息
		configData(){
			let configData = this.$common.getConfigData() || {};
			return configData
		},
		
	},
	methods: {
		...mapActions({
			GETUserInfo: 'common/getUserInfo',
		}),

		
		//天润+壳牌+标准单点登录 需要调用登录接口
		standardSSO(req,successFn,errorFn) {
			var param = {};
			if(req.loginType == 10) { //标准sso  + 天润新能 + 壳牌石油
				if(this.$common.ifStrBlank(req.ssotoken, 32)) {
					throw "-1|token失效！";
				} else {
					param.loginName = req.ssotoken;
					param.password = req.ssotoken;
					param.loginType = 10;
				}
			} else if(req.loginType == 12) { //金风科技，从ve过来
				if(this.$common.ifStrBlank(req.hyid, 32) || this.$common.ifStrBlank(req.sign, 32)) { //没有传会员id
					throw "-1|token失效！";
				} else {
					param.corpId = req.hyid;
					param.loginName = req.zcm;
					param.password = req.sign;
					param.loginType = 12;
				}
			}
		
			this.toSsoLoginAjax(param, req,successFn,errorFn);
		},
		// 中铁单点登录  无需登录，仅需要刷新token
		crecgSSO(req,successFn,errorFn) { //中国中铁单点登录||obj.searchType
				if(common.ifStrBlank(req.token, 32)) {
					throw "-1|token失效";
				}
				this.$common.sessionset('access_token', req.token);
				this.getUserInfo({"refresh": "1"},req,successFn,errorFn);
						
		},
		// 单点登录接口  调用 1.0.0 接口
		toSsoLoginAjax(param,req,successFn,errorFn){
			var urlStr = 'user.ssologin';
			this.$conn.getConn(urlStr)(param,(res)=>{
				var data = res.data || {};
				let token = data.token || data;
				if (token) {
					this.$common.sessionset('access_token', token);
					// 获取用户详情
					this.getUserInfo({},req,successFn,errorFn);
				}
			},(err)=>{
				errorFn&&errorFn(err)
			})
		},
		
		// 登录接口  1.1.0 接口
		toLoginAjax(param,req,successFn,errorFn){
			var urlStr = 'user.login';
			this.$conn.getConn(urlStr)(param,(res)=>{
				var data = res.data || {};
				let token = data.token;
				if (token) {
					this.$common.sessionset('access_token', token);
					// 获取用户详情
					this.getUserInfo({},req,successFn,errorFn);
				}else{
					successFn && successFn(false)
					this.$alert(data.msg,'',{
						type:'error',
					})
				}
			},(err)=>{
				errorFn&&errorFn(err)
			})
		},
		// 获取用户详情
		getUserInfo(userInfoParam,req,successFn,errorFn){
			
			let vm = this;
			this.GETUserInfo({
				vm,
				param:userInfoParam || {},
				cb: (data) => {
					successFn&&successFn(data);
					this.goToHomePath(req)
				},
				errorCb:(err)=>{
					errorFn&&errorFn(err)
				}
			})
		},
		// 跳转路由
		goToHomePath(obj) {
			obj = obj || {};
			obj.searchType = this.$common.trimToEmtry(obj.searchType, "home");
			obj.approvalNo = this.$common.trimToEmtry(obj.approvalNo || '');
			obj.tripType = this.$common.trimToEmtry(obj.tripType || '');
			obj.orderNo = this.$common.trimToEmtry(obj.orderNo || '');
			obj.orderType = this.$common.trimToEmtry(obj.orderType || '');

			obj.workItemId   = this.$common.trimToEmtry(obj.workItemId   || '');
			// 出差申请单 1标准版 2定制版
			let ccsqdType = 1;
			ccsqdType =  this.$common.getVipParamterByParNo(this.$store,20033, 1)
			var pathMap = {
				'home': '/yjTravel/home', //默认首页
				'flightList': '/yjTravel/book/tkRouter/tkQuery', //机票
				'hotelList': "/yjTravel/book/htCnRouter/htCnQuery", //酒店
				'trainList': "/yjTravel/book/trRouter/trQuery", //火车票
				'MyOrder': "/yjTravel/myTravel/userCenter/order", //我的订单
				'trainRefundTicket': '/yjEdit/trainRefund' , //（火车票 退入口）
				'trainChangeTicket': '/yjEdit/trainChange',  //（火车票 改入口）
				'hotelRefund': '/yjDetail/htRefundRooms',  //（酒店订单 退订入口）
				'flightRefundTicket': '/yjEdit/tkRefund',  //（机票 退入口）
				'flightChangeTicket': '/yjEdit/tkChange' , //（机票 改签入口）
				'hotelDetail': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/htCnOrderDetail',  //（酒店详）
				'flightDetail': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/tkOrderDetail' , //（机票详）
				'trainDetail': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/trainDetail',  //（火车票详）

				'hotelDetailSp': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/htCnOrderDetail',  //（酒店详）
				'flightDetailSp': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/tkOrderDetail' , //（机票详）
				'trainDetailSp': this.getPathUrl(obj.orderType,obj.orderNo) || '/yjDetail/trainDetail',  //（火车票详）

				'htInterNeed': '/yjTravel/book/inHtRouter/inHtEdit' , //（国际酒店需求单）
				'tkInterNeed': '/yjTravel/book/inTkRouter/inTkEdit',  //（国际机票需求单）
				'carDetail':'/yjDetail/carDetail',  //（用车订单详情）
				'travelDetail':ccsqdType == 1?'/ccsqdDetail':'/xakCcsqdDetail',  //（出差申请单详情）

				'travelDetailSp':ccsqdType == 1?'/ccsqdDetail':'/xakCcsqdDetail',  //（出差申请单详情审批）
			}
			this.$common.sessionset('ssoLogin',obj);
			var path = pathMap[obj.searchType];
			if (path) {
				let appType ;
				if(obj.searchType == 'hotelDetailSp' || obj.searchType == 'trainDetailSp' || obj.searchType == 'flightDetailSp' || obj.searchType == 'travelDetailSp'){
					appType = 1;
				}


				this.$router.replace({
					path: path,
					query: {
						ccsqdId: obj.approvalNo || undefined,
						tripType: obj.tripType || undefined,
						orderId: obj.orderNo || obj.approvalNo  || undefined,
						orderType:obj.orderType || undefined,
						workItemId  :obj.workItemId   || undefined,
						appType:appType
					}
				})
			} else {
				this.$router.replace({
					path: pathMap['home'],
				})
			}
			// 跳转后，获取详情，有延时
			// this.getCcsqdDetailByCcsqdIdOrNo()
		},
		
		// 登陆成功，跳转地址 订单详情
		getPathUrl(orderType,orderNo){
			let pathUrl = '';
			if(orderType){
				pathUrl =  this.$dict.detailConfig(orderType)
			}else if(orderNo){
				let shortOrderNo =  orderNo.substr(0,2);
				pathUrl = this.$dict.detailConfigByChat(shortOrderNo)
			}
			return pathUrl
		
		},
		
		// 获取单点登录信息
		getSsoLogin(){
			this.isShowLoginPage = false;
			var routeParamObj = this.$common.getParams() || {};
			this.$common.sessionset('reqParams',routeParamObj);
			 if(routeParamObj['loginType']){// 【1】 从第三方web网站单点过来  // 天润+壳牌+标准单点登录
				// 方法来自于 loginMixin
				this.standardSSO(routeParamObj,(res)=>{
					
				},(err)=>{
					this.$alert(err.tips,'登录失败',{
						type:"error"
					})
					this.isShowLoginPage = true;
				})
			}else if(routeParamObj['searchType']){ // 【2】 从第三方web网站单点过来 //中国中铁
				// 方法来自于 loginMixin
				this.crecgSSO(routeParamObj,(res)=>{
					
				},(err)=>{
					this.$alert(err.tips,'登录失败',{
						type:"error"
					})
					this.isShowLoginPage = true;
				})
				// 【3】： 从etsmsweb 管理端单点过来的，此时token 为共用的
				// window.location.href.indexOf('refresh')> -1
				// routeParamObj['refresh'] == 1
			}else if(window.location.href.indexOf('refresh')> -1){
				if(this.$common.sessionget('access_token')){
					// 方法来自于 loginMixin
					this.getUserInfo({},{},(res)=>{
						
					},(err)=>{
						this.$alert(err.tips,'登录失败',{
						type:"error"
					})
						this.isShowLoginPage = true;
					})
				}else{
					this.isShowLoginPage = true;
				}
				
			}else{ // 否则正常登录
				this.isShowLoginPage = true;
			}
			
			
		},
		
		
		// 根据出差单号或者id，获取出差单详情
		getCcsqdDetailByCcsqdIdOrNo(){
			var reqParams = this.$common.sessionget('reqParams') || {};
			if(!reqParams.approvalNo){
				return false
			}
			let obj = {
				ccsqdId:reqParams.approvalNo
			}
			this.$conn.getConn('travelRequest.travelRequestInfo')(obj,(res)=>{
				
				var ccsqdDetail = res.data || {};
				var ccsqdId = ccsqdDetail.id || reqParams.approvalNo;
			
				this.chuLiRouteByCcsqd(ccsqdId,ccsqdDetail)
			},(err)=>{
				
			})	
				
		},
		/**
		 *  let obj = {
					cxrList: [], //出行人列表
					detail: {}, //出差申请单信息
					proType: this.proType,
					currentRoute: {, //多选行程中的其中一项
					routeId: '', //出行id
					ccsqdNo:'',
					ccsqdId:'',
				}
		 ***/ 
		// 处理出差行程问题
		chuLiRouteByCcsqd(ccsqdId, ccsqdDetail, proType, callback) {
			var reqParams = this.$common.sessionget('reqParams') || {};
			var obj = {}; // 定义ccsqdObj
			obj.ccsqdId = ccsqdId;
			obj.ccsqdNo = ccsqdDetail.ccsqdNo;
			obj.detail = ccsqdDetail;
			obj.proType = proType || '';
			obj.cxrList = [];
			
			var routeObj = {}; // 指定行程详情
			var routeList = ccsqdDetail.allRouteList || [];
		
			//  默认取  如果有行程的，取改行程的行程！
			for (var k = 0; k < routeList.length; k++) {
				var routeItem = routeList[k][0] || {};
				if (routeItem.routeId == reqParams.routeId) {
					obj.routeId = reqParams.routeId;
					routeObj = routeItem;
					break;
				}
			}
		
			// 否则  如果没有行程id  取该产品 第一条行程
			if (!this.$common.isObjNotBlank(routeObj)) {
				for (var k = 0; k < routeList.length; k++) {
					for (var i = 0; i < routeList[k].length; i++) {
						var routeItem = routeList[k][i] || {};
						if (routeItem['proType'] == proType || routeItem['proType'] == '---') {
							obj.routeId = routeItem.routeId;
							routeObj = routeItem;
		
							break;
						}
					}
					if (i != routeList[k].length) {
						break;
					}
		
		
				}
			}
		
		
			ccsqdDetail.currentRoute = routeObj;
			
		
			var zongPeopleList = ccsqdDetail.personList || [];
			// 存储出行人
			var cxrIdArr = routeObj.cxrId ? routeObj.cxrId.split(',') : [];
			var cxrArr = routeObj.cxr ? routeObj.cxr.split(',') : [];

			var newList = [];
		
			for (var i = 0; i < zongPeopleList.length; i++) {
				var isAdd = false;
				zongPeopleList[i]['choosed'] = true;
				var peopleItem = zongPeopleList[i];
				peopleItem['cxrType'] = zongPeopleList[i]['exPerson'] == 1 ? '2' : '1';
				peopleItem['idcardList'] = peopleItem.vipIdcardResponses || [];
		
				ccsqdDetail.costCenter = ccsqdDetail.costCenter || [];
				for (var j = 0; j < ccsqdDetail.costCenter.length; j++) {
					var cost = ccsqdDetail.costCenter[j] || {};
					if (cost.costId == peopleItem.costId) {
						peopleItem.costCenterCode = cost.costCenterCode || ''; // 成本中心代码 ,
						peopleItem.costCenterId = cost.costId || ''; // 成本中心id ,
						peopleItem.costCenterName = cost.costCenterName || ''; // 成本中心名称 ,
						break;
					}
				}
		
				if (!peopleItem.costCenterId) {
					peopleItem.costCenterCode = ccsqdDetail.costCenterCode || ''; // 成本中心代码 ,
					peopleItem.costCenterId = ccsqdDetail.costCenterId || ''; // 成本中心id ,
					peopleItem.costCenterName = ccsqdDetail.costCenterName || ''; // 成本中心名称 ,
				}
		
				for (var k = 0; k < cxrArr.length; k++) {
					if (peopleItem['empId'] == cxrIdArr[k] && peopleItem['cxrName'] == cxrArr[k]) {
						isAdd = true;
						break;
					}
				}
		
				for (var j = 0; j < newList.length; j++) {
					if (newList[j]['empId'] == peopleItem['empId'] && newList[j]['cxrName'] == peopleItem['cxrName']) {
						isAdd = false;
						break;
		
					}
				}
		
				if (isAdd) {
					newList.push(peopleItem)
				}
		
			}
		
		
			obj.cxrList = newList;
			this.$common.sessionset('chooseCcsqdObj',obj)
		},
		
		
		
		// 初始化页面风格
		initPageSet(){
			let oldData =   this.configData;
			let newData = {
					compaySuper: this.configData.compId,
					corpId: '',
					corpName: this.configData.corpFullName,
					corpNo: '',
					domainName: this.configData.apiurl,
					etBrowserLogo: this.configData.logoImg || this.configData.defaultLogoImg,
					etBrowserName: this.configData.corpName,
					etCopyrightInformation: this.configData.icp,
					etServicePhone: this.configData.officalPhone,
					id: '',
					logoUrl: this.configData.logoImg || this.configData.defaultLogoImg,
					pageName: this.configData.corpName,
					qrCode: '',
					carouselPictures: [],
					serverPictures: [],
					tSysStyle: 1,
			};
			
			this.compInfo ={
				...oldData,
				...newData
			}
			this.$common.sessionset('pageSettingInfo',this.compInfo)
		},
		
		
		/**
		 *  获取页面风格配置
		 *
		 **/ 
		// 获取登录页面风格
		getEtPageSetList(){
			var hostName = window.location.hostname;  //域名
			let queryString = {}
				queryString = {
						compaySuper:this.configData.compId,
						domainName: hostName, //'39.106.172.100' 测试时用, hostName
						sysType: "etsmsobt"
					}
			this.$conn.getConn('commu.etPageSetList')(queryString,(res)=>{
				let data = res.data || {};
				this.getCompaySuper(data);
				
				
			},(err)=>{
				this.getCompaySuper();
				// this.$message.error(err.errMsg)
			})
			
		},
		// 设置页面登录风格
		getCompaySuper(data){
			if(data.compaySuper){
				this.compInfo = {
					compaySuper: data.compaySuper,
					corpId: data.corpId,
					corpName: data.corpName,
					corpNo: data.corpNo,
					domainName: data.domainName,
					etBrowserLogo: data.etBrowserLogo,
					etBrowserName: data.etBrowserName,
					etCopyrightInformation: data.etCopyrightInformation || this.configData.icp, //暂时给个备案号
					etServicePhone: data.etServicePhone,
					id: data.id,
					logoUrl: data.logoUrl,
					pageName: data.pageName,
					qrCode: data.qrCode,
					carouselPictures: data.carouselPictures,
					serverPictures: data.serverPictures,
					tSysStyle: data.tSysStyle,
				}
			}else{
				this.initPageSet();	
			}
			this.$common.sessionset('pageSettingInfo',this.compInfo)
			//默认修改标题
			this.$common.changeTitle(this.compInfo.etBrowserName);
			if( this.compInfo.etBrowserLogo){
				document.querySelector('link[rel*="icon"]').href = this.compInfo.etBrowserLogo || '';
			}
		},
		
		
		
	}

}


export default loginMixin
