<template>
	<div class="trp-main">
		<div v-if="orderType==11010">
			<div class="marTop25">
				<span class="title">退票订单已提交，
					<span>{{refundChangeInfo.refundStatusDesc?refundChangeInfo.refundStatusDesc:'等待办理'}}</span>
				</span>
			</div>
			<div class="gq-top">
				<div>
					退票行程：{{refundChangeInfo.fromDate}} ({{refundChangeInfo.fromWeek}}) {{refundChangeInfo.fromCityName}} 至 {{refundChangeInfo.toCityName}}
				</div>
				<div class="marTop20">
					<span>乘车人：{{refundChangeInfo.passengerName}}</span> 
					<span class="seexq" @click="goToRefundDetail(refundChangeInfo.id)">查看详情</span>
				</div>
			</div>	
		</div>
		<div v-if="orderType==11011">
			<div class="marTop25">
				<span class="title">
					改签订单已提交，
					<span>{{refundChangeInfo.changeStatusName?refundChangeInfo.changeStatusName:'等待办理'}}</span>
				</span>
			</div>
			<div class="gq-top">
				<div>改签行程：{{refundChangeInfo.newToDate}} ( {{refundChangeInfo.newFromWeek}} ) {{refundChangeInfo.newFromStationName}} 至 {{refundChangeInfo.newToStationName}}</div>
				<div class="marTop20">
					<span>乘车人：{{refundChangeInfo.passengerNames}}</span>
					<span class="seexq" @click="goToChangeDetail(refundChangeInfo.changeId)">查看详情</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'TrPage',
		props:{
			refundChangeInfo:{
				type:Object,
				default(){
					return {};
				}
			},
			orderType:{
				type:String,
				default:'',
			}
		},
		mounted(){
			console.log(this.refundChangeInfo)
		},
		methods:{
			goToChangeDetail(changeId){
				this.$emit('goToChangeDetail',changeId)
			},
			goToRefundDetail(refundId){
				this.$emit('goToRefundDetail',refundId)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.trp-main{
		.gq-top{
			margin: 40px 0;
		}
		.gq-top-i{
			margin: 20px 0 40px 0;
		}
		.marTop20{
			margin-top: 20px;
		}
		.marTop25{
			margin-top: 25px;
		}
		.title{
			font-size: 24px;
			color: #006BB9;
		}
		.seexq{
			cursor: pointer;
			color: #006BB9;
			margin-left: 20px;
		}
		.seexq:hover{
			color: #007FE9;
		}
	}
</style>