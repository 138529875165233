<template>
	<div class="nma-main">
		<el-card>
			<div class="flex between">
				<div class="setSelect flex flexStart">
					<div class=" selectOrder">
						<p class="marB10 colorB3 textLeft">行程信息</p>
						<el-select v-model="dataParams.product" placeholder="全部行程" @change="getChangeXc">
							<el-option v-for="item in productTypeList " :key="item.id" :label="item.text"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="setDateInput  selectOrder textLeft ">
						<p class="marB10 colorB3">订单编号</p>
						<el-input v-model="dataParams.orderNo" placeholder="订单编号" @change="getOrderNo"></el-input>
					</div>
					<div class="setDateInput2 marR40 textLeft">
						<p class="marB10 colorB3">出行人</p>
						<el-input v-model="dataParams.person" placeholder="出行人" @change="getPerson"></el-input>
					</div>
					<div class="flex flexEnd">
						<el-button type="primary" class="button" @click="query">查 询</el-button>
						<el-button plain @click="clear" class="button">清 空</el-button>
					</div>
				</div>

			</div>
		</el-card>
		<div class="setTabPaneCard marT20">
			<el-tabs type="border-card" v-model="allType" @tab-click="getChooseTable">
        <!--待我审批-->
				<el-tab-pane :label="'待我审批'+'( '+dwPageInfo.total+' )'">
					<el-table :data="dwApprovalList" v-loading="loading"
						element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
						element-loading-spinner="el-icon-loading">
						<el-table-column type="index" align="center" width="35">
						</el-table-column>
						<el-table-column label="行程类型" width="100">
							<template slot-scope="scope">
								<div class="flex">
									<p>{{configType[scope.row.orderType].text}}</p>
									<i v-if="scope.row.wbBg == 1" class="iconfont yj-icon-weizhang" style="font-size:20px;color:red;margin-left: 5px;" ></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="行程" width="190">
							<template slot-scope="scope">
								<div v-if=" scope.row.orderType == 11010 || scope.row.orderType == 11009 || scope.row.orderType == 11011 ||
											scope.row.orderType == 11001 || scope.row.orderType == 11002 || scope.row.orderType == 11003">
									出发/到达城市：{{scope.row.departCity}} - {{scope.row.arriveCity}}
								</div>
								<div v-if=" scope.row.orderType == 11007 || scope.row.orderType == 11008">
									入住离店城市：{{scope.row.departCity}}
								</div>
								<div v-if=" scope.row.orderType == 11098 || scope.row.orderType == 11099">
									目的地：{{scope.row.mdd || scope.row.manyMdd}}
								</div>
								<div>订单编号：<span class="colorL pointer"
										@click="goToDetail(scope.row)">{{scope.row.orderNo}}</span></div>
								<div>出差事由：{{scope.row.ccsy}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="person" label="出行人" width="85"></el-table-column>
						<el-table-column label="订单金额" width="75">
							<template slot-scope="scope">
								<div v-if="scope.row.wbBg==0" style="color:#FF9524;">
									¥{{scope.row.price?scope.row.price:0}}</div>
								<div v-if="scope.row.wbBg==1" style="color:#FF9524;" class="el-icon-warning">
									¥{{scope.row.price?scope.row.price:0}}</div>
							</template>
						</el-table-column>
						<el-table-column label="出发/到达/入住时间" width="180" show-overflow-tooltip>
							<template slot-scope="scope">
								<div>{{scope.row.departDate}} 至 {{scope.row.endDate}}</div>
								<span v-if="scope.row.overdueFlag==1">
									<span style="color:red;">行程过期</span>
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="bookDate" label="提交时间" width="80"></el-table-column>
						<el-table-column prop="spjd" label="审批进度" width="80" align="center"></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<div class="colorL pointer" @click="goToDetail(scope.row)">审批</div>
							</template>
						</el-table-column>
						<div slot="empty">
							<el-empty v-if="dwApprovalList.length==0" description="暂无待我审批数据"></el-empty>
						</div>
					</el-table>
					<el-pagination class="textLeft" background v-if="dwPageInfo.total && !loading"
						@current-change="handleDwChange" :current-page.sync="dwPageInfo.pageNum"
						:page-size="dwPageInfo.count" layout="total, prev, pager, next" :total="dwPageInfo.total">
					</el-pagination>
				</el-tab-pane>
				<!--我已审批-->
        <el-tab-pane :label="'我已审批'+'( '+yspPageInfo.total+' )'">
					<el-table :data="approvalList" style="width: 100%" v-loading="loading"
						element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
						element-loading-spinner="el-icon-loading">
						<el-table-column type="index" align="center" width="45">
						</el-table-column>
						<el-table-column label="行程类型" width="100">
							<template slot-scope="scope">
								<div>
									{{configType[scope.row.orderType].text}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="行程" width="188">
							<template slot-scope="scope">
								<div v-if=" scope.row.orderType == 11010 || scope.row.orderType == 11009 || scope.row.orderType == 11011 ||
											scope.row.orderType == 11001 || scope.row.orderType == 11002 || scope.row.orderType == 11003">
									出发/到达城市：{{scope.row.departCity}} - {{scope.row.arriveCity}}
								</div>
								<div v-if=" scope.row.orderType == 11007 || scope.row.orderType == 11008">
									入住离店城市：{{scope.row.departCity}}
								</div>
								<div v-if=" scope.row.orderType == 11098 || scope.row.orderType == 11099">
									目的地：{{scope.row.mdd || scope.row.manyMdd}}
								</div>
								<div>订单编号：<span class="colorL pointer"
										@click="goToDetail(scope.row)">{{scope.row.orderNo}}</span></div>
								<div>出差事由：{{scope.row.ccsy}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="person" label="出行人" width="115"></el-table-column>
						<el-table-column label="订单金额" width="75">
							<template slot-scope="scope">
								<div v-if="scope.row.wbBg==0" style="color:#FF9524;">
									¥{{scope.row.price?scope.row.price:0}}</div>
								<div v-if="scope.row.wbBg==1" style="color:#FF9524;" class="el-icon-warning">
									¥{{scope.row.price?scope.row.price:0}}</div>
							</template>
						</el-table-column>
						<el-table-column label="出发/到达/入住时间" width="175">
							<template slot-scope="scope">
								<div>{{scope.row.departDate}} 至 {{scope.row.endDate}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="spjd" label="审批进度" width="70"></el-table-column>
						<el-table-column label="审批状态" width="70">
							<template slot-scope="scope">
								<div v-if="scope.row.approveState==1" style="color:#FF9524;">审批中</div>
								<div v-if="scope.row.approveState==2" style="color:#409EFF;">审批通过</div>
								<div v-if="scope.row.approveState==3" style="color:red;">审批拒绝</div>
								<div v-if="scope.row.approveState==4">取消</div>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<div v-if="scope.row.approveState" class="colorL pointer"
									@click="goToDetail(scope.row)">订单详情</div>
							</template>
						</el-table-column>
						<div slot="empty">
							<el-empty v-if="approvalList.length==0" description="暂无已审批数据"></el-empty>
						</div>
					</el-table>
					<el-pagination class="textLeft" v-if="yspPageInfo.total && !loading" background
						@current-change="handleYspChange" :current-page.sync="yspPageInfo.pageNum"
						:page-size="yspPageInfo.count" layout="total, prev, pager, next" :total="yspPageInfo.total">
					</el-pagination>
				</el-tab-pane>
        <!--审批超时-->
        <el-tab-pane :label="'审批超时'+'( '+spccPageInfo.total+' )'">
        	<el-table :data="spccList" v-loading="loading"
        		element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
        		element-loading-spinner="el-icon-loading">
        		<el-table-column type="index" align="center" width="35">
        		</el-table-column>
        		<el-table-column label="行程类型" width="100">
        			<template slot-scope="scope">
        				<div class="flex">
        					<p>{{configType[scope.row.orderType].text}}</p>
        					<i v-if="scope.row.wbBg == 1" class="iconfont yj-icon-weizhang" style="font-size:20px;color:red;margin-left: 5px;" ></i>
        				</div>
        			</template>
        		</el-table-column>
        		<el-table-column label="行程" width="190">
        			<template slot-scope="scope">
        				<div v-if=" scope.row.orderType == 11010 || scope.row.orderType == 11009 || scope.row.orderType == 11011 ||
        							scope.row.orderType == 11001 || scope.row.orderType == 11002 || scope.row.orderType == 11003">
        					出发/到达城市：{{scope.row.departCity}} - {{scope.row.arriveCity}}
        				</div>
        				<div v-if=" scope.row.orderType == 11007 || scope.row.orderType == 11008">
        					入住离店城市：{{scope.row.departCity}}
        				</div>
        				<div v-if=" scope.row.orderType == 11098 || scope.row.orderType == 11099">
        					目的地：{{scope.row.mdd || scope.row.manyMdd}}
        				</div>
        				<div>订单编号：<span class="colorL pointer"
        						@click="goToDetail(scope.row)">{{scope.row.orderNo}}</span></div>
        				<div>出差事由：{{scope.row.ccsy}}</div>
        			</template>
        		</el-table-column>
        		<el-table-column prop="person" label="出行人" width="85"></el-table-column>
        		<el-table-column label="订单金额" width="75">
        			<template slot-scope="scope">
        				<div v-if="scope.row.wbBg==0" style="color:#FF9524;">
        					¥{{scope.row.price?scope.row.price:0}}</div>
        				<div v-if="scope.row.wbBg==1" style="color:#FF9524;" class="el-icon-warning">
        					¥{{scope.row.price?scope.row.price:0}}</div>
        			</template>
        		</el-table-column>
        		<el-table-column label="出发/到达/入住时间" width="180" show-overflow-tooltip>
        			<template slot-scope="scope">
        				<div>{{scope.row.departDate}} 至 {{scope.row.endDate}}</div>
        				<span v-if="scope.row.overdueFlag==1">
        					<span style="color:red;">行程过期</span>
        				</span>
        			</template>
        		</el-table-column>
        		<el-table-column prop="bookDate" label="提交时间" width="80"></el-table-column>
        		<el-table-column prop="spjd" label="审批进度" width="80" align="center"></el-table-column>
        		<el-table-column label="操作">
        			<template slot-scope="scope">
        				<div class="colorL pointer" @click="goToDetail(scope.row)">审批</div>
        			</template>
        		</el-table-column>
        		<div slot="empty">
        			<el-empty v-if="spccList.length==0" description="暂无待我审批数据"></el-empty>
        		</div>
        	</el-table>
        	<el-pagination class="textLeft" background v-if="spccPageInfo.total && !loading"
        		@current-change="handleDwChange" :current-page.sync="spccPageInfo.pageNum"
        		:page-size="spccPageInfo.count" layout="total, prev, pager, next" :total="spccPageInfo.total">
        	</el-pagination>
        </el-tab-pane>
      
      </el-tabs>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'NeedMyApproval',
		data() {
			return {
				overdueFlag: 1, //行程过期
				dataParams: { //查询参数
					product: '',
					person: '',
					orderNo: '',
				},
				allType: 0, // 默认查找第一项
				approvalType: 0, //需要我审批类型 0待我审批  1我已审批

				approvalList: [], // 已审批列表信息
				dwApprovalList: [], //待我审批列表信息
				dwPageInfo: { //待我审批列表分页信息
					count: 10,
					pageNum: 1,
					total: 0,
				},
				yspPageInfo: { // 已审批列表分页信息
					count: 10,
					pageNum: 1,
					total: 0,
				},
        spccList:[], // 审批超时列表
        spccPageInfo: { //审批超时审批列表分页信息
        	count: 10,
        	pageNum: 1,
        	total: 0,
        },
				productTypeList: [{
						text: '全部行程',
						id: '',
					},
					{
						text: '国内机票',
						id: '10901',
					},
					{
						text: '国际机票',
						id: '10902',
					},
					{
						text: '国内酒店',
						id: '10903',
					},
					{
						text: '火车票',
						id: '10904',
					},
					{
						text: '其他',
						id: '10999',
					}
				],
				loading: false, //加载
				configType: {
					11001: {
						text: '国内机票正常单',
						icon: '',
					},
					11002: {
						text: '国内机票退票单',
						icon: '',
					},
					11003: {
						text: '国内机票改签单',
						icon: '',
					},
					11004: {
						text: '国际机票正常单',
						icon: '',
					},
					11005: {
						text: '国际机票退票单',
						icon: '',
					},
					11006: {
						text: '国际机票改签单',
						icon: '',
					},
					11007: {
						text: '国内酒店正常单',
						icon: '',
					},
					11008: {
						text: '国内酒店退单',
						icon: '',
					},
					11022: {
						text: '国际酒店正常单',
						icon: '',
					},
					11023: {
						text: '国际酒店退单',
						icon: '',
					},
					11009: {
						text: '火车票正常单',
						icon: '',
					},
					11010: {
						text: '火车票退票单',
						icon: '',
					},
					11011: {
						text: '火车票改签单',
						icon: '',
					},
					11012: {
						text: '机场服务订单',
						icon: '',
					},
					11020: {
						text: '机场服务退单',
						icon: '',
					},
					11014: {
						text: '代客泊车订单',
						icon: '',
					},
					11019: {
						text: '代办值机',
						icon: '',
					},
					11015: {
						text: '保险订单',
						icon: '',
					},
					110151: {
						text: '保险退保',
						icon: '',
					},
					11018: {
						text: '用车订单',
						icon: '',
					},
					11024: {
						text: '餐饮订单',
						icon: '',
					},
					11097: {
						text: '其他订单',
						icon: '',
					},
					11098: {
						text: '变更单',
						icon: '',
					},
					11099: {
						text: '出差申请单',
						icon: '',
					}
				},
			}
		},
		computed: {
			applyType() {
				let params = '0';
				let query = this.$route.query
				if (query.approvalType) {
					params = query.approvalType;
				}
				return params
			}
		},
		mounted() {
			if (this.approvalType == 0) {
				// 获取待我审批 (需要我审批)
				this.getPendMyApproval();
			} else {
				// 获取已审批	
				this.getIHaveApproved();
			}
      this.getPendMyApproval(2);
			this.getMyApprovalTotal();

			// 接受子页面方法刷新需要我审批
			window['needMyApprovalRefresh'] = (val) => {
				if (val == 1) {
					this.getPendMyApproval();
					this.getMyApprovalTotal()
				}
			}

		},
		watch: {
			applyType: {
				handler(val) {
					this.allType = val
				},
				immediate: true

			}
		},
		methods: {
			// 查询
			query() {
				this.loading = true;
				if (this.approvalType == 0) {
					this.getPendMyApproval();
				} else if (this.approvalType == 1) {
					this.getIHaveApproved();
				}
			},
			// 清除信息
			clear() {
				this.dataParams.product = '';
				this.dataParams.orderNo = '';
				this.dataParams.person = '';
			},
			// 输入单号重置分页
			getOrderNo() {
				this.dwPageInfo.pageNum = 1;
				this.yspPageInfo.pageNum = 1;
        this.spccPageInfo.pageNum = 1;
			},
			//输入人重置分页
			getPerson() {
				this.dwPageInfo.pageNum = 1;
				this.yspPageInfo.pageNum = 1;
        this.spccPageInfo.pageNum = 1;
			},
			// 触发审批类型
			getChooseTable(e) {
				// this.clear();
				this.loading = true;
				if (e.index == 0 || e.index == 2) {
					this.dwPageInfo.pageNum = 1;
					this.getPendMyApproval(e.index);
					this.approvalType = 0;

				} else if (e.index == 1) {
					this.yspPageInfo.pageNum = 1;
					this.approvalType = 1;
					this.getIHaveApproved();

				}
			},
			// 选择分类处理分页初始化
			getChangeXc(e) {
				if (e == 0) {
					this.dwPageInfo.pageNum = 1;
					this.approvalType = 0
				} else if (e == 1) {
					this.yspPageInfo.pageNum = 1;
					this.approvalType = 1
				}
				this.query()
			},
			// 分页
			getChoosePageNum(e) {
				this.pageInfo.pageNum = e;
				this.query()
			},
			// 需要我审批 
			getPendMyApproval(type) {
				let queryParams = {};
        if(type){
          queryParams = {
          	pageNum: this.spccPageInfo.pageNum,
          	count: this.spccPageInfo.count,
          	overdueFlag: 1,
          	product: this.dataParams.product,
          	person: this.dataParams.person,
          	orderNo: this.dataParams.orderNo,
          }
        }else{
          queryParams = {
          	pageNum: this.dwPageInfo.pageNum,
          	count: this.dwPageInfo.count,
          	overdueFlag: "",
          	product: this.dataParams.product,
          	person: this.dataParams.person,
          	orderNo: this.dataParams.orderNo,
          }
        }
				
				this.$conn.getConn('user.myApp', {
					jsfile: false
				})(queryParams).then((res) => {
					let data = res.data || {};
          if(type){
            this.spccPageInfo.count = data.pageSize;
            this.spccPageInfo.pageNum = data.pageNum;
            this.spccPageInfo.total = data.total;
            this.spccList = data.list || [];
          }else{
            this.dwPageInfo.count = data.pageSize;
            this.dwPageInfo.pageNum = data.pageNum;
            this.dwPageInfo.total = data.total;
            this.dwApprovalList = data.list || [];
          }
					
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			//我已审批
			getIHaveApproved() {
				let queryParams = {};
				queryParams = {
					pageNum: this.yspPageInfo.pageNum,
					count: this.yspPageInfo.count,
					product: this.dataParams.product,
					person: this.dataParams.person,
					orderNo: this.dataParams.orderNo,
				}
				this.$conn.getConn('user.myApped', {
					jsfile: false
				})(queryParams).then((res) => {
					let data = res.data || {};
					this.yspPageInfo.count = data.pageSize;
					this.yspPageInfo.pageNum = data.pageNum;
					this.yspPageInfo.total = data.total;
					this.approvalList = data.list || [];
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 去详情页
			goToDetail(row) {
				let query = {};
				// appType 1 待我审批  2我已审批
				let appType = (this.approvalType - 0) + 1;
				query = {
					orderId: row.orderId,
					orderType: row.orderType,
					workItemId: row.workItemId,
					isShowApprove: row.isShowApprove,
					appType: appType,
					overdueFlag: row.overdueFlag,
				}
				let url = this.$dict.detailConfig(row.orderType)
				if (row.orderType == 11099) { //希安康订单详情orderType和正常单详情一样
					url = row.customizeFlag != 2 ? "/ccsqdDetail" : '/xakCcsqdDetail';
				}
				if (url) {
					let routeUrl = this.$router.resolve({
						path: url,
						query: query
					});
					window.open(routeUrl.href, '_blank')
				} else {
					this.$message({
						type: 'error',
						message: '请配置路由'
					})
				}

			},
			// 待我审批
			handleDwChange(e) {
				this.dwPageInfo.pageNum = e;
				this.getPendMyApproval()
				// this.getMyApprovalTotal()
			},
			// 已审批
			handleYspChange(e) {
				this.yspPageInfo.pageNum = e;
				this.getIHaveApproved()
			},
      // 审批超时
      handleSpccChange(e){
        this.spccPageInfo.pageNum = e;
        this.getPendMyApproval(2)
      },
      
			// 先获取审批条数
			getMyApprovalTotal() {
				this.$conn.getConn('user.approvalTotal')({}, (res) => {
					let data = res.data || {};
					this.dwPageInfo.total = data.myAppCount;
					this.yspPageInfo.total = data.myAppedCount;
					this.loading = false;
				}, (err) => {
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.nma-main {
		font-size: 12px;
		font-family: '微软雅黑';
		font-weight: 400;
		color: #B3B3B3;

		/deep/ .el-table .cell {
			padding: 0 5px;
		}

		.el-card.is-always-shadow {
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;
		}

		/deep/ .el-input__inner {
			border: none;
			background-color: #F5F5F5;
			border-radius: 2px;
			height: 40px;
			line-height: 40px;
		}

		.colorL {
			color: #409EFF;
		}

		.pointer {
			cursor: pointer;
		}

		.textLeft {
			text-align: left;
		}

		/deep/ .el-pagination__total {
			background-color: #f4f4f5;
			padding: 0 5px;
			border-radius: 2px;
		}

		.flex {
			display: flex;
		}

		.between {
			justify-content: space-between;
		}

		.flexStart {
			justify-content: flex-start;
		}

		.flexEnd {
			align-items: flex-end;
		}

		.textLeft {
			text-align: left;
		}

		.marB10 {
			margin-bottom: 10px;
		}

		.w150 {
			width: 150px;
		}

		.w195 {
			width: 195px;
		}

		.setSelect {
			.el-select {
				.el-input {
					width: 140px;
				}
			}

			/deep/ .el-input__inner {
				width: 140px;
			}

			/deep/ input::-webkit-input-placeholder {
				color: #B3B3B3;
			}

			/deep/ input::-moz-input-placeholder {
				color: #B3B3B3;
			}

			/deep/ input::-ms-input-placeholder {
				color: #B3B3B3;
			}
		}

		.setTabPaneCard {
			/deep/ .el-card__body {
				padding: 0;
			}

			/deep/ .el-tabs__content {
				padding: 0;
			}
		}

		.setDateInput {
			.el-input {
				width: 150px;
			}

			/deep/ .el-input__inner {
				width: 150px;
			}
		}

		.setDateInput2 {
			.el-input {
				width: 195px;
			}

			/deep/ .el-input__inner {
				width: 195px;
			}
		}

		.colorB3 {
			color: #b3b3b3;
		}

		.marT20 {
			margin-top: 20px;
		}

		.marR40 {
			margin-right: 40px;
		}

		.selectOrder {
			margin-right: 20px;
		}

		.button {
			height: 40px;
			width: 70px;
		}
	}
</style>
