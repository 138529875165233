<template>
	<div class="tri-main">
		<el-card class="marT16">
			<p class="text-left marBtm20 title-name">预订信息</p>
			<div class="tss-tsy" v-if="tripType==1">因公模式无法新增其他乘车人</div>
			<el-form ref="cxrListValidateForm">
				<div class="tri-box marBtm4" v-for="(item, index) in passengerList" :key="index">
					<div v-if="index<isAll?true:false" class="padTop20">
						<div class="tai-box-tit flex marBtm30">
							<span>乘客 {{index+1}}</span>
							<span v-show="tripType==2" class="removeColor pointer" @click="removePassenger(index)">{{item.passengerType=='1'?'移除旅客':'移除儿童'}}</span>
						</div>
						<div class="flex tri-box-content marBtm30">
							<el-form-item class="bfb-w25" label-position="top" label="乘客类型">
								<el-select  class="font15" v-model="item.psgType" @change="getpassengerType(item.psgType, index)" placeholder="请选择乘客类型">
								  <el-option
								    v-for="itemp in passengerTypeList"
								    :key="itemp.id"
								    :label="itemp.text"
								    :value="itemp.id">
								  </el-option>
								 
								</el-select> 
							</el-form-item>
							<el-form-item class="bfb-w65" label-position="top" label="乘客姓名">
								<el-input class="font15" placeholder="请输入乘车人姓名" v-model="item.empName"></el-input> 
							</el-form-item>
						</div>
						<div class="tri-box-content flex text-left marBtm30">
							<el-form-item  class="bfb-w25" label-position="top" label="证件类型">
								<el-select  v-model="item.useCardType" placeholder="请选择证件类型"  @change="getIdType(item.useCardType,index)">
								  <el-option
								    v-for="itemc in idTypeList"
								    :key="itemc.id"
								    :label="itemc.text"
								    :value="itemc.id">
									
								  </el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item  class="bfb-w65" label-position="top" label="证件号码">
								<el-input type="text" class="font15" placeholder="请输入证件号码"
									v-for="(cardItem, cardIndex) in item.idcardList"
									:key="cardItem.idType + cardIndex " 
									v-show="cardItem.idType == item.useCardType" 
									v-model="cardItem.idNo"
									@change="changeIdcardList(cardItem.idNo,item,index,cardIndex,'idNo')"
									>
									
								</el-input>  
							</el-form-item>
						</div>
                        <div  class="tri-box-content flex text-left marBtm30" v-show="item.useCardType != 1">
                            <el-form-item  class="bfb-w25" label-position="top" label="英文姓">
                            	<el-input type="text" class="font15" placeholder="请输入英文姓"
                            		v-for="(cardItem, cardIndex) in item.idcardList"
                            		:key="cardItem.idType + cardIndex " 
                            		v-show="cardItem.idType == item.useCardType" 
                            		v-model="cardItem.firstName"
                            		>
                            	</el-input>  
                            </el-form-item>
                            <el-form-item  class="bfb-w65"  label-position="top" label="英文名">
                            	<el-input type="text" class="font15" placeholder="请输入英文名"
                            		v-for="(cardItem, cardIndex) in item.idcardList"
                            		:key="cardItem.idType + cardIndex " 
                            		v-show="cardItem.idType == item.useCardType" 
                            		v-model="cardItem.lastName"
                            		>
                            	</el-input>  
                            </el-form-item>
                        </div>
						<div  class="tri-box-content flex text-left marBtm30">
							<el-form-item class="bfb-w25" label-position="top" label="手机号">
								<el-input  class="font15" placeholder="请输入乘车人手机号" v-model="item.phoneNumber" @change="getPhoneNumber"></el-input>  
							</el-form-item>
							<el-form-item class="bfb-w65" label-position="top" label="邮箱">
								<el-input  class="font15" placeholder="请输入乘车人邮箱" v-model="item.email" @change="getEmail"></el-input>  
							</el-form-item>
						</div>
						<div v-if="item.useCardType!=1?true:false">
							<div class="marBtm30 tri-box-content flex wrap text-left">
								<el-form-item  class="bfb-w25 marBtm20 hidden" label-position="top" label="出生日期">
									<el-date-picker	
										class="font15"
										align="right"
									    v-model="item.born"
									    type="date"
										@change="chooseStartDate(item.born ,index)"
									    placeholder="请选择出生日期">
									</el-date-picker>
								</el-form-item>
								<div class="bfb-w65 flex wrap">
									<el-form-item class="bfb-w42 " label-position="top" label="证件有效期"
										v-for="(cardItem,cardIndex) in item.idcardList"
										:key="cardItem.idType + cardIndex " 
										v-show="cardItem.idType == item.useCardType" 
										>
										<el-date-picker
											class="font15"
											style="width: 100%;"
										    type="date"
											v-model="cardItem.validDate"
											@change="changeIdcardList(cardItem.validDate,item,index,cardIndex,'validDate')"
										    placeholder="请选择证件有效期">
										</el-date-picker>
									</el-form-item>
									<el-form-item  class="bfb-w42" label-position="top" label="国籍"
										v-for="(cardItem,cardIndex) in item.idcardList"
										:key="cardItem.idType + 'placeOfIssue' " 
										v-show="cardItem.idType == item.useCardType" 
										>
										<el-select  v-model="cardItem.placeOfIssue" placeholder="请选择国籍"
										@change="changeIdcardList(cardItem.placeOfIssue,item,index,cardIndex,'placeOfIssue')"
										>
											<el-option
												v-for="itemgj in countryList"
												:key="itemgj.id"
												:label="itemgj.cName"
												:value="itemgj.id">
											</el-option>
										</el-select>  
									</el-form-item>	
								</div>
							</div>
						</div>
						<div  class="tri-box-content flex text-left marBtm30">
							<el-form-item class="bfb-w25" label-position="top" label="性别">
								<el-select  class="font15" v-model="item.sex" @change="updateCxrList()" placeholder="请选择乘客类型">
								  <el-option
								    v-for="itemp in sexList"
								    :key="itemp.id"
								    :label="itemp.text"
								    :value="itemp.value">
								  </el-option>
								 
								</el-select> 
							</el-form-item>	
						</div>
					</div>
				</div>		
			</el-form>
			<div v-if="passengerList.length>5" @click="selectAll" style="padding: 10px 0 20px 0;">
				<el-button type="text" style="font-size: 14px;">{{isAll==9?'收起':'查看更多'}}</el-button> 
			</div>
			<div class="borderTop" style="padding:40px 20px ;" v-show="tripType==2">
				<el-button class="el-icon-plus w180 marRt20" round size="medium" type="primary" @click="addPassenger"><span style="padding-left:5px;"></span>添加乘车人</el-button>
				<el-button class="el-icon-plus w180 marRt20" round size="medium" type="primary" plain @click="queryCxr"><span style="padding-left:5px;"></span>选择出行人</el-button>
			</div>
		</el-card>
		<yj-cxr-list 
    :visible="cxrVisible" 
    :empInfo="empInfo" 
    :tripType="tripType" 
    :maxLength="9" 
    :cxrList="cxrListForYg" 
    @chooseCxrList="chooseCxrList" 
    @cancleCxrList="closeCxr"></yj-cxr-list>
	</div>
</template>

<script>
	import { mapState, mapMutations} from 'vuex';
	export default{
		name: "TrainCxr",
		data() {
			return {
				
				cxrVisible: false,
				passenger: 1, // 乘车人数量
				passengerList: [], // 乘车人列表
				passengerTypeList: [  // 乘车人类型
					{
						id: '1',
						text: '成人'
					},{
						id: '2',
						text: '儿童'
					}
				],
				sexList: [  // 乘车人类型
					{
						id: '1',
						value: 'M',
						text: '男'
					},{
						id: '2',
						value: 'F',
						text: '女'
					}
				],
				idTypeList: [
					{id: '1',text: '中国居民身份证'},
					{id: '2',text: '护照'},
					{id: '3',text: '港澳通行证'},
					{id: '4',text: '台胞证'},
					{id: '10',text: '外国人永久居留证'},
				],
				countryList:[], // 国家信息列表
				useIdInfo:{ //证件信息
					cxrName: '',
					deptName: '',
					empId: '',
					email: '',
					empName: '',
					empNo: '',
					empRank: '',
					empRankName: '',
					phoneNumber:'',
					idNo:'',
					useCardType: '1',
					psgType: '1',
					validDate: '',
					born:'',
					sex:'',
				},
				
				isAll: 5, // 展示出行人
			}
		},
		computed: {
			...mapState({
				searchData: state => state.train.trainQuery.searchData,
				cxrListCopy: state => state.train.cxrList,		
				
			}),
			tripType() {
				return this.searchData.tripType
			},
			userInfo(){
				let userInfo = this.$common.sessionget('user_info') || {};
				return userInfo
			},
			empInfo() {
				return this.userInfo.empInfo || {};
			},
			vipCorp(){
				return this.userInfo.vipCorp || {};
			},
			 // 员工控件数据
			cxrListForYg(){
				let cxrList =  this.passengerList.filter((cxrItem,cxrIndex)=>{
					return cxrItem.cxrType == 1
				}) || []
				return cxrList
			},
		},
		watch:{
			cxrListCopy:{
				immediate: true, // 立即执行
				handler (val, oldVal) {
					// 处理出行人数据
				  var cxrList = this.$common.deepCopy(val);
				  if(cxrList && cxrList.length>0){
					  this.passengerList = cxrList.map((cxrItem,cxrIndex)=>{
					  	  cxrItem.psgType = cxrItem.psgType || '1';
					  	  cxrItem.useCardType = cxrItem.useCardType || '1';
					  	  return cxrItem
					  })
				  }else{
					  this.passengerList = [];
				  }
				 
				  
				},
				deep: true
			}
			
		},
		mounted() {
			this.queryCountry();
			// 处理初始化数据，未及时更新进缓存中的问题
			this.$nextTick(()=>{
				this.passengerList = this.$common.chuLiIdCardList(this.passengerList,'10904');
				console.log(this.passengerList)
				//乘车人就是出行人， this.passengerList == this.cxrList
				this.SETCxrList(this.passengerList)
			})
		},
		
		methods: {
			...mapMutations({
				SET_Passengerlength:'train/setPassengerlength',
				SETCxrList:'train/setCxrList',
			}),
			// 查看更多
			selectAll(){
				this.isAll =this.isAll==9?5:9;
			},
			// 更新出行人信息  如果传参，就用参数 || 没有就用
			updateCxrList(cxrList) {
				var newCxrList =  cxrList || this.passengerList;
				// 格式化数据结构
				this.SETCxrList(newCxrList)
				this.SET_Passengerlength(newCxrList.length)
				
			},
			// 格式化数据结构
			fommatCxrList(cxrList){
				var newCxrList = this.$common.chuLiIdCardList(cxrList,'10904');
				this.updateCxrList(newCxrList)			
			},
			// 证件出生日期
			chooseStartDate(e,i) {
				let date = this.$dateTime.YMDFormat(e)
				this.passengerList[i].born = date;
				this.updateCxrList();
			},
			
			// 乘客类型
			getpassengerType(e, i){
				this.passengerList[i].psgType = e;
				this.updateCxrList();
			},
			// 证件类型
			getIdType(idType, i){
				this.passengerList[i].useCardType = idType;
				this.updateCxrList();
			},
			//更新出行人证件列表信息
			changeIdcardList(val,item,index,cardIndex,attrName){
				if(attrName == 'validDate'){
					let date = this.$dateTime.YMDFormat(val);
					this.passengerList[index]['idcardList'][cardIndex][attrName] = date;
				}else if(attrName == 'placeOfIssue'){
					this.passengerList[index]['idcardList'][cardIndex][attrName] = val;
					for(var k=0;k<this.countryList.length;k++){
						if(this.countryList[k]['id'] == val){
							this.passengerList[index]['idcardList'][cardIndex]['placeOfIssueName'] = this.countryList[k]['cName'];
							break;
						}
					}		
				}else{
					this.passengerList[index]['idcardList'][cardIndex][attrName] = val;
				}
				
				
				this.updateCxrList();
			},
			// 性别处理
			sexChange(sex, index) {
				console.log(sex,index,'index');
			},
			getPhoneNumber(){
				this.updateCxrList();
			},
			getEmail(){
				this.updateCxrList();
			},
			// 选中国家
			// chooseCountry(e,i){
				
			// },
			// 获取国家信息
			queryCountry(){
				this.$conn.getConn('commu.getBClass')({parNo: 101},(res)=>{
					this.countryList = res.data.list || [];
				})
			},
			
			// 添加乘车人
			addPassenger() {
				let obj = {};
				if(this.passengerList.length < 9 && this.passengerList.length!=0){
					obj = this.$common.deepCopy(this.useIdInfo)
					this.passengerList.push(obj)
				}else if(this.passengerList.length==0){
					obj = this.$common.deepCopy(this.useIdInfo)
					this.passengerList.push(obj)	
					}else{
					this.$message({
						message: '乘车人最多选择9人',
						type:'error'
					})
				}
				this.fommatCxrList(this.passengerList)
				
			},
			// 移除旅客
			removePassenger(index) {
				this.passengerList.splice(index,1);
				this.updateCxrList();
			},
			// 选择出行人
			queryCxr(){
				this.cxrVisible = true;
			},
			// 关闭出行人组件
			closeCxr(){
				this.cxrVisible = false;
			},
			// 获取乘车人信息列表 (出行人)
			chooseCxrList(list){
				let chooseEmpInfoList = list.map((item,index)=>{
              item.email = item.email || '';
              item.empName = item.empName || item.cxrName;
              item.useCardType= item.useCardType || '1';
              item.passengerType =item.passengerType || '1';
              return item
            })
				this.fommatCxrList(chooseEmpInfoList)
				this.cxrVisible = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.tri-main{
		font-family: '微软雅黑';
		.tss-tsy{
			text-align: left;
			font-size: 14px;
			background: #fff2de;
			line-height: 20px;
			padding-left: 10px;
			// width: 300px;
			color: #ffab1c;
			margin:10px 0 30px;
		}
		.el-card.is-always-shadow{
			border: 1px solid #E6E6E6;
			box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
			border-radius: 0;
		}
		/deep/ .el-form-item__label{
			color:#748197;
		}
		.el-card {
			/deep/ .el-card__body{
				padding:30px;
			}
			
		}
		.title-name{
			font-size: 24px;
			font-weight: bold;
			color: #223344;
			line-height: 24px;
		}
		.marRt20{
			margin-right: 20px;
		}
		.w180{
			width: 180px;
		}
		.pointer{
			cursor: pointer;
		}
		.borderTop{
			border-top: 1px dotted #E6E6E6;
		}
		.padTop20{
			padding-top:20px;
		}
		.padBtm20{
			padding-bottom:20px;
		}
		.padlr20{
			padding: 0 20px;
		}
		.marT16{
			margin-top: 16px;
		}
		.text-left{
			text-align: left;
		}
		.marBtm40{
			margin-bottom: 40px;
		}
		.marBtm30{
			margin-bottom: 30px;
		}
		.marBtm20{
			margin-bottom: 20px;
		}
		.marBtm10{
			margin-bottom: 10px;
		}
		.marBtm5{
			margin-bottom: 5px;
		}
		.removeColor{
			color: #4089FF;
		}
		.hidden{
			overflow: hidden;
		}
		.font15{
			font-size: 15px;
		}
		.flex{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		.setLeftInput{
			width: 25%;
		}
		.bfb-w25{
			width: 25%;
		}
		.bfb-w42{
			width: 42%;
		}
		.bfb-w65{
			width: 65%;
		}
		.tri-box{
			.tai-box-tit{
				
			}
			.tri-box-content{
				text-align: left;
				/deep/ .el-input__inner{
					border: 0;
					padding: 0;
					border-radius: 0;
					border-bottom: 1px solid #C4CDDD;
					font-size: 15px;
				}
				/deep/ .el-input__prefix{
					display: none;
				}
				.tri-box-item{
					font-size: 12px;
					font-weight: 400;
					color: #748197;
					line-height: 15px;
				}
			}
		}
			
			
		
	}
</style>
