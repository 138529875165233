// 语言MAP
const homeLanguageMap = {
	// 中文
	cn:{
		cn:'中文',
		en:'英文',
	},
	en:{
		cn:'Chinese',
		en:'English',
	}
}

// let lang = localStorage.langs || 'cn'

export default homeLanguageMap