<template>
	<div class="trp-main">
		<el-card>
			<div class='trp-top'>
				<span class="hcp-title">火车票退票</span>	
				<refund-change-rule :orderType="11010"></refund-change-rule>
			</div>
			<div class="trp-con">
				<div class="trp-con-f" v-for="(item, index) in allData.ticketList" :key="item.empId">
					<el-checkbox v-model="item.checked" 
								:disabled="item.isReturn==0"
								class="checkBox" 
								@change="getTicket(item, index)"></el-checkbox>
					
					<span class="trp-fl1">{{item.passengerName}} (身份证{{item.idCode}})</span>
					<span class="trp-fl2">座席：{{item.seatTypeName}}</span>
					<span class="trp-fl3">座位号：{{item.seatCode}}</span>
					<span class="trp-fl4">票价：¥{{item.ticketPrice}}</span>
				</div>
				<div class="trp-con-t">
					<div class="trp-l1">{{allData.trainCode}}</div>
					<div class="trp-l2" >
						<div class='trp-l2-t'>
							<div class="trp-t-text">{{allData.fromTime}}</div>
							<div class="time-text"></div>
							<div class="trp-t-text">{{allData.toTime}}</div> 
						</div>
						<div class='trp-l2-f'>
							<div class="trp-f-text">{{allData.fromStationName}}</div> 
							<div class="sethx"></div>
							<div class="trp-f-text">{{allData.toStationName}}</div>
						</div> 
					</div>
					<div class="trp-l3">{{allData.fromDate}} {{allData.fromWeek}}</div>
				</div>
				
			</div>
		</el-card>
		<el-card style="margin-top: 20px;" v-if="needApprove">
			<!-- 审批规则 -->
			<div class="setSelect approval-rule">
				<h1 slot="header" class="text-left approval-rule-title">审批规则</h1>
				<el-select
					v-model="chooseSpgz.gzmc" 
					:loading="loadingSpgzList"
					value-key
					automatic-dropdown
					@focus="getSpgzList()"
					@change="getCurrentSpgz" 
					:placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'"		
					class="bfb-w100"
					>
				    <el-option
				      v-for="item in spgzList"
				      :key="item.gzId"
				      :label="item.gzmc"
				      :value="item">
				    </el-option>
				</el-select> 
			</div>
		</el-card> 
	</div>
</template>

<script>
	import RefundChangeRule from './RefundChangeRule';
	export default{
		name: 'TrainRefundPerson',
		data() {
			return {
				chooseList:[], // 选中退票人
				needApprove: false,
				choosePsg: {}, // 选中退票人
				loadingSpgzList: false, 
				spgzList:[], // 审批规则列表
				// 选中的审批规则
				chooseSpgz:{
					gzId:'', // 审批规则id
					gzmc:'', // 审批规则名称
					gzdm:''  // 审批规则代码
				},
				ticketList:[],
				spkzMap:{},
				isWuXuShenPi:true, // 是否无需审批
				empInfo:{},
			}
		},
		props:{
			allData:{
				type: Object,
				default: function () {
				    return {}
				}	
			}
		},
		components:{
			'refund-change-rule':RefundChangeRule,
		},
		created(){
			let userInfo = this.$common.sessionget('user_info') || {};
			this.empInfo = userInfo.empInfo || {};
		},
		watch:{
			allData:{
				handler(val){
					if(val.tripType == 1){
						this.getNeedApprove();
					} 
					// 当退票人只有一人时，默认处理退票人
					if(val.ticketList && val.ticketList.length==1){
						this.getTicket()
					}
				},
				immediate: true
			}
		},
		methods:{
			// 退票是否需要审批
			getNeedApprove(){
				this.$conn.getConn('commu.getUserTravelInfo')({},(res)=>{
					var data = res.data||{};
					var approvalBean = data.approvalBean||{};
					/*
						appBook : 审批属性（预订人）0审批 1全免 ,
						appTravel : 出差审批属性 0审批 1全免 2个免 ,
						spkzMap : 审批控制map格式<单据类型,审批控制对象> ,
						vipSpgzBean: 审批规则对象
						
						1 appBook:1 全免 appTravel:1  全免
						2 看 spkzMap是否有返回值，没有就是免审， 有参数按规则匹配是否需要审批  
						3 needApprove 需要审批才匹配审批规则
						
					*/
					if(approvalBean.appTravel==1 && approvalBean.appBook==1){
						this.needApprove = false;
					}else{
						if(approvalBean.spkzMap && JSON.stringify(approvalBean.spkzMap)!="{}"){
							this.spkzMap = approvalBean.spkzMap;
							this.needApprove = this.spkzMap['11010'].sfkq==1?true:false ;
						}else{
							this.needApprove = false;
						}
						
					}
					
					if(this.needApprove){
						this.getSpgzList();
					}
				},err=>{
					
				})
				
			},
			// 获取选中  退票的火车票订单
			getTicket(){
				this.chooseList = [];
				this.ticketList = [];
				this.allData.ticketList.map((item,index)=>{
					if(item.checked==true){
						var obj = {};
						this.chooseList.push(item.empId);
						obj = {
							ticketId: item.id,
							ticketType: item.changeStatus?2:1,
						}
						this.ticketList.push(obj);
					}
					
					return item
				})
				this.getSpgzList()
					
			},
			// 审批规则处理
			// 审批规则模块
			getSpgzList(){
				let isWei = false;				
				// 退票的员工id
				this.loadingSpgzList = true;
				if(this.needApprove){
					this.getSingleApprovalRules(isWei,this.chooseList);
				}

			},
			// 出差申请单模式下匹配审批规则
			getTravelApprovalRules(isWei,psgArr){
			
				let objParams = {};
				objParams = {
				  "bookEmpId": this.empInfo.empId,
				  "corpId":  this.empInfo.corpId,
				  "fcny":  0,
				  "ifViolate": isWei?1:0,
				  "orderType": 11010, // 单据类型
				  "projectId": '',
				  "routeId": this.allData.routeId,
				  "travelEmpIds": psgArr.join(','),
				}  
				this.$conn.getConn('commu.travelRouteMatchSpgz')(objParams).then((res)=>{
					this.spgzList = res.data || [];
					this.loadingSpgzList = false;
					if(this.spgzList.length == 0 ){
						this.isWuXuShenPi = true;
						this.chooseSpgz = {
							gzId:'',
							gzmc:'',
							gzdm:'',
						}
					}else{
						if(this.spgzList.length==1){
							this.chooseSpgz.gzId = this.spgzList[0].gzid; // 审批规则id
							this.chooseSpgz.gzmc = this.spgzList[0].gzmc; // 审批规则名称
							this.chooseSpgz.gzdm = this.spgzList[0].gzdm; // 审批规则代码
							this.$emit('chooseSpgz',this.chooseSpgz)
						}
						this.isWuXuShenPi = false;
					}
				}).catch((err)=>{
					this.spgzList = [];
					this.isWuXuShenPi = false;
					this.loadingSpgzList = false;
				})
			},
			// 单订模式下批配审批规则
			getSingleApprovalRules(isWei,psgArr){
				let objParams = {};
				objParams = {
				  "bookEmpId": this.empInfo.empId,
				  "corpId":  this.empInfo.corpId,
				  "ifViolate": isWei?1:0,
				  "orderType": 11010, // 单据类型
				  "projectId": '',
				  "travelEmpIds":  psgArr.join(','),
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams).then((res)=>{
					this.spgzList = res.data || [];
					this.loadingSpgzList = false;
					if(this.spgzList.length == 0 ){
						this.isWuXuShenPi = true;
						this.chooseSpgz = {
							gzId:'',
							gzmc:'',
							gzdm:'',
						}
					}else{
						if(this.spgzList.length==1){
							this.chooseSpgz.gzId = this.spgzList[0].gzid; // 审批规则id
							this.chooseSpgz.gzmc = this.spgzList[0].gzmc; // 审批规则名称
							this.chooseSpgz.gzdm = this.spgzList[0].gzdm; // 审批规则代码
							this.$emit('chooseSpgz',this.chooseSpgz)
						}
						this.isWuXuShenPi = false;
					}
				}).catch((err)=>{
					this.spgzList = [];
					this.isWuXuShenPi = false;
					this.loadingSpgzList = false;
				})	
			},
			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
				this.$emit('chooseSpgz',this.chooseSpgz)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
	    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
	    border-radius: 5px;
	}
	.trp-main{
		font-family: '微软雅黑';
		color: #223344;
		.el-card /deep/ .el-card__body{
			padding: 0;
		}
		.trp-top{
			display: flex;
			justify-content: space-between;
			height: 80px;
			text-align: left;
			background-color: #006BB9;
			padding: 0 30px;
			.hcp-title{
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 80px;
			}
			
		}
		.trp-con{
			padding: 30px;
			.trp-con-t{
				display: flex;
				background-color: #F3F5F8 ;
				height: 80px;
				padding:20px 0; 
				box-sizing: border-box;
				margin-bottom: 15px;
				.trp-l1{
					line-height: 40px;
					width: 175px;
					border-right: 1px solid #D1DBE7 ;
					font-weight: bold;
					font-size: 18px;
				}
				.trp-l2{
					width:631px;
					padding: 0 38px;
					border-right: 1px solid #D1DBE7 ;
					.trp-l2-t{
						display: flex;
						.trp-t-text{
							font-weight: bold;
							font-size: 18px;
							width:200px;
						}
						.time-text{
							width: 154px;
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
						}
					}
					.trp-l2-f{
						display: flex;
						.trp-f-text{
							font-size: 14px;
							font-weight: 400;
							line-height: 15px;
							padding-top: 2px;
							width:200px;
						}
						.sethx{
							background-color: #D1DBE7;
							margin-top: 7px;
							width: 154px;
							height: 1px;
						}
					}
				}
				.trp-l3{
					line-height: 40px;
					width: 332px;
				}
			}
		
			.trp-con-f{
				display: flex;
				height: 45px;
				padding: 13px 0;
				background-color: #F3F5F8 ;
				margin-bottom: 15px;
				box-sizing: border-box;
				.checkBox{
					width: 45px;
					line-height: 19px;
					border-right: 1px solid #D1DBE7;
				}
				.trp-fl1{
					width: 354px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl2{
					width: 209px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl3{
					width: 254px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl4{
					width: 274px;
					line-height: 19px;
				}
			}
		}
		.setSelect{
			/deep/ .el-input__inner{
				width: 675px;
				height: 45px;
				background: #F4F9FF;
				border: 3px solid #006BB9;
				border-radius: 1px;
			}
			.text-left{
				text-align: left;
			}
		}
		.approval-rule{
			margin-bottom: 30px;
			text-align: left;
			padding-left: 30px;
		}
		.approval-rule-title{
			margin: 30px 0 15px 0;
		}		
	}
</style>

